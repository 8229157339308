import { IAction } from 'core/interface/action';

export const SAVE_VALUES = 'save-jobs';
export const SAVE_EDIT_VALUES = 'save-edit-jobs';
export const SAVE_SINGLE_VALUES = 'save-single-jobs';
export const SAVE_JOB_PROFILE_CANDIDATE = 'save-job-profile-candidate';

const defaultState = {
  jobs: null,
  singleJob: null,
  jobProfileCandidate: null
};

export const SaveJobs = (value: any | number[]) => ({
  type: SAVE_VALUES,
  payload: value
});

export const SaveEditJobs = (value: any | number[]) => ({
  type: SAVE_EDIT_VALUES,
  payload: value
});

export const SaveSingleJobs = (value: any | number[]) => ({
  type: SAVE_SINGLE_VALUES,
  payload: value
});

export const SaveJobProfileCandidate = (value: any | number[]) => ({
  type: SAVE_JOB_PROFILE_CANDIDATE,
  payload: value
});

const jobsReducer = (state = defaultState, action: IAction<any | number[]>) => {
  switch (action.type) {
    case SAVE_VALUES:
      return {
        ...state,
        jobs: action.payload
      };
    case SAVE_EDIT_VALUES:
      return {
        ...state,
        jobs: action.payload
      };
    case SAVE_SINGLE_VALUES:
      return {
        ...state,
        singleJob: action.payload
      };
    case SAVE_JOB_PROFILE_CANDIDATE:
      return {
        ...state,
        jobProfileCandidate: action.payload
      };
    default:
      return state;
  }
};

export default jobsReducer;
