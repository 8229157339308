import React, { FC } from 'react';
import useLocales from 'hooks/useLocales';

import './Index.scss';

const FreeUpgradePopup: FC = (): JSX.Element => {
  const { t } = useLocales();
  return (
    <div className="upgrade">
      <p className="upgrade__top texts">{t('free_upgrade_text_first')}</p>
      <ul className="upgrade__list">
        <li className="upgrade__list-item texts">
          {t('free_upgrade_invite_text_first')}
        </li>
        <li className="upgrade__list-item texts">
          {t('free_upgrade_invite_text_second')}
        </li>
        <li className="upgrade__list-item texts">
          {t('free_upgrade_invite_text_third')}
        </li>
      </ul>
      <p className="texts">{t('free_upgrade_text_second')}</p>
    </div>
  );
};

export default FreeUpgradePopup;
