import React, { FC } from 'react';
import {
  TableContainer,
  Paper,
  Table as Mtable,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import { ITable } from 'core/interface/table';

import './Index.scss';

const Table: FC<ITable> = ({ columns, data, tableClassname }): JSX.Element => {
  return (
    <TableContainer component={Paper}>
      <Mtable className={tableClassname} sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.key}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow
              className="row"
              key={rowIndex}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {columns.map((column) => (
                <TableCell key={column.key}>{row[column.key]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Mtable>
    </TableContainer>
  );
};

export default Table;
