import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  ActivateCompanyEnum,
  ActivationPageEnum
} from 'core/enum/activationPage';
import CompanyReady from 'app/companyFinishStep';
import { IMe } from 'core/interface/me';
import ActivationWelcome from 'components/feature/activation/welcome/Index';
import useLocales from 'hooks/useLocales';
import { AuthService } from 'services/auth/auth';
import { AccountServce } from 'services/upload/file-upload';
import ReadyToStart from 'components/feature/activation/readyToStart';
import WhoYouAre from 'components/feature/activation/whoYouAre/Index';
import MatchPreferences from 'components/feature/activation/preferences/Index';
import Education from 'components/feature/activation/education/Index';
import Congratulation from 'components/feature/activation/congratulation/Index';
import EducationView from 'components/feature/activation/education-view/Index';
import ActivationCompanyLogo from 'components/feature/activation/companyLogo';
import CompanyInfo from 'components/feature/activation/companyInfo/Index';
import CompanyInfoSecond from 'components/feature/activation/companyInfoSecond/Index';
import ActivationAvatarLogo from 'components/feature/activation/avatarLogo';

import './Index.scss';

const ActivationPage: FC = (): JSX.Element => {
  const [link, setLink] = useState<string>(ActivationPageEnum.WELCOME);
  const [contentToShow, setContentToShow] = useState<JSX.Element>();
  const [user, setUser] = useState({
    name: '',
    surname: ''
  });
  const [companyData, setCompanyData] = useState<any>();
  const { t } = useLocales();
  const me = useSelector((state: IMe) => state.user).user;
  const navigate = useNavigate();

  useEffect(() => {
    if (me) {
      setUser({ name: me.first_name, surname: me.last_name });
    }
  }, [me]);

  useEffect(() => {
    contentToShowHandler();
  }, [link, user]);

  const handleGetStarted = () => {
    if (me?.role != 'candidate') {
      setLink(ActivateCompanyEnum.COMPANY_LOGO);
    } else {
      setLink(ActivationPageEnum.WHO_YOU_ARE);
    }
  };

  const handleOnSubmit = async (): Promise<void> => {
    try {
      const res = await AuthService.getAccount();
      if (!res.is_new && !res.company) {
        navigate('/dashboard');
      } else if (!res.is_new) {
        navigate('/company');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnNextPage = (page: string): void => {
    setLink(() => page);
  };

  const handleInfoChange = async () => {
    const body = {
      is_new: false
    };
    const formData = new FormData();
    for (const key in body) {
      if (key !== 'id') {
        formData.append(key, (body as any)[key]);
      }
    }
    try {
      await AccountServce.updateAccount(me.id, formData);
      handleOnSubmit();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSubmitCompany = async (): Promise<void> => {
    const avatarFormData = new FormData();
    const companyFormData = new FormData();

    for (const key in companyData) {
      if (key === 'avatar') {
        avatarFormData.append(key, (companyData as any)[key]);
      } else if (key !== 'id') {
        companyFormData.append(key, (companyData as any)[key]);
      }
    }

    try {
      const accountUpdate = await AccountServce.updateAccount(
        me.id,
        avatarFormData
      );
      const res = await AuthService.getAccountCompany(
        me.company,
        companyFormData
      );

      if (res && accountUpdate) {
        handleInfoChange();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const contentToShowHandler = (): void => {
    switch (link) {
      case ActivateCompanyEnum.COMPANY_LOGO:
        setContentToShow(
          <ActivationCompanyLogo
            setCompanyData={setCompanyData}
            t={t}
            onSubmit={() => handleOnNextPage(ActivateCompanyEnum.ADD_AVATAR)}
            onBack={() => handleOnNextPage(ActivateCompanyEnum.WELCOME)}
          />
        );
        break;
      case ActivateCompanyEnum.ADD_AVATAR:
        setContentToShow(
          <ActivationAvatarLogo
            setCompanyData={setCompanyData}
            t={t}
            onSubmit={() =>
              handleOnNextPage(ActivateCompanyEnum.NICE_TO_MEET_YOU_FIRST)
            }
            onBack={() => handleOnNextPage(ActivateCompanyEnum.COMPANY_LOGO)}
          />
        );
        break;
      case ActivateCompanyEnum.NICE_TO_MEET_YOU_FIRST:
        setContentToShow(
          <CompanyInfo
            setCompanyData={setCompanyData}
            onBack={() => handleOnNextPage(ActivateCompanyEnum.ADD_AVATAR)}
            onSubmit={() =>
              handleOnNextPage(ActivateCompanyEnum.NICE_TO_MEET_YOU_SECOND)
            }
          />
        );
        break;
      case ActivateCompanyEnum.NICE_TO_MEET_YOU_SECOND:
        setContentToShow(
          <CompanyInfoSecond
            onBack={() =>
              handleOnNextPage(ActivateCompanyEnum.NICE_TO_MEET_YOU_FIRST)
            }
            setCompanyData={setCompanyData}
            onSubmit={() =>
              handleOnNextPage(ActivateCompanyEnum.MAKE_CULTURE_PROFILE)
            }
          />
        );
        break;
      case ActivateCompanyEnum.MAKE_CULTURE_PROFILE:
        setContentToShow(
          <CompanyReady
            t={t}
            onSubmit={() => handleOnNextPage(ActivateCompanyEnum.CONGRATS)}
            onBack={() =>
              handleOnNextPage(ActivateCompanyEnum.NICE_TO_MEET_YOU_SECOND)
            }
          />
        );
        break;
      case ActivationPageEnum.GET_STARTED:
        setContentToShow(<ReadyToStart t={t} />);
        break;
      case ActivationPageEnum.WHO_YOU_ARE:
        setContentToShow(
          <WhoYouAre
            t={t}
            onSubmit={() =>
              handleOnNextPage(ActivationPageEnum.MATCH_PREFERENCES)
            }
          />
        );
        break;
      case ActivationPageEnum.MATCH_PREFERENCES:
        setContentToShow(<MatchPreferences t={t} onClick={handleOnNextPage} />);
        break;
      case ActivationPageEnum.EDUCATION:
        setContentToShow(
          <Education
            educationClassName="edu-activation"
            t={t}
            onClick={handleOnNextPage}
          />
        );
        break;
      case ActivationPageEnum.EDUCATION_VIEW:
        setContentToShow(<EducationView t={t} onClick={handleOnNextPage} />);
        break;
      case ActivationPageEnum.CONGRATS:
        setContentToShow(
          <Congratulation
            t={t}
            onSubmit={() => {
              handleOnSubmitCompany();
              handleOnSubmit();
            }}
          />
        );
        break;
      case ActivationPageEnum.WELCOME:
        setContentToShow(
          <ActivationWelcome
            title={`${t('Welcome')}, ${user?.name} ${user.surname}`}
            message={t('activation_congrats')}
            onGetStarted={handleGetStarted}
            t={t}
          />
        );
        break;
      default:
        setContentToShow(
          <ActivationWelcome
            title={`${t('Welcome')}, ${user?.name} ${user.surname}`}
            message={t('activation_congrats')}
            onGetStarted={handleGetStarted}
            t={t}
          />
        );
    }
  };

  return <div className={`activations activation-pref`}>{contentToShow}</div>;
};

export default ActivationPage;
