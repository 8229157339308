import { IAction } from 'core/interface/action';
import { JobCitiesResultsType } from 'core/interface/job';

export const SAVE_LOCATION = 'save-location';
export const APPEND_LOCATION = 'append-location';

const defaultState: any = {
  cities: null
};

export const SaveLocations = (value: JobCitiesResultsType[]) => ({
  type: SAVE_LOCATION,
  payload: value
});

export const AppendLocations = (value: JobCitiesResultsType[]) => ({
  type: APPEND_LOCATION,
  payload: value
});

const locationReducer = (
  state = defaultState,
  action: IAction<JobCitiesResultsType[]>
) => {
  switch (action.type) {
    case SAVE_LOCATION:
      return {
        ...state,
        cities: action.payload
      };
    case APPEND_LOCATION:
      return {
        ...state,
        cities: [...state.cities, ...action.payload]
      };
    default:
      return state;
  }
};

export default locationReducer;
