import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ErrorMessage,
  Form,
  Formik,
  FormikHelpers,
  FormikValues
} from 'formik';
import { initialValues, validation } from './form';
import { RootState } from 'redux/store';
import {
  SkillsPopup,
  InitialValueInterface,
  InitialValueData
} from 'core/interface/itSkill';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import Level from 'components/Level/Index';
import ActionButton from 'components/ActionButton/Index';
import Button from 'components/Button/Index';
import Image from 'components/image/Image';
import { FormValues } from 'core/interface/form';
import { CandidateService } from 'services/candidate/candidate';
import { SkillService } from 'services/skills/skillService';
import { IAutocompleteItem } from 'core/interface/autocompleteItem';
import Autocomplete from '../Autocomplete/Index';
import { IMe } from 'core/interface/me';

import './Index.scss';

const ITSkills: FC<SkillsPopup> = ({
  t,
  skill,
  handleAdd,
  showAddButton,
  skillType,
  handleClose
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValue, setInitialValue] =
    useState<InitialValueInterface>(initialValues);
  const [currentFormValues, setCurrentFormValues] = useState<FormikValues>();
  const me = useSelector((state: IMe) => state.user).user;
  const { computerSkills } = useSelector(
    (state: RootState) => state.computerSkills
  );

  useEffect(() => {
    getSkills('');
  }, []);

  useEffect(() => {
    const newInitial: InitialValueInterface = {
      skills: skill?.map((item) => ({
        id: item.id,
        computerSkill: item.skill.name,
        description: item.description,
        proficiency: item.proficiency
      }))
    };
    setInitialValue(newInitial);
  }, [skill]);

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    setLoading(true);
    try {
      const user = await CandidateService.getCandidate(me.candidate_id);

      const skillPromises = values.skills.map(
        async (item: InitialValueData) => {
          const currentHardSkills = user?.hard_skills || [];
          const currentSoftSkills = user?.soft_skills || [];

          if (skillType === 'Hard Skills') {
            await CandidateService.updateCandidate(
              {
                hard_skills: [...currentHardSkills, item.computerSkill]
              },
              me.candidate_id
            );
          } else {
            await CandidateService.updateCandidate(
              {
                soft_skills: [...currentSoftSkills, item.computerSkill]
              },
              me.candidate_id
            );
          }
        }
      );

      await Promise.all(skillPromises);
      handleClose && handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      actions.resetForm();
    }
  };

  const handleAddSkill = () => {
    if (handleAdd) {
      handleAdd(currentFormValues);
    }
  };
  const getSkills = (query: string = ''): void => {
    SkillService.getSkillsList(query);
  };

  const handleRemove = async (id: number): Promise<void> => {
    setLoading(true);
    await SkillService.removeSkill(me.candidate_id, id);
    setLoading(false);
  };

  const handleChange = (values: FormikValues): string => {
    setCurrentFormValues(values);
    return '';
  };

  return (
    <div className="skill">
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValue}
        validationSchema={validation(t)}
      >
        {({ setFieldValue, isValid, values, errors, setFieldError }) => {
          return (
            <Form>
              <div className="skills scrollable skills-popup-side">
                {skill?.map((item, index: number) => (
                  <div key={index}>
                    <div className="top">
                      <div className="field input-field">
                        <span className="label">{skillType}</span>
                        <div className="field-and-level">
                          <Autocomplete
                            fieldName="skill"
                            value={item.skill.name}
                            placeholder={skillType}
                            show_location={false}
                            className=""
                            onInputChange={(e) => {
                              getSkills(e);
                              setFieldValue(
                                `skills[${index}].computerSkill`,
                                e
                              );
                            }}
                            options={
                              computerSkills?.map(
                                (item: IAutocompleteItem) => ({
                                  id: item.id,
                                  title: item.name
                                })
                              ) || []
                            }
                            values={computerSkills}
                            onFocus={(e) => {
                              getSkills(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <span
                          className={`label ${
                            errors.skills &&
                            (errors.skills[index] as any)?.proficiency
                              ? 'label-err'
                              : ''
                          }`}
                        >
                          proficiency
                        </span>
                        <div className="level">
                          <Level
                            onClick={(level: number) =>
                              setFieldValue(
                                `skills[${index}].proficiency`,
                                level
                              )
                            }
                            isReadonly={false}
                            level={item.proficiency}
                          />
                          {!showAddButton && (
                            <span
                              onClick={() => handleRemove(item.id)}
                              className="remove"
                            >
                              <Image name="trash.svg" />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="field">
                      <span className="label">Description</span>
                      <FormField
                        inputClassName="input"
                        fieldName={`skills[${index}].description`}
                        type={InputType.text}
                        placeholder={skillType}
                      />
                      <ErrorMessage
                        className="fmessage"
                        name={`skills[${index}].description`}
                        component="div"
                      />
                    </div>
                  </div>
                ))}
              </div>
              {showAddButton && (
                <div className="add-btn">
                  <ActionButton iconName="plus.svg" onClick={handleAddSkill} />
                  <span className="add-skill">Add Skill</span>
                </div>
              )}

              {handleChange(values)}
              <Button
                disabled={loading}
                isLoading={loading}
                t={t}
                type="submit"
                title="Save"
                className={`save ${!showAddButton ? 'no-margin' : ''} ${
                  !isValid ? 'invalid-btn' : ''
                }`}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ITSkills;
