import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik, FormikHelpers } from 'formik';
import { ProfileService } from 'services/profile/profile';
import { validationSchema } from './form';
import { IMatchProfileReqBody } from 'core/interface/matchProfile';
import useLocales from 'hooks/useLocales';
import Autocomplete from 'components/shared/Autocomplete/Index';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import Button from 'components/Button/Index';
import { JobService } from 'services/jobs/job';
import { AuthService } from 'services/auth/auth';
import { RootState, dispatch } from '../../../../redux/store';
import { SaveCompanies } from '../../../../redux/slices/company';
import { IMe } from 'core/interface/me';

import './Index.scss';

const MatchProfilePopup = ({ onSubmit }: any): JSX.Element => {
  const { companies } = useSelector((state: RootState) => state.compony);
  const { t } = useLocales();
  const me = useSelector((state: IMe) => state.user).user;
  const [selectedIndustry, setSelectedIndustry] = useState<any>(
    companies?.industry
  );
  const { industry: industries } = useSelector(
    (state: RootState) => state.industries
  );

  const { cities } = useSelector((state: RootState) => state.locations);

  const getIndustries = async (query?: string): Promise<void> => {
    ProfileService.getIndustries(query);
  };

  const handleSubmit = async (
    values: IMatchProfileReqBody,
    actions: FormikHelpers<IMatchProfileReqBody>
  ): Promise<void> => {
    const formData = new FormData();
    for (const key in values) {
      if (key !== 'id') {
        formData.append(key, (values as any)[key]);
      }
    }
    formData.delete('logo');
    try {
      const res = await AuthService.getAccountCompany(me.company, formData);

      if (res) {
        dispatch(SaveCompanies(res));
        onSubmit();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCities = async (q: string, limit: number = 10) => {
    JobService.getLocations({
      limit,
      q
    });
  };
  console.log(cities, 'cities');

  const handleLoadMoreCities = () => {
    const params = { limit: 10, offset: cities.length };
    JobService.getLocations(params, true);
  };

  return (
    <div className="matchPopup">
      <Formik
        enableReinitialize
        initialValues={companies}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(t)}
      >
        {({ setFieldValue, isValid }) => (
          <Form>
            <div className="field">
              <span className="label">Industry</span>
              <Autocomplete
                className="auto"
                fieldName="industry"
                value={
                  industries?.find(
                    (industry) => industry?.id === +selectedIndustry
                  )?.name ?? ''
                }
                options={
                  industries?.map((e) => ({
                    id: e?.id,
                    title: e?.name
                  })) || []
                }
                show_location={false}
                placeholder={t('Industry')}
                onChange={(e) => {
                  setFieldValue(
                    'industry',
                    industries?.find((industry) => industry.name === e)?.id ??
                      ''
                  );
                  setSelectedIndustry(e);
                }}
                onInputChange={(e) => getIndustries(e)}
                values={industries}
                onFocus={(e) => {
                  getIndustries(e);
                }}
              />
            </div>
            <div className="field">
              <span className="label">Name account holder</span>
              <FormField
                inputClassName="input"
                fieldName="name"
                placeholder="Geert Jan"
                type={InputType.text}
              />
            </div>
            <div className="field">
              <span className="label">Job title</span>
              <FormField
                inputClassName="input"
                fieldName="contact_job_title"
                placeholder="title"
                type={InputType.text}
              />
            </div>
            <div className="field">
              <span className="label">Company address</span>
              <Autocomplete
                fieldName="address"
                className="auto"
                value={companies.address}
                options={
                  cities.map((e: any) => ({
                    id: e?.id,
                    title: e?.display_name
                  })) || []
                }
                show_location={true}
                placeholder={t('Company address')}
                onChange={(e) => {
                  setFieldValue(
                    'address',
                    cities.find((city: any) => city.display_name === e)
                      ?.display_name ?? ''
                  );
                }}
                onInputChange={(e) => getCities(e)}
                values={cities}
                onFocus={(e) => getCities(e)}
                loadMore={handleLoadMoreCities}
              />
            </div>
            <Button
              type="submit"
              t={t}
              title="Save"
              className={`${isValid ? 'valid' : 'invalid'} save`}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MatchProfilePopup;
