import { JobCardActionEnum } from 'core/enum/jobCardAction';
import { IJobCard } from 'core/interface/jobCard';

const jobCards: IJobCard[] = [
  {
    id: -1,
    icon: 'amazon.svg',
    sub_title: 'Recrout',
    title: 'Junior Marketeer Digital',
    messageAndResponse: {
      response: 'Oct 18 2023, 10:40 a.m.',
      send: 'Oct 18 2023, 10:40 a.m.'
    },
    actions: [
      JobCardActionEnum.ACCEPT,
      JobCardActionEnum.CANCEL,
      JobCardActionEnum.MORE_INFO,
      JobCardActionEnum.PROFILE_VIEW
    ],
    matches: [
      {
        percent: 90,
        title: 'Talent Match'
      },
      {
        percent: 88,
        title: 'Cultural Fit'
      }
    ],
    profileType: 'Buisness'
  }
];

const STAGE_TYPES = [
  { value: 0, to_show: 'New' },
  { value: 1, to_show: 'In review' },
  { value: 2, to_show: 'Contacted' },
  { value: 3, to_show: 'Interviewed' },
  { value: 4, to_show: 'Offered' },
  { value: 5, to_show: 'Hired' }
];


export const STATUS_TYPES = [
  { value: 0, label: 'Default' },
  { value: 1, label: 'Pending' },
  { value: 2, label: 'Approved' },
  { value: 3, label: 'Denied' },
  { value: 4, label: 'Cancelled' }
];

const contracts = [
  {
    label: 'Interim/Deta',
    value: 1
  },
  {
    label: 'Temporarly/Part-Time',
    value: 2
  },
  {
    label: 'Permanent',
    value: 3
  }
];

export { jobCards, STAGE_TYPES, contracts };
