import React, { FC } from 'react';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import { IActionPages } from 'core/interface/actionPages';

import './Index.scss';

const Congratulation: FC<IActionPages> = ({ t, onSubmit }): JSX.Element => {
  const handleSubmit = (): void => {
    onSubmit();
  };

  return (
    <div className="congrats">
      <Image name="congrats.svg" width={440} height={483} />
      <h1 className="congrats-title">{t('congrats')}</h1>
      <p className="congrats-desc">{t('register_success')}</p>
      <Button
        onClick={handleSubmit}
        className="congrats-continue"
        title="continue"
        t={t}
      />
    </div>
  );
};

export default Congratulation;