import React from 'react';
import { Zoom, Tooltip } from '@material-ui/core';
import {
  BarType,
  CompetencyDataGroup,
  SortedGroupsListType
} from 'core/interface/assessment';

import './Index.scss';

export const MAX_COMPETENCY_SLIDER_RANGE = 9;
export const MIN_COMPETENCY_SLIDER_RANGE = 1;

const TalentOverviewPopup = ({ bars }: BarType) => {
  return (
    <div className="overview">
      <div className="overview__bar">
        {bars?.map(
          ({ groupTitle, list }: CompetencyDataGroup, index: number) => (
            <div key={index}>
              <h6 className="overview__bar_title">{groupTitle}</h6>
              {list?.map((el: SortedGroupsListType, i: number) => (
                <Tooltip
                  arrow
                  placement="left"
                  TransitionComponent={Zoom}
                  title={el.description}
                  key={i}
                >
                  <div key={i} className="bar">
                    <span className="bar__label">{el.label}</span>
                    <div className="bar_box">
                      <span className="min_max">
                        {MIN_COMPETENCY_SLIDER_RANGE}
                      </span>
                      <div className="bar__line">
                        <div
                          className="bar__line-progress"
                          style={{
                            width: `${
                              (el?.value / MAX_COMPETENCY_SLIDER_RANGE) * 100
                            }%`
                          }}
                        >
                          {el.value}
                        </div>
                      </div>
                      <span className="min_max">
                        {MAX_COMPETENCY_SLIDER_RANGE}
                      </span>
                    </div>
                  </div>
                </Tooltip>
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default TalentOverviewPopup;
