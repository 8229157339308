import React, { FC } from 'react';
import { IContentBlock } from 'core/interface/contentBlock';
import ActionButton from '../ActionButton/Index';
import SkeletonLoader from 'components/shared/Skeleton/Index';
import NoData from 'components/shared/NoData/Index';

import './Index.scss';

const ContentBlock: FC<IContentBlock> = ({
  onActionClick,
  title,
  children,
  className,
  buttonIcon,
  secondButton,
  showButton = true,
  showLoader = false,
  noData = false,
  isScrollable = false,
  disableAction = false,
  heigth
}): JSX.Element => {
  const handleAction = (): void => {
    if (onActionClick) {
      onActionClick();
    }
  };

  return (
    <div className={`block ${className}`}>
      {showLoader ? (
        <SkeletonLoader />
      ) : (
        <>
          <div className="block__header">
            <span className="block__header-title">{title}</span>
            <div className="buttons btns">
              {secondButton}
              {showButton && (
                <ActionButton
                  className={disableAction ? 'disabled' : ''}
                  iconName={buttonIcon}
                  onClick={handleAction}
                />
              )}
            </div>
          </div>
          <div
            className={`block__content ${isScrollable ? 'scrollable' : ''}`}
            style={{
              maxHeight: heigth
            }}
          >
            {children} {noData && <NoData />}
          </div>
        </>
      )}
    </div>
  );
};

export default ContentBlock;
