import React from 'react';
import useLocales from 'hooks/useLocales';
import Image from 'components/image/Image';

import './Index.scss';

const PeopleReview = ({ item }: any): JSX.Element => {
  const { t } = useLocales();

  return (
    <div className="reviewCart" >
      <span className="description">{item.description}</span>
      <div className="aboutPerson">
        <Image url={item?.avatar} width={50} height={50} className='round' />
        <p className="fullNameText">{item.first_name} {item.last_name}</p>
        <span className="positionText">{item?.job_title}</span>
      </div>
    </div>
  );
};

export default PeopleReview;
