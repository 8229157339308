import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  IworkExperience,
  IworkExperienceData
} from 'core/interface/workExperience';
import { RootState } from 'redux/store';
import { Form, Formik, FormikHelpers } from 'formik';
import { initialValues, validation } from './form';
import DropDown from 'components/Dropdown';
import { jobContracts } from 'core/constants/workExperience';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import { months, years } from 'core/constants/aboutMePopUpConstants';
import Autocomplete from 'components/shared/Autocomplete/Index';
import Checkboxes from 'components/shared/Checkbox/Index';
import Button from 'components/Button/Index';
import DescriptionCard from 'components/shared/DescriptionCard/Index';
import ActionButton from 'components/ActionButton/Index';
import { ProfileService } from 'services/profile/profile';
import { ISchool, IndustriesData } from 'core/interface/education-side';
import { JobService } from 'services/jobs/job';
import { IWorkExperienceBody } from 'core/interface/work-experience-side';
import { ExperienceService } from 'services/experience/experienceService';
import { IMe } from 'core/interface/me';
import { isValidURL } from 'helpers/isValidURL';
import { getDateFormatter } from 'helpers/dateConverter';
import { contractTypes } from 'core/constants/workType';
import { ILocationRedux } from 'core/interface/education';

import './Index.scss';

const WorkExperiencePopup: FC<IworkExperience> = ({ t }): JSX.Element => {
  const { industry: industries } = useSelector(
    (selector: RootState) => selector.industries
  );
  const [initialValue, setInitialValue] = useState(initialValues);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const me = useSelector((state: IMe) => state.user).user;
  const [timeoutFunc, setTimeoutFunc] = useState<NodeJS.Timeout | null>(null);
  const { experience } = useSelector(
    (state: RootState) => state.workExperiences
  );

  const cities = useSelector(
    (selector: ILocationRedux) => selector.locations?.cities || []
  );
  useEffect(() => {
    setShowForm(experience?.length === 0);
  }, [experience]);

  const handleSubmit = async (
    values: IworkExperienceData,
    actions: FormikHelpers<IworkExperienceData>
  ): Promise<void> => {
    setLoading(true);
    if (!isValidURL(values.company_website)) {
      actions.setErrors({ company_website: 'Invalid URL' });
      return;
    }
    if (!isEdit) {
      setExperience(values);
    } else {
      const body: IWorkExperienceBody = {
        company: values.company_name,
        contract: +values.work_type,
        description: values.description,
        start: `${values.start.year}-${values.start.month}-01`,
        industry:
          industries?.find((ind) => ind.name === values.industry)?.id ?? 0,
        location: values.location,
        end:
          values.finish && Object.values(values.finish).some((e) => e === '')
            ? null
            : values.finish
              ? `${values.finish.year}-${values.finish.month}-02`
              : null,
        title: values.job_title,
        website: values.company_website
      };
      await ExperienceService.updateExperience(
        me.candidate_id,
        initialValue.id,
        body
      );
      setLoading(false);
    }
  };

  const setExperience = async (values: IworkExperienceData): Promise<void> => {
    const body: IWorkExperienceBody = {
      company: values.company_name,
      contract: +values.work_type,
      description: values.description,
      start: `${values.start.year}-${values.start.month}-01`,
      industry: Number(
        industries?.find((ind) => ind.name === values.industry)?.id
      ),
      location: values.location,
      end:
        values.finish && Object.values(values.finish).some((e) => e === '')
          ? null
          : values.finish
            ? `${values.finish.year}-${values.finish.month}-${values.finish.day}`
            : null,
      title: values.job_title,
      website: values.company_website
    };
    if (values.ongoing) {
      body.end = null;
    }
    ExperienceService.setExperience(me.candidate_id, body);
  };

  const handleRemove = (id: number): void => {
    ExperienceService.removeExperience(me.candidate_id, id);
  };

  const handleEdit = (id: number): void => {
    const foundExperience = experience?.find(
      (exp: { id: number }) => exp.id === id
    );
    if (foundExperience) {
      const body: IworkExperienceData = {
        company_name: foundExperience.company,
        company_website: foundExperience.website,
        description: foundExperience.description,
        id: foundExperience.id,
        job_title: foundExperience.title,
        industry: foundExperience.industry.name,
        work_type: String(
          jobContracts.find(
            (contract) => contract.value === foundExperience.contract
          )?.value
        ),
        start: {
          month: getDateFormatter(foundExperience.start, 'MM'),
          year: getDateFormatter(foundExperience.start, 'YYYY'),
          day: getDateFormatter(foundExperience.start, 'D')
        },
        finish: {
          month: getDateFormatter(foundExperience.end, 'MM'),
          year: getDateFormatter(foundExperience.end, 'YYYY'),
          day: getDateFormatter(foundExperience.end, 'D')
        },
        location: {
          display_name: foundExperience.location.display_name,
          id: foundExperience.location.id
        },
        ongoing: !foundExperience.end
      };
      setInitialValue(body);
    }
    setIsEdit(true);
  };

  useEffect(() => {
    getIndustries('', false);
    getCities('');
  }, []);

  const getIndustries = async (
    search: string = '',
    bounce = true
  ): Promise<void> => {
    if (bounce) {
      if (timeoutFunc) {
        clearTimeout(timeoutFunc);
      }
      const timeout = setTimeout(() => {
        ProfileService.getIndustries(search);
      }, 500);
      setTimeoutFunc(timeout);
    } else {
      ProfileService.getIndustries(search);
    }
  };

  const getCities = (query: string = ''): void => {
    JobService.getLocations({
      query
    });
  };

  const handleLoadMoreCities = () => {
    const params = { limit: 10, offset: cities.length };
    JobService.getLocations(params, true);
  };

  return (
    <div className="wexperience scrollable">
      {!showForm &&
        experience?.map(
          (
            item: {
              id: number;
              description: string;
              title: string;
              start: string;
              end: string | undefined;
              location: { display_name: any };
              contract: string | number;
              industry: { name: any };
            },
            index: React.Key | null | undefined
          ) => (
            <DescriptionCard
              handleRemove={(e: number) => handleRemove(e)}
              handleEdit={(e: number) => {
                handleEdit(e);
                setShowForm(true);
              }}
              key={index}
              id={item.id}
              description={item.description}
              title={item.title}
              year_from={item.start}
              year_to={item.end}
              details={[
                {
                  icon: 'location.svg',
                  title: item.location.display_name
                },
                {
                  icon: 'work-progress.svg',
                  title:
                    (jobContracts?.find(
                      (contract) => contract.value === item.contract
                    )?.to_show || '') + ''
                },
                { icon: 'engineering.svg', title: item.industry.name }
              ]}
            />
          )
        )}

      {!showForm && (
        <div onClick={() => setShowForm(true)} className="add-experience">
          <ActionButton
            className="action-add"
            onClick={() => setShowForm(true)}
            iconName="plus.svg"
          />
          <span className="add-experience-title">
            {t('Add Work Experience')}
          </span>
        </div>
      )}

      <div className={`wexperience__add ${showForm ? '' : 'no-visible'}`}>
        <Formik
          enableReinitialize
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          initialValues={initialValue}
          validationSchema={validation(t)}
        >
          {({
            setFieldValue,
            isValid,
            values,
            errors,
            touched,
            setFieldTouched
          }) => {
            return (
              <Form>
                <div className="field">
                  <span className="label">Industry</span>
                  <Autocomplete
                    className="inputs"
                    fieldName="industry"
                    value={values.industry}
                    options={
                      industries?.map((item: ISchool) => ({
                        id: item.id,
                        title: item.name
                      })) || []
                    }
                    placeholder="Industry"
                    show_location={false}
                    onInputChange={(e) => {
                      getIndustries(e);
                      setFieldValue(
                        'industry',
                        industries?.find((ind) => ind.name === e)?.name
                      );
                    }}
                    values={industries}
                    onFocus={(e) => {
                      getIndustries(e);
                    }}
                  />
                </div>

                <div className="job">
                  <div className="left">
                    <div className="field">
                      <span className="label">Job Title</span>
                      <FormField
                        type={InputType.text}
                        fieldName="job_title"
                        placeholder="Job title"
                        inputClassName="input"
                      />
                    </div>
                    <div className="field">
                      <span className="label">Company Website</span>
                      <FormField
                        type={InputType.text}
                        fieldName="company_website"
                        placeholder="Company Website"
                        inputClassName="input"
                      />
                    </div>
                  </div>
                  <div className="right">
                    <div className="field">
                      <span className="label">Company name</span>
                      <FormField
                        type={InputType.text}
                        fieldName="company_name"
                        placeholder="Company Name"
                        inputClassName="input"
                      />
                    </div>
                    <div className="field">
                      <span className="label">Location</span>
                      <Autocomplete
                        className="inputs"
                        fieldName="location"
                        value={values.location.display_name}
                        options={
                          cities.map((item: IndustriesData) => ({
                            id: item.id,
                            title: item.display_name
                          })) || []
                        }
                        placeholder="Location"
                        show_location={false}
                        onInputChange={(e) => {
                          getCities(e);
                          setFieldValue('location', {
                            id: cities.find((city) => city.display_name === e)
                              ?.id,
                            display_name: cities.find(
                              (city) => city.display_name === e
                            )?.display_name
                          });
                        }}
                        values={cities}
                        loadMore={handleLoadMoreCities}
                      />
                    </div>
                  </div>
                </div>

                <div className="field">
                  <span className="label">Description</span>
                  <FormField
                    type={InputType.text}
                    fieldName="description"
                    placeholder="Description"
                    inputClassName="input"
                  />
                </div>
                <div className="field">
                  <span className="label">Work type</span>
                  <DropDown
                    error={
                      errors.work_type && touched.work_type
                        ? errors.work_type
                        : ''
                    }
                    placeholder="Work type"
                    defaultValue={values.work_type}
                    dropdownClassName="dropdown"
                    items={contractTypes}
                    selectedValue={(value) => {
                      setFieldTouched('work_type', true);
                      setFieldValue('work_type', value);
                    }}
                  />
                </div>
                <div className="bottom">
                  <div className="left">
                    <div className="field">
                      <span className="label">Start</span>
                      <DropDown
                        error={
                          errors.start?.month && touched.start?.month
                            ? errors.start?.month
                            : ''
                        }
                        placeholder="Month"
                        defaultValue={values.start.month}
                        dropdownClassName="dropdown month"
                        items={months}
                        selectedValue={(value) => {
                          setFieldTouched('start.month', true);
                          setFieldValue('start.month', value);
                        }}
                      />
                    </div>
                    <DropDown
                      error={
                        errors.start?.year && touched.start?.year
                          ? errors.start?.year
                          : ''
                      }
                      placeholder="Year"
                      defaultValue={values.start.year}
                      dropdownClassName="dropdown year"
                      items={years}
                      selectedValue={(value) => {
                        setFieldTouched('start.year', true);
                        setFieldValue('start.year', value);
                      }}
                    />
                  </div>
                  <div className={`left ${values.ongoing ? 'disabled' : ''}`}>
                    <div className="field">
                      <span className="label">Finish</span>
                      <DropDown
                        dropdownClassName="dropdown month"
                        placeholder="Month"
                        defaultValue={values.finish && values.finish.month}
                        items={months}
                        maxRange={+values.start.month}
                        selectedValue={(value) => {
                          setFieldValue('finish.month', value);
                        }}
                      />
                    </div>
                    <DropDown
                      dropdownClassName="dropdown year"
                      placeholder="Year"
                      items={years}
                      maxRange={+values.start.year}
                      defaultValue={values.finish && values.finish.year}
                      selectedValue={(value) => {
                        setFieldValue('finish.year', value);
                      }}
                    />
                  </div>
                </div>
                <div className="field">
                  <Checkboxes
                    onArrayChange={(e) => {
                      if (Array.isArray(e)) {
                        setFieldValue('ongoing', e[0].isChecked);
                        setInitialValue(() => ({
                          ...values,
                          ongoing: Boolean(e[0].isChecked)
                        }));
                      }
                    }}
                    checkboxes={[
                      {
                        label: 'Ongoing',
                        isChecked: values.ongoing
                      }
                    ]}
                  />
                </div>
                <Button
                  isLoading={loading}
                  t={t}
                  title={`Save`}
                  type="submit"
                  className={`save ${!isValid ? 'invalid-btn' : ''}`}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default WorkExperiencePopup;
