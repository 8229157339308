import React, { FC, useEffect, useState } from 'react';
import { SkillsPopup } from 'core/interface/itSkill';
import ITSkills from '../../ITskill/Index';
import { ISkills } from 'core/interface/skills';
import { FormikValues } from 'formik';

const SkillPopup: FC<SkillsPopup> = ({
  t,
  skill,
  showAddButton = false,
  skillType,
  handleClose
}) => {
  const [skills, setSkills] = useState<ISkills[]>([]);

  useEffect(() => {
    if (skill) setSkills(skill);
  }, [skill]);

  const handleAddSkill = (skills: FormikValues | undefined) => {
    if (skills) {
      const stateSkills: ISkills[] = [];
      skills?.skills.forEach((item: FormikValues) => {
        const newSkills: ISkills = {
          description: item.description,
          id: -1,
          proficiency: item.proficiency,
          skill: {
            id: '',
            name: item.computerSkill
          }
        };
        stateSkills.push(newSkills);
      });
      const newSkills: ISkills = {
        description: '',
        id: -1,
        proficiency: 0,
        skill: {
          id: '',
          name: ''
        }
      };
      setSkills((prev) => [...prev, newSkills]);
    }
  };

  return (
    <div className="itSkills">
      <ITSkills
        skill={skills}
        skillType={skillType}
        t={t}
        handleAdd={(skills) => handleAddSkill(skills)}
        showAddButton={showAddButton}
        handleClose={handleClose}
      />
    </div>
  );
};

export default SkillPopup;
