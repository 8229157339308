import React, { FC, useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import Activation from '../Index';
import { initialValues, validationSchema } from './form';
import {
  IActivationEducation,
  IEducationFormState
} from 'core/interface/activation';
import { days, months, years } from 'core/constants/aboutMePopUpConstants';
import { IAuthSteps } from 'core/interface/authSteps';
import { ActivationPageEnum } from 'core/enum/activationPage';
import DropDown from 'components/Dropdown';
import Checkboxes from 'components/shared/Checkbox/Index';
import Button from 'components/Button/Index';
import { IAutocompleteItem } from 'core/interface/autocompleteItem';
import { ProfileService } from 'services/profile/profile';
import Autocomplete from 'components/shared/Autocomplete/Index';
import { getDateFormatter } from 'helpers/dateConverter';
import { EducationService } from 'services/education/educationService';
import { IMe } from 'core/interface/me';
import { useSelector } from 'react-redux';
import { Degrees } from 'core/constants/degreeConstants';
import { averageGraduation } from 'core/constants/averageGraduation';
import { IDegreeRedux, ISchoolRedux } from 'core/interface/education';
import { RootState, store } from '../../../../redux/store';
import {
  RemoveEducationForm,
  SaveEducationForm
} from '../../../../redux/slices/educationForm';
import { IEducationSideItem } from 'core/interface/education-side';

import './Index.scss';

const Education: FC<IAuthSteps> = ({
  t,
  onClick,
  closePopup,
  showTopSide,
  className,
  educationClassName,
  educationInitialValues,
  isOneBtn = false,
  isPopup = false
}): JSX.Element => {
  const schools =
    useSelector((state: ISchoolRedux) => state.schools).schools || [];
  const [loader, setLoader] = useState<boolean>(false);
  const study = useSelector((state: IDegreeRedux) => state.study).degrees;

  const [formValues, setFormValues] =
    useState<IActivationEducation>(initialValues);
  const me = useSelector((state: IMe) => state.user).user;
  const educationForm = useSelector(
    (state: IEducationFormState) => state.educationForm
  ).educationForm;

  const { educations } = useSelector((state: RootState) => state.educations);

  useEffect(() => {
    if (educationForm) {
      setFormValues(educationForm);
    }
  }, [educationForm]);

  useEffect(() => {
    getSchools();
    getDegrees();
    getEducations();
  }, [me]);

  useEffect(() => {
    if (educationInitialValues) {
      setFormValues(educationInitialValues);
    }
  }, [educationInitialValues]);

  const getEducations = (): void => {
    EducationService.getEducations(me.candidate_id);
  };

  const getSchools = (schools: string = '') => {
    ProfileService.getSchools(schools);
  };

  const getDegrees = (deg: string = '') => {
    ProfileService.getDegrees(deg);
  };

  const saveValuesInState = (values: IActivationEducation) => {
    const { dispatch } = store;
    dispatch(SaveEducationForm(values));
  };

  const handleSubmit = async (
    values: IActivationEducation,
    actions?: FormikHelpers<IActivationEducation>
  ): Promise<void> => {
    setLoader(true);
    let isNull = false;
    if (values.isCurrent) {
      isNull = true;
    } else if (
      !values.isCurrent &&
      Object.values(values.finish).some((e) => e === '')
    ) {
      isNull = true;
    }
    const body = {
      school: schools.find((sch) => sch.name === values.school)?.id,
      study: study.find((studies) => studies.name === values.study_field)?.id,
      degree: values.degree,
      start: `${values.start.year}-${values.start.month}-${values.start.day}`,
      end: !isNull
        ? `${values.finish.year}-${values.finish.month}-${values.finish.day}`
        : null,
      grade: values.average_graduation,
      graduate: values.isGraduated
    } as any;
    if (educationInitialValues && educationInitialValues.id) {
      await ProfileService.editEducation(
        me.candidate_id,
        educationInitialValues.id,
        body
      );
    } else {
      await EducationService.setEducation(me.candidate_id, body);
    }
    RemoveEducationForm();
    actions?.resetForm();
    onClick(ActivationPageEnum.EDUCATION_VIEW);
    closePopup && closePopup();
    setLoader(false);
  };

  return (
    <Activation
      showTopSide={showTopSide}
      className={
        isPopup ? '' : `activation-edu ${className ?? ''} ${className}`
      }
      title="What is your education"
      currentStep={3}
      isPopup={true}
    >
      <div className={`edu ${educationClassName ?? ''}`}>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={validationSchema()}
        >
          {({
            setFieldValue,
            values,
            isValid,
            errors,
            touched,
            setErrors,
            setFieldTouched
          }) => {
            return (
              <Form>
                <div className="edu__top">
                  <div className="field edu-fields">
                    <span className="label">School</span>
                    <Autocomplete
                      fieldName="school"
                      value={values.school}
                      className="inputs"
                      options={
                        schools.map((item: IAutocompleteItem) => ({
                          id: item.id,
                          title: item.name
                        })) || []
                      }
                      placeholder="Schools"
                      show_location={false}
                      onInputChange={(e) => {
                        setFieldValue('school', e);
                      }}
                      values={schools}
                    />
                  </div>
                  <div className="field">
                    <span className="label">Field of study</span>
                    <Autocomplete
                      fieldName="study_field"
                      value={values.study_field}
                      className="inputs"
                      options={
                        study.map((item: IAutocompleteItem) => ({
                          id: item.id,
                          title: item.name
                        })) || []
                      }
                      placeholder="Field of study"
                      show_location={false}
                      onInputChange={(e) => {
                        if (study.find((degree) => degree.name === e)) {
                          setFieldValue('study_field', e);
                        }
                        // else {
                        //   setErrors({ study_field: t('Invalid study_field') });
                        // }
                      }}
                      values={study}
                    />
                  </div>
                </div>
                <div className="field">
                  <span className="label">Educational attainment</span>
                  <DropDown
                    error={
                      touched.educational_attainment &&
                      errors.educational_attainment
                        ? errors.educational_attainment
                        : ''
                    }
                    placeholder="Educational attainment"
                    dropdownClassName="dropdown"
                    defaultValue={values.educational_attainment}
                    items={years}
                    selectedValue={(value) => {
                      setFieldTouched('educational_attainment', true);
                      setFieldValue('educational_attainment', value);
                    }}
                  />
                </div>
                <div className="field">
                  <span className="label">Degrees</span>
                  <DropDown
                    defaultValue={values.degree}
                    error={touched.degree && errors.degree ? errors.degree : ''}
                    placeholder="Degrees"
                    dropdownClassName="dropdown"
                    items={Degrees}
                    selectedValue={(value) => {
                      setFieldTouched('degree', true);
                      setFieldValue('degree', value);
                    }}
                  />
                </div>
                <div className="edu__top date">
                  <div className="edu__top-left left-edu">
                    <div className="field start-field">
                      <span className="label">Start</span>
                      <DropDown
                        error={
                          touched.start?.month && touched.start?.month
                            ? errors.start?.month
                            : ''
                        }
                        placeholder="Month"
                        dropdownClassName="dropdown"
                        defaultValue={values.start.month}
                        items={months}
                        selectedValue={(value) => {
                          setFieldTouched('start.month', true);
                          setFieldValue('start.month', value);
                        }}
                      />
                    </div>
                    <div className="field start-field">
                      <span className="label">&nbsp;</span>
                      <DropDown
                        error={
                          touched.start?.day && touched.start?.day
                            ? errors.start?.day
                            : ''
                        }
                        placeholder="Day"
                        defaultValue={(values.start && values.start.day) || ''}
                        dropdownClassName="dropdown day"
                        items={days}
                        selectedValue={(value) => {
                          setFieldTouched('start.day', true);
                          setFieldValue('start.day', value);
                        }}
                      />
                    </div>
                    <div className="field year-field">
                      <span className="label">&nbsp;</span>
                      <DropDown
                        error={
                          touched.start?.year && touched.start?.year
                            ? errors.start?.year
                            : ''
                        }
                        placeholder="Year"
                        dropdownClassName="dropdown"
                        defaultValue={values.start.year}
                        items={years}
                        selectedValue={(value) => {
                          setFieldTouched('start.year', true);
                          setFieldValue('start.year', value);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={`edu__top-left left-edu no-margin ${
                      values.isCurrent ? 'disabled' : ''
                    }`}
                  >
                    <div className="field start-field">
                      <span className="label">Finish</span>
                      <DropDown
                        error={
                          touched.finish?.month && touched.finish?.month
                            ? errors.finish?.month
                            : ''
                        }
                        placeholder="Month"
                        dropdownClassName="dropdown"
                        defaultValue={values.finish.month}
                        items={months}
                        maxRange={
                          +values.start.month >= +values.finish.month &&
                          +values.start.year >= +values.finish.year
                            ? +values.start.month
                            : undefined
                        }
                        selectedValue={(value) => {
                          setFieldTouched('finish.month', true);
                          setFieldValue('finish.month', value);
                        }}
                      />
                    </div>
                    <div className="field start-field  day-field">
                      <span className="label">&nbsp;</span>
                      <DropDown
                        error={
                          touched.finish?.day && touched.finish?.day
                            ? errors.finish?.day
                            : ''
                        }
                        placeholder="Day"
                        defaultValue={values.finish.day}
                        maxRange={
                          +values.start.month >= +values.finish.month &&
                          +values.start.year >= +values.finish.year
                            ? +values.start.day
                            : undefined
                        }
                        dropdownClassName="dropdown day"
                        items={days}
                        selectedValue={(value) => {
                          setFieldTouched('finish.day', true);
                          setFieldValue('finish.day', value);
                        }}
                      />
                    </div>
                    <div className="field year-drop">
                      <span className="label">&nbsp;</span>
                      <DropDown
                        error={
                          touched.finish?.year && touched.finish?.year
                            ? errors.finish?.year
                            : ''
                        }
                        placeholder="Year"
                        dropdownClassName="dropdown"
                        defaultValue={
                          values.finish.year || formValues.finish.year
                        }
                        items={years}
                        maxRange={+values.start.year}
                        selectedValue={(value) => {
                          setFieldValue('finish.year', value);
                          setFieldTouched('finish.year', true);
                          setFieldValue('finish.year', value);
                          setFormValues(() => ({
                            ...values,
                            finish: { ...values.finish, year: value }
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="field">
                  <Checkboxes
                    onArrayChange={(e) => {
                      if (Array.isArray(e)) {
                        setFieldValue('isCurrent', e[0].isChecked);
                      }
                    }}
                    checkboxes={[
                      { label: 'Current', isChecked: values.isCurrent }
                    ]}
                  />
                </div>
                <div className="field">
                  <span className="label">Average graduation grade</span>
                  <DropDown
                    error={
                      touched.average_graduation && errors.average_graduation
                        ? errors.average_graduation
                        : ''
                    }
                    placeholder="Average graduation grade"
                    dropdownClassName="dropdown"
                    defaultValue={values.average_graduation}
                    items={averageGraduation}
                    selectedValue={(value) => {
                      setFieldTouched('average_graduation', true);
                      setFieldValue('average_graduation', value);
                    }}
                  />
                </div>
                <div className="field">
                  <Checkboxes
                    onArrayChange={(e) => {
                      if (Array.isArray(e)) {
                        setFieldValue('isGraduated', e[0].isChecked);
                      }
                    }}
                    checkboxes={[
                      {
                        label: 'I graduated from this study',
                        isChecked: values.isGraduated
                      }
                    ]}
                  />
                </div>
                <div className="buttons">
                  {!isOneBtn && (
                    <Button
                      className="back button"
                      t={t}
                      title="back"
                      onClick={() => {
                        saveValuesInState(values);
                        onClick(ActivationPageEnum.MATCH_PREFERENCES);
                      }}
                    />
                  )}
                  <div className="nex-btn-s">
                    {!isPopup && educations && educations.length > 0 && (
                      <Button
                        isLoading={loader}
                        className="next button skip"
                        t={t}
                        onClick={() =>
                          onClick(ActivationPageEnum.EDUCATION_VIEW)
                        }
                        title={'Skip'}
                        disableLoading
                      />
                    )}

                    <Button
                      isLoading={loader}
                      className={`next button ${
                        !isValid ? 'invalid-btn' : ''
                      } ${isOneBtn ? 'one-btn' : ''}`}
                      type="submit"
                      t={t}
                      title={isOneBtn ? 'Save' : 'Next'}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Activation>
  );
};

export default Education;
