import { ICurrentLang, IPrivacyPolicyTerms } from '../hooks/type';

export const LANGS: ICurrentLang[] = [
  {
    value: 'en',
    label: 'EN',
    icon1: 'en.svg'
  },
  {
    value: 'nl',
    label: 'NL',
    icon1: 'nl.svg'
  }
];

export const dropDownLabels = [
  [
    { value: '', to_show: '', placeholder: 'Industry' },
    { value: 'teaser', to_show: 'teaser' }
  ],
  [
    { value: '', to_show: '', placeholder: 'Degree Type' },
    { value: 'teaser', to_show: 'teaser' }
  ],
  [
    { value: '', to_show: '', placeholder: 'Computer skill' },
    { value: 'teaser', to_show: 'teaser' }
  ]
];

export const getCareerLevelLabels = (career_level: string[]) => [
  [{ label: '0-1 Year', isChecked: career_level?.includes('1'), value: '1' }],
  [{ label: '1-3 Year', isChecked: career_level?.includes('2'), value: '2' }],
  [{ label: '3-5 Year', isChecked: career_level?.includes('3'), value: '3' }],
  [{ label: '5+ Year', isChecked: career_level?.includes('4'), value: '4' }]
];

export const getSalaryLabels = (salary: string[]) => [
  [{ label: '0-2000', isChecked: salary?.includes('1'), value: '1' }],
  [{ label: '2000-3000', isChecked: salary?.includes('2'), value: '2' }],
  [{ label: '3000-4000', isChecked: salary?.includes('3'), value: '3' }],
  [{ label: '4000-5000', isChecked: salary?.includes('4'), value: '4' }],
  [{ label: '5000+', isChecked: salary?.includes('5'), value: '5' }]
];

export const getCareerLevelByValue = (value: number): string => {
  switch (value) {
    case 1:
      return '0-1 Year';
    case 2:
      return '1-3 Year';
    case 3:
      return '3-5 Year';
    case 4:
      return '5+ Year';
    default:
      return '';
  }
};

export const getSalaryByValue = (value: number): string => {
  switch (value) {
    case 1:
      return '0-2000';
    case 2:
      return '2000-3000';
    case 3:
      return '3000-4000';
    case 4:
      return '4000-5000';
    case 5:
      return '5000+';
    default:
      return '';
  }
};

export const getSalaryByRange = (value: any) => {
  switch (value) {
    case 1:
      return {
        min: 0,
        max: 2000
      };
    case 2:
      return {
        min: 2000,
        max: 3000
      };
    case 3:
      return {
        min: 3000,
        max: 4000
      };
    case 4:
      return {
        min: 4000,
        max: 5000
      };
    case 5:
      return {
        min: 5000,
        max: Infinity
      };
    default:
      return '';
  }
};

export const privacyPolicy: IPrivacyPolicyTerms[] = [
  {
    title: 'Privacybeleid',
    content:
      'Wij waarderen het vertrouwen dat u in ons stelt en wij zullen daarom zorgvuldig met uw gegevens omgaan. Wij respecteren deprivacy van Gebruikers van onze site en dragen er zorg voor dat de persoonlijke informatie die u ons verschaft vertrouwelijkwordt behandeld. Dit privacybeleid is van toepassing op alle diensten en websites die Recrout aanbiedt, verder te noemenAanbieder.'
  },
  {
    title: '01',
    content:
      'Wij gebruiken uw gegevens voor het leveren van onze diensten, aan u, inclusief de weergave van aangepaste inhoud enadvertenties (‘behavioural advertising’); voor controle, onderzoek en analyse doeleinden om onze diensten teonderhouden, te beveiligen en te verbeteren; het veiligstellen van het technisch functioneren van ons netwerk; hetbeschermen van de rechten of het eigendom van Aanbieder of Gebruikers; en het ontwikkelen van nieuwe diensten. In bepaalde gevallen is het mogelijk dat we gegevens verwerken namens of volgens de instructies van derden, zoals onze advertentiepartners. Voor zover wij uw gegevens voor andere doeleinden dan hiervoor omschreven willen gebruiken doen wij dat slechts met uw voorafgaande toestemming.'
  },
  {
    title: '02',
    content:
      '<p>Aanbieder verzamelt voor genoemde doelen de volgende gegevens:</p> <span>Accountgegevens:</span> als u een Account aanmaakt, vragen we u om persoonsgegevens te verstrekken voor het Account waaronder uw naam, e-mailadres, geboortedatum en een wachtwoord. Wij bewaren uw gegevens op beveiligde servers;<br /><br /> <span>Cookies</span>: wanneer u de Website bezoekt, verzenden we een of meer cookies (een klein bestand met een tekenreeks) naar uw computer waaraan uw browser kan worden herkend. We gebruiken cookies om de kwaliteit van onze service te verbeteren, onder andere door gebruikersvoorkeuren op te slaan, de zoekresultaten en advertentierelevantie te verbeteren en gebruikerstrends bij te houden. Aanbieder gebruikt ook cookies in zijn advertentieservices om adverteerders en uitgevers te helpen bij het weergeven en beheren van advertenties op de Website. We plaatsen wellichteen cookie in uw browser wanneer u de website bezoekt of op een advertentie klikt. De meeste browsers zijn aanvankelijk ingesteld om cookies te accepteren, maar u kunt uw browser opnieuw instellen om alle cookies te weigeren of om aan te geven wanneer een cookie wordt verzonden;<br /><br /> <span>Loggegevens</span>: wanneer u de Website opent, leggen onze servers automatisch de gegevens vast die uw browser verzendt wanneer u een website bezoekt. Deze serverlogbestanden bevatten mogelijk gegevens als uw webverzoek, IP-adres, browsertype, browsertaal, de datum en tijd van uw verzoek en een of meer cookies waaraan uw browser kan worden herkend;<br /><br /> <span>Gebruikerscommunicatie</span>: Gebruikerscommunicatie: wanneer u e-mail of andere berichten naar Aanbieder verzendt, is het mogelijk dat we die berichten bewaren om uw vragen te verwerken, uw verzoeken te beantwoorden en onze diensten te verbeteren. <br /><br /> Aanbieder kan uw persoonsgegevens op servers die zich in de Verenigde Staten bevinden (laten) verwerken'
  },
  {
    title: '03',
    content:
      '<p>Aanbieder deelt alleen in de volgende gevallen persoonsgegevens met derden:</p> Als we uw toestemming hiervoor hebben verkregen. We hebben uw toestemming nodig voor het delen van gevoelige persoonsgegevens.<br /> Als we dergelijke gegevens aan onze dochterondernemingen, gelieerde bedrijven of andere vertrouwde bedrijven of personen moeten leveren met het doel de persoonsgegevens namens ons (Aanbieder) te verwerken. We vereisen dat deze partijen ermee instemmen dergelijke gegevens te verwerken volgens onze instructies en in overeenstemming met dit privacybeleid en andere gewenste maatregelen over vertrouwelijkheid en beveiliging. Als we dergelijke gegevens aan gelieerde bedrijven of andere vertrouwde bedrijven of personen leveren met het doel ute voorzien van aangepaste inhoud en advertenties (‘behavioural advertising’).<br /><br /> Als we naar beste weten van oordeel zijn dat het toegankelijk maken, verwerken, gebruiken, bewaren of openbaar maken van dergelijke gegevens redelijkerwijs noodzakelijk is om (a) geldende wet- en regelgeving, wettelijke procedures of een afdwingbaar verzoek van een officiële instantie na te komen, (b) uitvoering aan de toepasselijke voorwaarden te geven, met inbegrip van onderzoek naar mogelijke niet-nakoming daarvan, (c) fraude, technische of beveiligingsproblemen op te sporen, te voorkomen of anderszins af te handelen, of (d) de rechten, eigendom of veiligheid van Aanbieder, zijn Gebruikers en het publiek te beschermen tegen dreigend gevaar voor zover wettelijk toegestaan of vereist.'
  },
  {
    title: '04',
    content:
      'We treffen gepaste beveiligingsmaatregelen als bescherming tegen ongeoorloofde toegang tot of ongeoorloofde aanpassing, openbaarmaking of vernietiging van gegevens. Hieronder vallen interne controles van ons beleid en beveiligingsmaatregelen met betrekking tot het verzamelen, opslaan en verwerken van gegevens, alsook fysieke beveiligingsmaatregelen als bescherming tegen ongeoorloofde toegang tot systemen waarop we persoonsgegevens bewaren.'
  },
  {
    title: '05',
    content:
      'We beperken de toegang tot persoonsgegevens tot die werknemers, contractanten en agenten van Aanbieder die deze gegevens nodig hebben om onze diensten te beheren, te ontwikkelen of te verbeteren. Onze werknemers en door ons ingeschakelde derden zijn verplicht om de vertrouwelijkheid van uw gegevens te respecteren.'
  },
  {
    title: '06',
    content:
      'Aanbieder verwerkt persoonsgegevens alleen voor de doeleinden waarvoor de gegevens zijn verzameld en in overstemming met dit privacybeleid.'
  },
  {
    title: '07',
    content:
      'Wanneer u de Dienst gebruikt, doen we naar vermogen inspanningen om u te voorzien van toegang tot uw persoonsgegevens en om deze gegevens te corrigeren als ze niet correct zijn, of om dergelijke gegevens op uw verzoek te verwijderen als het bewaren van deze gegevens niet anderszins is vereist volgens de geldende wetgeving of voor legale zakelijke doeleinden. <div>We vragen Gebruikers zichzelf en de gevraagde gegevens die toegankelijk gemaakt, gecorrigeerd of verwijderd moeten worden, te identificeren voordat dergelijke verzoeken worden verwerkt, en we kunnen om ons moverende redenen weigeren dergelijke verzoeken te verwerken, onder meer indien die op onredelijke manier herhaaldelijk of systematisch zijn, buitensporige technische inspanningen vergen, de privacy van anderen in gevaar brengen, of die praktisch gezien vrijwel onuitvoerbaar zijn (bijvoorbeeld verzoeken met betrekking tot gegevens die worden bewaard op back-uptapes), of waarvoor toegang normaal gesproken niet is vereist. In alle gevallen waarin we toegang en correctie van gegevens mogelijk maken, voeren we deze service naar onze discretie kosteloos of voor een marktconform tarief uit.</div>'
  }
];

export const termsConditions: IPrivacyPolicyTerms[] = [
  {
    title: '1.0 Definities',
    content:
      'In deze Algemene Voorwaarden worden de in dit artikel opgenomen begrippen met een hoofdletter gebruikt. Onder deze begrippen wordt het navolgende verstaan:<br /><p>Algemene Voorwaarden</p>Deze voorwaarden, ongeacht de vorm waarin deze kenbaar worden gemaakt.<br /><p>Tegoed/Credits</p>Een door Opdrachtgever aangeschaft tegoed of credit waarmee bepaalde Diensten kunnen worden aangeschaft.<p>Diensten</p>De door Recrout aangeboden diensten, zoals onder meer het online plaatsen van vacatures, alsook het verkrijgen van toegang c.q. inzage te krijgen in de door Recrout aangelegde CV- database en profielen en alle andere diensten die voorwerp zijn van enige aanbieding, offerte, overeenkomst of andere rechtshandeling in de relatie tussen Recrout en Opdrachtgever.<p>Gebruiker</p>De Werkzoekende, de Opdrachtgever en elk overig natuurlijk of rechtspersoon die gebruik maakt van (de Diensten van Recrout zoals aangeboden op) de Site.<p>Recrout</p>Recrout, de gebruiker van de Algemene Voorwaarden op www.recrout.com, gevestigd aan de Grote Belt 264, 2133 GJ te Hoofddorp ingeschreven onder KvK-nummer 63509008 met BTW nummer 855266946B01.<p>Opdrachtgever</p>De natuurlijke of rechtspersoon met wie een Overeenkomst wordt gesloten en/of die van de Diensten van Recrout gebruik maakt zoals onder meer aangeboden op de Site.<p>Overeenkomst</p>De overeenkomst tussen Opdrachtgever en Recrout op grond waarvan Recrout Dienst(en) aan Opdrachtgever ter beschikking stelt.<p>Platform en Website</p>De portal via welke Recrout haar diensten aanbiedt en welke toegankelijk is via www.Recrout.com of www.Recrout.nl of www.Recrout.me<p>Werkzoekende</p>De natuurlijke persoon die haar CV online plaatst en/of anderszins van de Diensten van Recrout gebruik maakt zoals aangeboden op de Site.'
  },
  {
    title: '2.0 Algemeen',
    content: ''
  },
  {
    title: '2.1',
    content:
      'In deze Algemene Voorwaarden worden de in dit artikel opgenomen begrippen met een hoofdletter gebruikt. Onder deze begrippen wordt De Algemene Voorwaarden zijn van toepassing op de toegang tot en het gebruik van de Site door Gebruikers, het verlenen van Diensten door Recrout aan haar Opdrachtgever, respectievelijk haar rechtsopvolger, al dan niet vastgelegd in aanbiedingen, offertes, Overeenkomsten van Recrout of voortvloeiend uit andere eventuele rechtshandelingen tussen Recrout en haar Opdrachtgever, respectievelijk haar rechtsopvolger.het navolgende verstaan:'
  },
  {
    title: '2.2',
    content:
      'Eventuele door Gebruiker voorgestelde afwijkingen van de Algemene Voorwaarden zijn slechts geldig indien deze uitdrukkelijk schriftelijk, dan wel via elektronische weg door Recrout en Gebruiker zijn overeengekomen.'
  },
  {
    title: '2.3',
    content:
      'Recrout heeft te allen tijde het recht de Algemene Voorwaarden te wijzigen of aan te vullen.'
  },
  {
    title: '2.4',
    content:
      'Partijen wijzen uitdrukkelijk de toepasselijkheid van eventuele algemene voorwaarden van Opdrachtgever van de hand.'
  },
  {
    title: '2.5',
    content:
      'Indien deze Algemene Voorwaarden eenmaal van toepassing zijn geweest op een rechtsverhouding tussen Recrout en Opdrachtgever, wordt Opdrachtgever geacht bij voorbaat te hebben ingestemd met de toepasselijkheid van de Algemene Voorwaarden op nadien gesloten en te sluiten Overeenkomsten, inclusief wijzigingen en aanvullingen van de Algemene Voorwaarden.'
  },
  {
    title: '2.6',
    content:
      'Indien en voor zover enige bepaling van de Algemene Voorwaarden nietig wordt verklaard of vernietigd wordt, zullen de overige bepalingen van de Algemene Voorwaarden onverminderd van kracht blijven. Recrout zal dan een nieuwe bepaling vaststellen ter vervanging van de nietige/vernietigde bepaling, waarbij zoveel mogelijk de strekking van de nietige/vernietigde bepaling in acht zal worden genomen.'
  },
  {
    title: '2.7',
    content:
      'Bij tegenstrijdigheid tussen bepalingen uit een Overeenkomst en de Algemene Voorwaarden, prevaleren de eerstgenoemde bepalingen.'
  },
  {
    title: '3.0 Offertes/Overeenkomst',
    content: ''
  },
  {
    title: '3.1',
    content:
      'Alle offertes, inclusief aanbiedingen, van Recrout zijn vrijblijvend, tenzij daarin nadrukkelijk anders is vermeld.'
  },
  {
    title: '3.2',
    content:
      'Opdrachten van Opdrachtgever binden Recrout pas nadat de opdracht door Recrout per e-mail is bevestigd, dan wel nadat Recrout kenbaar heeft gemaakt dat zij met de uitvoering van de Overeenkomst is begonnen.'
  },
  {
    title: '4.0 Positie Recrout',
    content:
      'De Site probeert werkzoekenden en werkgevers/intermediairs tot elkaar te brengen. Recrout is echter nimmer partij bij een eventuele overeenkomst die gesloten wordt tussen dergelijke partijen door gebruikmaking van de Site.'
  },
  {
    title: '5.0 Offertes/Overeenkomst',
    content: ''
  },
  {
    title: '5.1',
    content:
      'Opdrachtgever garandeert dat alle gegevens, waaronder, maar niet beperkt tot, bedrijfsgegevens en bankgegevens, die voor de uitvoering van de Overeenkomst aan Recrout dienen te worden verstrekt volledig, juist en actueel zijn. Daarnaast garandeert Opdrachtgever dat hij (wettelijk) bevoegd is gebruik te maken van de Site en de Diensten af te nemen.'
  },
  {
    title: '5.2',
    content:
      'Opdrachtgever dient er zorg voor te dragen dat de omschrijving van de door hem geplaatste vacature en/of bedrijfsinformatie overeenkomt met de werkelijkheid en derhalve juist, volledig en actueel is en voldoet aan de daartoe gestelde eisen van Recrout. Opdrachtgever mag uitsluitend daadwerkelijk bestaande, concrete en op het moment van plaatsing openstaande vacatures plaatsen op de Site. Opdrachtgever mag niet in de geplaatste vacature verwijzen naar andere vacatures die niet vermeld staan op de Site. Door het plaatsen van de vacature en/of bedrijfsinformatie geeft Opdrachtgever Recrout toestemming deze gegevens op de Site te plaatsen of anderszins aan te wenden voor het verlenen van Diensten.'
  },
  {
    title: '5.3',
    content:
      'Opdrachtgever garandeert dat de door haar geplaatste vacature en/of bedrijfsinformatie geen inbreuk maakt op de (intellectuele eigendoms)rechten van derden.'
  },
  {
    title: '5.4',
    content:
      'Recrout behoudt zich het recht voor (de teksten van) vacatures en/of bedrijfsinformatie (waaronder mede begrepen foto- en/of videomateriaal) in te korten, te wijzigen, te weigeren of te verwijderen van de Site indien dit naar het oordeel van Recrout noodzakelijk of wenselijk is, zonder dat dit op enige wijze kan leiden tot enig recht van Opdrachtgever op schadevergoeding en/of restitutie. Verwijdering zal onder meer kunnen plaatsvinden in de volgende gevallen: * de vacature en/of bedrijfsinformatie is onjuist, onvolledig, niet actueel, niet bestaand en/of niet concreet; * Opdrachtgever heeft een CV op de Site geplaatst; * de vacature bevat een verwijzing naar andere - niet op de Site vermelde - vacatures; * de vacature en/of bedrijfsinformatie maakt inbreuk op de (intellectuele eigendoms)rechten van derden of is anderszins onrechtmatig jegens derden; * de vacature en/of bedrijfsinformatie is in strijd met de in Nederland geldende wetgeving en regelgeving, waaronder mede verstaan, maar niet beperkt tot, de Nederlandse Reclame Code of de NVP sollicitatiecode * strijd met de openbare orde of de betamelijkheid.'
  },
  {
    title: '5.5',
    content:
      'De door Recrout verstrekte inloggegevens zijn Opdrachtgever-gebonden en niet overdraagbaar. Evenwel mag een inlogaccount binnen de eigen organisatie van Opdrachtgever vrij worden gebruikt.'
  },
  {
    title: '5.6',
    content:
      'Opdrachtgever dient zorgvuldig om te gaan met de door Recrout verstrekte inloggegevens.'
  },
  {
    title: '5.7',
    content:
      'Het is Opdrachtgever niet toegestaan om CV’s te plaatsen op de Site.'
  },
  {
    title: '5.8',
    content:
      'De door Recrout gehanteerde voorwaarden zijn van toepassing op vacatures ongeacht of deze handmatig worden geplaatst danwel geautomatiseerd worden aangeleverd.'
  },
  {
    title: '5.9',
    content:
      'Bij een vermoeden van het opgeven van een valse identiteit of onjuiste gegevens kunnen wij om verificatie middelen vragen die de identiteit van Opdrachtgever verifiëren. Bij het niet gehoor geven aan een identificatie verzoek of bij het vaststellen van opgeven van een valse identificatie wordt Opdrachtgever levenslang verwijderd en uitgesloten aan deelname op Recrout en alle aanverwante organisaties. Hierbij wordt de politie ingelicht en gaat Opdrachtgever akkoord met het betalen van 500 euro per dag dat de overtreding duurt of heeft geduurd.'
  },
  {
    title: '6.0 Gegevens Werkzoekende',
    content: ''
  },
  {
    title: '6.1',
    content:
      'Indien Werkzoekende gebruik wenst te maken van de Site, dient Werkzoekende ervoor zorg te dragen dat zijn gegevens, waaronder mede doch niet beperkt tot, naam, adres en woonplaats gegevens en alle informatie met betrekking tot zijn CV volledig, juist en actueel zijn. De door Werkzoekende in zijn CV verstrekte informatie dient relevant te zijn en overeen te stemmen met de doelstelling van de Site. Daarnaast garandeert Werkzoekende dat hij (wettelijk) bevoegd is op enige wijze gebruik te maken van de Site.'
  },
  {
    title: '6.2',
    content:
      'Recrout behoudt zich het recht voor de teksten van de geplaatste CV’s in te korten of te wijzigen. Recrout behoudt zich tevens het recht voor om CV’s te verwijderen van de Site in geval de inhoud van de CV inbreuk maakt op de rechten van derden, waaronder maar niet beperkt tot intellectuele eigendomsrechten.'
  },
  {
    title: '6.3',
    content:
      'Bij een vermoeden van het opgeven van een valse identiteit of onjuiste gegevens kunnen wij om verificatie middelen vragen die de identiteit van gebruiker verifiëren. Bij het niet gehoor geven aan een identificatie verzoek of bij het vaststellen van opgeven van een valse identificatie wordt gebruiker levenslang verwijderd en uitgesloten aan deelname op Recrout en alle aanverwante organisaties. Hierbij wordt de politie ingelicht en gebruiker gaat akkoord met het betalen van 500 euro per dag dat de overtreding duurt of heeft geduurd.'
  },
  {
    title: '7.0 Reikwijdte gebruiksrecht tot inzage CV- en vacaturedatabase',
    content: ''
  },
  {
    title: '7.1',
    content: ''
  },
  {
    title: '7.2',
    content:
      'Opdrachtgever erkent dat Recrout producent is van de vacature- en CV-database in de zin van de Databankenwet en auteursrechthebbende is van de vacature- en CV-database.'
  },
  {
    title: '7.3',
    content:
      'Opdrachtgever mag de vacature- en CV-database slechts gebruiken binnen de eigen onderneming met het doel om nieuwe werknemers voor haar onderneming te werven, zulks enkel voorzover dit is toegestaan op grond van de Databankenwet en overige van toepassing zijnde wettelijke regels.'
  },
  {
    title: '7.4',
    content:
      'Opdrachtgever mag databestanden uit de vacature- en/of CV-database of delen daarvan niet openbaar maken en deze niet kopieren of anderszins verveelvoudigen, wijzigen of aan derden op enige wijze ter beschikking stellen, downloaden en in een eigen database opslaan of op een eigen computersysteem bewaren, behoudens voor zover zij hiertoe voorafgaande schriftelijke toestemming heeft verkregen van Recrout.'
  },
  {
    title: '7.5',
    content:
      'Opdrachtgever mag databestanden uit de CV-database, of delen daarvan, slechts gebruiken met het oog op het vervullen van een concrete, passende en op het moment van het gebruik van de CV-database openstaande vacature en alleen voorzover de databestanden uit de CV-database, of delen daarvan, redelijkerwijs aansluiten op deze concrete vacature.'
  },
  {
    title: '7.6',
    content:
      'Het is Gebruiker niet toegestaan om op oneigenlijke wijze gebruik te maken van de Diensten van Recrout. Dit houdt onder meer in dat het Gebruiker niet is toegestaan enige soft- en/of hardware matige tools en oplossingen (in eigen beheer of beschikbaar gesteld door derden) te gebruiken, voor zover deze gericht zijn op het overnemen van data, dan wel om de vacaturedatabase en CV-database op enigerlei andere wijze te spideren, scrapen, doorzoeken of op andere oneigenlijke wijze te gebruiken en/of in te zien.7.7<br />Uitgesloten van het gebruik van Recrout zijn o.a. commerciële recruiters en intermediairs die als doel hebben het werven van kandidaten voor derden. Werving en selectie voor derden is nadrukkelijk niet toegestaan op Recrout. Alleen het plaatsen van directe, relevante en rechtstreekse vacatures en zoeken naar kandidaten voor directe, relevante en rechtstreekse vacatures zijn geoorloofd bij Recrout. Bij misbruik van deze bepaling gaat de gebruiker akkoord met het betalen van 500 euro per dag dat de overtreding duurt of heeft geduurd.'
  },
  {
    title: '8.0 Intellectuele eigendomsrechten',
    content: ''
  },
  {
    title: '8.1 Intellectuele eigendomsrechten',
    content:
      'De (intellectuele eigendoms)rechten met betrekking tot de Site, waaronder mede begrepen de auteursrechten op de teksten, afbeeldingen, vormgeving, foto’s en overig (stilstaand en/of bewegend) beeldmateriaal, geluidsmateriaal, formats, software en overige materialen, de databankrechten en merkrechten (inclusief domeinnamen) berusten bij Recrout of haar licentiegevers met uitzondering van het materiaal dat door Gebruiker is geleverd in verband met de plaatsing van een CV, vacature en/of bedrijfsinformatie.'
  },
  {
    title: '8.2 Intellectuele eigendomsrechten',
    content:
      'Het is Gebruiker niet toegestaan de Site of enige inhoud daarvan, zonder voorafgaande schriftelijke toestemming van Recrout, op welke wijze dan ook aan derden beschikbaar te stellen, te reproduceren, verspreiden, verzenden, of anderszins te verveelvoudigen of op welke wijze ook in een ander document of ander materiaal te verwerken.Dit houdt onder meer in dat het Gebruiker niet is toegestaan, zonder schriftelijke voorafgaande toestemming van Recrout, een substantieel gedeelte van de inhoud van de vacaturedatabank en de CV-database op te vragen en te hergebruiken, te downloaden naar een eigen database of computersysteem en/of niet-wezenlijke delen van de inhoud van de databank herhaald en systematisch op te vragen en te hergebruiken in de zin van de Databankenwet.Eventuele kopieen die Gebruiker van (een gedeelte van) de Site maakt - voor zover toegestaan - dienen alle vermeldingen van auteursrecht en andere (intellectuele eigendoms)rechten te behouden die op de oorspronkelijke download of uitdraai te zien zijn.'
  },
  {
    title: '9.0 Spam/harvesten',
    content: ''
  },
  {
    title: '9.1',
    content:
      'Het gebruik van (persoons)gegevens van derden die door hen via de Site openbaar zijn gemaakt, waaronder mede wordt verstaan, maar niet beperkt tot, e-mailadressen van Werkzoekenden en Opdrachtgevers, is enkel en alleen toegestaan met het oog op het vervullen van een concrete, openstaande en passende vacature. Het is onder geen beding toegestaan de (persoons)gegevens te verwerken voor enig ander doel, hetgeen onder meer inhoudt dat het verboden is: * Het (ongevraagd) organiseren van iedere vorm van commerciele communicatie door middel van e-mail, fax, SMS, MMS, post of anderszins met werkzoekende(n), althans het direct of indirect promoten van de goederen, diensten of het imago van een onderneming, organisatie of persoon voorzover een en ander niet betrekking heeft op het vervullen van een concrete vacature; * het (ongevraagd) organiseren van iedere vorm van commerciele communicatie door middel van e- mail, fax, SMS, MMS, post of anderszins met een werkgever/intermediair, althans het direct of indirect promoten van de goederen, diensten of het imago van een onderneming, organisatie of persoon, zulks ongeacht of deze producten of diensten (indirect) betrekking hebben op het vervullen van een vacature; * iedere andere vorm van ongevraagde communicatie; * e-mailadressen van Opdrachtgevers en/of Werkzoekenden te verzamelen om welke reden dan ook.'
  },
  {
    title: '9.2',
    content:
      'Indien Gebruiker (gedeeltelijk) in strijd handelt met het in dit artikel bepaalde verbeurt Gebruiker, zonder nadere waarschuwing of ingebrekestelling en zonder dat daartoe rechterlijke tussenkomst is vereist, een direct opeisbare boete van EUR 5.000,- (vijfduizend euro) per gebeurtenis, waarbij het gebruik van een (persoons)gegeven geldt als 1 gebeurtenis, zulks onverminderd het recht van Recrout om schadevergoeding en/of nakoming te vorderen. Artikel 10 Uitsluiting <br /> Recrout behoudt zich het recht voor om Gebruiker uit te sluiten van elk verder gebruik van de Site en de Overeenkomst met Opdrachtgever te ontbinden indien Gebruiker op enige wijze handelt in strijd met deze Algemene Voorwaarden, enige bepaling van Nederlands recht of met de netiquette die Gebruiker op internet in acht dient te nemen, zulks onverminderd het recht van Recrout om nadere rechtsmaatregelen tegen de Gebruiker te nemen en schadevergoeding te eisen. <br /> Bedragen die Recrout voor de ontbinding heeft gefactureerd in verband met hetgeen zij reeds ter uitvoering van de Overeenkomst heeft verricht of geleverd, blijven met inachtneming van het in de vorige volzin bepaalde onverminderd verschuldigd en worden op het moment van de ontbinding direct opeisbaar.'
  },
  {
    title: '10.0',
    content: ''
  },
  {
    title: '11.0 Geheimhouding',
    content:
      'Opdrachtgever erkent dat zij in het kader van deze overeenkomst de beschikking krijgt over vertrouwelijke gegevens van Werkzoekenden en/of Recrout. Opdrachtgever verplicht zich jegens zowel Werkzoekenden als Recrout tot geheimhouding van deze vertrouwelijke gegevens. Opdrachtgever zal deze gegevens te allen tijde vertrouwelijk behandelen en deze niet openbaar maken en/of verveelvoudigen zonder de voorafgaande uitdrukkelijke schriftelijke toestemming van de betreffende Werkzoekende of Recrout. Onder vertrouwelijke informatie in de zin van dit artikel wordt mede verstaan alle persoonlijke gegevens opgenomen in de CV-database.'
  },
  {
    title: '12.0 Overmacht',
    content:
      'Geen der Partijen is gehouden tot het nakomen van enige verplichting indien zij daartoe verhinderd is als gevolg van overmacht. Onder overmacht wordt mede verstaan een niet toerekenbare tekortkoming van toeleveranciers en/of andere derden waarvan Recrout gebruik maakt.'
  },
  {
    title: '13.0 Onderhoud',
    content:
      'Recrout is gerechtigd haar systeem (tijdelijk) buiten gebruik te stellen of het gebruik ervan te beperken in het geval dit noodzakelijk is voor het onderhouden of aanpassen van (onderdelen van) de Site, zonder dat hierdoor enig recht van Gebruiker op schadevergoeding jegens Recrout ontstaat.'
  },
  {
    title: '14.0 Garantie',
    content:
      'Recrout heeft de inhoud van de Site met de grootst mogelijke zorg samengesteld. Recrout garandeert echter niet: * Dat Werkzoekende bevoegd is een eventuele overeenkomst met Opdrachtgever te sluiten; * dat de informatie op de Site juist, volledig en actueel is, waaronder mede verstaan de door Werkzoekenden opgegeven (persoonlijke) informatie, inclusief de CV’s; * dat Werkzoekende daadwerkelijk een overeenkomst met Opdrachtgever zal sluiten; * dat de Site ononderbroken zal werken en vrij zal zijn van fouten; en, * dat de systemen van Recrout vrij zijn van onrechtmatig gebruik door derden.'
  },
  {
    title: '15.0 Onderzoeksplicht',
    content: ''
  },
  {
    title: '15.1',
    content:
      'Opdrachtgever is verplicht bij plaatsing van de vacatures en/of bedrijfsinformatie te onderzoeken of deze gegevens beantwoorden aan de Overeenkomst. Indien dit niet het geval is heeft Opdrachtgever de verplichting om eventuele klachten gemotiveerd per e-mail binnen 8 dagen na de plaatsing van de betreffende vacature en/of bedrijfsinformatie te zenden aan het adres info@Recrout.com'
  },
  {
    title: '15.2',
    content:
      'Beantwoordt de geplaatste advertentie en/of bedrijfsinformatie niet aan de Overeenkomst, dan is Recrout ter harer keuze slechts gehouden tot herstel c.q. wijziging van de (tekst van de) advertentie en/of bedrijfsinformatie of vervanging van de geplaatste advertentie en/of bedrijfsinformatie.'
  },
  {
    title: '16.0 Aansprakelijkheid',
    content: ''
  },
  {
    title: '16.1',
    content:
      'De totale aansprakelijkheid van Recrout voor directe schade wegens toerekenbare tekortkomingen in de nakoming van de Overeenkomst, is beperkt tot het bedrag dat Opdrachtgever voor de betreffende Overeenkomst heeft betaald aan Recrout.'
  },
  {
    title: '16.2',
    content:
      'Recrout is nimmer aansprakelijk voor indirecte schade, daaronder begrepen gevolgschade, gederfde winst, gemiste besparingen, schade door bedrijfsstagnatie en schade door verlies van gegevens.'
  },
  {
    title: '16.3',
    content:
      'Recrout sluit tevens iedere aansprakelijkheid uit voor welke schade dan ook, direct en/of indirect, op enige wijze ontstaan door en/of voortvloeiend uit het gebruik van de Site en/of de Diensten door Gebruiker. Meer in het bijzonder zal Recrout in geen geval aansprakelijk zijn voor welke schade dan ook, die op enige wijze ontstaat door en/of voortvloeit uit: Handelingen door Opdrachtgever verricht, zoals het sluiten van een overeenkomst, die zouden zijn ingegeven door op de Site geplaatste informatie; de onmogelijkheid (delen van) de Site, waaronder mede verstaan maar niet beperkt tot fotoclips en/of video’s, te gebruiken; het feit dat de bepaalde informatie op de Site onjuist, onvolledig of niet actueel is; en het onrechtmatig gebruik van de systemen van Recrout, waaronder de Site, door een derde.'
  },
  {
    title: '16.4',
    content:
      'De in deze voorwaarden opgenomen beperkingen van de aansprakelijkheid voor directe en/of indirecte schade zijn niet van toepassing in gevallen waarin de schade te wijten is aan opzet of grove schuld van Recrout of haar ondergeschikten.'
  },
  {
    title: '17.0 Vrijwaring',
    content:
      'De in deze voorwaarden opgenomen beperkingen van de aansprakelijkheid voor directe en/of indirecte schade zijn niet van toepassing in gevallen waarin de schGebruiker vrijwaart Recrout volledig voor alle mogelijke claims van derden op enige wijze voortvloeiende uit en/of verband houdende met het gebruik door Gebruiker van de Site of met door hem geplaatste CV’s, vacatures en/of bedrijfsinformatie op de Site, waaronder mede verstaan elke claim die gebaseerd is op de bewering dat de betreffende CV, vacature en/of bedrijfsinformatie inbreuk maakt op de intellectuele eigendomsrechten van derden.ade te wijten is aan opzet of grove schuld van Recrout of haar ondergeschikten.'
  },
  {
    title: '18.0 Verwijzingen',
    content:
      'De Site bevat verwijzingen (bijvoorbeeld door middel van een hyperlink, banner of button) naar websites van derden. Recrout heeft geen zeggenschap over deze websites. Recrout is niet verantwoordelijk voor de inhoud van deze websites.'
  },
  {
    title: '19.0 Beveiliging',
    content:
      'Recrout spant zich naar redelijkheid in haar systemen te beveiligen tegen verlies en/of tegen enige vorm van onrechtmatig gebruik. Recrout legt hiervoor passende technische en organisatorische maatregelen ten uitvoer, waarbij onder meer rekening wordt gehouden met de stand van de techniek.'
  },
  {
    title: '20.0 Betalingsvoorwaarden',
    content: ''
  },
  {
    title: '20.1',
    content:
      'Opdrachtgever dient, het voor de Diensten verschuldigde bedrag te betalen volgens de op de Site, dan wel de op de factuur, aangegeven wijze binnen veertien (14) dagen na factuurdatum.'
  },
  {
    title: '20.2',
    content: 'Alle prijzen op de Site zijn exclusief BTW.'
  },
  {
    title: '20.3',
    content:
      'Bij overschrijding van de betalingstermijn is Recrout gerechtigd rente in rekening te brengen overeenkomstig het bij de wet vastgestelde tarief. Deze rente is Opdrachtgever enkel en alleen door het overschrijden van de betalingstermijn verschuldigd, zulks zonder noodzaak tot aanmaning of ingebrekestelling. Daarnaast is Recrout gerechtigd de uitvoering van de Overeenkomst op te schorten zolang Opdrachtgever niet aan de betreffende betalingsverplichting heeft voldaan.'
  },
  {
    title: '20.4',
    content:
      'Indien Recrout genoodzaakt is ter inning van aan haar verschuldigde bedragen rechtskundige bijstand in te roepen, zullen alle daarvoor gemaakte kosten, zowel buitengerechtelijke als gerechtelijke, door de betreffende Opdrachtgever aan Recrout worden vergoed.'
  },
  {
    title: '20.5',
    content:
      'Indien Opdrachtgever via maandelijkse automatische incasso het voor de Diensten verschuldigde (deel)bedrag betaalt, dient Opdrachtgever voor voldoende bank/giro saldo rond de afgesproken incassodatum zorg te dragen. Bij gebreke van een betaling op de vervaldag verkeert Opdrachtgever van rechtswege in verzuim en gebreke, derhalve zonder dat daartoe een nadere ingebrekestelling vereist zal zijn. Alsdan is de totale vordering van Recrout, ook van facturen waarvan de vervaldag nog niet is verstreken, terstond en ineens opeisbaar.'
  },
  {
    title: '20.6',
    content:
      'Een credit is het betaald gebruik dat een Opdrachtgever maakt van de site van Aanbieders, zoals aangegeven op de site en berekend per handeling zoals vermeld. Een creditpakket is het aantal credits dat de Gebruiker aanschaft. De Gebruiker kan de credits gebruiken binnen de termijn die in de aanbieding van het pakket wordt vermeld. Deze termijn zal niet korter zijn dan 30 dagen. Credits die niet zijn gebruikt binnen 6 maanden na aanschaf vervallen, tenzij nadrukkelijk anders overeengekomen.'
  },
  {
    title: '21.0 Zekerheid',
    content: ''
  },
  {
    title: '21.1',
    content:
      'Indien er goede redenen zijn te twijfelen aan het feit dat Opdrachtgever zijn verplichtingen niet tijdig en/of volledig zal nakomen, is Opdrachtgever verplicht op eerste verzoek van Recrout zekerheid te stellen in de door Recrout gewenste vorm.'
  },
  {
    title: '21.2',
    content:
      'Indien Opdrachtgever niet binnen 14 dagen voldoet aan het verzoek genoemd in het voorgaande lid, is Recrout gerechtigd de uitvoering van de Overeenkomst op te schorten zolang Opdrachtgever niet aan het verzoek heeft voldaan.'
  },
  {
    title: '22.0 Duur en (tussentijdse) beindiging',
    content: ''
  },
  {
    title: '22.1',
    content:
      'Elke Overeenkomst wordt aangegaan voor de daarin aangegeven periode. Tussentijdse beëindiging is niet mogelijk, m.u.v. de gevallen genoemd in art. 22.2 en 22.4.'
  },
  {
    title: '22.2',
    content:
      'Elk der partijen heeft tevens het recht de Overeenkomst te ontbinden indien de andere partij, ook na een deugdelijke en zo gedetailleerd mogelijke, schriftelijke ingebrekestelling, stellende een redelijke termijn voor zuivering van de tekortkoming, toerekenbaar tekort blijft schieten in de nakoming van verplichtingen uit de Overeenkomst.'
  },
  {
    title: '22.3',
    content:
      'Indien Opdrachtgever op het moment van de ontbinding als bedoeld in art. 21.2 reeds prestaties ter uitvoering van de Overeenkomst heeft ontvangen, zullen deze prestaties en de daarmee samenhangende betalingsverplichtingen geen voorwerp van ongedaanmaking zijn, tenzij Recrout ten aanzien van die prestaties in verzuim is.'
  },
  {
    title: '22.4',
    content:
      'Ingeval 1 van beide partijen in staat van faillissement geraakt, surseance van betaling aanvraagt of de bedrijfsvoering staakt, heeft de andere partij het recht de Overeenkomst zonder inachtneming van een opzegtermijn te beeindigen, een en ander onder behoud van rechten. Artikel 23 Diversen<br /><br />Op deze Algemene Voorwaarden is Nederlands recht van toepassing. Alle eventuele geschillen worden voorgelegd aan de daartoe bevoegde rechter te Amsterdam. De Algemene Voorwaarden kunnen te allen tijde door Recrout aangepast worden. Recrout adviseert daarom de Algemene Voorwaarden regelmatig te bekijken. Indien en voorzover enige bepaling uit deze Algemene Voorwaarden nietig wordt verklaard of vernietigd wordt, zullen de overige bepalingen onverminderd van kracht blijven. Recrout zal alsdan een nieuwe bepaling vaststellen ter vervanging van de nietige/ vernietigde bepaling, waarbij zoveel mogelijk de strekking van de nietige/vernietigde bepaling in acht zal worden genomen.<br /><br />'
  }
];
