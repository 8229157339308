import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Image from 'components/image/Image';
import PeopleReview from 'components/peopleReview';
import Popup from 'components/shared/Popups/Popup/Index';
import WhatPeopleSay from 'components/whatPeopleSay';
import { IMe } from 'core/interface/me';
import { callForAllElement } from 'helpers/callAll';
import { CMSService } from 'services/cms/cms';
import { useParams } from 'react-router';

function PeopleSay() {
  const [openModal, setOpenModal] = useState(false);
  const { cms } = useSelector((state: RootState) => state.cms);
  const me = useSelector((state: IMe) => state.user).user;
  const { id } = useParams();

  const handleChange = (companyData: any) => {
    callForAllElement(companyData, (testimonial) => {
      const formData = new FormData();
      for (const key in testimonial) {
        if (key !== 'id') {
          formData.append(key, (testimonial as any)[key]);
        }
      }
      return CMSService.updateCMSTestimonials(
        me?.company,
        formData,
        'testimonials'
      );
    });
    handleModal();
  };

  const handleUpdate = (companyData: any) => {
    callForAllElement(companyData, (testimonial) => {
      const formData = new FormData();
      for (const key in testimonial.changedFields) {
        if (key !== 'id') {
          if (!Object.keys(testimonial.changedFields).includes('avatar')) {
            return CMSService.updateCMSTestimonial(
              testimonial.page,
              testimonial.changedFields,
              testimonial.id
            );
          } else {
            formData.append(key, (testimonial.changedFields as any)[key]);
          }
        }
      }
      return CMSService.updateCMSTestimonial(
        testimonial.page,
        formData,
        testimonial.id
      );
    });
    handleModal();
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };
  return (
    <div>
      <div className="peopleSay">
        <h1>What people say</h1>
        {!id && (
          <div className="pencil_icon" onClick={handleModal}>
            <div className="pencil_circle" />
            <Image
              className="pencil"
              name="pencil.svg"
              width={13}
              height={13}
            />
          </div>
        )}
      </div>
      <div className="peopleContainer">
        <div className="purpleContainer" />
        <div className="peopleContent">
          {cms?.testimonials?.map((e: any) => (
            <PeopleReview item={e} key={e.id} />
          ))}
        </div>
      </div>
      {openModal && (
        <Popup
          title="Create career page"
          className="expertise"
          onClose={handleModal}
        >
          <WhatPeopleSay
            companyData={cms}
            isOneBtn={true}
            setNewData={handleChange}
            onClose={handleModal}
            showTopSide={false}
            isPopup={true}
            updateData={handleUpdate}
            showTitle
          />
        </Popup>
      )}
    </div>
  );
}

export default PeopleSay;
