import React, { FC, useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/Index';
import Popup from '../Popups/Popup/Index';
import TalentOverviewPopup from '../Popups/TalentOverview/Index';
import Button from 'components/Button/Index';
import AssessmentPopup from '../Popups/Assessment/Index';
import useLocales from 'hooks/useLocales';
import { JobService } from 'services/jobs/job';
import { useSelector } from 'react-redux';
import { IMe } from 'core/interface/me';
import { RootState } from 'redux/store';
import { getDateFormatter } from 'helpers/dateConverter';
import RadarChartComp from '../RadarChart/RadarChartComp';
import {
  CommonInterface,
  CompetencyData,
  CompetencyDataGroup,
  YearData
} from 'core/interface/assessment';
import {
  competencyScoresYearColors,
  generateRandomColor,
  getComparedData
} from 'helpers/getCompared';
import AssessmentYearLabel from '../assessmentYearLabel/Index';
import { useTheme } from '@material-ui/core';

const CompetencyScores: FC = (): JSX.Element => {
  const { t } = useLocales();
  const theme = useTheme();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAssesmentPopup, setShowAssesmentPopup] = useState<boolean>(false);
  const [competencyYear, setCompetencyYear] = useState<YearData[]>([]);
  const [displayData, setDisplayData] = useState<CompetencyData>();
  const [selectedTab, setSelectedTab] = useState<YearData>();
  const [groups, setGroups] = useState<string[]>([]);
  const [bars, setBars] = useState<CompetencyDataGroup[]>([]);
  const [group, setGroup] = useState<string>('');
  const [currentGroup, setCurrentGroup] = useState<CompetencyData>();
  const [currentCompareGroup, setCurrentCompareGroup] = useState<
    CompetencyData | any
  >();
  const [sorted, setSorted] = useState<CommonInterface[]>();
  const [compareData, setCompareData] = useState<CompetencyData[]>([]);
  const me = useSelector((state: IMe) => state.user).user;
  const { assessmentData } = useSelector(
    (state: RootState) => state.assessment
  );
  const [intervalValue, setIntervalValue] = useState<NodeJS.Timeout | null>(
    null
  );

  const getInitAssessments = async () => {
    setIsLoading((prev) => (prev = true));
    await JobService.getassesments(me.id);
    setIsLoading((prev) => (prev = false));
  };

  useEffect(() => {
    getInitAssessments();
  }, []);

  const calculateAverages = (data: CompetencyData) => {
    const groupsData =
      data && groups.length
        ? groups.map((group: string) => {
            const current = data[group as keyof typeof data] as any;
            return {
              groupTitle: group,
              list: current?.lables?.map((el: string, i: number) => ({
                label: el,
                description: current.descriptions[i],
                value: current.data[i]
              }))
            };
          })
        : [];
    return groupsData;
  };

  useEffect(() => {
    if (selectedTab?.status === 2) {
      const interval = setInterval(() => {
        JobService.getassesments(me.id);
      }, 30000);
      setIntervalValue(interval);
      return () => {
        if (intervalValue) {
          clearInterval(intervalValue);
        }
      };
    } else if (intervalValue) {
      clearInterval(intervalValue);
    }
  }, [selectedTab?.status]);

  useEffect(() => {
    JobService.getAssesmentsResult(me.id);
  }, [me]);

  useEffect(() => {
    const sorted = assessmentData?.sort((a: YearData, b: YearData) => {
      const dateA = a?.created_at && new Date(a?.created_at).getTime();
      const dateB = b?.created_at && new Date(b?.created_at).getTime();
      return dateA && dateB && dateA + dateB;
    });
    setSorted(sorted);
    const dates = sorted.map((el: CommonInterface) => ({
      title: getDateFormatter(el.created_at, 'Y'),
      id: el.id,
      status: el.status,
      created_at: new Date(el.created_at)
    }));

    const isFirstResultOlderThanAYear = dates?.find(
      (date: YearData) =>
        date?.created_at?.getFullYear() == new Date().getFullYear()
    );

    setCompetencyYear(
      dates.map((item: YearData, index: number) => {
        const randomColor = generateRandomColor('#1C9DD7', '#a750af');

        return {
          ...item,
          isActive: index === 0,
          color: competencyScoresYearColors[index] ?? randomColor
        };
      })
    );

    if (!isFirstResultOlderThanAYear) {
      const currentYear = new Date().getFullYear();

      const newTab = {
        title: currentYear.toString(),
        id: `new_tab_${currentYear}`,
        isActive: false
      };

      setCompetencyYear((prevDates: YearData[]) => [newTab, ...prevDates]);
    }
    const result = sorted[0]?.report?.competencies;
    const groups = Object.keys(result || []);
    const compareData = sorted
      .slice(1)
      .map(
        (item: { report: { competencies: any } }) => item?.report?.competencies
      );

    setCompareData(compareData);

    setDisplayData(result);
    setGroups(groups);
    setGroup(groups[0]);
    setSelectedTab({
      isActive: dates[0]?.id,
      status: dates[0]?.status,
      date: dates[0]?.title
    });
  }, [assessmentData]);

  useEffect(() => {
    if (displayData) {
      const current =
        (displayData[group as keyof typeof displayData] as CompetencyData) ||
        null;
      const barData = calculateAverages(displayData);
      setBars(barData);
      setCurrentGroup(current);
    }
    if (compareData) {
      const currentCompareGroups = compareData.map(
        (data: any) => data[group as keyof typeof data] || {}
      );
      const currentCompare =
        currentCompareGroups.length > 0 ? currentCompareGroups : null;
      setCurrentCompareGroup(currentCompare);
    }
  }, [displayData, group, selectedTab]);

  const handleOpenOverview = (): void => {
    setShowPopup(true);
  };
  const handleClose = (): void => {
    setShowPopup(false);
    setShowAssesmentPopup(false);
  };

  const handleOpenAssesment = (): void => {
    setShowAssesmentPopup(true);
  };
  const handleTabChange = (item: YearData, index: number): void => {
    setSelectedTab({
      isActive: item?.id,
      status: item?.status,
      date: item?.title
    });

    setCompetencyYear((prevValues) =>
      prevValues.map((prevItem) => ({
        ...prevItem,
        isActive: prevItem?.id === item?.id
      }))
    );

    if (sorted) {
      const currentIndex = sorted.findIndex(
        (el: CommonInterface) => el.id === item?.id
      );

      const compData =
        sorted
          .slice(currentIndex + 1)
          .map((el: CommonInterface) => el?.report?.competencies) || [];

      setCompareData(compData);

      const showData = sorted.find((el: CommonInterface) => el.id === item?.id)
        ?.report?.competencies;

      if (showData) {
        setDisplayData(showData);
        const groups = Object.keys(showData);
        setGroups(groups);
        setGroup('');
        setTimeout(() => {
          setGroup(groups[0]);
        }, 300);
      }
    }
  };
  const comparisonsArray: any[] = [];
  currentCompareGroup?.forEach((element: any) => {
    const comp = getComparedData(currentGroup, element);
    comparisonsArray.push(comp);
  });

  return (
    <>
      {!isLoading && (
        <div className="tab__comp__head">
          {competencyYear.map((item, index) => (
            <div
              onClick={() => {
                handleTabChange(item, index);
              }}
              key={index}
              className={`tab__comp__head__title ${
                item.isActive ? 'active' : ''
              }`}
            >
              <span className="tab__comp__head__title-title">{item.title}</span>
              <span className="tab__comp__head__title-line"></span>
            </div>
          ))}
        </div>
      )}
      <div className="tab__comp__tab">
        {selectedTab?.status === 3 ? (
          <>
            <div className="tab__comp__tab__left">
              <div className="tab__comp__tab__left__options scrollable ">
                <div className="tab__comp__tab__left__options__years">
                  {competencyYear.map((el) => {
                    if (
                      !el.title ||
                      !el.color ||
                      Number(selectedTab.date) < Number(el.title)
                    )
                      return;

                    return (
                      <AssessmentYearLabel
                        year={el.title}
                        color={
                          el.id === selectedTab.isActive
                            ? theme.palette.primary.main
                            : el.color
                        }
                        key={el.id}
                      />
                    );
                  })}
                </div>
                {groups?.map((item, index) => (
                  <div
                    onClick={() => {
                      setGroup('');
                      setTimeout(() => {
                        setGroup(item);
                      }, 300);
                    }}
                    key={index}
                    className="tab__comp__tab__left__options__option"
                  >
                    <span className="tab__comp__tab__left__options__option-dot"></span>
                    <span
                      className={`tab__comp__tab__left__options__option-title ${
                        item === group ? 'active' : ''
                      }`}
                    >
                      {item}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className={'tab__comp__tab__right assesment'}>
              <>
                <ActionButton
                  onClick={handleOpenOverview}
                  iconName="lupa.svg"
                />
                {group && selectedTab?.date && (
                  <RadarChartComp
                    boxSx={{ width: '100%' }}
                    labels={currentGroup?.lables}
                    points={currentGroup?.data}
                    descriptions={currentGroup?.descriptions}
                    compareTitle={t('Previous Year Result')}
                    comparePoints={comparisonsArray || ''}
                    assessment={true}
                    chartHeight={400}
                    chartWidth={400}
                    years={competencyYear}
                  />
                )}
              </>
            </div>
          </>
        ) : (
          <div className="assesment_buttons">
            {me?.company ? <span> Candidate didn&apos;t take assessment</span> : selectedTab?.status != 2 && selectedTab?.status != 3 ? (
              <Button
                t={t}
                onClick={handleOpenAssesment}
                className="start-assesment"
                title="start_assesment"
              />
            ) : (
              <span>Result will be in shortly…</span>
            )}
          </div>
        )}
      </div>
      {showPopup && (
        <Popup
          className="overview-popup"
          title={`Talent overview-${selectedTab?.date}`}
          description="These are your competencies and talents. Please note that a low score does not have to be a
        bad score. It often means that this talent is compensated by other positive talents."
          onClose={handleClose}
        >
          <TalentOverviewPopup bars={bars} />
        </Popup>
      )}
      {showAssesmentPopup && (
        <Popup title={t('wanna_start_assesment')} onClose={handleClose}>
          <AssessmentPopup onBackClick={setShowAssesmentPopup} t={t} />
        </Popup>
      )}
    </>
  );
};

export default CompetencyScores;
