import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useNavigate } from 'react-router';
import Footer from 'components/Footer/Index';
import JobCard from 'components/JobCard/Index';
import Navbar from 'components/Navbar';
import { jobCards } from 'core/constants/jobCardConstant';
import DropDown from 'components/Dropdown';
import { months } from 'core/constants/aboutMePopUpConstants';
import useLocales from 'hooks/useLocales';
import SingleMessage from 'components/shared/Message/Index';
import { singleMessages } from 'core/constants/messagesConstants';
import { JobService } from 'services/jobs/job';
import { IApplicationResult } from 'core/interface/jobDTO';
import { JobCardActionEnum } from 'core/enum/jobCardAction';
import { SwitcherEnum } from 'core/enum/switcherEnum';
import Image from 'components/image/Image';

import './Index.scss';

const Applications: FC = (): JSX.Element => {
  const { t } = useLocales();
  const navigate = useNavigate();
  const [switcher, setSwitcher] = useState<number>(SwitcherEnum.ALL);
  const { applications } = useSelector(
    (state: RootState) => state.applications
  );

  useEffect(() => {
    getApplications();
  }, []);

  const handleDropdownChange = (item: string): void => {
    console.log(item);
  };

  const getApplications = (): void => {
    JobService.getApplications();
  };

  const handleSwitcher = (e: number): void => {
    setSwitcher(e);
  };

  return (
    <div>
      <Navbar />
      <div className="apps fjobs">
        <div className="back_buttons" onClick={() => navigate('/dashboard')}>
          <Image name="arrow-left.svg" />
          {t('Back to profile')}
        </div>
        <div className="middle">
          <div className="apps__filter">
            <div className="apps__filter__incoming">
              <span className="filter-title">{t('Incoming requests')}</span>
              <p className="apps__filter__incoming-desc">
                {t(
                  'There are no incoming applications. Try to create job openings on the'
                )}
                &nbsp;
                <a className="job-opening" href="">
                  {t('job openings tab')}
                </a>
              </p>
              <DropDown
                dropdownClassName="dropdown-app"
                placeholder={t('Select your job post')}
                items={months}
                selectedValue={handleDropdownChange}
              />
            </div>

            <div className="apps__filter__incoming mess">
              <span className="filter-title">{t('Messages')}</span>
              <div className="apps__filter__incoming__swiper">
                <span
                  onClick={() => handleSwitcher(SwitcherEnum.ALL)}
                  className={`apps__filter__incoming__swiper-btn ${
                    switcher === SwitcherEnum.ALL ? 'active' : ''
                  } `}
                >
                  {t('All')}
                </span>
                <span
                  onClick={() => handleSwitcher(SwitcherEnum.UNREAD)}
                  className={`apps__filter__incoming__swiper-btn ${
                    switcher === SwitcherEnum.UNREAD ? 'active' : ''
                  } `}
                >
                  {t('Unread')}
                </span>
              </div>
            </div>
            <div className="apps__filter__messages">
              {singleMessages.map((message, index) => (
                <SingleMessage
                  className="apps__filter__messages-message"
                  key={index}
                  date={message.date}
                  message={message.message}
                  subtitle={message.subtitle}
                />
              ))}
            </div>
          </div>
          <div className="apps__matches">
            <>
              <span className="apps__matches-title">{t('Applications')}</span>
              {applications?.results?.map(
                (item: IApplicationResult, index: number) => (
                  <div key={index} className="apps__matches__card">
                    <JobCard
                      id={item.job.id}
                      title={item.job.title}
                      sub_title={item.job.description}
                      icon={
                        item.job.company.logo ??
                        '/static/images/recrout-logo-letter.jpg'
                      }
                      actions={[
                        JobCardActionEnum.PROFILE_VIEW,
                        JobCardActionEnum.MORE_INFO,
                        JobCardActionEnum.CANCEL
                      ]}
                      matches={[
                        { percent: +item.score, title: 'Talent Match' },
                        { percent: +item.culture_score, title: 'Cultural Fit' }
                      ]}
                    />
                  </div>
                )
              )}
            </>
            <>
              <span className="apps__matches-title incoming">
                {t('Incoming requests')}
              </span>
              {jobCards.map((item, index) => (
                <div key={index} className="apps__matches__card">
                  <JobCard
                    id={item.id}
                    title={item.title}
                    sub_title={item.sub_title}
                    icon={'/static/images/recrout-logo-letter.jpg'}
                    actions={item.actions}
                    matches={item.matches}
                  />
                </div>
              ))}
            </>
          </div>
        </div>
      </div>
      <div className="footers">
        <Footer />
      </div>
    </div>
  );
};

export default Applications;
