import { ChartData } from "chart.js";

const teaserJobChart: ChartData = {
  labels: [
    ['Conceptual', 'Thinking'],
    ['Drive And', 'Persistence'],
    ['Managing', 'Change'],
    ['Written', 'Communication'],
    ['Systemic', 'Thinking']
  ],
  datasets: [
    {
      label: 'Flexibility',
      data: [5, 8, 6, 4, 3],
      backgroundColor: 'rgba(0, 158, 226, 0.50)',
      borderColor: '#009EE2',
      borderWidth: 1,
    },
    {
      label: 'Detail Focus',
      data: [4, 6, 7, 5, 6],
      backgroundColor: 'rgba(82, 37, 131, 0.50)',
      borderColor: '#522583',
      borderWidth: 1,
    },
  ],
}
const teaserCultureChart: ChartData = {
  labels: [
    ['Conceptual', 'Thinking'],
    'Risk Taking',
    'Planning',
    'Impact',
    ['Information', 'Seeking'],
  ],
  datasets: [
    {
      label: 'Flexibility',
      data: [6, 4, 3, 5, 8],
      backgroundColor: 'rgba(0, 158, 226, 0.50)',
      borderColor: '#009EE2',
      borderWidth: 1,
    },
    {
      label: 'Detail Focus',
      data: [6, 5, 2, 4, 7],
      backgroundColor: 'rgba(82, 37, 131, 0.50)',
      borderColor: '#522583',
      borderWidth: 1,
    },
  ],
}

export { teaserJobChart, teaserCultureChart }
