import React, { FC, useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { IActionvationPreferenceForm } from 'core/interface/activation';
import { IAuthSteps } from 'core/interface/authSteps';
import { ActivationPageEnum } from 'core/enum/activationPage';
import {
  availableAbout,
  availableRelocate,
  candidateWorkHours,
  manageExperience,
  monthlySalary,
  workExperience
} from 'core/constants/preferenceConstats';
import DropDown from 'components/Dropdown';
import Checkboxes from 'components/shared/Checkbox/Index';
import Button from 'components/Button/Index';
import { RootState } from '../../../../redux/store';
import Activation from '../Index';
import { initialValues, validationSchema } from './form';
import { useSelector } from 'react-redux';
import { ICheckboxItem } from 'core/interface/checkboxes';
import Multiselect from 'components/shared/Multiselect/Index';
import { OptionType } from 'core/interface/autocomplete';
import Image from 'components/image/Image';
import { CandidateService } from 'services/candidate/candidate';
import { IMe } from 'core/interface/me';
import Autocomplete from 'components/shared/Autocomplete/Index';
import { ILocationRedux, IndustryRedux } from 'core/interface/education';
import { JobService } from 'services/jobs/job';

import './Index.scss';

const MatchPreferences: FC<IAuthSteps> = ({
  t,
  onClick,
  showTopSide,
  isOneBtn = false
}): JSX.Element => {
  const me = useSelector((state: IMe) => state.user).user;
  const { candidates } = useSelector((state: RootState) => state.candidates);
  const industries = useSelector(
    (selector: IndustryRedux) => selector.industries?.industry || []
  );
  const cities = useSelector(
    (selector: ILocationRedux) => selector.locations?.cities || []
  );
  const [selectedIndustries, setSelectedIndustries] = useState<OptionType[]>(
    []
  );
  const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [timeoutFunc, setTimeoutFunc] = useState<NodeJS.Timeout | null>(null);

  const [initialVal, setInitialVal] =
    useState<IActionvationPreferenceForm>(initialValues);

  const handleInputChange = async (e: string): Promise<void> => {
    if (timeoutFunc) {
      clearTimeout(timeoutFunc);
    }
    const timeout = setTimeout(() => {
      JobService.getLocations({ q: e ?? '' });
    }, 500);
    setTimeoutFunc(timeout);
  };

  useEffect(() => {
    setInitialVal({
      ...initialVal,
      availability: String(candidates.availability),
      contract: candidates.contract,
      expect_per_month: String(candidates.salary),
      industries:
        candidates?.industries?.map((ind: { id: any }) => ind.id) || [],
      interests: candidates?.interests?.map((el: string, index: number) => ({
        title: el
      })),
      industry_preferences: '',
      location: candidates.locations
        ? candidates.locations[0]
        : { display_name: '', id: -1 },
      management_exp: String(candidates.management_exp),
      willing_to_move: String(candidates.relocation),
      work_exp: String(candidates.work_exp),
      work_hours: String(candidates.salary)
    });
    setSelectedIndustries(
      () =>
        candidates?.industries?.map((ind: { id: any; name: any }) => ({
          id: ind.id,
          name: ind.name,
          title: ind.name
        }))
    );
    setSelectedInterests(
      () => candidates?.interests?.map((el: string, index: number) => el)
    );
  }, [candidates]);

  const handleSubmit = async (
    values: IActionvationPreferenceForm,
    actions: FormikHelpers<IActionvationPreferenceForm>
  ): Promise<void> => {
    setLoader(true);
    const body = {
      availability: +values.availability,
      work_hours: +values.work_hours,
      relocation: +values.willing_to_move,
      work_exp: +values.work_exp,
      management_exp: +values.management_exp,
      salary: +values.expect_per_month,
      locations: [values.location],
      industries: values.industries,
      interests: selectedInterests.map((el) => el),
      contract: values.contract,
      default_lang: 1
    };

    await CandidateService.updateCandidate(body, me.candidate_id);
    onClick(ActivationPageEnum.EDUCATION);
    setLoader(false);
  };

  const handleIndustryChange = (industry: string | OptionType[]): void => {
    if (typeof industry != 'string' && industry) {
      setSelectedIndustries((prev) => {
        const prevArray = Array.isArray(prev) ? prev : [];
        return [...new Set([...prevArray, ...industry])];
      });
    }
  };

  const handleInterestChange = (interest: string | OptionType[]): void => {
    if (typeof interest != 'string' && interest) {
      setSelectedInterests((prev) => {
        const prevArray = Array.isArray(prev) ? prev : [];
        const interests = interest.map((el) => el.title as string);
        return [...new Set([...prevArray, ...interests])];
      });
    }
  };

  const handleRemoveIndustry = (e: string): void => {
    setSelectedIndustries((prev) => prev.filter((ind) => ind.title !== e));
  };

  const handleRemoveInterets = (e: string): void => {
    setSelectedInterests((prev) => prev.filter((ind) => ind !== e));
  };

  const handleLoadMoreCities = () => {
    const params = { limit: 10, offset: cities.length };
    JobService.getLocations(params, true);
  };

  return (
    <Activation
      showTopSide={showTopSide}
      title="Indicate your match preferences"
      currentStep={2}
    >
      <div className="preference">
        <Formik
          enableReinitialize={true}
          initialValues={initialVal}
          isInitialValid={false}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={validationSchema(t)}
        >
          {({
            setFieldValue,
            errors,
            values,
            touched,
            isValid,
            setErrors,
            setFieldTouched
          }) => {
            return (
              <Form>
                <div className="form-body scrollable">
                  <div className="top">
                    <div className="fields">
                      <span className="label">Available about</span>
                      <DropDown
                        fieldName="availability"
                        defaultValue={values.availability}
                        placeholder={t('Available about')}
                        error={
                          touched.availability && errors.availability
                            ? errors.availability
                            : ''
                        }
                        dropdownClassName="dropdown"
                        items={availableAbout}
                        selectedValue={(e) => {
                          setFieldTouched('availability', true);
                          setFieldValue('availability', e);
                        }}
                      />
                    </div>
                    <div className="fields willing">
                      <span className="label">Are you willing to move?</span>
                      <DropDown
                        fieldName="willing_to_move"
                        defaultValue={values.willing_to_move}
                        error={
                          touched.willing_to_move && errors.willing_to_move
                            ? errors.willing_to_move
                            : ''
                        }
                        placeholder={t('Willing to move')}
                        dropdownClassName="dropdown"
                        items={availableRelocate}
                        selectedValue={(e) => {
                          setFieldTouched('willing_to_move', true);
                          setFieldValue('willing_to_move', e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="fields">
                    <span className="label">Work experience</span>
                    <DropDown
                      fieldName="work_exp"
                      defaultValue={values.work_exp}
                      placeholder={t('Work experience')}
                      error={
                        touched.work_exp && errors.work_exp
                          ? errors.work_exp
                          : ''
                      }
                      dropdownClassName="dropdown"
                      items={workExperience}
                      selectedValue={(e) => {
                        setFieldTouched('work_exp', true);
                        setFieldValue('work_exp', e);
                      }}
                    />
                  </div>
                  <div className="fields">
                    <span className="label">Work hours</span>
                    <DropDown
                      defaultValue={values.work_hours}
                      dropdownClassName="dropdown"
                      items={candidateWorkHours}
                      selectedValue={(e) => {
                        setFieldTouched('work_hours', true);
                        setFieldValue('work_hours', e);
                      }}
                      fieldName="work_hours"
                      placeholder={t('Work hours')}
                      error={
                        touched.work_hours && errors.work_hours
                          ? errors.work_hours
                          : ''
                      }
                    />
                  </div>
                  <div className="fields">
                    <span className="label">
                      What do you expect to earn per month?
                    </span>
                    <DropDown
                      fieldName="expect_per_month"
                      defaultValue={values.expect_per_month}
                      placeholder={t('Salary expectation')}
                      error={
                        touched.expect_per_month && errors.expect_per_month
                          ? errors.expect_per_month
                          : ''
                      }
                      dropdownClassName="dropdown"
                      items={monthlySalary}
                      selectedValue={(e) => {
                        setFieldTouched('expect_per_month', true);
                        setFieldValue('expect_per_month', e);
                      }}
                    />
                  </div>
                  <div className="fields loc">
                    <span className="label">Location</span>
                    <Autocomplete
                      loadMore={handleLoadMoreCities}
                      fieldName="location"
                      className={`inputs `}
                      value={initialVal.location.display_name}
                      options={
                        cities.map((item, index) => ({
                          key: index,
                          id: item.id,
                          title: item.display_name
                        })) || []
                      }
                      placeholder="Location"
                      show_location={false}
                      onInputChange={(e) => {
                        handleInputChange(e);
                        if (cities.find((cit) => cit.display_name === e)) {
                          setFieldValue('location', {
                            id: cities.find((city) => city.display_name === e)
                              ?.id,
                            display_name: cities.find(
                              (city) => city.display_name === e
                            )?.display_name
                          });
                        } else {
                          setErrors({
                            location: { display_name: t('Invalid Name') }
                          });
                        }
                      }}
                      values={cities}
                    />
                  </div>
                  <div className="fields">
                    <span className="label">Managerial experience</span>
                    <DropDown
                      defaultValue={values.management_exp}
                      placeholder={t('Managerial experience')}
                      error={
                        touched.management_exp && errors.management_exp
                          ? errors.management_exp
                          : ''
                      }
                      dropdownClassName="dropdown"
                      items={manageExperience}
                      selectedValue={(e) => {
                        setFieldTouched('management_exp', true);
                        setFieldValue('management_exp', e);
                      }}
                    />
                  </div>
                  <div className="fields">
                    <span className="label">Industry preferences</span>

                    <Multiselect
                      fieldName="industries"
                      value={selectedIndustries ?? []}
                      className={`${errors.industries ? 'error' : ''}`}
                      placeholder={t('Industry')}
                      show_location={false}
                      onChange={(e: string | OptionType[]) => {
                        handleIndustryChange(e);
                        if (typeof e != 'string') {
                          setFieldValue(
                            'industries',
                            e.map((ind) => ind.id)
                          );
                        }
                      }}
                      // onInputChange={(e) => getIndustries(e ?? '')}
                      options={
                        industries?.map((e) => ({
                          id: e?.id,
                          title: e?.name
                        })) || []
                      }
                    />
                    <div className="ind">
                      {selectedIndustries &&
                        selectedIndustries.map((industry, index) => (
                          <span
                            className="industry-selected"
                            key={industry.title}
                          >
                            {industry.title}{' '}
                            <span
                              onClick={() =>
                                handleRemoveIndustry(industry.title ?? '')
                              }
                            >
                              <Image
                                className="remove"
                                name="removes.svg"
                                width={10}
                                height={10}
                              />
                            </span>
                          </span>
                        ))}
                    </div>

                    <span className="error-msg">{errors.industries}</span>
                  </div>
                  <div className="fields">
                    <span className="label">Interested in</span>

                    <Multiselect
                      fieldName="interests"
                      value={selectedIndustries ?? []}
                      className={`${errors.interests ? 'error' : ''}`}
                      placeholder={t('Interests in')}
                      show_location={false}
                      onChange={(e) => {
                        handleInterestChange(e);
                        if (typeof e != 'string') {
                          setFieldValue(
                            'interests',
                            e.map((ind) => ind.title)
                          );
                        }
                      }}
                      options={
                        industries?.map((e) => ({
                          id: e?.id,
                          title: e?.name
                        })) || []
                      }
                    />
                    <div className="ind">
                      {selectedInterests &&
                        selectedInterests.map((interest, index) => (
                          <span className="industry-selected" key={interest}>
                            {interest}{' '}
                            <span
                              onClick={() =>
                                handleRemoveInterets(interest ?? '')
                              }
                            >
                              <Image
                                className="remove"
                                name="removes.svg"
                                width={10}
                                height={10}
                              />
                            </span>
                          </span>
                        ))}
                    </div>

                    <span className="error-msg">{errors.industries}</span>
                  </div>
                  <div className="checkboxes">
                    <span className="bold-title">Contract</span>
                    <Checkboxes
                      onArrayChange={(e: ICheckboxItem | ICheckboxItem[]) => {
                        if (Array.isArray(e)) {
                          setFieldValue(
                            'contract',
                            e
                              .filter((item) => item.isChecked)
                              .map((item) => Number(item.value))
                          );
                        } else {
                          setFieldValue('contract', e.value);
                        }
                      }}
                      onChange={(e) => e}
                      className="contract"
                      checkboxes={[
                        {
                          label: 'Interim/Deta',
                          value: '1',
                          isChecked:
                            values.contract && values.contract.includes(1)
                        },
                        {
                          label: 'Temporarly/Part-Time',
                          value: '2',
                          isChecked:
                            values.contract && values.contract.includes(2)
                        },
                        {
                          label: 'Permanent',
                          value: '3',
                          isChecked:
                            values.contract && values.contract.includes(3)
                        }
                      ]}
                    />
                  </div>
                </div>
                {isOneBtn ? (
                  <div className="buttons one">
                    <Button
                      isLoading={loader}
                      className={`next button oneBTN ${
                        !isValid ||
                        (selectedIndustries && selectedIndustries.length === 0)
                          ? 'invalid'
                          : 'valid'
                      }`}
                      type="submit"
                      t={t}
                      title="Save"
                    />
                  </div>
                ) : (
                  <div className="buttons">
                    <Button
                      className="back button"
                      t={t}
                      title="back"
                      onClick={() => onClick(ActivationPageEnum.WHO_YOU_ARE)}
                    />
                    <Button
                      isLoading={loader}
                      className={`next button ${
                        !isValid ||
                        (selectedIndustries && selectedIndustries.length === 0)
                          ? 'invalid'
                          : 'valid'
                      }`}
                      type="submit"
                      t={t}
                      title="Next"
                    />
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Activation>
  );
};

export default MatchPreferences;
