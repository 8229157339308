import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { IActivationEducation } from 'core/interface/activation';

const validation = (t: TFunction) => {
  return Yup.object({
    university: Yup.string().trim().required(t('required')),
    field_of_study: Yup.string().trim().required(t('required')),
    degree_type: Yup.string().trim().required(t('required')),
    average_graduation: Yup.number().required(t('required')),
    school: Yup.string().trim().required(t('required')),
    study_field: Yup.string().trim().required(t('required')),
    ongoing: Yup.boolean(),
    is_graduated: Yup.boolean(),
    start: Yup.object({
      month: Yup.string().trim().required(t('required')),
      year: Yup.string().trim().required(t('required'))
    }),
    finish: Yup.object({
      month: Yup.string().trim().required(t('required')),
      year: Yup.string().trim().required(t('required'))
    })
  });
};

const initialValues: IActivationEducation = {
  educational_attainment: '',
  graduation_grade: '',
  isGraduated: false,
  average_graduation: '',
  school: '',
  study_field: '',
  isCurrent: false,
  start: {
    month: '',
    year: '',
    day: ''
  },
  finish: {
    month: '',
    year: '',
    day: ''
  }
};

export { initialValues, validation };
