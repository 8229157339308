import { store } from '../../redux/store';
import { ICandidate, ISharedLinks } from 'core/interface/candidate';
import ApiBase from 'services/base/apiBase';
import {
  AddCandidatesVideos,
  DeleteCandidateVideos,
  SaveCandidates,
  SaveCandidatesOverwie,
  SaveCandidatesSharedLinks
} from '../../redux/slices/candidates';

export class CandidateService {
  private static readonly apiURL = new ApiBase<ICandidate>();

  public static async getCandidate(
    candidate_id: number
  ): Promise<ICandidate | void> {
    const { dispatch } = store;
    try {
      const res = await this.apiURL.getAsync(`/candidates/${candidate_id}/`);
      dispatch(SaveCandidates(res));
      return res;
    } catch (error) {
      console.error('Error fetching candidate:', error);
    }
  }

  public static async updateCandidate(
    body: object,
    candidate_id: number
  ): Promise<ICandidate | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiURL.updateAsync(
        `/candidates/${candidate_id}/`,
        body
      );
      dispatch(SaveCandidates(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async getCandidateOverview(
    candidate_id: number
  ): Promise<ICandidate> {
    const { dispatch } = store;
    const res = await this.apiURL.getAsync(
      `/candidates/overview/${candidate_id}/`
    );
    dispatch(SaveCandidatesOverwie(res));
    return res;
  }

  public static async getSharedLinks(id: number): Promise<Array<ISharedLinks>> {
    const { dispatch } = store;
    const res = await this.apiURL.getAsync(`/candidates/${id}/shared-links/`);
    dispatch(SaveCandidatesSharedLinks(res));
    return res
  }

  public static async saveSharedLinks(id: number, body: { title?: string, url: string }): Promise<ISharedLinks> {
    const { dispatch } = store;
    const res = await this.apiURL.postAsync(
      `/candidates/${id}/shared-links/`,
      body
    );
    dispatch(AddCandidatesVideos(res));
    return res;
  }

  public static async deleteSharedLinks(candidateId: number, sharedLinksId: number): Promise<boolean> {
    const { dispatch } = store
    const res = await this.apiURL.deleteAsync(`/candidates/${candidateId}/shared-links/${sharedLinksId}/`)
    dispatch(DeleteCandidateVideos(sharedLinksId));
    return res;
  }
}
