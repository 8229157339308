import React, { FC } from 'react';
import { ITooltip } from 'core/interface/tooltip';

import './Index.scss';

const Tooltip: FC<ITooltip> = ({ isShow, message, className }): JSX.Element => {
  return (
    <div className={`tooltip ${isShow ? 'show' : ''} ${className ?? ''}`}>
      <span className="tooltip__message">{message}</span>
    </div>
  );
};

export default Tooltip;
