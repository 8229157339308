import { UploadedFiles } from 'core/interface/candidate';
import { IAction } from 'core/interface/action';

export const SAVE_FILE = 'save-file';

const defaultState = {
  files: null
};

export const SaveFiles = (value: UploadedFiles) => ({
  type: SAVE_FILE,
  payload: value
});

const fileReducer = (
  state = defaultState,
  action: IAction<UploadedFiles[]>
) => {
  switch (action.type) {
    case SAVE_FILE:
      return {
        ...state,
        files: action.payload
      };
    default:
      return state;
  }
};

export default fileReducer;
