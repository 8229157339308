import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import jsPDF from 'jspdf';
import useLocales from 'hooks/useLocales';
import html2canvas from 'html2canvas';
import { DownloadPDFPopupI } from 'core/interface/pdf';
import { Checkbox, CircularProgress } from '@mui/material';
import Image from 'components/image/Image';
import { pdfOptions } from 'core/constants/pdfConstants';
import { PDFTitles } from 'core/enum/pdfTitles';
import CandidateGraphs from 'components/shared/PdfComponents/candidateGraphs/Index';
import CandidateBasic from 'components/shared/PdfComponents/candidateBasic/Index';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import './Index.scss';

const DownloadPDFPopup: FC<DownloadPDFPopupI> = ({
  setIsClose
}): JSX.Element => {
  const { t } = useLocales();
  const [graphsTemplate, setGraphsTemplate] = useState<HTMLDivElement>();
  const [fullTemplate, setFullTemplate] = useState<HTMLDivElement>();
  const [PDFType, setPDFType] = useState<PDFTitles>(PDFTitles.FULL);

  const [isAnonym, setIsAnonym] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<{
    index: number;
    isLoading: boolean;
  }>({ index: 0, isLoading: false });
  const candiadate = useSelector(
    (state: RootState) => state.candidate
  ).candidate;

  const componentRef = useRef(null);

  const convertImgToBase64URL = (url: string) => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
        .catch(reject);
    });
  };

  const downloadPDF = (): void => {
    const template = componentRef.current;
    if (!template) {
      return;
    }

    setTimeout(async () => {
      const elements: any = componentRef.current;
      const asyncFunctions = [];
      for (let i = 0; i < elements.children.length; i++) {
        const element = elements.children[i];
        const imgs = element.querySelectorAll('img');
        for (const img of imgs) {
          const base64 = await convertImgToBase64URL(img.src);
          img.src = base64;
        }
        if (elements.children[i].children.length) {
          asyncFunctions.push(
            html2canvas(element, { allowTaint: true, useCORS: true })
          );
        }
      }
      const results = await Promise.all(asyncFunctions);
      const pdf = new jsPDF();
      for (let i = 0; i < results.length; i++) {
        const canvas = results[i];
        const data = canvas.toDataURL('image/png');
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
          (imgProperties.height * pdfWidth) / imgProperties.width;
        if (i) pdf.addPage();
        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
      }
      pdf.save(`${isAnonym ? 'anonymous' : candiadate?.name}.pdf`);
      setIsLoading((prev) => ({ ...prev, isLoading: false }));
      pdf.close();
    }, 3000);
  };

  const handleDownloadClick = (
    type: PDFTitles,
    indexOfClickedItem: number
  ): void => {
    setPDFType(type);
    setIsLoading({ index: indexOfClickedItem, isLoading: true });

    downloadPDF();
  };

  const handleAnonymousChange = (e: SyntheticEvent): void => {
    const isChecked = (e.target as HTMLInputElement).checked;
    setIsAnonym(isChecked);
  };

  return (
    <div className="download-pdf">
      <div className="anonym">
        <Checkbox
          onChange={(e) => handleAnonymousChange(e)}
          id="anonym"
          aria-label={t('Make anonymous')}
        />
        <label htmlFor="anonym">{t('Make anonymous')}</label>
      </div>
      {pdfOptions.map((item, index) => (
        <div key={index} className="item">
          <span className="text">{t(`${item}`)}</span>
          <span
            onClick={() => handleDownloadClick(item, index)}
            className="download"
          >
            {isLoading.index === index && isLoading.isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <Image name="download.svg" width={25} height={25} />
            )}
          </span>
        </div>
      ))}

      <div
        style={{
          height: 0,
          overflow: 'hidden'
        }}
      >
        <div
          id="divToPrint"
          style={{
            width: '800px',
            minWidth: '1200px',
            background: '#FFFFFF'
          }}
          ref={componentRef}
        >
          {PDFType !== PDFTitles.GRAPHS && (
            <CandidateBasic isAnonym={isAnonym} setTemplate={setFullTemplate} />
          )}
          {PDFType !== PDFTitles.BASIC && (
            <CandidateGraphs
              isAnonym={isAnonym}
              setTemplate={setGraphsTemplate}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DownloadPDFPopup;
