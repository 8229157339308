import React, { FC, useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import {
  ICandidateLanguage,
  ILanguageBody,
  ILanguageFormInitial
} from 'core/interface/candidate-language';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { initialValues, validationSchema } from './form';
import useLocales from 'hooks/useLocales';
import { ILanguagePopup } from 'core/interface/languagePopup';
import Level from 'components/Level/Index';
import Autocomplete from 'components/shared/Autocomplete/Index';
import { LanguageService } from 'services/language';
import Button from 'components/Button/Index';
import Image from 'components/image/Image';
import { IMe } from 'core/interface/me';

import './Index.scss';

const LanguagePopup: FC<ILanguagePopup> = ({ onClose }): JSX.Element => {
  const { t } = useLocales();
  const me = useSelector((state: IMe) => state.user).user;
  const [loader, setLoader] = useState<boolean>(false);
  const [formValue, setFormValue] =
    useState<ILanguageFormInitial>(initialValues);
  const { candidate_language } = useSelector(
    (state: RootState) => state.languages
  );
  const { languages } = useSelector((state: RootState) => state.languages);

  const handleSubmit = async (
    values: ILanguageFormInitial,
    actions: FormikHelpers<ILanguageFormInitial>
  ): Promise<void> => {
    setLoader(true);

    const languagePromises: Promise<void>[] = values.languages.map(
      async (language) => {
        let lang;
        if (typeof language.language === 'string') {
          lang = languages?.find((l) => l.name === language.language)?.id;
        } else {
          lang = language.language;
        }
        const body = {
          language: lang,
          proficiency: language.proficiency
        } as ILanguageBody;

        try {
          await LanguageService.updateCandidateLanguage(
            me.candidate_id,
            language.id,
            body
          );
        } catch (error) {
          console.log(error);
        }
      }
    );

    try {
      await Promise.all(languagePromises);
      onClose();
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (candidate_language && candidate_language?.length > 0) {
      const initial = {
        languages: candidate_language?.map((item) => {
          const lang = {
            id: item.id,
            language: item?.language?.name,
            proficiency: item?.proficiency
          };
          return lang;
        })
      } as ILanguageFormInitial;
      setFormValue(initial);
    }
  }, [candidate_language]);

  const handleDelete = (id: number)=> {
    try {
      LanguageService.removeLanguage(me.candidate_id, id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const getLanguages = (query: string = '') => {
    try {
      LanguageService.getAllLanguages(query);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="languagesp">
      <Formik
        validationSchema={validationSchema(t)}
        initialValues={formValue}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue, isValid, values }) => {
          return (
            <Form>
              <div className="langs">
                {candidate_language?.map((item, index) => (
                  <div key={item.id} className="lang">
                    <div className="lang-side">
                      {index === 0 && (
                        <span className="label">{t('Language')}</span>
                      )}

                      <Autocomplete
                        fieldName="language"
                        show_arrow={true}
                        value={item?.language?.name}
                        options={
                          languages?.map((e) => ({
                            id: e?.id,
                            title: e?.name
                          })) || []
                        }
                        show_location={false}
                        placeholder="Language"
                        onChange={(e) => {
                          const selectedLang = languages?.find(
                            (lang) => lang.name === e
                          );
                          setFieldValue(
                            `languages[${index}].language`,
                            selectedLang && selectedLang.id
                              ? selectedLang.id
                              : ''
                          );
                          getLanguages(e);
                        }}
                        onInputChange={(e) => {
                          getLanguages(e);
                        }}
                        onFocus={(e) => {
                          getLanguages(e);
                        }}
                        values={languages}
                      />
                    </div>
                    <div className="lang-side prof-side edit-lang-prof">
                      {index === 0 && (
                        <span className="label">{t('Proficiency')}</span>
                      )}

                      <Level
                        level={values.languages[index]?.proficiency}
                        isReadonly={false}
                        onClick={(e) => {
                          setFieldValue(`languages[${index}].proficiency`, e);
                        }}
                      />
                    </div>
                    <div className="lang-side trash-side">
                      <span
                        onClick={() => handleDelete(item.id)}
                        className="trash-icon"
                      >
                        <Image name="trash.svg" />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <Button
                isLoading={loader}
                t={t}
                title="Save"
                className={`save-btn ${!isValid ? 'invalid-btn' : ''}`}
                type="submit"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LanguagePopup;
