import { IAction } from 'core/interface/action';

export const SAVE_VALUES = 'save-request';
export const SAVE_SINGLE_VALUES = 'save-single-request';

const defaultState = {
  request: null,
  singleRequest: null
};

export const SaveRequest = (value: any | number[]) => ({
  type: SAVE_VALUES,
  payload: value
});

export const SaveSingleRequest = (value: any | number[]) => ({
  type: SAVE_SINGLE_VALUES,
  payload: value
});

const requestReducer = (state = defaultState, action: IAction<any | number[]>) => {
  switch (action.type) {
    case SAVE_VALUES:
      return {
        ...state,
        request: action.payload
      };
    case SAVE_SINGLE_VALUES:
      return {
        singleRequest: action.payload
      };
    default:
      return state;
  }
};

export default requestReducer;
