import * as Yup from 'yup';
import { IActionvationPreferenceForm } from 'core/interface/activation';
import { TFunction } from 'i18next';

const initialValues: IActionvationPreferenceForm = {
  availability: '',
  willing_to_move: '',
  work_exp: '',
  expect_per_month: '',
  management_exp: '',
  industry_preferences: '',
  interests: [],
  work_hours: '',
  contract: [],
  industries: [],
  location: {
    display_name: '',
    id: -1
  }
};

const validationSchema = (t: TFunction) => {
  return Yup.object({
    availability: Yup.string()
      .required(t('required'))
      .notOneOf(['null'])
      .nullable(),
    work_hours: Yup.string()
      .required(t('required'))
      .notOneOf(['null'])
      .nullable(),
    willing_to_move: Yup.string()
      .required(t('required'))
      .notOneOf(['null'])
      .nullable(),
    work_exp: Yup.string()
      .required(t('required'))
      .notOneOf(['null'])
      .nullable(),
    management_exp: Yup.string()
      .required(t('required'))
      .notOneOf(['null'])
      .nullable(),
    expect_per_month: Yup.string()
      .required(t('required'))
      .notOneOf(['null'])
      .nullable(),
    industry_preferences: Yup.string().optional(),
    industries: Yup.array(),
    location: Yup.object({
      id: Yup.number().required(t('required')),
      display_name: Yup.string().required(t('required'))
    }),
    contract: Yup.array().required(t('required')),
    default_lang: Yup.number()
  });
};

export { initialValues, validationSchema };
