import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getDateFormatter } from 'helpers/dateConverter';
import { IExtraActivitiesData } from 'core/interface/extraActivities';

import './Index.scss';

const ExtraActivities: FC = (): JSX.Element => {
  const { extraActivities } = useSelector(
    (state: RootState) => state.extraActivites
  );
  return (
    <div className="extra_activities">
      {extraActivities?.map((item: IExtraActivitiesData, index: number) => (
        <div key={index} className="about__right__bottom__left__info">
          <div className="about__right__bottom__left__info__header">
            <span className="about__right__bottom__left__info__header-title m-hidden ">
              {item.title}
            </span>
            <span className="about__right__bottom__left__info__header-year">
              {getDateFormatter(item.start, 'YYYY')} {item.end ? '-' : ''}{' '}
              {getDateFormatter(item.end, 'YYYY')}
            </span>
          </div>
          <span className="about__right__bottom__left__info-desc s-hidden ">
            {item.description}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ExtraActivities;
