import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers, FieldArray, ErrorMessage } from 'formik';
import Button from 'components/Button/Index';
import Activation from 'components/feature/activation/Index';
import ActionButton from 'components/ActionButton/Index';
import ImagePicker from 'components/imagePicker';
import FormField from 'components/FormField/Index';
import useLocales from 'hooks/useLocales';
import { InputType } from 'core/enum/inputType';
import { validationSchema } from './form';
import { CMSService } from 'services/cms/cms';

import './Index.scss';

const testimonialHasChanged = (
  currentTestimonial: any,
  originalTestimonial: any
) => {
  let hasChanged = false;
  const changedFields: any = {};
  if (currentTestimonial.first_name !== originalTestimonial.first_name) {
    hasChanged = true;
  }
  if (currentTestimonial.last_name !== originalTestimonial.last_name) {
    hasChanged = true;
  }
  if (currentTestimonial.job_title !== originalTestimonial.job_title) {
    hasChanged = true;
  }
  if (currentTestimonial.description !== originalTestimonial.description) {
    hasChanged = true;
  }
  const avatarChanged =
    currentTestimonial.avatar !== originalTestimonial.avatar;
  if (avatarChanged) {
    hasChanged = true;
  }
  if (hasChanged) {
    changedFields.first_name = currentTestimonial.first_name;
    changedFields.last_name = currentTestimonial.last_name;
    changedFields.job_title = currentTestimonial.job_title;
    changedFields.description = currentTestimonial.description;
    changedFields.page = currentTestimonial.page;
    if (avatarChanged) {
      changedFields.avatar = currentTestimonial.avatar;
    }
    return {
      id: currentTestimonial.id,
      page: currentTestimonial.page,
      changedFields: changedFields
    };
  }
  return null;
};

const WhatPeopleSay: any = ({
  onSubmit,
  onBack,
  onClose,
  setCompanyData,
  companyData,
  isPopup = false,
  className,
  isOneBtn = false,
  showTopSide,
  setNewData,
  updateData,
  showTitle
}: any): JSX.Element => {
  const { t } = useLocales();
  const [formValues, setFormValues] = useState<any>({
    testimonials: [
      {
        avatar: null,
        first_name: '',
        last_name: '',
        job_title: '',
        description: ''
      }
    ]
  });

  useEffect(() => {
    if (companyData)
      setFormValues({
        testimonials: companyData?.testimonials
      });
  }, [companyData]);

  const handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
    if (setCompanyData) {
      setCompanyData((prev: any) => ({
        ...prev,
        ...values
      }));
      onSubmit();
    } else {
      const newData = values?.testimonials.filter(
        (el: { isNew: boolean }) => el?.isNew
      );
      if (newData.length) {
        setNewData(newData);
      }
      const oldData = values?.testimonials.filter(
        (el: { isNew: boolean }) => !el?.isNew
      );
      if (oldData.length) {
        const changedOldTestimonials: any[] = [];
        oldData.forEach((oldTestimonial: { id: any }) => {
          const originalTestimonial = formValues.testimonials.find(
            (t: { id: any }) => t.id === oldTestimonial.id
          );
          if (originalTestimonial) {
            const result = testimonialHasChanged(
              oldTestimonial,
              originalTestimonial
            );
            if (result) {
              const { id, page, changedFields } = result;
              if (Object.keys(changedFields).length > 0) {
                changedOldTestimonials.push({
                  id: id,
                  page: page,
                  changedFields: changedFields
                });
              }
            }
          }
        });
        updateData(changedOldTestimonials);
      }
    }
  };

  const handleRemove = (testimonial: any) => {
    const { page, id } = testimonial;
    CMSService.removeTestimonials(page, id);
  };

  return (
    <div className="who">
      <Activation
        showTopSide={showTopSide}
        currentStep={4}
        title="What people say"
        className={
          isPopup ? '' : `activation-edu ${className ?? ''} ${className}`
        }
        headerIconDisable
        totalSteps={8}
        isCareerPage={true}
        handleModalClose={onClose}
        isPopup={true}
        showTitle={showTitle}
      >
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={validationSchema(t)}
        >
          {({
            setFieldValue,
            isValid,
            values,
            errors,
            touched,
            setFieldTouched
          }) => {
            return (
              <Form>
                <div className="organization scrollable">
                  <FieldArray name="testimonials">
                    {({ remove, push }) => (
                      <>
                        <div className="addJob">
                          <ActionButton
                            iconName="plus.svg"
                            onClick={() =>
                              push({
                                avatar: null,
                                first_name: '',
                                last_name: '',
                                job_title: '',
                                description: '',
                                isNew: true
                              })
                            }
                          />
                          <span>Add Job openings</span>
                        </div>
                        {values?.testimonials?.map(
                          (testimonial: any, index: number) => {
                            return (
                              <div key={index}>
                                {isPopup && (
                                  <div className="addJob">
                                    <ActionButton
                                      iconName="remove.svg"
                                      onClick={() => handleRemove(testimonial)}
                                    />
                                    <span>Remove</span>
                                  </div>
                                )}
                                <div>
                                  <span className="label">Photo</span>
                                  <ImagePicker
                                    setLogo={() => {}}
                                    setFiledValue={(selectedLogo: any) => {
                                      setFieldValue(
                                        `testimonials.${index}.avatar`,
                                        selectedLogo
                                      );
                                    }}
                                    logo={
                                      testimonial.avatar && testimonial.avatar
                                    }
                                    max={1}
                                  />
                                  <ErrorMessage
                                    name={`testimonials.${index}.avatar`}
                                  >
                                    {(msg) => (
                                      <div className="error-ms">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>
                                <div className="fields_flex">
                                  <div className="field many autocomplete">
                                    <span className="label">
                                      {t('First Name')}
                                    </span>
                                    <FormField
                                      inputClassName={`input ${
                                        (errors as any)?.testimonials?.[index]
                                          ?.first_name &&
                                        (touched as any).testimonials?.[index]
                                          ?.first_name
                                          ? 'error'
                                          : ''
                                      }`}
                                      fieldName={`testimonials.${index}.first_name`}
                                      placeholder="Alan"
                                      type={InputType.text}
                                    />
                                    <ErrorMessage
                                      name={`testimonials.${index}.first_name`}
                                    >
                                      {(msg) => (
                                        <div className="error-ms">{msg}</div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                  <div className="field many">
                                    <span className="label">
                                      {t('Last Name')}
                                    </span>
                                    <FormField
                                      inputClassName={`input ${
                                        (errors as any)?.testimonials?.[index]
                                          ?.last_name &&
                                        (touched as any).testimonials?.[index]
                                          ?.last_name
                                          ? 'error'
                                          : ''
                                      }`}
                                      fieldName={`testimonials.${index}.last_name`}
                                      placeholder="Geni"
                                      type={InputType.text}
                                    />
                                    <ErrorMessage
                                      name={`testimonials.${index}.last_name`}
                                    >
                                      {(msg) => (
                                        <div className="error-ms">{msg}</div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div className="fields">
                                  <span className="label">
                                    {t('Job Title')}
                                  </span>
                                  <FormField
                                    inputClassName={`input ${
                                      (errors as any)?.testimonials?.[index]
                                        ?.job_title &&
                                      (touched as any).testimonials?.[index]
                                        ?.job_title
                                        ? 'error'
                                        : ''
                                    }`}
                                    fieldName={`testimonials.${index}.job_title`}
                                    placeholder="Geni"
                                    type={InputType.text}
                                  />
                                  <ErrorMessage
                                    name={`testimonials.${index}.job_title`}
                                  >
                                    {(msg) => (
                                      <div className="error-ms">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>
                                <div className="fields">
                                  <span className="label">
                                    Text description
                                  </span>
                                  <FormField
                                    inputClassName={`input ${
                                      (errors as any)?.testimonials?.[index]
                                        ?.description &&
                                      (touched as any).testimonials?.[index]
                                        ?.description
                                        ? 'error'
                                        : ''
                                    }`}
                                    fieldName={`testimonials.${index}.description`}
                                    placeholder="Text"
                                    type={InputType.text}
                                  />
                                  <ErrorMessage
                                    name={`testimonials.${index}.description`}
                                  >
                                    {(msg) => (
                                      <div className="error-ms">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </>
                    )}
                  </FieldArray>
                  {!isOneBtn ? (
                    <div className="button_container">
                      <Button
                        onClick={onBack}
                        t={t}
                        title="Back"
                        className="av__content__buttons-back"
                      />
                      <Button
                        className={`next ${
                          !isValid ? 'invalid' : `${isValid}`
                        }`}
                        type="submit"
                        title="Next"
                        t={t}
                      />
                    </div>
                  ) : (
                    <div className="btn_container">
                      <Button
                        className="next button one-btn"
                        type="submit"
                        t={t}
                        title={'Save'}
                      />
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Activation>
    </div>
  );
};

export default WhatPeopleSay;
