export enum CareerPageModalEnum {
  CREATE_CAREER = 'create career',
  JOB_OPENINGS = 'job openings',
  ORGANIZATION_CULTURE = 'organization culture',
  WHAT_PEOPLE_SAY = 'what people say',
  MATCH_PROFILE = 'match profile',
  UPLOAD_VIDEO = 'upload video',
  OUR_BENEFITS = 'our benefits',
  BE_IN_TOUCH = 'be touch',
  CONGRATS = 'congratulations'
}
