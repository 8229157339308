import React, { FC } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { IMessage } from 'core/interface/contact';
import { InputType } from 'core/enum/inputType';
import FormField from 'components/FormField/Index';
import { initialValues, validation } from './form';
import useLocales from '../../hooks/useLocales';

import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer/Index';

import './index.scss';

const ContactUs: FC = (): JSX.Element => {
  const { t } = useLocales();

  const handleSubmit = async (
    values: IMessage,
    e: FormikHelpers<IMessage>
  ): Promise<void> => {
    e.resetForm();
  };

  return (
    <div className="main">
      <Navbar />
      <div className="contact">
        <Image name="contact.svg" className="contact__image" />
        <div className="contact__form">
          <div className="contact__form__left">
            <h1 className="contact__form__left-title">Contact Us</h1>
            <Formik
              initialValues={initialValues}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
              validationSchema={validation(t)}
            >
              {({ isValid, setFieldValue, errors }) => (
                <Form>
                  <div className="field">
                    <span className="label">Name</span>
                    <FormField
                      inputClassName="input"
                      fieldName="name"
                      type={InputType.text}
                      placeholder="Name"
                    />
                  </div>
                  <div className="field">
                    <span className="label">Email</span>
                    <FormField
                      inputClassName="input"
                      fieldName="email"
                      type={InputType.email}
                      placeholder="Email"
                    />
                  </div>
                  <div className="field">
                    <span className="label">Phone Number</span>
                    <FormField
                      inputClassName="input"
                      fieldName="phone"
                      type={InputType.text}
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="field">
                    <span className="label">Message</span>
                    <textarea
                      className={`input area ${errors.message ? 'error' : ''}`}
                      placeholder="Message"
                      onChange={(e) => setFieldValue('message', e.target.value)}
                    />
                  </div>
                  <Button
                    t={t}
                    title="Send"
                    type="submit"
                    className={`send ${!isValid ? 'invalid-btn' : ''}`}
                  />
                </Form>
              )}
            </Formik>
          </div>
          <div className="contact__form__right">
            <p className="contact__form__right-about">
              You are always welcome to come and have a coffee with us and
              discuss the various opportunities of collaboration OR just to have
              a chat about your current recruitment challenges.
            </p>
            <div className="contact__form__right__location">
              <span className="contact__form__right__location-title">
                Our locations
              </span>
              <div className="contact__form__right__location-location">
                <span className="contact__form__right__location-location-circle">
                  <Image name="location.svg" width={12} height={12} />
                </span>
                <span className="contact__form__right__location-location-title">
                  Wildenborch 6, 1112 XB te Diemen
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footers">
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
