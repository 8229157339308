import React, { FC, useState } from 'react';
import { IRangeButtonProps } from 'core/interface/rangeButton';
import InputRange, { InputRangeClassNames } from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import './Index.scss';

const RangeButton: FC<IRangeButtonProps> = ({
  rangeButton,
  maxValue = 9,
  minValue = 1,
  onChange,
  values
}): JSX.Element => {
  const [value, setValue] = useState<number>(1);

  const classNames: InputRangeClassNames = {
    activeTrack: 'active-track',
    disabledInputRange: '',
    inputRange: 'range',
    labelContainer: '',
    maxLabel: `max-label ${value === maxValue ? 'disable' : ''}`,
    minLabel: `min-label ${value === minValue ? 'disable' : ''}`,
    slider: `inputRange ${value === maxValue ? 'max' : ''}`,
    track: `track ${value === maxValue ? 'max' : ''}`,
    sliderContainer: 'slider-container',
    valueLabel: 'value-label'
  };
  return (
    <div className="range">
      <span className="range-label">{rangeButton.label}</span>
      <div className="range__slider">
        <InputRange
          classNames={classNames}
          maxValue={maxValue}
          minValue={minValue}
          value={values ? values : value}
          onChangeComplete={value => setValue(+value)}
          onChange={(value) => onChange({ label: rangeButton.label, value: Number(value) })
          }
        />
        {(value || values) !== maxValue && <span className="range__slider-point"></span>}
      </div>
    </div>
  );
};

export default RangeButton;
