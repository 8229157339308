import { IAutocompleteItem } from 'core/interface/autocompleteItem';
import { ITSkill, InitialValueBody } from 'core/interface/itSkill';
import { SaveComputerSkills } from '../../redux/slices/computer-skills';
import { SaveSkills } from '../../redux/slices/skills';
import { store } from '../../redux/store';
import ApiBase from 'services/base/apiBase';
import { prepareUpdatedData } from '../../helpers/stateChanges';

export class SkillService {
  private static apiBase = new ApiBase();

  public static async getSkills(
    candidateId: number
  ): Promise<ITSkill[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/candidates/${candidateId}/it-skills/`
      );
      dispatch(SaveSkills(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async getSkillsList(
    search: string
  ): Promise<IAutocompleteItem[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/fixtures/skills/?q=${search}&lang=en`
      );
      dispatch(SaveComputerSkills(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async addSkill(
    candidate_id: number,
    body: InitialValueBody
  ): Promise<IAutocompleteItem[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.postAsync(
        `/candidates/${candidate_id}/it-skills/`,
        body
      );
      const updatedState = prepareUpdatedData(
        'create',
        'skills',
        res,
        'skills'
      );
      dispatch(SaveSkills(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async updateSkill(
    candidate_id: number,
    skill_id: number,
    body: InitialValueBody
  ): Promise<IAutocompleteItem[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.updateAsync(
        `/candidates/${candidate_id}/it-skills/${skill_id}/`,
        body
      );
      const updatedState = prepareUpdatedData(
        'update',
        'skills',
        res,
        'skills'
      );
      dispatch(SaveSkills(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async removeSkill(
    candidate_id: number,
    id: number
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.deleteAsync(
        `/candidates/${candidate_id}/it-skills/${id}/`
      );
      const updatedState = prepareUpdatedData(
        'delete',
        'skills',
        {
          id: id
        },
        'skills'
      );
      dispatch(SaveSkills(updatedState));
      return res;
    } catch (error) {
      console.log(error);

      return null;
    }
  }
}
