import React, { FC, FormEvent, useEffect, useState } from 'react';
import { IWritableChip } from 'core/interface/writableChip';
import Image from 'components/image/Image';

import './Index.scss';

const WritableChip: FC<IWritableChip> = ({
  onSelectedValues,
  className,
  inputClassName,
  placeholder,
  defaultValues,
  handleError,
  setTouch
}): JSX.Element => {
  const [values, setValues] = useState<string[]>([]);
  const [typedValue, setTypedValue] = useState<string>('');

  useEffect(() => {
    onSelectedValues(values);
    if (handleError) {
      if (!values.length) {
        handleError((prevErrors: any) => ({
          ...prevErrors,
          requirementsError: true
        }));
      } else {
        handleError((prevErrors: any) => ({
          ...prevErrors,
          requirementsError: false
        }));
      }
    }
  }, [values]);

  const handleInput = (e: FormEvent<HTMLInputElement>): void => {
    const value = (e.target as HTMLInputElement).value.trim();
    if (
      (e.nativeEvent as InputEvent).inputType !== 'insertLineBreak'
    ) {
      setTypedValue(value);
    }
  };

  const handleRemove = (value: string): void => {
    const updatedValues = values?.filter((v) => v !== value);
    setValues(updatedValues);
  };

  useEffect(() => {
    if (defaultValues) {
      setValues(defaultValues);
    }
  }, [defaultValues]);

  const handleEnter = (): void => {
    if (typedValue !== '') {
      setValues((prevValues) => [...prevValues, typedValue]);
      setTypedValue('');
      onSelectedValues(values);
      setTouch && setTouch();
    }
  };

  console.log('====================================');
  console.log(typedValue);
  console.log('====================================');

  return (
    <div className={`wchip ${className ?? ''}`}>
      <input
        value={typedValue}
        placeholder={placeholder}
        className={`${inputClassName ?? ''} input`}
        type="text"
        onInput={(e) => handleInput(e)}
        onKeyDown={(e) => {
          if (e?.code === 'Enter') {
            e?.preventDefault();
            handleEnter();
          }
        }}
      />
      {values?.map((value, index) => (
        <span className="chip-selected" key={index}>
          {value}{' '}
          <span onClick={() => handleRemove(value)}>
            <Image
              className="remove"
              name="removes.svg"
              width={10}
              height={10}
            />
          </span>
        </span>
      ))}
    </div>
  );
};

export default WritableChip;
