import { IAction } from 'core/interface/action';
import { IAutocompleteItem } from 'core/interface/autocompleteItem';

export const SAVE_SKILLS = 'save-computer-skills';
export const SAVE_ALL_SKILLS = 'save-computer-all-skills';

const defaultState = {
  computerSkills: null,
  computerAllSkills: null
};

export const SaveComputerSkills = (value: IAutocompleteItem[]) => ({
  type: SAVE_SKILLS,
  payload: value
});
export const SaveComputerAllSkills = (value: IAutocompleteItem[]) => ({
  type: SAVE_ALL_SKILLS,
  payload: value
});

const computerSkillsReducer = (
  state = defaultState,
  action: IAction<IAutocompleteItem[]>
) => {
  switch (action.type) {
    case SAVE_SKILLS:
      return {
        ...state,
        computerSkills: action.payload
      };
    case SAVE_ALL_SKILLS:
      return {
        ...state,
        computerAllSkills: action.payload
      };
    default:
      return state;
  }
};

export default computerSkillsReducer;
