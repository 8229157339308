import React, { FC, useEffect, useState } from 'react';

import useLocales from 'hooks/useLocales';
import { JobService } from 'services/jobs/job';
import { RequestsService } from 'services/requests/request';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import IncomingRequests from 'components/shared/IncomingRequests/Index';
import Popup from 'components/shared/Popups/Popup/Index';
import ProfilePopUp from 'components/shared/ProfilePopUp/Index';
import { CircularProgress, MenuItem, Select } from '@material-ui/core';
import Image from 'components/image/Image';
import OutgoingRequests from 'components/shared/OutgoingRequests/Index';
import Navbar from 'components/Navbar';
import MatchResultHeader from 'components/matchResultHeader';
import { useParams } from 'react-router';
import Footer from 'components/Footer/Index';
import NoData from 'components/shared/NoData/Index';
import { getDateFormatter } from 'helpers/dateConverter';
import { STATUS_TYPES } from 'core/constants/jobCardConstant';

import './Index.scss';

const FindCandidate: FC = (): JSX.Element => {
  const { t } = useLocales();
  const { applications } = useSelector(
    (state: RootState) => state.applications
  );
  const [openModal, setOpenModal] = useState(false)
  const [getOneRequest, setOneRequest] = useState<any>(null)

  const { singleRequest }: any = useSelector(
    (state: RootState) => state.requests
  );
  const { jobs } = useSelector((state: RootState) => state.jobs);
  const { id } = useParams();
  const { singleJob: job } = useSelector((state: RootState) => state.jobs);
  const [filteredApplications, setFilteredApplications] = useState<any>([])
  const [lastFilterType, setLastFilterType] = useState<number | null>();
  const [filteredJobs, setFilteredJobs] = useState<any>([])
  const [lastFilterStatus, setLastFilterStatus] = useState<number | null>();

  const [statusCounts, setStatusCounts] = useState<any>({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0
  });

  const getJobById = () => {
    JobService.getJobByIdOrSlug(id ? +id : 0);
  }

  useEffect(() => {
    getJobById();
  }, []);


  const [applicationCounts, setApplicationCounts] = useState<{ incomingCount: number; hiredCount: number }>({
    incomingCount: 0,
    hiredCount: 0
  });

  const [jobPosts, setJobPosts] = useState([])
  const [selectedJob, setSelectedJob] = useState<any>("")

  const handleModal = (open: boolean, id: number) => {
    setOpenModal(open)
    const oneElement = applications?.results?.find((el: any) => el.id === id)
    setOneRequest(oneElement)
  }

  const handleClosePopup = () => {
    setOpenModal(false)
    setOneRequest(null)
  }

  useEffect(() => {
    if (selectedJob && jobs.results) {
      const filteredApps = applications?.results.filter((app: any) => app.job.title === selectedJob);
      setFilteredApplications(filteredApps);
    } else {
      setFilteredApplications(applications?.results || []);
    }
  }, [selectedJob, applications]);

  useEffect(() => {
    const newStatusCounts: any = { ...statusCounts };
    Object.keys(newStatusCounts).forEach((key) => {
      newStatusCounts[key] = 0;
    });
    singleRequest?.results?.forEach((result: { status: number }) => {
      const status = result?.status;
      if (status in newStatusCounts) {
        newStatusCounts[status]++;
      }
    });
    setStatusCounts(newStatusCounts);
    setFilteredJobs(singleRequest?.results)
  }, [singleRequest])


  useEffect(() => {
    const newApplications =  applications?.results?.reduce((count: any, el: { stage: number; }) => {
      if (el.stage === 0) {
        count++;
      }
      return count;
    }, 0)

    const hired =  applications?.results?.reduce((count: any, el: { stage: number; }) => {
      if (el.stage === 5) {
        count++;
      }
      return count;
    }, 0)

    setApplicationCounts({
      hiredCount: hired,
      incomingCount: newApplications
    })

  }, [filteredApplications])

  useEffect(() => {
    const jobNames = jobs?.results?.map((el: any) => el.title)
    setJobPosts(jobNames)
  }, [jobs])


  const changeStage = (id: number, data: any) => {
    JobService.manageJob(id, data)
  }

  const getApplications = (): void => {
    JobService.getApplications(id ? +id : 0);
  };

  const getCandidateRequests = (): void => {
    RequestsService.getRequest(id ? +id : 0);
  };
  const cancelHandler = (id: number) => {
    JobService.rejectApplication(id)
  }

  const jobNames = () => {
    JobService.getJobs();
  }

  const stageOutgoingHandler = (id: number, data: any) => {
    RequestsService.changeRequest(data, id, "find-candidate")
  }

  useEffect(() => {
    getApplications()
    getCandidateRequests()
    jobNames()
  }, [])


  const handleStagefilter = (type: number) => {
    let filteredApps = null;
    if (lastFilterType === type) {
      filteredApps = applications?.results;
      setFilteredApplications(filteredApps)
      setLastFilterType(null);
    } else {
      filteredApps = applications?.results.filter((app) => app.stage === type);
      setFilteredApplications(filteredApps)
      setLastFilterType(type);
    }
  };


  const handleStatusFilter = (type: number) => {
    let filteredJob = null;
    if (lastFilterStatus === type) {
      filteredJob = singleRequest?.results;
      setFilteredJobs(filteredJob)
      setLastFilterStatus(null);
    } else {
      filteredJob = singleRequest?.results?.filter((app: { status: number }) => app.status === type);
      setFilteredJobs(filteredJob)
      setLastFilterStatus(type);
    }
  };


  return (
    <>
      <Navbar />
      <MatchResultHeader data={job} />
      <div className="candidateOver">
        <div className="candidateOver__right">
          <div className="candidateOver__right__top">
            <span className="candidateOver__right__top-title">
              {t('Incoming request')}
            </span>
            <div className="candidateOver__right__top__counts">
              <button className="candidateOver__right__top__counts-warn btn" onClick={() => handleStagefilter(0)}>
                {lastFilterType === 0 && <Image name="check.svg" />}
                {t('New applicant ')}
                {(`(${applicationCounts.incomingCount})`)}
              </button>
              <button className="candidateOver__right__top__counts-pass btn" onClick={() => handleStagefilter(5)}>
                {lastFilterType === 5 && <Image name="check.svg" />}
                {t('Candidate Hired ')}
                {`(${applicationCounts.hiredCount})`}
              </button>
            </div>
            <Select
              displayEmpty
              value={selectedJob}
              className="select_jobs"
              placeholder='Select your job post'
              onChange={(e) => setSelectedJob(e?.target?.value)}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return <em>Select your job post</em>;
                }
                return selected
              }}
              IconComponent={() => (
                <>
                  <Image
                    name="right-arrow.svg"
                  />
                </>
              )}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {jobPosts?.map((el: any) => (
                <MenuItem value={el} key={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="candidateOver__right__bottom">
            {applications === null ?
              <div className="loadingContainer">
                <CircularProgress className="loading" />
              </div> : filteredApplications.length ? filteredApplications?.map((item: any) => (
                <IncomingRequests
                  key={item.id}
                  id={item.id}
                  name={item.candidate.name}
                  email={item.candidate.email}
                  sub_title={item.candidate.pitch}
                  icon={item.candidate.avatar}
                  score={item.score}
                  culture_score={item.culture_score}
                  date={item.created_at}
                  hired_at={item.offered_at}
                  industry={item.candidate.industries}
                  stage={item.stage}
                  status={item.status}
                  setStage={changeStage}
                  cancel={cancelHandler}
                  setOpen={handleModal}
                />
              )) : <div className="no_data">
                <NoData />
              </div>}
          </div>
        </div>

        <div className="candidateOver__right">
          <div className="candidateOver__right__top">
            <span className="candidateOver__right__top-title">
              {t('Outgoing requests')}
            </span>
            <div className="candidateOver__right__top__counts">
              {STATUS_TYPES?.map((statusType: any) => (
                <button
                  onClick={() => handleStatusFilter(statusType.value)}
                  key={statusType.value}
                  className="candidateOver__right__top__counts-pass btn"
                >
                  {lastFilterStatus === statusType.value && <Image name="check.svg" />}
                  {t(statusType.label)} ({statusCounts[statusType.value]})
                </button>
              ))}
            </div>
          </div>
          <div className="candidateOver__right__bottom">
            {singleRequest === null ?
              <div className="loadingContainer">
                <CircularProgress className="loading" />
              </div> : filteredJobs?.length ? filteredJobs?.map((item: any, index: any) => (
                <OutgoingRequests
                  key={item.id}
                  id={item.id}
                  name={item.candidate.name}
                  email={item.candidate.email}
                  sub_title={item.candidate.pitch}
                  icon={item.candidate.avatar}
                  score={item.score}
                  culture_score={item.culture_score}
                  date={item.created_at}
                  modified_at={item.modified_at}
                  industry={item.candidate.industries}
                  stage={item.stage}
                  responded_at={item.responded_at}
                  status={item.status}
                  stageOutgoingHandler={stageOutgoingHandler}
                />
              )) : <div className="no_data">
                <NoData />
              </div>}
          </div>
        </div>
      </div>
      {openModal && getOneRequest && (
        <Popup
          className='popUp'
          title="Match Profile"
          description={getOneRequest?.job?.title}
          onClose={handleClosePopup}
        >
          <ProfilePopUp getOneRequest={getOneRequest?.candidate}
            getJob={getOneRequest?.job}
            id={getOneRequest?.id}
            onClick={() => changeStage(getOneRequest?.id, { status: 4, responded_at: getDateFormatter(new Date(), "YYYY-MM-DDTHH:mm:ss.000000Z") })} />
        </Popup>
      )}
      <div className="footers">
        <Footer />
      </div>
    </>
  );
};

export default FindCandidate;
