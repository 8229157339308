import * as Yup from 'yup';
import { TFunction } from 'i18next';

const initialValues: any = {
  password: '',
  repeatPassword: ''
};

const validation = (t: TFunction) => {
  return Yup.object({
    password: Yup.string().min(5).required(t('required')),
    repeatPassword: Yup.string().min(5).required(t('required'))
  });
};

export { initialValues, validation };
