export const fetchImageAndConvertToBase64 = async (
  url: string
): Promise<string> => {
  const response = await fetch(url);
  const blob = await response.blob();

  const img = document.createElement('img');
  img.src = URL.createObjectURL(blob);

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  return new Promise((resolve) => {
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      context?.drawImage(img, 0, 0);
      const base64data = canvas.toDataURL('image/png');
      URL.revokeObjectURL(img.src);
      resolve(base64data);
    };
  });
};
