import { IAction } from 'core/interface/action';
import { IActivationEducation } from 'core/interface/activation';

export const SAVE_EDUCATION_FORM = 'save-education';
export const REMOVE_EDUCATION_FORM = 'remove-education';

const defaultState = {
  educationForm: null
};

export const SaveEducationForm = (value: IActivationEducation) => ({
  type: SAVE_EDUCATION_FORM,
  payload: value
});
export const RemoveEducationForm = () => ({
  type: REMOVE_EDUCATION_FORM,
  payload: null
});

const educationFormReducer = (
  state = defaultState,
  action: IAction<IActivationEducation>
) => {
  switch (action.type) {
    case SAVE_EDUCATION_FORM:
      return {
        ...state,
        educationForm: action.payload
      };
    case REMOVE_EDUCATION_FORM:
      return {
        ...state,
        educationForm: null
      };
    default:
      return state;
  }
};

export default educationFormReducer;
