import React, { FC, useEffect } from 'react';
import useLocales from 'hooks/useLocales';
import { SubscribtionPlansService } from 'services/subscribtionPlans/subscribtionPlans';

import './Index.scss';

const RecruitMembership: FC = (): JSX.Element => {
  const { t } = useLocales();

  const handleGetPlanData = async () => {
    try {
      const response = await SubscribtionPlansService.getPlansData();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleGetPlanData();
  }, []);

  return (
    <>
      <div className="container">
        <div className="title">{t('my_recruit_membership')}</div>
        <div className="info_section">
          <div className="info_item">
            <div className="left_side">{t('current_plan')}</div>
            <div className="right_side">premium</div>
          </div>
          <div className="info_item">
            <div className="left_side">{t('started')}</div>
            <div className="right_side">01-01-2019</div>
          </div>
          <div className="info_item">
            <div className="left_side">{t('expires')}</div>
            <div className="right_side">16-10-2024</div>
          </div>
          <div className="info_item">
            <div className="left_side">{t('remaining_apps')}</div>
            <div className="right_side">8</div>
          </div>
          <div className="info_item">
            <div className="left_side">{t('remaining_profile_requests')}</div>
            <div className="right_side">8</div>
          </div>
          <div className="info_item">
            <div className="left_side">{t('next_limit_reset')}</div>
            <div className="right_side">16-11-2023</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecruitMembership;
