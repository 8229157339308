import { IExtraActivitiesForm } from 'core/interface/extraActivities';
import { isValidUrl } from 'helpers/isValudURL';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

const validation = (t: TFunction) => {
  return Yup.object({
    title: Yup.string().required(t('required')),
    description: Yup.string().required(t('required')),
    website: Yup.string()
      .notRequired()
      .default('http://www.test.com')
      .test('isValidUrl', 'Invalid URL', function (value) {
        return isValidUrl(value ?? '');
      }),
    start: Yup.object({
      day: Yup.string().required(),
      month: Yup.string().required(),
      year: Yup.string().required()
    }),
    end: Yup.object({
      day: Yup.string().required(),
      month: Yup.string().required(),
      year: Yup.string().required()
    })
  });
};

const initialValues: IExtraActivitiesForm = {
  title: '',
  description: '',
  end: {
    day: '',
    month: '',
    year: ''
  },
  start: {
    day: '',
    month: '',
    year: ''
  },
  website: ''
};

export { validation, initialValues };
