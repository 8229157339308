import React, { FC, useState } from 'react';
import Button from 'components/Button/Index';
import { IAssessmentPopup } from 'core/interface/assessmentPopup';
import { IMe } from 'core/interface/me';
import { useSelector } from 'react-redux';
import { JobService } from 'services/jobs/job';

import './Index.scss';

const AssessmentPopup: FC<IAssessmentPopup> = ({ t, onBackClick }) => {
  const me = useSelector((state: IMe) => state.user).user;
  const [loading, setLoading] = useState<boolean>(false);

  const handleBack = (): void => {
    onBackClick(false);
  };

  const handleStart = async (): Promise<void> => {
    getAssessments();
  };

  const getAssessments = async (): Promise<void> => {
    setLoading(true);
    try {
    const res=  await JobService.postassesments(me.id);
    if (res.test_logon_url) window.location.href = res.test_logon_url;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="assessment">
      <p className="assessment-paragraph">
        Thank you for completing the details. The more we love youknow the
        better we can match you. Your details areIn addition, you are always
        protected with us.
      </p>
      <div className="assessment__buttons">
        <Button
          onClick={handleBack}
          t={t}
          className="assessment__buttons-btn back"
          title="back"
        />
        <Button
          isLoading={loading}
          onClick={handleStart}
          t={t}
          className="assessment__buttons-btn start"
          title="start_assessment"
        />
      </div>
    </div>
  );
};

export default AssessmentPopup;
