import React, { FC } from 'react';
import {
  freePricing,
  premiumPricing,
  proPricing
} from 'core/constants/pricing';
import PricingOption from 'components/feature/PricingOption/Index';
import useLocales from 'hooks/useLocales';
import Image from 'components/image/Image';
import FAQItems from 'components/feature/FAQitems';
import { faqItems } from 'core/constants/faqItems';
import Footer from 'components/Footer/Index';
import Navbar from 'components/Navbar';

import './Index.scss';

const MembershipInfo: FC = (): JSX.Element => {
  const { t } = useLocales();

  return (
    <div className="memberInfo">
      <Navbar />
      <div className="memberInfo__top">
        <span className="memberInfo__top-title">Our Pricing Option</span>
        <div className="memberInfo__top__pricing">
          <PricingOption
            className="memberInfo__top__pricing-option"
            t={t}
            details={freePricing}
          />
          <PricingOption
            className="memberInfo__top__pricing-option"
            t={t}
            details={premiumPricing}
          />
          <PricingOption
            t={t}
            details={proPricing}
          />
        </div>
      </div>
      <div className="memberInfo__include">
        <span className="memberInfo__include-title">All plans include</span>
        <div className="memberInfo__include__plans">
          <div className="memberInfo__include__plans__items">
            <Image name="blue-check.svg" width={19} height={19} />
            <span className="memberInfo__include__plans__items-title">
              Free talent assessment
            </span>
          </div>
          <div className="memberInfo__include__plans__items">
            <Image name="blue-check.svg" width={19} height={19} />
            <span className="memberInfo__include__plans__items-title">
              Free personal branding page
            </span>
          </div>
          <div className="memberInfo__include__plans__items">
            <Image name="blue-check.svg" width={19} height={19} />
            <span className="memberInfo__include__plans__items-title">
              No adds and no other spam!
            </span>
          </div>
          <div className="memberInfo__include__plans__items">
            <Image name="blue-check.svg" width={19} height={19} />
            <span className="memberInfo__include__plans__items-title">
              You are in control over who has access to your data
            </span>
          </div>
          <div className="memberInfo__include__plans__items">
            <Image name="blue-check.svg" width={19} height={19} />
            <span className="memberInfo__include__plans__items-title">
              Option yo temporarily turn of your profile
            </span>
          </div>
          <div className="memberInfo__include__plans__items">
            <Image name="blue-check.svg" width={19} height={19} />
            <span className="memberInfo__include__plans__items-title">
              Never look for your resumeagain, access whereveryou are
            </span>
          </div>
        </div>
      </div>
      <div className="memberInfo__accept">
        <span className="memberInfo__accept-title">
          We accept the following payment methods
        </span>
        <div className="memberInfo__accept__methods">
          <span className="memberInfo__accept__methods-method">
            <Image name="deal.svg" width={74} height={66} />
          </span>
          <span className="memberInfo__accept__methods-method">
            <Image name="bank.svg" width={74} height={66} />
          </span>
        </div>
        <Image
          name="ssl.svg"
          className="memberInfo__accept-ssl"
          width={70}
          height={70}
        />
      </div>
      <div className="memberInfo__faq">
        {faqItems.map((item, index) => (
          <FAQItems
            key={index}
            title={item.title}
            description={item.description}
            number={`${index + 1 >= 10 ? index : `0${index + 1}`}`}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default MembershipInfo;
