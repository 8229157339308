import React, { FC } from 'react';
import { useNavigate, createSearchParams, useLocation  } from 'react-router-dom';
import { IAuthContent } from 'core/interface/authContent';
import Image from '../image/Image';
import { Role } from 'core/enum/role';

import './index.scss';

const AuthContent: FC<IAuthContent> = ({
  backURL,
  title,
  children,
  onRoleSubmit,
  role
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleRoleChange = (role: Role) => {
    onRoleSubmit(role);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('role', role);

    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(searchParams)}`,
    });
  };

  const habdleBack = (): void => {
    navigate(backURL ? backURL + `?role=${role}` : '');
  };

  return (
    <div className="content">
      {backURL && (
        <div onClick={habdleBack} className="content__arrow">
          <Image name="arrow-left.svg" />
          <span className="content__arrow-text">Back</span>
        </div>
      )}
      <div className="content__middle">
        <Image name="new-rec-logos.svg" width={210} height={95} />
        {title !== 'Reset password' && (
          <div className="content__middle__switcher">
            <div
              className={`content__middle__switcher__button ${
                role === Role.CANDIDATE ? 'left' : 'right'
              }`}
            />
            <span
              onClick={() => handleRoleChange(Role.CANDIDATE)}
              className={`content__middle__switcher-item ${
                role === Role.CANDIDATE ? 'active' : ''
              }`}
            >
              Candidate
            </span>
            <span
              onClick={() => handleRoleChange(Role.FIND_TALENT)}
              className={`content__middle__switcher-item ${
                role === Role.FIND_TALENT ? 'active' : ''
              }`}
            >
              Find talent
            </span>
          </div>
        )}
      </div>
      <span className="content-title">{title}</span>
      <div className="children">{children}</div>
    </div>
  );
};

export default AuthContent;
