import { ISocialNetworkForm } from 'core/interface/aboutMe';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

const initialValues: ISocialNetworkForm = {
  networks: []
};

const validationSchema = (t: TFunction) => {
  return Yup.object({
    networks: Yup.array(
      Yup.object({
        id: Yup.number().optional(),
        network: Yup.string().required(t('required')),
        url: Yup.string()
          .required(t('required'))
          .test('isValidURL', t('Invalid URL'), (value) => {
            if (!value) {
              return true;
            }
            return /^(ftp|http|https):\/\/[^ "]+$/.test(value);
          })
      })
    )
  });
};

export { initialValues, validationSchema };
