export enum DashboardTabEnum {
  ABOUT_ME = 'About Me',
  PREFERENCES = 'preferences',
  SKILLS = 'skills',
  EDUCATION_EXPERIENCE = 'education',
  IMAGE_YOUTUBE = 'image_youtube'
}

export enum ProfileTabEnum {
  ABOUT_ME = 'about me',
  COMPETENCY_SCORES = 'competency scores',
  SKILLS = 'skills',
  EDUCATION_EXPERIENCE = 'education',
  IMAGE_YOUTUBE = 'image_youtube'
}
