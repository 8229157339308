import React, { useEffect, useState } from 'react';
import {
  Formik,
  Form,
  FormikHelpers,
  FieldArray,
  Field,
  ErrorMessage
} from 'formik';
import Button from 'components/Button/Index';
import Activation from 'components/feature/activation/Index';
import ActionButton from 'components/ActionButton/Index';
import FormField from 'components/FormField/Index';
import useLocales from 'hooks/useLocales';
import { InputType } from 'core/enum/inputType';
import { socialNetwork } from 'core/constants/aboutMePopUpConstants';
import Dropdown from 'components/Dropdown';
import { validationSchema } from './form';
import { CMSService } from 'services/cms/cms';

import './Index.scss';

const linkHasChanged = (currentBenefit: any, originalLinks: any) => {
  let hasChanged = false;
  const changedFields: any = {};
  if (currentBenefit.network !== originalLinks.network) {
    hasChanged = true;
  }
  if (currentBenefit.url !== originalLinks.url) {
    hasChanged = true;
  }
  if (hasChanged) {
    changedFields.network = currentBenefit.network;
    changedFields.url = currentBenefit.url;
    changedFields.page = currentBenefit.page;
    return {
      id: currentBenefit.id,
      page: currentBenefit.page,
      changedFields: changedFields
    };
  }
  return null;
};

const BeInTouch: any = ({
  onSubmit,
  onBack,
  onClose,
  setCompanyData,
  companyData,
  isPopup = false,
  className,
  isOneBtn = false,
  companyDataSocial,
  setCompanyDataSocial,
  showTopSide,
  setNewData,
  setLinkData,
  showTitle
}: any): JSX.Element => {
  const { t } = useLocales();
  const [formValues, setFieldValue] = useState<any>({
    contact_email: '',
    contact_phone: '',
    social_media_links: [
      {
        url: '',
        network: ''
      }
    ]
  });

  useEffect(() => {
    setFieldValue({
      contact_email: companyData?.contact_email || '',
      contact_phone: companyData?.contact_phone || '',
      social_media_links: companyDataSocial?.social_media_links ||
        companyData?.social_media_links || [
          {
            url: '',
            network: ''
          }
        ]
    });
  }, [companyData]);

  const handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
    const { contact_email, contact_phone, social_media_links } = values;
    if (setCompanyData) {
      setCompanyData((prev: any) => ({
        ...prev,
        contact_email,
        contact_phone
      }));
      setCompanyDataSocial((prev: any) => ({
        ...prev,
        social_media_links
      }));
      onSubmit();
    } else {
      const data: any = {};
      if (contact_email !== companyData.contact_email) {
        data.contact_email = contact_email;
      }
      if (contact_phone !== companyData.contact_phone) {
        data.contact_phone = contact_phone;
      }
      const newLinks: any[] = [];
      const changedLinks: any[] = [];
      social_media_links.forEach((links: any, index: number) => {
        const originalLinks =
          (companyData.social_media_links || [])[index] || {};
        if (links.isNew) {
          newLinks.push(links);
        } else {
          const result = linkHasChanged(links, originalLinks);
          if (result) {
            changedLinks.push(result);
          }
        }
      });
      data && setNewData(data);
      changedLinks && setLinkData(changedLinks, false);
      newLinks && setLinkData(newLinks, true);
    }
  };

  const handleRemove = (benefit: any) => {
    const { page, id } = benefit;
    CMSService.removeSocialMediaLink(page, id);
  };

  return (
    <div className="who">
      <Activation
        showTopSide={showTopSide}
        title="Be in touch!"
        currentStep={8}
        className={
          isPopup ? '' : `activation-edu ${className ?? ''} ${className}`
        }
        headerIconDisable
        totalSteps={8}
        isCareerPage={true}
        handleModalClose={onClose}
        isPopup={true}
        showTitle={showTitle}
      >
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={validationSchema(t)}
        >
          {({
            setFieldValue,
            isValid,
            values,
            errors,
            touched,
            setFieldTouched
          }) => {
            return (
              <Form>
                <div className="ourBenefits scrollable ">
                  <div className="fields">
                    <span className="label">Contact phone</span>
                    <FormField
                      inputClassName="input"
                      fieldName="contact_phone"
                      placeholder="*** *** ** **"
                      type={InputType.text}
                    />
                  </div>
                  <div className="fields">
                    <span className="label">Email</span>
                    <FormField
                      inputClassName="input"
                      fieldName="contact_email"
                      placeholder="Text"
                      type={InputType.text}
                    />
                  </div>
                  <FieldArray name="social_media_links">
                    {({ push, remove }) => (
                      <>
                        {values?.social_media_links &&
                          values?.social_media_links?.map(
                            (socialItem: any, index: number) => (
                              <div key={index}>
                                {isPopup && (
                                  <div className="addBenefits">
                                    <ActionButton
                                      iconName="remove.svg"
                                      onClick={() => handleRemove(socialItem)}
                                    />
                                    <span>Remove</span>
                                  </div>
                                )}
                                <div className="fields_flex">
                                  <div className="field many autocomplete">
                                    <span className="label">
                                      {t('Profile URL')}
                                    </span>
                                    <Field
                                      name={`social_media_links.${index}.url`}
                                      className={`input ${
                                        (errors as any)?.social_media_links?.[
                                          index
                                        ]?.url &&
                                        (touched as any).social_media_links?.[
                                          index
                                        ]?.url
                                          ? 'error'
                                          : ''
                                      }`}
                                      placeholder="Profile URL"
                                      type="text"
                                    />
                                    <ErrorMessage
                                      name={`social_media_links.${index}.url`}
                                    >
                                      {(msg) => (
                                        <div className="error-ms">{msg}</div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                  <div className="field many">
                                    <span className="label">
                                      {t('Social Media')}
                                    </span>
                                    <Dropdown
                                      placeholder={t('Select Social Media')}
                                      items={socialNetwork}
                                      defaultValue={socialItem.network}
                                      selectedValue={(selectedValue) =>
                                        setFieldValue(
                                          `social_media_links.${index}.network`,
                                          selectedValue
                                        )
                                      }
                                    />
                                    <ErrorMessage
                                      name={`social_media_links.${index}.network`}
                                    >
                                      {(msg) => (
                                        <div className="error-ms">{msg}</div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        <div className="addBenefits">
                          <ActionButton
                            iconName="plus.svg"
                            onClick={() => push({ isNew: true })}
                          />
                          <span>Add Social Media</span>
                        </div>
                      </>
                    )}
                  </FieldArray>
                  {!isOneBtn ? (
                    <div className="button_container">
                      <Button
                        onClick={onBack}
                        t={t}
                        title="Back"
                        className="av__content__buttons-back"
                      />
                      <Button
                        className={`next ${
                          !isValid ? 'invalid' : `${isValid}`
                        }`}
                        type="submit"
                        title="Next"
                        t={t}
                      />
                    </div>
                  ) : (
                    <div className="btn_container">
                      <Button
                        className="next button one-btn"
                        type="submit"
                        t={t}
                        title={'Save'}
                      />
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Activation>
    </div>
  );
};

export default BeInTouch;
