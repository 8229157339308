import {
  ICandidateLanguage,
  ICandidateLanguageData,
  ILanguageBody
} from 'core/interface/candidate-language';
import {
  SaveCandidateLanguages,
  SaveLanguages
} from '../../redux/slices/languages';
import { store } from '../../redux/store';
import ApiBase from 'services/base/apiBase';
import { prepareUpdatedData } from '../../helpers/stateChanges';

export class LanguageService {
  private static apiBase = new ApiBase<ICandidateLanguageData[]>();

  public static async getCandidateLanguage(
    candidate_id: number
  ): Promise<ICandidateLanguageData[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/candidates/${candidate_id}/languages/`
      );
      dispatch(SaveCandidateLanguages(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async setCandidateLanguage(
    candidate_id: number,
    languageBody: ILanguageBody
  ) {
    const { dispatch } = store;
    const res = await this.apiBase.postAsync(
      `/candidates/${candidate_id}/languages/`,
      languageBody
    );
    const updatedState = prepareUpdatedData(
      'create',
      'languages',
      res,
      'candidate_language'
    );
    dispatch(SaveCandidateLanguages(updatedState));
    return res;
  }

  public static async getAllLanguages(
    query: string
  ): Promise<ICandidateLanguage[]> {
    const { dispatch } = store;
    const res = await this.apiBase.getAsync(
      `/fixtures/languages/?q=${query}&lang=en`
    );
    dispatch(SaveLanguages(res));
    return res;
  }

  public static async updateCandidateLanguage(
    candidate_id: number,
    langId: number,
    languageBody: ILanguageBody
  ): Promise<ICandidateLanguage[]> {
    const { dispatch } = store;
    const res = await this.apiBase.updateAsync(
      `/candidates/${candidate_id}/languages/${langId}/`,
      languageBody
    );
    const updatedState = prepareUpdatedData(
      'update',
      'languages',
      res,
      'candidate_language'
    );
    dispatch(SaveCandidateLanguages(updatedState));
    return res;
  }
  public static async removeLanguage(
    candidate_id: number,
    langId: number
  ): Promise<boolean> {
    const { dispatch } = store;
    const res = await this.apiBase.deleteAsync(
      `/candidates/${candidate_id}/languages/${langId}/`
    );
    const updatedState = prepareUpdatedData(
      'delete',
      'languages',
      {
        id: langId
      },
      'candidate_language'
    );
    dispatch(SaveCandidateLanguages(updatedState));
    return res;
  }
}
