import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'redux/store';
// import { enqueueSnackbar } from './notifications';
import { ErrorsEnum } from 'core/enum/error';
import Cookies from 'js-cookie';

const initialState = {
  messages: []
};

const slice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setErrors(state, action) {
      state.messages = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const setRequestError =
  (err: any, isSuccessMessage = false) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    if (err?.response?.data?.detail === ErrorsEnum.INVALID_TOKEN) {
      Cookies.remove('accesstoken');
      window.location.href = '/login';
    }
    let message = '';

    let variant = '';
    if (err.data || err?.response?.data?.detail) {
      message = err?.response?.data?.detail || err.data;
      variant = 'error';
    } else if (err && isSuccessMessage) {
      variant = 'success';
      message = err;
    }
    const { errors } = getState();
    const newMessages = [];
    for (const message of errors.messages) {
      if (message) {
        newMessages.push({
          value: message,
          showed: true,
          variant
        });
      }
    }
    dispatch(
      slice.actions.setErrors([
        ...newMessages,
        {
          value: message,
          showed: false,
          variant
        }
      ])
    );
  };
