import { IAction } from 'core/interface/action';
import { Filters, InvitationList } from 'core/interface/invitations';

export const SAVE_VALUES = 'save-invitations';
export const SET_FILTER = 'set-filter';

const defaultState = {
  filters: {
    page: 1,
    limit: 4,
    offset: 0,
    role: 'candidate'
  },
  invitations: null,
};

export const SaveInvitations = (value: InvitationList) => ({
  type: SAVE_VALUES,
  payload: value
});



export const SetFilter = (field: string, value: any) => ({
  type: SET_FILTER,
  payload: {
    [field]: value
  }
});

const invitataionReducer = (
  state = defaultState,
  action: IAction<InvitationList>
) => {
  switch (action.type) {
    case SAVE_VALUES:
      return {
        ...state,
        invitations: action.payload
      };
    case SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default invitataionReducer;
