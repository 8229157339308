export const benefitsData = [
  {
    title: 'Benefits 1',
    description:
      'Vitae nulla nunc euismod vel nunc euismod velpretium tellus accumsan nulla nunc euismod ve semper. '
  },
  {
    title: 'Benefits 1',
    description:
      'Vitae nulla nunc euismod vel nunc euismod velpretium tellus accumsan nulla nunc euismod ve semper. '
  },
  {
    title: 'Benefits 1',
    description:
      'Vitae nulla nunc euismod vel nunc euismod velpretium tellus accumsan nulla nunc euismod ve semper. '
  },
  {
    title: 'Benefits 1',
    description:
      'Vitae nulla nunc euismod vel nunc euismod velpretium tellus accumsan nulla nunc euismod ve semper. '
  }
];

export const organizationalCulture = [
  {
    width: 127,
    height: 127,
  },
  {
    width: 275,
    height: 275,
  },
  {
    width: 424,
    height: 424,
  },
  {
    width: 183,
    height: 183,
  },
  {
    width: 210,
    height: 210,
  },
  {
    width: 133,
    height: 133,
  }
];
