import { IAddLanguageForm } from 'core/interface/candidate-language';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

const initialValues: IAddLanguageForm = {
  language: '',
  proficiency: 0
};

const validationSchema = (t: TFunction) => {
  return Yup.object({
    language: Yup.string().required(t('required')),
    proficiency: Yup.number().min(1).required()
  });
};

export { initialValues, validationSchema };
