import React, { FC, useRef, useState, useEffect } from 'react';
import {
  Autocomplete as Acomplete,
  AutocompleteRenderInputParams,
  TextField
} from '@mui/material';
import { useField } from 'formik';
import useLocales from 'hooks/useLocales';
import Image from 'components/image/Image';
import { IAutocomplete, OptionType } from 'core/interface/autocomplete';
import { IAutocompleteValue } from 'core/interface/education-side';
import './Index.scss';

const Autocomplete: FC<IAutocomplete> = ({
  options,
  placeholder,
  show_location = false,
  onChange,
  onInputChange,
  value,
  className,
  onClose,
  values,
  show_arrow = false,
  onFocus,
  disabled = false,
  fieldName,
  loadMore
}) => {
  const [rotate, setRotate] = useState<boolean>(false);
  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const listboxRef = useRef<HTMLUListElement | null>(null);
  const [currentValue, setCurrentValue] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [field, meta] = useField(fieldName);
  const { t } = useLocales();
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  useEffect(() => {
    if (value) {
      setSelectedOption(value);
    }
  }, [value]);

  useEffect(() => {
    if (value == '' || value) {
      setCurrentValue(value);
    }
  }, [value]);

  const handleOnClose = () => {
    setRotate(false);
    if (onClose) onClose();
  };

  const handleOpen = (): void => {
    setRotate(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetElement = event.target as Element;

      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(targetElement) &&
        !targetElement.classList.contains('MuiAutocomplete-option')
      ) {
        setRotate(false);
        if (onClose) onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [autocompleteRef, onClose]);

  const handleScroll = (event: any) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom) {
      setScrollPosition(event.target.scrollTop);
      loadMore && loadMore();
    }
  };

  useEffect(() => {
    if (listboxRef.current) {
      listboxRef.current.scrollTop = scrollPosition;
    }
  }, [options]);

  return (
    <div className="complete" ref={autocompleteRef}>
      {show_location && (
        <Image
          className="location"
          name="locations.svg"
          width={16}
          height={16}
        />
      )}
      {show_arrow && !show_location && (
        <span onClick={() => setRotate((prev) => !prev)}>
          <Image
            className={`location ${rotate ? 'rotate' : ''}`}
            name="down-arrow.svg"
            width={16}
            height={16}
          />
        </span>
      )}
      <Acomplete
        disabled={disabled}
        open={rotate}
        defaultValue={currentValue}
        onClose={handleOnClose}
        onFocus={(event) => {
          if (onFocus) {
            const val = (event.target as HTMLInputElement).value;
            onFocus(val || '');
          }
        }}
        value={currentValue}
        className={`customAutocomplete ${className ?? ''} ${
          meta.touched && meta.error ? 'error' : ''
        }`}
        freeSolo
        ListboxProps={{
          className: 'scrollable scrollableInput',
          onScroll: handleScroll,
          ref: listboxRef
        }}
        options={options.map((option: OptionType) => option.title)}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...field}
            key={params.id}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: !disabled && currentValue && (
                <span
                  className="close-autocom"
                  onClick={() => {
                    onChange && onChange('');
                    onInputChange && onInputChange('');
                    handleOnClose();
                    setCurrentValue('');
                  }}
                >
                  <Image
                    className="clear-autocomplete"
                    name="close.svg"
                    aria-label="clear"
                  />
                </span>
              )
            }}
            placeholder={placeholder}
          />
        )}
        onBlur={(event: React.FocusEvent<HTMLDivElement, Element>) => {
          const val = (event.target as HTMLInputElement).value;
          const option = options.find((opt: OptionType) => opt.title === val);
          if (val.trim() === '') {
            setCurrentValue('');
            return;
          }
          if (!option) {
            setCurrentValue(selectedOption || '');
          } else {
            const newValue = option ? option.title : val;
            if (newValue === '') {
              setCurrentValue('');
              return;
            }
            if (newValue && value === '') {
              setCurrentValue(newValue);
              return;
            }
            const current = values.find(
              (e: IAutocompleteValue) =>
                e.display_name === newValue || e.name === newValue
            );
            if (current) {
              onChange && onChange(val);
              onInputChange && onInputChange(val);
            } else {
              if (value) {
                setCurrentValue(value);
              }
            }
          }
        }}
        onChange={(_, value) => {
          setSelectedOption(value as string);
          onChange && onChange(value as string);
        }}
        onInputChange={(e, inputValue) => {
          if ((inputValue == '' || inputValue) && e?.isTrusted) {
            setCurrentValue(inputValue);
            onInputChange && onInputChange(inputValue);
          }
        }}
        onOpen={handleOpen}
      />
      {meta.touched && meta.error && (
        <span className="error-message"> {t('required')} </span>
      )}
    </div>
  );
};

export default Autocomplete;
