import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import PricingOption from 'components/feature/PricingOption/Index';
import {
  freePricing,
  premiumPricing,
  proPricing
} from 'core/constants/pricing';
import { ITranslate } from 'core/interface/translate';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';

import './Index.scss';

const UpgradePlanPopup: FC<ITranslate> = ({ t }): JSX.Element => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/membership-pricing');
  };
  return (
    <div className="plan">
      <div className="plan__options">
        <PricingOption t={t} details={freePricing} />
        <PricingOption
          t={t}
          details={premiumPricing}
        />
        <PricingOption
          t={t}
          details={proPricing}
          
        />
      </div>
      <div className="plan__accept">
        <span className="plan__accept-title">{t('accept_methods')}</span>
        <div className="plan__accept__banks">
          <span className="plan__accept__banks-image">
            <Image name="deal.svg" width={74} height={66} />
          </span>
          <span className="plan__accept__banks-image">
            <Image name="bank.svg" width={74} height={66} />
          </span>
        </div>
      </div>
      <div className="plan__ssl_info">
        <span></span>
        <Button
          className="plan__ssl_info-btn"
          t={t}
          title="more_info"
          onClick={handleRedirect}
        />
        <Image name="ssl.svg" width={98} height={98} />
      </div>
    </div>
  );
};

export default UpgradePlanPopup;
