import React from 'react';
import Image from 'components/image/Image';
import RadioButton from 'components/RadioGroup/Index';
import { tocapitalize } from 'utils/formatString';
import { IUpgradePlanPopups } from './types';

import './Index.scss';

const UpgradePlanPopups = ({ plan }: IUpgradePlanPopups): JSX.Element => {
  const upgratedPlan = tocapitalize(plan)
  const handleOnChange = () => {};

  return (
    <div className="pop">
      <div className="pop__radios">
        <span className="pop__radios-title">Membership</span>
        <RadioButton
          className="member-radio"
          isRow={false}
          buttons={[
            {
              label: `${upgratedPlan} membership for 3 months`,
              value: '1',
              right_text: '€ 12,10'
            },
            {
              label: `${upgratedPlan} membership for 6 months`,
              value: '2',
              right_text: '€ 12,10'
            },
            {
              label: `${upgratedPlan} membership for 12 months`,
              value: '3',
              right_text: '€ 12,10'
            }
          ]}
          onChange={handleOnChange}
        />
      </div>
      <div className="pop__choose">
        <span className="pop__choose-title">Choose payment method</span>
        <span className="pop__choose-total">
          Total (including VAT) &nbsp; <strong>€ 12,10</strong>
        </span>
        <div className="pop__choose__items">
          <span className="pop__choose__items-method">
            <Image name="deal.svg" width={74} height={66} />
          </span>
          <span className="pop__choose__items-method">
            <Image name="bank.svg" width={74} height={66} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlanPopups;
