const orderColumns = [
  { label: 'Description', key: 'description' },
  { label: 'Price', key: 'price' },
  { label: 'VAT', key: 'vat' },
  { label: 'Quantity', key: 'qty' },
  { label: 'Total', key: 'total' }
];
const orderData = [
  { description: 'Recrout Pro', price: '€ 159', vat: '6%', qty: 24, total: '€ 400' }
];

export { orderColumns, orderData }
