import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { RootState } from 'redux/store';
import ChartComponent from 'components/RadarChart/Index';
import ProfileHeaderPDF from 'components/shared/PdfHeader/Index';
import { PdfComponents } from 'core/interface/pdfComponents';
import useLocales from 'hooks/useLocales';
import { ChartDataI, IPairResult } from 'core/interface/pdf';

import './Index.scss';

const CandidateGraphs: FC<PdfComponents> = ({
  setTemplate,
  isAnonym,
  hasHeader = true
}): JSX.Element => {
  const { t } = useLocales();
  const canidateRef = useRef<HTMLDivElement>(null);
  const [chartData, setChartData] = useState<any[]>([]);
  const candidateOverview = useSelector(
    (state: RootState) => state.candidates
  ).candidatesOverview;
  const assessment = useSelector(
    (state: RootState) => state.assessment
  ).assessment;

  const pairCompetencies = () => {
    const competencies = candidateOverview?.competencies;
    const pairResult: IPairResult[] = [];
    assessment?.forEach((assesment: any) => {
      competencies.forEach((competency: any) => {
        if (competency?.competency_id === assesment?.id) {
          const obj = {
            groupName: assesment.group.name,
            groupId: assesment.group.id,
            chartData: {
              score: competency.score,
              name: assesment.name
            }
          };
          pairResult.push(obj);
        }
      });
    });

    const groupedData = pairResult.reduce((acc, obj) => {
      const { groupName, chartData } = obj;
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(chartData);
      return acc;
    }, {} as any);

    const transformedData = Object.keys(groupedData).map(
      (groupName: string) => {
        return {
          groupName,
          data: groupedData[groupName].map(
            ({ name, score }: { name: string; score: number }) => ({
              name,
              score
            })
          )
        };
      }
    );

    const chartArray: ChartDataI[] = [];
    transformedData.forEach((chart) => {
      const chartData = {
        name: chart.groupName,
        data: {
          labels: chart.data.map((item: any) => item.name),
          datasets: [
            {
              label: 'Flexibility',
              data: chart.data.map((item: any) => item.score),
              backgroundColor: 'rgba(31, 119, 180, 0.5)',
              borderColor: '#1F77B4',
              borderWidth: 3
            }
          ]
        }
      } as ChartDataI;
      chartArray.push(chartData);
    });

    setChartData(chartArray);
  };

  useEffect(() => {
    pairCompetencies();
  }, [assessment, candidateOverview]);

  useEffect(() => {
    if (canidateRef.current) {
      setTemplate(canidateRef.current);
    }
  }, [canidateRef]);

  return (
    <>
      <Grid className="full" style={{ height: '1700px', position: 'relative' }}>
        <ProfileHeaderPDF isAnonym={isAnonym} />

        <h1 className="profile">{t('Your personality profile')}</h1>
        <div className="charts">
          {chartData.map((item, index) => {
            if (index > 3) return;
            return (
              <Grid
                key={index}
                style={{
                  padding: '0 10px'
                }}
              >
                <div className="chart-data-show">
                  <span className="chart-title">{item.name}</span>
                  <ChartComponent
                    key={index}
                    isPDF={true}
                    width={300}
                    height={300}
                    className="chart-radar-full"
                    data={item.data}
                    showTicks={false}
                  />
                </div>{' '}
              </Grid>
            );
          })}
        </div>
      </Grid>
      <Grid className="full" style={{ height: '1700px', position: 'relative' }}>
        <ProfileHeaderPDF isAnonym={isAnonym} />
        <h1 className="profile">{t('Your personality profile')}</h1>
        <div className="charts pdf-second-charts">
          <Grid
            style={{
              padding: '0 10px'
            }}
          >
            <div className="chart-data-show">
              <span className="chart-title">{chartData[4]?.name}</span>
              <ChartComponent
                isPDF={true}
                className="chart-radar-full"
                data={chartData[4]?.data}
                showTicks={false}
              />
            </div>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default CandidateGraphs;
