import { PlanEnum } from 'core/enum/plan';
import { IPricingOptioDetails } from 'core/interface/pricingOption';

const freePricing: IPricingOptioDetails = {
  hint: 'Earn a free premium account by inviting your friends!',
  plan: PlanEnum.BASIC,
  price: 'Free',
  plan_info: [
    'Discover your 5 top talents',
    'Match with jobs',
    'Send up to three job applications a month',
    'Receive up to three company profile requests per month',
    'You can not pimp your profile',
    'No personal match mails',
    'No personal profile URL link',
    'No download profile PDF',
    'You will not be an anonymous job hunter'
  ]
};

const premiumPricing: IPricingOptioDetails = {
  hint: '* Receive a 15% year discount with this plan',
  plan: PlanEnum.PREMIUM,
  price: '€ 3,33',
  plan_info: [
    'Receive insight into 45 talents and competencies!',
    'Get personalized match mails',
    'Get an additional talent assessment after six months',
    'Option to be an anonymous job hunter',
    'Personal shareable profile URL',
    'Receive up to 12 company requests a month',
    'Send up to 12 job applications a month',
    'Download a shareable PDF of you profile to share',
    'YPimp your profile'
  ]
};

const proPricing: IPricingOptioDetails = {
  hint: '* Only with a yearly payment',
  plan: PlanEnum.PRO,
  price: '€ 8.33',
  plan_info: [
    'Full professional talent assessment rapport (+25pages)',
    'Unlimited job applications per month',
    'Unlimited profile requests from companies',
    'All premium features',
    'Free access to all new features',
    'Personal Recrout matchmaker',
    'Exclusive Recrout membership'
  ]
};

export { freePricing, premiumPricing, proPricing };
