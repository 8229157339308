export enum ActivationPageEnum {
  WELCOME = 'welcome',
  WHO_YOU_ARE = 'who you are',
  MATCH_PREFERENCES = 'match preferences',
  EDUCATION = 'education',
  EDUCATION_VIEW = 'education view',
  GET_STARTED = 'get started',
  CONGRATS = 'congratulations'
}

export enum ActivateCompanyEnum {
  WELCOME = 'welcome',
  COMPANY_LOGO = 'company logo',
  ADD_AVATAR = 'add avatar',
  NICE_TO_MEET_YOU_FIRST = 'nice to meet you first',
  NICE_TO_MEET_YOU_SECOND = 'nice to meet you second',
  MAKE_CULTURE_PROFILE = 'make culture profile',
  CONGRATS = 'congratulations'
}
