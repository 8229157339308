import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import Button from 'components/Button/Index';
import ImagePicker from 'components/imagePicker';
import Activation from 'components/feature/activation/Index';
import useLocales from 'hooks/useLocales';
import Image from 'components/image/Image';
import ActionButton from 'components/ActionButton/Index';
import { formatBytes } from 'helpers/bytesFormat';
import axios from 'axios';

import './Index.scss';
import { CMSService } from 'services/cms/cms';
import { isValidURL } from 'helpers/isValidURL';

const getImageInfo = async (imageUrl: any) => {
  try {
    const response = await axios.get(imageUrl?.image, {
      responseType: 'arraybuffer'
    });

    const contentLength = response.headers['content-length'];
    if (contentLength) {
      const urlParts = imageUrl?.image.split('/');
      const fileNameWithParams = urlParts[urlParts.length - 1];
      const fileName = fileNameWithParams.split('?')[0];
      return {
        size: contentLength,
        name: fileName,
        image: imageUrl?.image,
        page: imageUrl.page,
        id: imageUrl.id
      };
    }
  } catch (error) {
    console.error('Error fetching the image:', error);
    return null;
  }
};
const OrganizationCulture: any = ({
  onSubmit,
  onBack,
  onClose,
  setCompanyData,
  companyData,
  isPopup = false,
  className,
  isOneBtn = false,
  showTopSide,
  setNewData,
  showTitle
}: any): JSX.Element => {
  const { t } = useLocales();
  const [logo, setLogo] = useState<any>(
    companyData?.gallery || companyData?.logo || []
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (companyData?.gallery) {
      const fetchGalleryData = async () => {
        const galleryData = await Promise.all(
          companyData.gallery.map(async (element: any) => {
            const res = await getImageInfo(element);
            return res;
          })
        );
        setLogo(galleryData);
      };
      fetchGalleryData();
    }
  }, [companyData?.gallery]);

  const handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
    if (logo.length === 0) {
      setError('Please upload at least one image.');
      actions.setSubmitting(false);
      return;
    }
    const newImages = logo.filter((item: any) => !item.id);
    if (setNewData) {
      setNewData(newImages);
    } else {
      setCompanyData((prev: any) => ({
        ...prev,
        logo
      }));
      onSubmit();
    }
    setError(null);
  };

  const handleRemove = (index: number) => {
    const removeItem = logo?.filter(
      (el: any, indexToRemove: number) => indexToRemove !== index
    );
    setLogo(removeItem);
  };

  const removeGallery = (page_id: number, image_id: number) => {
    CMSService.removeImage(page_id, image_id);
  };

  return (
    <div>
      <Activation
        showTopSide={showTopSide}
        title="Our organizational culture"
        currentStep={3}
        className={
          isPopup ? '' : `activation-edu ${className ?? ''} ${className}`
        }
        headerIconDisable
        totalSteps={8}
        isCareerPage={true}
        handleModalClose={onClose}
        isPopup={true}
        showTitle={showTitle}
      >
        <Formik
          enableReinitialize
          initialValues={[]}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          //   validationSchema={validationSchema(t)}
        >
          {({
            setFieldValue,
            isValid,
            values,
            errors,
            touched,
            setFieldTouched,
            setErrors
          }) => {
            return (
              <Form>
                <div className="organization scrollable">
                  <ImagePicker setLogo={setLogo} setSizes={true} logo={logo} />
                  {error && !logo?.length && (
                    <span className="error-ms"> {t('required')} </span>
                  )}
                  <div className="selectedImages">
                    {logo &&
                      logo?.map(
                        (
                          item: {
                            name: string;
                            size: number;
                            image: string;
                            id?: number;
                            page?: number;
                          },
                          index: number
                        ) => {
                          const progressBarWidth =
                            (item.size / (1024 * 1024)) * 100;
                          return (
                            <div key={index} className="image imageContainer">
                              <Image
                                key={index}
                                name="PNG.svg"
                                className="png"
                              />
                              <div className="bar">
                                <div className="bar_labels">
                                  <span className="bar__label">
                                    {item.name}
                                  </span>
                                  <span className="bar__scale">
                                    {formatBytes(item.size)} MB
                                  </span>
                                </div>
                                <div className="bar_box">
                                  <div className="bar__line">
                                    <div
                                      className="bar__line-progress"
                                      style={{
                                        width: `${progressBarWidth}%`
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <ActionButton
                                onClick={() =>
                                  isValidURL(item.image)
                                    ? item?.id &&
                                      item?.page &&
                                      removeGallery(item?.page, item?.id)
                                    : handleRemove(index)
                                }
                                iconName="removeImage.svg"
                                className="removeImage"
                                height={24}
                                width={24}
                              />
                            </div>
                          );
                        }
                      )}
                  </div>
                  {!isOneBtn ? (
                    <div className="button_container">
                      <Button
                        onClick={onBack}
                        t={t}
                        title="Back"
                        className="av__content__buttons-back"
                      />
                      <Button
                        className={`next ${
                          !isValid ? 'invalid' : `${isValid}`
                        }`}
                        type="submit"
                        title="Next"
                        t={t}
                      />
                    </div>
                  ) : (
                    <div className="btn_container">
                      <Button
                        className="next button one-btn"
                        type="submit"
                        t={t}
                        title={'Save'}
                      />
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Activation>
    </div>
  );
};

export default OrganizationCulture;
