import React, { FC, useEffect, useState } from 'react';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import ContentBlock from 'components/ContentBlock/Index';
import Popup from 'components/shared/Popups/Popup/Index';
import CertificationsPopup from 'components/shared/Popups/Certification/Index';
import WorkExperiencePopup from 'components/shared/Popups/WorkExperience/Index';
import EducationPopup from 'components/shared/Popups/Education/Index';
import { EducationService } from 'services/education/educationService';
import Education from 'components/shared/Education/Index';
import Certificate from 'components/shared/Certificate/Index';
import { IEducationExperienceTab } from 'core/interface/education-side';
import { ExperienceService } from 'services/experience/experienceService';
import Experience from 'components/shared/Experience/Index';
import { CertificateService } from 'services/certificates/certificateService';
import ActionButton from 'components/ActionButton/Index';
import Level from 'components/Level/Index';
import { SkillService } from 'services/skills/skillService';
import { ISkills } from 'core/interface/skills';
import SkillPopup from 'components/shared/Popups/Skills/Index';

import './Index.scss';

const EducationExperienceTab: FC<IEducationExperienceTab> = ({
  t,
  candidate_id,
  fromGuest
}): JSX.Element => {
  const [showCertifications, setShowCertifications] = useState<boolean>(false);
  const [showWorkExperience, setShowWorkExperience] = useState<boolean>(false);
  const [showEducationPopup, setShowEducationPopup] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showEditSkills, showSetEditSkills] = useState<boolean>(false);
  const [showAddSkill, setShowAddSkill] = useState<boolean>(false);
  const [resend, setResend] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const { skills } = useSelector((state: RootState) => state.skills);
  const { educations } = useSelector((state: RootState) => state.educations);
  const { experience } = useSelector(
    (state: RootState) => state.workExperiences
  );
  const { certifications } = useSelector(
    (state: RootState) => state.certifications
  );

  useEffect(() => {
    const fetchData = () => {
      getCertifications();
      getEducations();
      getExperience();
      handleClose();
    };

    fetchData();
  }, []);

  const handleToggle = (
    stateSetter: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    return () => stateSetter((prevState) => !prevState);
  };

  const handleClose = () => {
    setShowCertifications(false);
    setShowWorkExperience(false);
    setShowEducationPopup(false);
    showSetEditSkills(false);
    setShowAddSkill(false);
  };

  const getEducations = async (): Promise<void> => {
    setShowLoader(true);
    try {
      if (candidate_id) {
        EducationService.getEducations(candidate_id);
        setShowLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getExperience = async (): Promise<void> => {
    setShowLoader(true);
    if (candidate_id) {
      await ExperienceService.getExperience(candidate_id);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getSkills();
    setResend(false);
  }, [resend, showEditSkills]);

  const getSkills = async (): Promise<void> => {
    setShowLoader(true);
    if (candidate_id) {
      await SkillService.getSkills(candidate_id);
    }
    setShowLoader(false);
  };

  const getCertifications = async (): Promise<void> => {
    setShowLoader(true);
    try {
      if (candidate_id) {
        await CertificateService.getCertifications(candidate_id);
        setShowLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="tab experience">
      <div className="tab__top">
        <ContentBlock
          isScrollable={true}
          onActionClick={handleToggle(setShowWorkExperience)}
          className="tab__education"
          title={t('Work Experience')}
          noData={experience?.length === 0 || !experience}
          showLoader={showLoader}
          showButton={fromGuest ? false : true}
        >
          <Experience t={t} candidate_id={candidate_id} />
        </ContentBlock>

        <ContentBlock
          isScrollable={true}
          onActionClick={handleToggle(setShowEducationPopup)}
          className="tab__education education"
          title={t('education')}
          noData={educations?.length === 0 || !educations}
          showLoader={showLoader}
          showButton={fromGuest ? false : true}
        >
          <Education t={t} education={educations || []} />
        </ContentBlock>
      </div>
      <div className="tab__bottom">
        <ContentBlock
          isScrollable={true}
          onActionClick={handleToggle(setShowCertifications)}
          className="tab__education education"
          title={t('certifications')}
          noData={certifications?.length === 0 || !certifications}
          showLoader={showLoader}
          showButton={fromGuest ? false : true}
        >
          <div className="tab__bottom__content">
            <Certificate t={t} />
          </div>
        </ContentBlock>
        <ContentBlock
          isScrollable={true}
          className={`tab__education education skills__block ${
            skills?.length === 0 ? 'disable-edit' : ''
          }`}
          title={t('IT Skills')}
          onActionClick={handleToggle(showSetEditSkills)}
          noData={skills?.length === 0 || !skills}
          showLoader={showLoader}
          showButton={fromGuest ? false : true}
          secondButton={
            fromGuest ? (
              <></>
            ) : (
              <ActionButton
                onClick={handleToggle(setShowAddSkill)}
                iconName="plus.svg"
              />
            )
          }
        >
          <div className="skill_content">
            {skills?.map((skill: ISkills) => (
              <div key={skill.id} className="skill-side">
                <div className="">
                  <span className="skill-title certification-skill s-hidden">
                    {skill.skill.name}
                  </span>
                  <span className="skill-desc skill-desc-block certification-skill-desc s-hidden">
                    {skill.description}
                  </span>
                </div>

                <Level
                  className="skillLevel"
                  level={skill.proficiency}
                  isReadonly={true}
                />
              </div>
            ))}
          </div>
        </ContentBlock>
      </div>
      {showCertifications && (
        <Popup
          title="Certifications"
          className="expertise"
          onClose={handleClose}
        >
          <CertificationsPopup handleClose={handleClose} t={t} />
        </Popup>
      )}
      {showWorkExperience && (
        <Popup
          title="Work experience"
          className="expertise"
          onClose={handleClose}
        >
          <WorkExperiencePopup t={t} />
        </Popup>
      )}
      {showEducationPopup && (
        <Popup title="Education" className="expertise" onClose={handleClose}>
          <EducationPopup
            onClose={() => handleClose()}
            t={t}
            education={educations || []}
            isShowAdd={setShowForm}
          />
        </Popup>
      )}
      {showEditSkills && (
        <Popup title="IT Skills" className="expertise" onClose={handleClose}>
          <SkillPopup
            handleClose={handleClose}
            t={t}
            skill={skills}
            skillType="Hard Skills"
          />
        </Popup>
      )}
      {showAddSkill && (
        <Popup title="IT Skills" className="expertise" onClose={handleClose}>
          <SkillPopup
            handleClose={handleClose}
            t={t}
            showAddButton={true}
            skill={[
              {
                description: '',
                id: 0,
                proficiency: 0,
                skill: { id: '0', name: '' }
              }
            ]}
            skillType="Hard Skills"
          />
        </Popup>
      )}
    </div>
  );
};

export default EducationExperienceTab;
