import React, { FC, useState } from 'react';
import Image from 'components/image/Image';
import { JobCardActionEnum } from 'core/enum/jobCardAction';
import { getDateFormatter } from 'helpers/dateConverter';
import { STAGE_TYPES } from 'core/constants/jobCardConstant';
import { MenuItem, Select } from '@material-ui/core';

import './Index.scss';

const actions = [
  JobCardActionEnum.ACCEPT,
  JobCardActionEnum.CANCEL,
  JobCardActionEnum.MORE_INFO,
  JobCardActionEnum.PROFILE_VIEW
]

const OutgoingRequests: FC<any> = ({
  id,
  name,
  sub_title,
  email,
  icon,
  score,
  culture_score,
  date,
  responded_at,
  industry,
  stageOutgoingHandler,
  status
}): JSX.Element => {

  return (
    <div className="jCard">
      <div className="jCard__left">
        <Image url={icon} name={!icon ? "no-avatar.svg" : ""} className="jCard__left-image" />
        <div className="jCard__left__info">
          <div className="jCard__left__info_name">
            <span className="jCard__left__info-title">
              {name || email}
            </span>
            {industry?.map((el: any) => (
              <span className="jCard__left__info_industry" key={el?.id}>
                {el?.name}
              </span>
            ))}
          </div>
          <span className="jCard__left__info-sub">{sub_title || ""}</span>
        </div>
      </div>
      <div className='jCard__middle'>
        <div className="jCard__middle__percents">
          <span className="jCard__middle__matches-title"> {score || 0}% Talent Match</span>
          <span className="jCard__middle__matches-title">{culture_score || 0}% Cultural Fit</span>
        </div>
      </div>
      <div className="jCard__middle">
        <div className="jCard__middle__percents">
        </div>
        <div className="jCard__middle__details">
          <div className="jCard__middle__details__item">
            <span className="jCard__middle__details__item-title">
              Message send on
            </span>
            <span className="jCard__middle__details__item-desc">
              {getDateFormatter(date, 'MMM DD YYYY, h:mm a')}
            </span>
          </div>
          <div className="jCard__middle__details__item">
            <span className="jCard__middle__details__item-title">
              Response on:
            </span>
            <span className="jCard__middle__details__item-desc">
              {getDateFormatter(responded_at, 'MMM DD YYYY, h:mm a')}
            </span>
          </div>
        </div>
      </div>
      <div className="jCard__right">
        {responded_at ? (
          <>
            {actions.map((action, index) => (
              <div key={index} className="jCard__right__actions">
                {action === JobCardActionEnum.ACCEPT && status === 2 && (
                  <span
                    className='jCard__right__actions-action disabled'
                  >
                    <Image name="accept.svg" width={45} height={45} />
                  </span>
                )}
                {action === JobCardActionEnum.CANCEL && status === 3 &&
                  (
                    <span
                      className='jCard__right__actions-action disabled'
                    >
                      <Image name="cancel.svg" width={45} height={45} />
                    </span>
                  )}
              </div>
            ))}
          </>
        ) : (
          <>
            {actions.map((action, index) => (
              <div key={index} className="jCard__right__actions">
                {action === JobCardActionEnum.ACCEPT && (
                  <span
                    onClick={() => stageOutgoingHandler(id, { status: 2, responded_at: getDateFormatter(new Date(), "YYYY-MM-DDTHH:mm:ss.000000Z") })}
                    className='jCard__right__actions-action'
                  >
                    <Image name="accept.svg" width={45} height={45} />
                  </span>
                )}
                {action === JobCardActionEnum.CANCEL &&
                  (
                    <span
                      onClick={() => stageOutgoingHandler(id, { status: 4, responded_at: getDateFormatter(new Date(), "YYYY-MM-DDTHH:mm:ss.000000Z") })}
                      className='jCard__right__actions-action'
                    >
                      <Image name="cancel.svg" width={45} height={45} />
                    </span>
                  )}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default OutgoingRequests;
