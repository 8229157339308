import { FC } from 'react';
import { IAssessmentYearLabel } from 'core/interface/assessmentYearLabel';

import './Index.scss';

const AssessmentYearLabel: FC<IAssessmentYearLabel> = ({ year, color }) => {
  return (
    <div className="assessment-year-label-wrapper">
      <span
        className="assessment-year-label-color"
        style={{ backgroundColor: color }}
      />
      <span>{year}</span>
    </div>
  );
};

export default AssessmentYearLabel;
