import React, { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import useLocales from 'hooks/useLocales';
import Button from 'components/Button/Index';
import { MatchProfileService } from 'services/matchProfile/matchProfile';
import { IMatchProfile } from 'core/interface/matchProfile';
import NoData from 'components/shared/NoData/Index';
import MatchingProfileBlock from 'components/MatchingProfileBlock/Index';
import Popup from 'components/shared/Popups/Popup/Index';
import { JobService } from 'services/jobs/job';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CompetenciesSelect from 'components/shared/CompetenciesSelect';
import { useNavigate } from 'react-router';

import './Index.scss';

const MatchingProfile: FC = (): JSX.Element => {
  const { t } = useLocales();
  const [editPopUp, setEditPopUp] = useState<boolean>(false);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [ids, setIds] = useState<string[]>([]);
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [selectedMatchProfileId, setSelectedMatchProfileId] = useState<any>();
  const [selectedMatchProfile, setSelectedMatchProfile] = useState<any>();

  const [formValues, setFormValues] = useState({
    matching: '',
    culture_profile: ''
  });
  const { assessmentByGroup } = useSelector(
    (state: RootState) => state.assessment
  );

  const { matchProfile }: any = useSelector((state: RootState) => state.match);
  const [loading, setLoading] = useState<boolean>(true);


  const getApplications = () => {
    JobService.getAssessmentByGroup();
  };

  const getSearchProfile = () => {
    MatchProfileService.getMatchProfilesSearch(query);
  };

  useEffect(() => {
    if (selectedMatchProfile) {
      setFormValues({
        matching: selectedMatchProfile?.title,
        culture_profile: selectedMatchProfile?.culture_profile
      });
      setSelectedLabels(selectedMatchProfile?.competency_labels);
      const a = selectedMatchProfile?.competencies?.map(
        (e: any) => e?.competency?.id
      );
      setIds(a);
    }
  }, [selectedMatchProfile]);

  useEffect(() => {
    getSearchProfile();
    getMatchProfiles();
    getApplications();
  }, []);

  useEffect(() => {
    const selectedProfile = matchProfile?.results?.find(
      (profile: any) => profile.id === selectedMatchProfileId
    );

    if (selectedProfile) {
      setSelectedMatchProfile(selectedProfile);
    }
  }, [matchProfile, selectedMatchProfileId]);

  const getMatchProfiles = async (): Promise<void> => {
    try {
      setLoading(true);
      setTimeout(async () => {
        await MatchProfileService.getMatchProfiles(false);
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDelete = async (id: number): Promise<void> => {
    try {
      await MatchProfileService.deleteMatchProfile(id, false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClosePopup = (): void => {
    setSelectedMatchProfile(null);
    setSelectedMatchProfileId(null);
    setSelectedLabels([]);
    setFormValues({ matching: '', culture_profile: '' });
    setEditPopUp(!editPopUp);
  };

  const handleSetSelected = (label: string, id: any, text: string) => {
    const newSelected: string[] = [...selectedLabels];
    const newId: string[] = [...ids];
    const index = selectedLabels.indexOf(label);
    const indexID = ids.indexOf(id);
    if (index !== -1 && indexID !== -1) {
      newSelected.splice(index, 1);
      newId.splice(indexID, 1);
    } else if (newSelected.length < 10 && newId.length < 10) {
      newSelected.push(label);
      newId.push(id);
    } else if (newSelected.length < 7) {
      return;
    }
    setSelectedLabels(newSelected);
    setIds(newId);
  };

  const handleNext = () => {
    if (
      (selectedLabels.length >= 7 && selectedLabels.length <= 10) &&
      formValues.matching &&
      formValues.culture_profile
    ) {
      const idLabelArray = ids.map((id, index) => ({
        id,
        competency: selectedLabels[index]
      }));
      const existingLabels = selectedMatchProfile?.competency_labels || [];
      const competencyScores = selectedMatchProfile?.competency_min_scores || [];

      const data = {
        name: formValues.matching,
        culture_profile: formValues.culture_profile,
        competencies: idLabelArray,
        is_culture: false,
        edit: selectedMatchProfile ? selectedMatchProfile?.id : false,
        scores: idLabelArray.map(({ competency }) =>
          existingLabels.includes(competency)
            ? competencyScores[existingLabels.indexOf(competency)]
            : 0
        )
      }

      localStorage.setItem('selected', JSON.stringify(data));
      navigate('/culture');
    }
  };

  const gerSearch = (id: number, type: string) => {
    navigate(`/match-result/${type}/${id}`);
  };

  const getJobList = (id: number) => {
    navigate(`/match-candidate/${id}`);
  };

  return (
    <div className="matching">
      <div className="matching__header">
        <span className="matching__header-title">Matching Profile</span>
        <Button
          t={t}
          title="Create New Match Profile"
          className="matching__header-btn"
          onClick={() => setEditPopUp(true)}
        />
      </div>
      {loading ? (
        <div className="loadingContainer">
          <CircularProgress className="loading" />
        </div>
      ) : matchProfile?.results?.length === 0 ? (
        <NoData />
      ) : (
        <div className="match-blocks">
          {matchProfile?.results?.map((profile: any) => (
            <MatchingProfileBlock
              id={profile.id}
              title={profile.title}
              name={''}
              date={profile.last_competencies_updated_on}
              key={profile.id}
              setEditPopUp={setEditPopUp}
              setDeletedItem={handleDelete}
              setSelectedMatchProfileId={setSelectedMatchProfileId}
              getOnePost={getJobList}
              getList={gerSearch}
              type="find-candidate"
            />
          ))}
        </div>
      )}
      {editPopUp && (
        <Popup
          className="popup-add-new"
          title="Please select the most important competencies for your job opening."
          description="We advise you to select no more than 10 competencies. And you should only go for the once that really matter to achieve success in the work the candidate needs to execute. The average is 7 competencies per matching profile. But depending on your results you can always add or delete competencies. This way you can fine-tune your ideal profile."
          onClose={handleClosePopup}
        >
          <CompetenciesSelect
            setQuery={setQuery}
            competencies={assessmentByGroup}
            selectedList={selectedLabels}
            onSelect={handleSetSelected}
            next={handleNext}
            matchProfile={true}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </Popup>
      )}
    </div>
  );
};

export default MatchingProfile;
