import React, { FC, useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { ChartType } from 'core/interface/chartType';
import { chartCustomTooltip } from 'helpers/chartCustomTooltip';

const ChartComponent: FC<ChartType> = ({
  data,
  className,
  width = 250,
  height = 250,
  showTicks = true,
  isPDF = false,
  labelColor = 'black',
  max,
  tooltipTitle,
  showTooltipColor = true,
  fontSize = 14,
  showCustomTooltip,
  tooltipCallback,
  labelTruncateFunction = (label) => label
}) => {
  const [exportedImageSrc, setExportedImageSrc] = useState<string | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    if (isPDF) {
      setTimeout(() => {
        exportChart();
      }, 1000);
    }
  }, [isPDF]);

  const exportChart = () => {
    if (!chartInstance || !canvasRef.current) return;

    const base64Image = chartInstance.current?.toBase64Image();

    if (!base64Image) return;

    const canvas = document.createElement('canvas');
    canvas.width = canvasRef.current.width;
    canvas.height = canvasRef.current.height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        setExportedImageSrc(canvas.toDataURL('image/png'));
      };
      img.src = base64Image;
    }
  };

  useEffect(() => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext('2d');

    if (ctx) {
      const newChartInstance = new Chart(ctx, {
        type: 'radar',
        data,
        options: {
          scales: {
            r: {
              min: 0,
              max,
              pointLabels: {
                display: true,
                color: labelColor,
                callback: labelTruncateFunction
              },
              ticks: {
                display: false,
                count: 6
              },
              grid: {
                lineWidth: 0.74,
                color: '#808080'
              }
            }
          },
          plugins: {
            legend: {
              display: false,
              fullSize: false,
              labels: {
                color: 'red'
              }
            },
            tooltip: {
              callbacks: {
                label: tooltipCallback,
                title: tooltipTitle
              },
              displayColors: showTooltipColor,
              enabled: !showCustomTooltip,
              external: showCustomTooltip ? chartCustomTooltip : undefined
            }
          },
          font: {
            weight: 'bold',
            size: 20
          }
        }
      });

      chartInstance.current = newChartInstance;
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, showTicks]);

  return (
    <>
      <div
        className={className}
        style={{
          position: 'relative',
          width: `${width}px`,
          margin: 'auto',
          display: `${exportedImageSrc ? 'none' : 'block'}`
        }}
      >
        <span
          style={{
            display: 'block',
            width: `${isPDF ? 'auto' : 'auto'}`,
            height: `${isPDF ? 'auto' : 'auto'}`,
            visibility: `${isPDF ? 'hidden' : 'visible'}`
          }}
        >
          <canvas ref={canvasRef} />
        </span>
      </div>
      {exportedImageSrc && isPDF && (
        <img
          src={exportedImageSrc}
          alt="Exported Chart"
          style={{ width: '220px', height: '220px' }}
        />
      )}
    </>
  );
};

export default ChartComponent;
