import { store } from '../../redux/store';
import { SaveAssessment } from '../../redux/slices/assessment';
import { ICompetencies } from 'core/interface/competencies';
import ApiBase from 'services/base/apiBase';

export class CompetencieService {
  private static readonly apiBase = new ApiBase();

  public static async getCompetencies(): Promise<ICompetencies[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync('/assessment/competencies/');
      dispatch(SaveAssessment(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
