import React, { FC, useEffect, useState } from 'react';
import ContentBlock from 'components/ContentBlock/Index';
import SharedImages from 'components/shared/SharedImages/Index';
import SharedVideos from 'components/shared/SharedVideos/Index';
import { ITranslate } from 'core/interface/translate';
import Popup from 'components/shared/Popups/Popup/Index';
import ActionButton from 'components/ActionButton/Index';
import Image from 'components/image/Image';
import Level from 'components/Level/Index';
import AddSocialMediaPopup from 'components/shared/Popups/AddSocialNetwork/Index';
import SocialNetworkPopup from 'components/shared/Popups/SocialNetwork';
import LanguagePopup from 'components/shared/Popups/Languages/updateLanguage/Index';
import AddLanguagePopup from 'components/shared/Popups/Languages/addLanguage/Index';
import YoutubeVideosPopup from 'components/shared/Popups/YoutubeVideos/Index';
import SharedImagesPopup from 'components/shared/Popups/SharedImages/Index';
import { CandidateService } from 'services/candidate/candidate';
import { AccountServce } from 'services/upload/file-upload';
import { LanguageService } from 'services/language';
import { ISocialMediaData } from 'core/interface/socialMedia';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import './Index.scss';

const ImageAndYoutubeTab: FC<ITranslate> | any = ({
  t,
  fromGuest,
  candidate_id
}: any) => {
  const { candidates, candidatesSharedLinks } = useSelector(
    (state: RootState) => state.candidates
  );

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showVideosPopup, setShowVideosPopup] = useState<boolean>(false);
  const [showImagesPopup, setShowImagesPopup] = useState<boolean>(false);
  const [showEditNetworks, setSowEditNetworks] = useState<boolean>(false);
  const [showAddNetworks, setSowAddNetworks] = useState<boolean>(false);
  const [showEditLanguage, setShowEditLanguages] = useState<boolean>(false);
  const [showAddLanguage, setShowAddLanguage] = useState<boolean>(false);
  const [showEditHardSkill, setShowAddHardSkill] = useState<boolean>(false);
  const { socialMedia } = useSelector((state: RootState) => state.socialMedia);
  const files = useSelector((state: RootState) => state.file).files;
  const me = useSelector((state: RootState) => state.user).user;
  const { candidate_language } = useSelector(
    (state: RootState) => state.languages
  );
  const { skills } = useSelector((state: RootState) => state.skills);

  const getSharedVideos = async (): Promise<void> => {
    try {
      await CandidateService.getSharedLinks(candidates?.id);
    } catch (e) {
      console.log(e, 'eeeee');
    }
  };

  useEffect(() => {
    if (me) {
      AccountServce.getUploadedImages(candidate_id ?? me.candidate_id);
    }
    getLanguages();
  }, [me]);

  useEffect(() => {
    getSharedVideos();
  }, []);

  const handleEditImages = () => {
    setShowImagesPopup(true);
  };

  const handleEditVideos = () => {
    setShowVideosPopup(true);
  };

  const closePopup = () => {
    setShowVideosPopup(false);
    setShowImagesPopup(false);
  };

  const handleEdit = (): void => {
    setSowEditNetworks(true);
  };

  const getLanguages = async (): Promise<void> => {
    if (me) {
      await LanguageService.getCandidateLanguage(me.candidate_id);
    }
  };

  const handleLanguageClose = () => {
    setShowEditLanguages(false);
    setShowAddLanguage(false);
    getLanguages();
  };

  const handleAdd = (): void => {
    setSowAddNetworks(true);
  };

  const handleAddLanguage = () => {
    setShowAddLanguage(true);
  };

  const handleClose = (): void => {
    setShowAddHardSkill(false);
    setSowEditNetworks(false);
    setShowEditLanguages(false);
    setShowAddLanguage(false);
    setSowAddNetworks(false);
    return;
  };

  return (
    <>
      <div className="tab">
        <ContentBlock
          className="tab__photos"
          title={t('shared_image')}
          onActionClick={handleEditImages}
          noData={files?.length === 0 || !files}
          showButton={fromGuest ? false : true}
        >
          {files ? (
            <SharedImages className="image_container" content={files} />
          ) : (
            <></>
          )}
        </ContentBlock>
        <ContentBlock
          className="tab__videos"
          title={t('Shared video’s')}
          onActionClick={handleEditVideos}
          showButton={fromGuest ? false : true}
        >
          <SharedVideos content={candidatesSharedLinks} />
        </ContentBlock>
        {showVideosPopup && (
          <Popup title="Shared videos" onClose={closePopup}>
            <YoutubeVideosPopup closePopup={closePopup} />
          </Popup>
        )}
        {showImagesPopup && (
          <Popup title="Shared images" onClose={closePopup}>
            <SharedImagesPopup handleClose={closePopup} t={t} />
          </Popup>
        )}
      </div>
      <div className="lang-and-skill">
        {!fromGuest && (
          <ContentBlock
            className="skills__block"
            isScrollable={true}
            title={t('Social networks')}
            onActionClick={handleEdit}
            disableAction={
              (socialMedia && socialMedia.length === 0) || !socialMedia
            }
            noData={socialMedia?.length === 0 || !socialMedia}
            showLoader={showLoader}
            secondButton={
              <ActionButton onClick={handleAdd} iconName="plus.svg" />
            }
          >
            <div className="skill_content">
              {socialMedia?.map((media: ISocialMediaData) => (
                <div key={media.id} className="skill-side">
                  <span className="skill-title skill-title-block">
                    {media.network.split('_').join(' ')}
                  </span>
                  <span className="skill-desc skill-desc-block">
                    <Image
                      name={media.network + '.svg'}
                      width={30}
                      height={30}
                    />
                  </span>
                </div>
              ))}
            </div>
          </ContentBlock>
        )}
        {!fromGuest && (
          <ContentBlock
            className="skills__block langs"
            isScrollable={true}
            title={t('Language')}
            onActionClick={() => setShowEditLanguages(true)}
            noData={!candidate_language || candidate_language.length === 0}
            disableAction={
              (candidate_language && candidate_language.length === 0) ||
              !candidate_language
            }
            showLoader={showLoader}
            secondButton={
              <ActionButton onClick={handleAddLanguage} iconName="plus.svg" />
            }
          >
            {candidate_language && candidate_language?.length > 0 ? (
              <div className="skill_content">
                {candidate_language?.map((language) => (
                  <div key={language?.id} className="skill-side">
                    <span className="skill-title">
                      <span className="lang-name">
                        {language?.language?.name}{' '}
                      </span>
                      <span className="icon-flag">
                        <img
                          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${language?.language?.flag?.toUpperCase()}.svg`}
                        />
                      </span>
                    </span>
                    <Level
                      className="skillLevel"
                      level={language?.proficiency}
                      isReadonly={true}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </ContentBlock>
        )}
        {showEditNetworks && (
          <Popup
            title="Social networks"
            className="expertise"
            onClose={handleClose}
          >
            <SocialNetworkPopup onClose={() => handleClose()} />
          </Popup>
        )}
        {showEditLanguage && (
          <Popup title="Languages" className="expertise" onClose={handleClose}>
            <LanguagePopup onClose={handleLanguageClose} />
          </Popup>
        )}
        {showAddLanguage && (
          <Popup title="Languages" className="expertise" onClose={handleClose}>
            <AddLanguagePopup onClose={handleLanguageClose} />
          </Popup>
        )}
        {showAddNetworks && (
          <Popup
            title="Social networks"
            className="expertise"
            onClose={handleClose}
          >
            <AddSocialMediaPopup onClose={handleClose} />
          </Popup>
        )}
      </div>
    </>
  );
};

export default ImageAndYoutubeTab;
