import React, { FC } from 'react';
import { ILanguage } from 'core/interface/language';

import './Index.scss';

const NotFound: FC<ILanguage> = () => {
  return (
    <h1 className="text">Page not found</h1>
  );
};

export default NotFound;
