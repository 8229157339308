import React, { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import AuthContent from 'components/AuthContent';
import FormField from 'components/FormField/Index';
import { IResetPassword } from 'core/interface/resetPassword';
import { InputType } from 'core/enum/inputType';
import { initialValues, validation } from './form';
import Button from 'components/Button/Index';
import { AuthService } from '../../services/auth/auth';
import useLocales from 'hooks/useLocales';
import { Role } from 'core/enum/role';

import './index.scss';

const ResetPassword: FC = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const userRole = searchParams.get('role');
  const [loading, setLoading] = useState<boolean>(false);
  const [role, setRole] = useState<Role>((userRole as Role) ?? Role.CANDIDATE);
  const { t } = useLocales();

  const handleRole = (role: Role): void => {
    setRole(role);
  };

  const handleSubmit = async (
    values: IResetPassword,
    actions: FormikHelpers<IResetPassword>
  ): Promise<void> => {
    setLoading(true);
    const body = {
      email: values.email
    } as IResetPassword;
    try {
      await AuthService.resetPassword(body);
      actions.resetForm();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContent
      backURL="/login"
      onRoleSubmit={handleRole}
      title="Reset password"
      role={role}
    >
      <div className="reset">
        <Formik
          initialValues={initialValues}
          validationSchema={validation(t)}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
        >
          {({ isValid }) => (
            <Form>
              <FormField
                type={InputType.email}
                placeholder="Email"
                fieldName="email"
              />
              <Button
                t={t}
                isLoading={loading}
                className={`login-btn ${!isValid ? 'invalid-btn' : ''}`}
                title="send_password"
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
    </AuthContent>
  );
};

export default ResetPassword;
