import { IAction } from 'core/interface/action';
import { ApiResponse } from 'core/interface/jobDTO';

export const SAVE_VALUES = 'save-applications';

const defaultState = {
  applications: null
};

export const SaveApplications = (value: ApiResponse) => ({
  type: SAVE_VALUES,
  payload: value
});

const applicationsReducer = (
  state = defaultState,
  action: IAction<ApiResponse>
) => {
  switch (action.type) {
    case SAVE_VALUES:
      return {
        ...state,
        applications: action.payload
      };
    default:
      return state;
  }
};

export default applicationsReducer;
