import axios from 'axios';
import Cookies from 'js-cookie';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  timeout: 5000
});

axiosInstance.interceptors.request.use((request) => {
  const accessToken = Cookies.get('accesstoken');
  const lng = localStorage.getItem('i18nextLng');

  if (lng) {
    request.headers['Accept-Language'] = lng;
  }

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  return request;
});

export default axiosInstance;
