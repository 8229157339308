import { store } from '../../redux/store';
import {
  ICertificationBody,
  ICertificationData
} from 'core/interface/certification';
import ApiBase from 'services/base/apiBase';
import { SaveCertifications } from '../../redux/slices/certifications';
import { prepareUpdatedData } from '../../helpers/stateChanges';

export class CertificateService {
  private static readonly apiBase = new ApiBase();

  public static async getCertifications(
    candidate_id: number
  ): Promise<ICertificationData[]> {
    const { dispatch } = store;
    const res = await this.apiBase.getAsync(
      `/candidates/${candidate_id}/certifications/`
    );
    dispatch(SaveCertifications(res));
    return res;
  }

  public static async addCertificate(
    candidate_id: number,
    certification_body: ICertificationBody
  ): Promise<ICertificationBody | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.postAsync(
        `/candidates/${candidate_id}/certifications/`,
        certification_body
      );
      const updatedState = prepareUpdatedData(
        'create',
        'certifications',
        res,
        'certifications'
      );
      dispatch(SaveCertifications(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async removeCertification(
    candidate_id: number,
    certificate_id: number
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.deleteAsync(
        `/candidates/${candidate_id}/certifications/${certificate_id}/`
      );
      const updatedState = prepareUpdatedData(
        'delete',
        'certifications',
        {
          id: certificate_id
        },
        'certifications'
      );
      dispatch(SaveCertifications(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async updateCertification(
    candidate_id: number,
    certificate_id: number,
    certification_body: ICertificationBody
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.putAsync(
        `/candidates/${candidate_id}/certifications/${certificate_id}/`,
        certification_body
      );
      const updatedState = prepareUpdatedData(
        'update',
        'certifications',
        res,
        'certifications'
      );
      dispatch(SaveCertifications(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
