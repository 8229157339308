import { ICandidate } from 'core/interface/candidate';
import { IAction } from 'core/interface/action';

export const SAVE_CANDIDATE = 'save-candidate';
export const UPDATE_CANDIDATE = 'update-candidate';

const defaultState = {
  candidate: null
};

export const SaveCandidate = (value: ICandidate | null) => ({
  type: SAVE_CANDIDATE,
  payload: value
});

const candidateReducer = (
  state = defaultState,
  action: IAction<ICandidate>
) => {
  switch (action.type) {
    case SAVE_CANDIDATE:
      return {
        ...state,
        candidate: action.payload
      };
    default:
      return state;
  }
};

export default candidateReducer;
