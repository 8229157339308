import { SettingsHeaderEnum } from 'core/enum/settings';
import { ISettingsHeader } from 'core/interface/headerItems';
import { TFunction } from 'i18next';

const settingsHeader = (t: TFunction<string, undefined>): ISettingsHeader[] => {
  return [
    {
      title: t(SettingsHeaderEnum.PROFILE),
      isActive: true
    },
    {
      title: t(SettingsHeaderEnum.SOCIAL_MEDIA),
      isActive: false
    },
    {
      title: t(SettingsHeaderEnum.GENERAL_TERMS),
      isActive: false
    },
    {
      title: t(SettingsHeaderEnum.PRIVACY_STATEMENT),
      isActive: false
    }
  ];
};

export { settingsHeader };
