import React, { FC, useState } from 'react';
import { ISharedImagesAndVideos } from 'core/interface/sharedImages';
import ImageModal from '../imageModal';

import './Index.scss';

const SharedImages: FC<ISharedImagesAndVideos> = ({
  content,
  className
}): JSX.Element => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageClick = (imageUrl: string): void => {
    setSelectedImage(imageUrl);
  };

  const handleCloseModal = (): void => {
    setSelectedImage(null);
  };

  return (
    <div className="tab__photos__content">
      {content?.map((item, index) => (
        <img
          key={index}
          className={`tab__photos__content-photo ${className ?? ''}`}
          src={item.uploaded_file}
          alt={item.uploaded_file}
          onClick={() => handleImageClick(item.uploaded_file)}
        />
      ))}
      <ImageModal
        open={!!selectedImage}
        handleClose={handleCloseModal}
        image={selectedImage}
      />
    </div>
  );
};

export default SharedImages;
