import React, { FC, useRef, useState } from 'react';
import { CropperRef, Cropper } from 'react-advanced-cropper';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import { AccountServce } from 'services/upload/file-upload';
import { AuthService } from 'services/auth/auth';
import { IMe } from 'core/interface/me';
import { useSelector } from 'react-redux';
import { IAvatarPopup } from 'core/interface/avatarPopup';
import { UpdateUser } from '../../../../redux/slices/user';
import { store } from '../../../../redux/store';

import './Index.scss';

const AvatarPopup: FC<IAvatarPopup> = ({ t, onPopupClose }): JSX.Element => {
  const fileRef = useRef(null);
  const me = useSelector((state: IMe) => state.user).user;
  const [avatar, setAvatar] = useState<File | string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileContent, setFileContent] = useState<string | ArrayBuffer | null>(
    ''
  );
  const { dispatch } = store;
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    handleImageChosen(e.dataTransfer.files[0]);
  };
  const handleImageRead = (e: ProgressEvent<FileReader>) => {
    const content = (e.target as FileReader).result;
    setFileContent(content);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target as HTMLInputElement;

    if (fileInput.files && fileInput.files.length > 0) {
      const file: File = fileInput.files[0];
      handleImageChosen(file);
    } else {
      console.error('No file selected');
    }
  };

  const handleImageChosen = async (file: File) => {
    const reader = new FileReader();
    reader.onloadend = handleImageRead;
    reader.readAsDataURL(file);
    setAvatar(file);
  };

  const onChange = async (cropper: CropperRef) => {
    const canvas = cropper.getCanvas();
    const dataURL = canvas?.toDataURL();
    const base64 = dataURL?.replace(/^data:image\/[a-z]+;base64,/, '');
    try {
      const blob = await fetch(`data:image/png;base64,${base64}`).then((res) =>
        res.blob()
      );
      const fileName = `avatarImage${Math.random()}.png`;
      const fileType = 'image/png';
      const file = new File([blob], fileName, { type: fileType });
      setAvatar(file);
    } catch (error) {
      console.log(error);
    }
  };

  const chooseFile = () => {
    if (fileRef.current) {
      (fileRef.current as HTMLInputElement).click();
    }
  };

  const handleAvatarSave = async (): Promise<void> => {
    setLoading(true);
    if (avatar) {
      const formData = new FormData();
      formData.append('avatar', avatar);
      try {
        await AccountServce.updateAccount(me.id, formData);
        const res = await AuthService.getAccount();
        dispatch(UpdateUser(res));
        onPopupClose(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="av">
      <div className="av__content">
        {me?.avatar && fileContent === '' ? (
          <img src={me?.avatar} alt="" className="cropper" />
        ) : (
          <Cropper
            src={fileContent as string}
            onChange={onChange}
            className={'cropper'}
          />
        )}

        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className="av__content__upload"
        >
          <div className="av__content__upload__top">
            <Image name="upload-file.svg" width={40} height={40} />
            <span className="av__content__upload__top-title">
              Drag and drop your files here
            </span>
            <span className="av__content__upload__top-support">
              Files supported: JPG, PNG
            </span>
            <span className="av__content__upload__top-or">or</span>
          </div>
          <div className="av__content__upload__bottom">
            <Button
              t={t}
              onClick={chooseFile}
              className="av__content__upload__bottom-browse"
              title="Browse File"
            />
          </div>
        </div>
        <input
          onChange={handleInputChange}
          ref={fileRef}
          className="file"
          type="file"
          accept="image/png, image/jpeg"
        />
        <Button
          isLoading={loading}
          onClick={handleAvatarSave}
          t={t}
          title="Save"
          className="av__content-save"
        />
      </div>
    </div>
  );
};

export default AvatarPopup;
