import React, { FC } from 'react';
import { ISkillsBlock } from 'core/interface/skills';

import './Index.scss';

const SkillsBlock: FC<ISkillsBlock> = ({ title }): JSX.Element => {
  return <span className="s-block">{title}</span>;
};

export default SkillsBlock;
