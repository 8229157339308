import React, { FC, useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { initialValues, validationSchema } from './form';
import { IActionvationWhoForm } from 'core/interface/activation';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import DropDown from 'components/Dropdown';
import { days, months, years } from 'core/constants/aboutMePopUpConstants';
import RadioButton from 'components/RadioGroup/Index';
import Button from 'components/Button/Index';
import useLocales from 'hooks/useLocales';
import { IMe } from 'core/interface/me';
import { Gender } from 'core/enum/gender';
import { useSelector } from 'react-redux';
import { IActionPages } from 'core/interface/actionPages';
import { AccountServce } from 'services/upload/file-upload';
import Activation from '../Index';
import { store } from '../../../../redux/store';
import { UpdateUser } from '../../../../redux/slices/user';
import { getDateFormatter } from 'helpers/dateConverter';

import './Index.scss';

const WhoYouAre: FC<IActionPages> = ({ onSubmit }): JSX.Element => {
  const { t } = useLocales();
  const [showLoader, setShowLoader] = useState<boolean>();
  const me = useSelector((state: IMe) => state.user).user;
  const [formValues, setFormValues] =
    useState<IActionvationWhoForm>(initialValues);

  const handleSubmit = async (
    values: IActionvationWhoForm,
    actions: FormikHelpers<IActionvationWhoForm>
  ) => {
    const { dispatch } = store;
    setShowLoader(true);
    const body = {
      ...values,
      birthday: `${values.birthday.year}-${
        months.find((m) => m.value === values.birthday.month)?.value || '01'
      }-${values.birthday.day}`,
      gender: +values.gender,
      user: me.id
    };
    const formData = new FormData();
    for (const key in body) {
      if (key !== 'id') {
        formData.append(key, (body as any)[key]);
      }
    }
    try {
      const response = await AccountServce.updateAccount(me.id, formData);
      dispatch(UpdateUser(response));
      onSubmit();
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setFormValues({
        ...formValues,
        first_name: me?.first_name,
        last_name: me?.last_name,
        gender: me?.gender,
        birthday: {
          day: getDateFormatter(me?.birthday, 'D'),
          month: getDateFormatter(me?.birthday, 'MM'),
          year: getDateFormatter(me?.birthday, 'YYYY')
        }
      });
    }, 500);
  }, [me]);

  return (
    <div className="who">
      <Activation title="Quickly fill in who you are" currentStep={1}>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={validationSchema(t)}
        >
          {({
            setFieldValue,
            isValid,
            values,
            errors,
            touched,
            setFieldTouched
          }) => {
            return (
              <Form>
                <div className="top">
                  <div className="fields">
                    <span className="label">First name</span>
                    <FormField
                      inputClassName="input"
                      fieldName="first_name"
                      placeholder="Alan"
                      type={InputType.text}
                    />
                  </div>
                  <div className="fields">
                    <span className="label">Last name</span>
                    <FormField
                      inputClassName="input"
                      fieldName="last_name"
                      placeholder="Geni"
                      type={InputType.text}
                    />
                  </div>
                </div>
                <div className="birthday">
                  <div className="fields">
                    <span className="label">Birthday</span>
                    <div className="downs">
                      <DropDown
                        error={
                          touched.birthday?.day && errors.birthday?.day
                            ? errors.birthday.day
                            : ''
                        }
                        placeholder="Day"
                        className="dropdown"
                        defaultValue={values.birthday.day}
                        items={days}
                        selectedValue={(day) => {
                          setFieldTouched('birthday.day', true);
                          setFieldValue('birthday.day', day);
                        }}
                      />
                      <DropDown
                        error={
                          touched.birthday?.month && errors.birthday?.month
                            ? errors.birthday.month
                            : ''
                        }
                        placeholder="Month"
                        className="dropdown"
                        items={months}
                        defaultValue={
                          months.find(
                            (month) => month.value === values.birthday.month
                          )?.value
                        }
                        selectedValue={(month) => {
                          setFieldTouched('birthday.month', true);
                          setFieldValue('birthday.month', month);
                        }}
                      />
                      <DropDown
                        error={
                          touched.birthday?.year && errors.birthday?.year
                            ? errors.birthday.year
                            : ''
                        }
                        placeholder="Year"
                        dropdownClassName="dropdown"
                        defaultValue={values.birthday.year}
                        items={years}
                        minRange={new Date().getFullYear() - 18}
                        selectedValue={(year) => {
                          setFieldTouched('birthday.year', true);
                          setFieldValue('birthday.year', year);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="gender">
                  <span className="label gender">Gender</span>
                  <RadioButton
                    className="radio-gender"
                    buttons={[
                      {
                        label: 'Male',
                        value: '1',
                        isChecked: +values.gender === +Gender.MALE
                      },
                      {
                        label: 'Female',
                        value: '2',
                        isChecked: +values.gender === +Gender.FEMALE
                      }
                    ]}
                    onChange={(gender) => {
                      setFieldValue('gender', gender);
                    }}
                  />
                </div>
                <Button
                  isLoading={showLoader}
                  className={`next ${!isValid ? 'invalid' : `${isValid}`}`}
                  type="submit"
                  title="Next"
                  t={t}
                />
              </Form>
            );
          }}
        </Formik>
      </Activation>
    </div>
  );
};

export default WhoYouAre;
