import React, { FC } from 'react';
import { IActivationWelcome } from 'core/interface/activation';
import Activation from '../Index';
import Button from 'components/Button/Index';

import './Index.scss';

const ActivationWelcome: FC<IActivationWelcome> = ({
  title,
  message,
  t,
  onGetStarted
}): JSX.Element => {
  const handleGetStarted = () => {
    onGetStarted && onGetStarted();
  };
  return (
    <div className="activationWelcome">
      <Activation title={title}>
        <div className="activationWelcome__content">
          <p className="activationWelcome__content-message">{message}</p>
          {onGetStarted && (
            <Button
              onClick={handleGetStarted}
              className="activationWelcome__content-btn"
              t={t}
              title="get_started"
            />
          )}
        </div>
      </Activation>
    </div>
  );
};

export default ActivationWelcome;
