import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { IworkExperienceData } from 'core/interface/workExperience';

const validation = (t: TFunction) => {
  return Yup.object({
    industry: Yup.string().required(t('required')),
    job_title: Yup.string()
      .required(t('required'))
      .max(300, `${t('less_than')} 300 ${t('characters')}`),
    company_name: Yup.string().required(t('required')),
    company_website: Yup.string().required(t('required')),
    location: Yup.object({
      id: Yup.number().required(t('required')),
      display_name: Yup.string().required(t('required'))
    }),
    description: Yup.string().required(t('required')),
    work_type: Yup.string().required(t('required')),
    ongoing: Yup.boolean(),
    start: Yup.object({
      month: Yup.string().required(t('required')),
      year: Yup.string().required(t('required'))
    }),
    finish: Yup.object({
      month: Yup.string(),
      year: Yup.string()
    })
  });
};

const initialValues: IworkExperienceData = {
  id: 1,
  company_name: '',
  company_website: '',
  description: '',
  industry: '',
  job_title: '',
  location: {
    id: 0,
    display_name: ''
  },
  ongoing: false,
  work_type: '',
  start: {
    month: '',
    year: '',
    day: ''
  },
  finish: {
    month: '',
    year: '',
    day: ''
  }
};

export { initialValues, validation };
