import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import DropDown from 'components/Dropdown';
import Image from 'components/image/Image';
import JobOpeningCart from 'components/jobOpeningCart';
import Autocomplete from 'components/shared/Autocomplete/Index';
import { workExperience } from 'core/constants/preferenceConstats';
import { RootState } from 'redux/store';
import { JobService } from 'services/jobs/job';
import useLocales from 'hooks/useLocales';
import JobOpenings from 'components/jobOpenings';
import Popup from 'components/shared/Popups/Popup/Index';
import Button from 'components/Button/Index';
import { Slider } from '@mui/material';
import { IndustryRedux } from 'core/interface/education';
import { jobContracts } from 'core/constants/workExperience';
import { getSalaryByRange } from 'constants/constants';
import { CMSService } from 'services/cms/cms';
import { IMe } from 'core/interface/me';
import { useNavigate, useParams } from 'react-router';

function JobOpening() {
  const { t } = useLocales();
  const { cities } = useSelector((state: RootState) => state.locations);
  const [formValues, setFormValues] = useState<any>({});
  const [value, setValue] = useState<any[]>([0, 5000]);
  const [openModal, setOpenModal] = useState(false);
  const { cms } = useSelector((state: RootState) => state.cms);
  const { jobs } = useSelector((state: RootState) => state.jobs);
  const industries = useSelector(
    (selector: IndustryRedux) => selector.industries?.industry || []
  );
  const me = useSelector((state: IMe) => state.user).user;
  const [filteredJobs, setFilteredJobs] = useState<any[]>([]);
  const [filterData, setFilterData] = useState<any>({});
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const filtered = jobs?.results.filter((job: any) => {
      if (!job.is_public) {
        return false;
      }
      const rangeValue = getSalaryByRange(job.salary);
      const { industry, location, required_experience, type, range } =
      filterData;
      const industryMatch = !industry || job.industries?.includes(industry);
      const locationMatch = !location || job.location?.id === location.id;
      const experienceMatch =
        !required_experience || job.work_exp === +required_experience;
      const typeMatch = !type || job?.duration === +type;
      const salaryMatch =
        !range ||
        (rangeValue &&
          rangeValue?.min >= range[0] &&
          rangeValue &&
          rangeValue?.max <= range[1]);
      return (
        industryMatch &&
        locationMatch &&
        experienceMatch &&
        typeMatch &&
        salaryMatch
      );
    });

    setFilteredJobs(filtered || jobs?.results);
  }, [jobs, filterData]);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (cms?.show_jobs) {
      getApplications();
    }
  }, [cms?.show_jobs]);

  const getApplications = (): void => {
    JobService.getJobs({ company: cms?.id });
  };

  const handleChange = (companyData: any) => {
    const formData = new FormData();

    for (const key in companyData) {
      if (key !== 'id') {
        formData.append(key, (companyData as any)[key]);
      }
    }
    CMSService.updateCMS(me?.company, formData);
    handleModal();
  };

  const handleLoadMoreCities = () => {
    const params = { limit: 10, offset: cities.length };
    JobService.getLocations(params, true);
  };

  return (
    <>
      <div className="jobOpeningContainer">
        <h1>Job openings</h1>
        {!id && <div className="pencil_icon" onClick={handleModal}>
          <div className="pencil_circle" />
          <Image className="pencil" name="pencil.svg" width={13} height={13} />
        </div>}
      </div>
      <div className="filterContainer">
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={(values, actions) => { }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="containerJob">
                <div className="fields_flex">
                  <div className="inputContainer">
                    <span className="label">{t('Specialty')}</span>
                    <Autocomplete
                      fieldName="industry"
                      show_arrow={true}
                      value={
                        filterData?.industry
                          ? industries.find(
                            (industry) =>
                              industry.id === +filterData?.industry
                          )?.name ?? ''
                          : ''
                      }
                      options={
                        industries?.map((e) => ({
                          id: e?.id,
                          title: e?.name
                        })) || []
                      }
                      show_location={false}
                      placeholder={t('Industry')}
                      onChange={(e) => {
                        const activeItem = String(
                          industries.find((industry) => industry.name === e)?.id
                        );
                        setFilterData((prev: any) => ({
                          ...prev,
                          industry: +activeItem
                        }));
                      }}
                      values={industries}
                    />
                  </div>
                  <div className="inputContainer">
                    <span className="label">{t('Work location')}</span>
                    <Autocomplete
                      value={filterData?.location?.display_name || ''}
                      options={
                        cities?.map((e: any) => ({
                          id: e?.id,
                          title: e?.display_name
                        })) || []
                      }
                      show_location={true}
                      placeholder={t('Country, province, city')}
                      onChange={(e) => {
                        const response = cities?.find(
                          (city: any) => city.display_name === e
                        );
                        setFilterData((prev: any) => ({
                          ...prev,
                          location: response
                        }));
                      }}
                      fieldName="location"
                      values={cities}
                      loadMore={handleLoadMoreCities}
                    />
                  </div>
                  <div className="inputContainer">
                    <span className="label">{t('Experience')}</span>
                    <DropDown
                      items={workExperience}
                      placeholder={t('Years')}
                      defaultValue={filterData?.required_experience || ''}
                      selectedValue={(e) =>
                        setFilterData((prev: any) => ({
                          ...prev,
                          required_experience: e
                        }))
                      }
                    />
                  </div>
                  <div className="inputContainer">
                    <span className="label">{t('Type')}</span>
                    <DropDown
                      placeholder={t('Full time')}
                      items={jobContracts}
                      defaultValue={filterData?.type || ''}
                      selectedValue={(e) =>
                        setFilterData((prev: any) => ({ ...prev, type: e }))
                      }
                    />
                  </div>
                  <div className="fieldsSalary">
                    <div className="salary_label">
                      <span className="label">Salary range</span>
                      <span className="label">{`${value[0]}$ - ${value[1]}$`}</span>
                    </div>
                    <Slider
                      max={5000}
                      getAriaLabel={() => 'Temperature range'}
                      value={value}
                      onChange={(e, value: any) => {
                        setValue(value);
                        setFilterData((prev: any) => ({
                          ...prev,
                          range: value
                        }));
                      }}
                      valueLabelDisplay="auto"
                      style={{ color: '#522583' }}
                    />
                  </div>
                  <div
                    className="clearContainer"
                    onClick={() => {
                      const publicJobs = jobs?.results.filter((job: any) => job.is_public);
                      setFilteredJobs(publicJobs);
                      setFilterData({});
                      setValue([0, 5000]);
                    }}
                  >
                    <span>Clear All</span>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="cardSection">
        <div className="cartContainerFlex">
          {cms?.show_jobs &&
            filteredJobs?.map((e: any, index: any) => (
              <JobOpeningCart item={e} key={index} />
            ))}
        </div>
        <Button className="next all" title="Show all jobs" t={t} onClick={() => navigate(`/filter-job/${cms?.id}`)} />
      </div>
      {openModal && (
        <Popup title="Job openings" className="expertise" onClose={handleModal}>
          <JobOpenings
            companyData={cms}
            setNewData={handleChange}
            isOneBtn={true}
            onClose={handleModal}
            showTopSide={false}
            isPopup={true}
            showTitle
          />
        </Popup>
      )}
    </>
  );
}

export default JobOpening;
