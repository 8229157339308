import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import useLocales from 'hooks/useLocales';
import DropDown from 'components/Dropdown';
import FormField from 'components/FormField/Index';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import {
  ISocialNetwork,
  ISocialNetworkForm,
  ISocialNetworkPopup
} from 'core/interface/aboutMe';
import { IMe } from 'core/interface/me';
import { InputType } from 'core/enum/inputType';
import { socialNetwork } from 'core/constants/aboutMePopUpConstants';
import { ProfileService } from 'services/profile/profile';
import { RootState } from '../../../../redux/store';
import { validationSchema } from './form';

import './index.scss';

const SocialNetworkPopup: FC<ISocialNetworkPopup> = ({
  onClose
}): JSX.Element => {
  const { t } = useLocales();
  const { socialMedia } = useSelector((state: RootState) => state.socialMedia);
  const me = useSelector((state: IMe) => state.user).user;

  const handleSubmit = (values: ISocialNetworkForm) => {
    values.networks.forEach((element: ISocialNetwork) => {
      if (element.id) {
        ProfileService.updateSocialMedias(me.candidate_id, element.id, element);
        onClose();
      }
    });
  };

  useEffect(() => {
    if (socialMedia.length === 0 || !socialMedia) {
      onClose();
    }
  }, [socialMedia]);

  const handleRemove = (id?: number): void => {
    if (id && me.candidate_id) {
      ProfileService.deleteSocialMedia(me.candidate_id, id);
    }
  };

  return (
    <div className="socialMedia">
      <Formik
        initialValues={{ networks: socialMedia }}
        enableReinitialize
        validationSchema={validationSchema(t)}
        onSubmit={handleSubmit}
      >
        {({ isValid, values, setFieldValue }) => {
          return (
            <Form>
              <div className="formData scrollable">
                {values.networks.map((item: ISocialNetwork, index: number) => (
                  <div key={index} className="fieldset">
                    <DropDown
                      defaultValue={item.network}
                      items={socialNetwork}
                      selectedValue={(e) =>
                        setFieldValue(`networks[${index}].network`, e)
                      }
                    />
                    <FormField
                      inputClassName="input"
                      fieldName={`networks[${index}].url`}
                      type={InputType.text}
                      placeholder="URL"
                    />
                    <span
                      className="trash"
                      onClick={() => handleRemove(item.id)}
                    >
                      <Image name="trash.svg" />
                    </span>
                  </div>
                ))}
              </div>
              <Button
                t={t}
                title="Save"
                type="submit"
                className={`save-btn ${!isValid ? 'invalid-btn' : ''}`}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SocialNetworkPopup;
