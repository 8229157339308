import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useNavigate, useParams } from 'react-router';
import JobCard from 'components/JobCard/Index';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer/Index';
import JobFilters from 'components/feature/jobFilters';
import { JobService } from 'services/jobs/job';
import { IJob, IJobFilters } from 'core/interface/job';
import NoData from 'components/shared/NoData/Index';
import { JobCardActionEnum } from 'core/enum/jobCardAction';
import useLocales from 'hooks/useLocales';
import Image from 'components/image/Image';
import Pagination from 'components/shared/Pagination/Index';

import './Index.scss';

const FilterJobs: FC = (): JSX.Element => {
  const { t } = useLocales();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filter, setFilter] = useState<IJobFilters>();
  const { jobs } = useSelector((state: RootState) => state.jobs);
  const { id } = useParams()
  const targetRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    getJobs();
  }, [filter]);

  const getJobs = async (
    limit: number = 10,
    offset: number = 0
  ): Promise<void> => {
    try {
      JobService.getJobs({
        limit,
        offset,
        industries: Number(filter?.industry),
        skills: Number(filter?.computer_skill),
        degrees: Number(filter?.degree_type),
        locations: Number(filter?.city.id),
        salaries: filter?.salary.map((item) => Number(item)) ?? [],
        work_exps: filter?.career_level.map((item) => Number(item)) ?? [],
        company: id ? +id : null
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterOpen = (): void => {
    setShowFilter(prev => !prev)
  }

  const handleChangeScroll = (): void => {
    if (targetRef?.current) {
      targetRef.current.scrollIntoView({
        block: 'start',
      });
    }
  }
  useEffect(() => {
    handleChangeScroll()
  }, [targetRef])

  return (
    <>
      <Navbar />
      <div className="fjobs" ref={targetRef}>
        <div className="top">
          {!id && <div className="back_buttons" onClick={() => navigate('/dashboard')}>
            <Image name="arrow-left.svg" />
            {t('Back to profile')}
          </div>}
          <div className="back_buttons" onClick={handleFilterOpen}>
            <Image name="filtering.svg" />
            {t('Open Filter')}
          </div>
        </div>
        <div className="filter-and-jobs">
          <div className={`filtering ${showFilter ? 'show' : ''}`}>
            <JobFilters applyFilter={setFilter} />
          </div>
          <div className="fjobs__matches">
            <div className="fjobs__matches__cards">
              {jobs?.results?.length === 0 ? (
                <NoData />
              ) : (
                jobs?.results?.map((item: IJob, index: number) => (
                  <div key={index} className="fjobs__matches__card">
                    <JobCard
                      id={item.id}
                      title={item.title}
                      sub_title={item.description}
                      icon={
                        item.company.logo ??
                        '/static/images/recrout-logo-letter.jpg'
                      }
                      actions={[
                        JobCardActionEnum.MORE_INFO,
                        JobCardActionEnum.ACCEPT,
                        JobCardActionEnum.PROFILE_VIEW,
                        JobCardActionEnum.CANCEL
                      ]}
                      matches={[
                        { percent: +item.score, title: 'Talent Match' },
                        { percent: +item.culture_score, title: 'Cultural Fit' }
                      ]}
                    />
                  </div>
                ))
              )}
            </div>

            <Pagination
              hasNext={jobs?.next ? jobs.next : null}
              itemsPerPage={10}
              dataLength={jobs?.count ?? 1}
              onNext={(e) => getJobs(10, e.prevPage)}
              onPrev={(e) => getJobs(10, e.prevPage !== 0 ? e.prevPage : 0)}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FilterJobs;
