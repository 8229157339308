import { IAction } from 'core/interface/action';
import { IUser } from 'core/interface/user';

export const SAVE_USER = 'SAVE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const SaveUser = (user: IUser) => ({
  type: SAVE_USER,
  payload: user
});

export const UpdateUser = (user: IUser) => ({
  type: UPDATE_USER,
  payload: user
});

export const RemoveUser = () => ({
  type: REMOVE_USER
});

const initialState = {
  user: null
};

const userReducer = (state = initialState, action: IAction<IUser>) => {
  switch (action.type) {
    case SAVE_USER:
      return {
        ...state,
        user: action.payload
      };
    case REMOVE_USER:
      return {
        ...state,
        user: null
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload
      };
    default:
      return state;
  }
};

export default userReducer;
