import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ICertificationData } from 'core/interface/certifications';
import { ITranslate } from 'core/interface/translate';

import './Index.scss';

const Certificate: FC<ITranslate> = ({
  t,
}): JSX.Element => {
  const { certifications } = useSelector(
    (state: RootState) => state.certifications
  );
  return (
    <div className="tab__content">
      {certifications?.map((item:ICertificationData) => (
        <div className="tab__content__certification" key={item.id}>
          <div className="tab__content__header">
            <span className="tab__content__header-title s-hidden">{item.institute}</span>
            <span className="tab__content__header-years">{item.year}</span>
          </div>
          <div className="tab__content__desc certificate_body">
            <span className="tab__content__desc-text s-hidden ">{item.title}</span>
            {item.recieved && (
              <span className="tab__content__desc-received">
                {t('received_certificate')}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Certificate;
