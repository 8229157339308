import React, { FC, useEffect, useState } from 'react';
import { FacebookShareButton, LinkedinShareButton } from 'react-share';
import RadarChart from 'components/RadarChart/Index';
import { aboutTabChartData } from 'core/constants/aboutTabConstants';
import { IAboutAndShare } from 'core/interface/aboutMe';
import Image from 'components/image/Image';
import { CandidateService } from 'services/candidate/candidate';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { CompetencieService } from 'services/competencies/competencies';
import { ChartData } from 'chart.js';
import { chartLabelTruncate } from 'helpers/chartLabelTruncate';

import './Index.scss';

const AboutAndShare: FC<IAboutAndShare> = ({ t, description }): JSX.Element => {
  const me = useSelector((state: RootState) => state.user).user;
  const [chartData, setChartData] = useState<ChartData>();
  const candidateOverview = useSelector(
    (state: RootState) => state.candidates
  ).candidatesOverview;
  const assessment = useSelector(
    (state: RootState) => state.assessment
  ).assessment;

  useEffect(() => {
    getCandidateOverview();
  }, []);

  useEffect(() => {
    pairCompetencies();
  }, [candidateOverview, assessment]);

  const getCandidateOverview = async () => {
    if (me?.candidate_id) {
      await CandidateService.getCandidateOverview(me?.candidate_id);
      await CompetencieService.getCompetencies();
    }
  };

  const pairCompetencies = () => {
    const competencies = candidateOverview?.competencies?.slice(0, 5);
    const result: any[] = [];
    assessment?.forEach((assesment: any) => {
      competencies?.forEach((competency: any) => {
        if (competency.competency_id === assesment.id) {
          const obj = {
            name: assesment.name,
            score: competency.score
          };
          result.push(obj);
        }
      });
    });

    const aboutTabChartData = {
      labels: result.map((res) => res.name),
      datasets: [
        {
          label: t('Score'),
          data: result.map((sc) => sc.score),
          backgroundColor: 'rgba(31, 119, 180, 0.5)',
          borderColor: 'rgba(31, 119, 180, 0.9)',
          borderWidth: 3
        }
      ]
    };

    if (JSON.stringify(chartData) !== JSON.stringify(aboutTabChartData)) {
      setChartData(aboutTabChartData);
    }
  };

  return (
    <div className="about__right__top__share">
      <div className="about__right__top__left">
        <span className="about__right__top__left-desc">{description}</span>
        <div className="btn">
          <Image className="img-btn" name="fb.svg" />
          <FacebookShareButton
            url={'https://npm-react-share-demo.netlify.app/'}
          >
            {t('share_facebook')}
          </FacebookShareButton>
        </div>
        <div className="btn linkedin-share">
          <Image className="img-btn" name="in.svg" />
          <LinkedinShareButton
            url={'https://npm-react-share-demo.netlify.app/'}
          >
            {t('share_linkedin')}
          </LinkedinShareButton>
        </div>
      </div>

      <div className="about__right__top__radar">
        {chartData && (
          <RadarChart
            data={chartData}
            showTicks={false}
            labelTruncateFunction={chartLabelTruncate}
            max={9}
            tooltipCallback={(ctx) =>
              `You scored ${ctx.parsed.r} out of 9 for ${ctx.label}`
            }
            showTooltipColor={false}
            tooltipTitle={() => ''}
            showCustomTooltip={true}
            width={310}
            height={250}
            fontSize={20}
          />
        )}
      </div>
    </div>
  );
};

export default AboutAndShare;
