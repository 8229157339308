import React from 'react';
import { IPrivacyPolicyTermsCard } from './types';

import './index.scss';

export default function PrivacyPolicyTermsCard({
  title,
  content,
  className
}: IPrivacyPolicyTermsCard) {
  return (
    <div className="terms_context">
      <h2 className="terms_context_title">{title}</h2>
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
}
