import ApiBase from '../base/apiBase';
import {
  IJobData,
  IJobCities,
  IJobParams,
  IJob,
  JobCitiesResultsType
} from 'core/interface/job';
import { IApplicationResult, IJobDTO } from 'core/interface/jobDTO';
import { AppendLocations, SaveLocations } from '../../redux/slices/location';
import { store } from '../../redux/store';
import {
  SaveEditJobs,
  SaveJobProfileCandidate,
  SaveJobs,
  SaveSingleJobs
} from '../../redux/slices/jobs';
import { SaveApplications } from '../../redux/slices/apllications';
import {
  SaveAssessmentByGroup,
  SaveAssessmentReport,
  SaveAssessmentValues
} from '../../redux/slices/assessment';
import { prepareUpdatedData } from 'helpers/stateChanges';
import { enqueueSnackbar } from 'notistack';

export class JobService {
  private static apiBase = new ApiBase<IJobData>();

  public static async getJobs(params?: IJobParams): Promise<IJobData> {
    let queryParams = '';
    let isFirstParam = true;
    if (params)
      for (const [key, value] of Object.entries(params)) {
        if (value) {
          queryParams += isFirstParam
            ? `/?${key}=${value}`
            : `&${key}=${value}`;
          isFirstParam = false;
        }
      }
    const { dispatch } = store;
    const res = await this.apiBase.getAsync(
      `/jobs${queryParams}${params ? '' : '/'}`
    );
    dispatch(SaveJobs(res));
    return res;
  }

  public static async getLocations(
    params: object,
    append = false
  ): Promise<IJobCities | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        'https://nora.recrout.com/api/cities/?limit=10',
        params
      );
      const uniqueLocations = new Map();

      res?.results?.forEach((obj: JobCitiesResultsType) => {
        if (!uniqueLocations.has(obj.display_name)) {
          uniqueLocations.set(obj.display_name, obj);
        }
      });
      const uniqueObjects = Array.from(uniqueLocations.values());
      if (append) {
        dispatch(AppendLocations(uniqueObjects));
      } else {
        dispatch(SaveLocations(uniqueObjects));
      }
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async getJobByIdOrSlug(
    idOrSlug: string | number
  ): Promise<IJob | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(`/jobs/${idOrSlug}/`);
      dispatch(SaveSingleJobs(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async getJobProfileCandidatesById(
    id: string,
    params: any,
    body: any
  ): Promise<any | void> {
    try {
      const { dispatch } = store;

      const res = await this.apiBase.postAsync(
        `/jobs/${id}/find_candidates/`,
        body,
        params
      );

      dispatch(SaveJobProfileCandidate(res));
      return res;
    } catch (error) {
      console.error('Error fetching:', error);
    }
  }

  public static async deleteJobByIdOrSlug(
    idOrSlug: string | number
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.deleteAsync(`/jobs/${idOrSlug}/`);
      const updatedState = prepareUpdatedData(
        'delete',
        'jobs',
        {
          id: idOrSlug
        },
        'jobs'
      );
      dispatch(SaveJobs(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async updateJobByIdOrSlug(
    idOrSlug: string | number,
    body: IJob
  ): Promise<IJob> {
    const { dispatch } = store;
    const res = await this.apiBase.updateAsync(`/jobs/${idOrSlug}/`, body);
    dispatch(SaveSingleJobs(res));
    return res;
  }

  public static async getassesments(user_id: number): Promise<void> {
    const { dispatch } = store;

    try {
      const res = await this.apiBase.getAsync(
        `/assessment/assessments/?user_id=${user_id}`
      );
      dispatch(SaveAssessmentValues(res));
      return res;
    } catch (error) {
      console.error('Error fetching assessments:', error);
      throw error;
    }
  }

  public static async getAssessmentByGroup(): Promise<void> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/assessment/competencies_by_group/`
      );
      dispatch(SaveAssessmentByGroup(res));
      return res;
    } catch (error) {
      console.error('Error fetching assessments:', error);
    }
  }

  public static async postassesments(user_id: number): Promise<any> {
    return this.apiBase.postAsync('/assessment/assessments/', {
      user: user_id
    });
  }

  public static async getAssesmentsResult(user_id: number) {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/assessment/assessments/?user_id=${user_id}`
      );
      dispatch(SaveAssessmentReport(res));
    } catch (error) {
      console.error('Error fetching assessments report:', error);
    }
  }

  public static async saveCultureProfile(): Promise<void> {
    const body = {
      title: 'Sub Zero',
      anonymous: false,
      company: 1,
      competencies: [
        { competency: 4, min_score: 2 },
        { competency: 18, min_score: 3 },
        { competency: 22, min_score: 5 },
        { competency: 33, min_score: 5 },
        { competency: 45, min_score: 3 }
      ],
      is_culture: true
    };
    return this.apiBase.postAsync(`/match/profiles/?culture=true`, body);
  }

  public static async getApplications(
    id?: number
  ): Promise<IJobDTO<IApplicationResult[]> | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/jobs/applications/?jobID=${id || ''}`
      );
      dispatch(SaveApplications(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async applyForAJob(job: number | any): Promise<any> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.postAsync(`/jobs/applications/`, { job });
      dispatch(SaveApplications(res));
      return res;
    } catch (error) {
      return null;
    }
  }

  public static async manageJob(id: number, data: any | any): Promise<any> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.updateAsync(
        `/jobs/applications/${id}/manage/`,
        data
      );
      const updatedState = prepareUpdatedData(
        'update',
        'applications',
        res,
        'applications'
      );
      if (data?.status === 4) {
        enqueueSnackbar('Request Cancelled', {
          variant: 'success'
        });
      }
      dispatch(SaveApplications(updatedState));
      return res;
    } catch (error) {
      return null;
    }
  }

  public static async rejectApplication(id: number): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.updateAsync(
        `/jobs/applications/${id}/cancel/`,
        {}
      );
      const updatedState = prepareUpdatedData(
        'delete',
        'applications',
        {
          id: id
        },
        'applications'
      );
      dispatch(SaveApplications(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async saveJob(body: unknown): Promise<IJobData | void> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.postAsync(`/jobs/`, body);
      const updatedState = prepareUpdatedData('create', 'jobs', res, 'jobs');
      dispatch(SaveJobs(updatedState));
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  public static async saveJobEdit(
    id: number,
    body: unknown
  ): Promise<IJobData | void> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.updateAsync(`/jobs/${id}/`, body);
      const updatedState = prepareUpdatedData('create', 'jobs', res, 'jobs');
      dispatch(SaveEditJobs(updatedState));
      return res;
    } catch (error) {
      console.log(error);
    }
  }
}
