import * as Yup from 'yup';
import { SignUpType } from 'core/interface/auth';
import { TFunction } from 'i18next';
import { Role } from 'core/enum/role';

const candidateValidation = (t: TFunction) => {
  return Yup.object({
    first_name: Yup.string()
      .trim()
      .min(1, `${t('least')} 1 ${t('characters')}`)
      .max(15, `${t('less_than')} 15 ${t('characters')}`)
      .required(t('required')),
    last_name: Yup.string()
      .trim()
      .min(1, `${t('least')} 1 ${t('characters')}`)
      .max(15, `${t('less_than')} 15 ${t('characters')}`)
      .required(t('required')),
    password: Yup.string()
      .trim()
      .min(8, `${t('least')} 8 ${t('characters')}`)
      .required(t('required')),
    email: Yup.string().trim().email(t('invalid_email')).required(t('required'))
  });
};

const talentValidation = (t: TFunction) => {
  return Yup.object({
    name: Yup.string()
      .trim()
      .min(1, `${t('least')} 1 ${t('characters')}`)
      .max(15, `${t('less_than')} 15 ${t('characters')}`)
      .required(t('required')),
    website: Yup.string()
      .trim()
      .required(t('required'))
      .test('isValidURL', t('invalid URL'), (value) => {
        if (!value) {
          return true; 
        }
        return /^(ftp|http|https):\/\/[^ "]+$/.test(value);
      }),
    contact_name: Yup.string()
      .trim()
      .min(1, `${t('least')} 1 ${t('characters')}`)
      .max(30, `${t('less_than')} 30 ${t('characters')}`)
      .required(t('required')),
    voucher_code: Yup.string()
      .trim()
      .min(1, `${t('least')} 1 ${t('characters')}`)
      .max(15, `${t('less_than')} 15 ${t('characters')}`),
    contact_email: Yup.string()
      .trim()
      .email(t('invalid_email'))
      .required(t('required'))
  });
};

const initialValues: SignUpType = {
  email: '',
  first_name: '',
  last_name: '',
  password: '',
  // company: 3,
  // recruiter_permission: 'view_all',
  role: Role.CANDIDATE
};

export { candidateValidation, initialValues, talentValidation };
