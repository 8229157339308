import React, { FC } from 'react';
import { getDateFormatter } from 'helpers/dateConverter';
import Image from 'components/image/Image';
import { IDescriptionCard } from 'core/interface/descriptionCard';

import './Index.scss';

const DescriptionCard: FC<IDescriptionCard> = ({
  id,
  title,
  description,
  details,
  year_from,
  year_to,
  handleRemove,
  handleEdit
}): JSX.Element => {
  const handleRemoveItem = (): void => {
    handleRemove(id);
  };

  const handleEditItem = (): void => {
    handleEdit && handleEdit(id);
  };

  return (
    <div className="desc">
      <div className="left">
        <div className="desc__top">
          <span className="desc__top-title">{title}</span>
          <span className="desc__top-years">
            {getDateFormatter(year_from, 'YYYY')} {year_to ? '-' : ''}{' '}
            {getDateFormatter(year_to ?? '', 'YYYY')}
          </span>
        </div>
        <p className="desc__about">{description}</p>
        <div className="desc__more">
          {details.map((item, index) => (
            <div key={index} className="desc__more__icons">
              {item.title && (
                <span className="desc__more__icons-icon">
                  <Image className="" name={item.icon} />
                </span>
              )}

              <span className="desc__more__icons-title">{item.title}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="right">
        <span onClick={handleRemoveItem} className="action_buttons">
          <Image name="trash.svg" />
        </span>
        <span onClick={handleEditItem} className="action_buttons">
          <Image name="edit-pen.svg" />
        </span>
      </div>
    </div>
  );
};

export default DescriptionCard;
