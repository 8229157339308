import { ISharedForm } from 'core/interface/sharedImagesPopup';
import { TFunction } from 'i18next';

import * as Yup from 'yup';

const validation = (t: TFunction) => {
  return Yup.object({
    title: Yup.string().trim().required(t('required')),
    description: Yup.string().trim()
  });
};

const initialValues: ISharedForm = {
  title: '',
  description: ''
};

export { validation, initialValues };
