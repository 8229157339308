import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik, FormikHelpers } from 'formik';
import { ProfileService } from 'services/profile/profile';
import { initialValues, validationSchema } from './form';
import { ICompanyInfo } from 'core/interface/matchProfile';
import useLocales from 'hooks/useLocales';
import Autocomplete from 'components/shared/Autocomplete/Index';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import Button from 'components/Button/Index';
import { RootState } from '../../../../redux/store';
import Activation from '../Index';

import './Index.scss';

const CompanyInfo = ({
  onSubmit,
  onBack,
  setCompanyData
}: any): JSX.Element => {
  const { t } = useLocales();
  const { industry: industries } = useSelector(
    (state: RootState) => state.industries
  );
  const [selectedIndustry, setSelectedIndustry] = useState<any>();

  const getIndustries = async (query?: string): Promise<void> => {
    ProfileService.getIndustries(query);
  };

  const handleSubmit = async (
    values: ICompanyInfo,
    actions: FormikHelpers<ICompanyInfo>
  ) => {
    setCompanyData((prev: any) => ({
      ...prev,
      ...values,
      industry: selectedIndustry?.industry
    }));
    onSubmit();
  };

  return (
    <Activation title="Nice to meet you" currentStep={3} totalSteps={5}>
      <>
        <h4 className="company-info">
          Please tell us a little bit more about you and your company
        </h4>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={validationSchema(t)}
        >
          {({ setFieldValue, isValid, values }) => (
            <Form className="form">
              <div className="field">
                <span className="label">Industry</span>
                <Autocomplete
                  fieldName="industry"
                  value={
                    industries?.find(
                      (industry) => industry?.id === +selectedIndustry?.industry
                    )?.name ?? ''
                  }
                  options={
                    industries?.map((e) => ({
                      id: e?.id,
                      title: e?.name
                    })) || []
                  }
                  show_location={false}
                  show_arrow={true}
                  placeholder={t('Industry')}
                  onChange={(e) =>
                    setSelectedIndustry((prev: any) => ({
                      ...prev,
                      industry: String(
                        industries?.find((industry) => industry.name === e)?.id
                      )
                    }))
                  }
                  onInputChange={(e) => {
                    getIndustries(e);
                    setFieldValue(
                      'industry',
                      industries?.find((ind) => ind.name === e)?.name
                    );
                  }}
                  values={industries}
                />
              </div>
              <div className="field">
                <span className="label">Name account holder</span>
                <FormField
                  inputClassName="input"
                  fieldName="name"
                  placeholder="Geert Jan"
                  type={InputType.text}
                />
              </div>
              <div className="av__content__buttons ">
                <Button
                  onClick={onBack}
                  t={t}
                  title="Back"
                  className="av__content__buttons-back"
                />
                <Button
                  type="submit"
                  t={t}
                  title="Next"
                  className="av__content__buttons-next"
                />
              </div>
            </Form>
          )}
        </Formik>
      </>
    </Activation>
  );
};

export default CompanyInfo;
