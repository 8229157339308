import React, { FC } from 'react';
import { IPreferencesPopup } from 'core/interface/preferences';
import MatchPreferences from 'components/feature/activation/preferences/Index';

import './Index.scss';

const PreferencesPopup: FC<IPreferencesPopup> = ({
  t,
  handleClose
}): JSX.Element => {
  return (
    <div className="preferences">
      <MatchPreferences
        isOneBtn={true}
        showTopSide={false}
        t={t}
        onClick={() => handleClose()}
      />
    </div>
  );
};

export default PreferencesPopup;
