export interface ICompetencyInformation {
  onClose: () => void;
}

export const tableColumns = [
  { label: 'Score', key: 'score' },
  { label: 'Percentage of the normgroup', key: 'normgroup_percentage' },
  { label: 'Natural ability', key: 'natural_ability' }
];

export const tableData = [
  {
    score: '1',
    normgroup_percentage: '4%',
    natural_ability: 'Very Low'
  },
  {
    score: '2',
    normgroup_percentage: '7%',
    natural_ability: 'Low'
  },
  {
    score: '3',
    normgroup_percentage: '12%',
    natural_ability: 'Low'
  },
  {
    score: '4',
    normgroup_percentage: '17%',
    natural_ability: 'Average'
  },
  {
    score: '5',
    normgroup_percentage: '20%',
    natural_ability: 'Average'
  },
  {
    score: '6',
    normgroup_percentage: '17%',
    natural_ability: 'Average'
  },
  {
    score: '7',
    normgroup_percentage: '12%',
    natural_ability: 'Hight'
  },
  {
    score: '8',
    normgroup_percentage: '7%',
    natural_ability: 'Hight'
  },
  {
    score: '9',
    normgroup_percentage: '4%',
    natural_ability: 'Very Hight'
  }
];
