import * as Yup from 'yup';
import { ITSkill } from 'core/interface/itSkill';
import { TFunction } from 'i18next';
import { FormValues } from 'core/interface/form';

export type initialValuesType = Pick<
  ITSkill,
  'computerSkill' | 'proficiency' | 'description'
>;

const initialValues: FormValues = {
  skills: [
    {
      computerSkill: '',
      description: '',
      proficiency: 0
    }
  ]
};

const validation = (t: TFunction) => {
  return Yup.object({
    skills: Yup.array(
      Yup.object().shape({
        computerSkill: Yup.string().trim().required(t('required')),
        description: Yup.string().trim().required(t('required')).min(1, `${t('least')} 1 ${t('characters')}`),
        proficiency: Yup.number().default(0).min(1)
      })
    )
  });
};

export { initialValues, validation };
