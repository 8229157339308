import './locales/i18n';
import './utils/highlight';

import React from 'react';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { createRoot } from 'react-dom/client';

import './locales/i18n';
import './utils/highlight';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/serviceWorker.js')
      .then(
        (reg) => console.log('ServiceWorker scope: ', reg.scope),
        (err) => console.log('ServiceWorker registration failed: ', err)
      )
      .catch((err) => console.log(err));
  });
} else {
  console.log('service worker is not supported');
}

{/*  eslint-disable-next-line @typescript-eslint/ban-ts-comment */ }
{/* @ts-ignore */ }
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);

reportWebVitals();
