import React, { FC, useEffect, useState } from 'react';
import Image from 'components/image/Image';
import { ISteps, StepType } from 'core/interface/steps';

import './Index.scss';

const Steps: FC<ISteps> = ({ totalSteps, currentStep }): JSX.Element => {
  const [step, setStep] = useState<StepType[]>([]);

  useEffect(() => {
    const newSteps = [] as StepType[];
    for (let i = 0; i < totalSteps; i++) {
      const step = {
        isActive: false,
        isPassed: false
      } as StepType;

      if (currentStep >= i + 1) {
        step.isActive = true;
        step.isPassed = false;
      }
      if (currentStep - 1 >= i + 1) {
        step.isActive = false;
        step.isPassed = true;
      }
      newSteps.push(step);
    }
    setStep(newSteps);
  }, [totalSteps, currentStep]);

  return (
    <div className="steps">
      {step.map((step, index) => (
        <div key={index} className="steps__step">
          {index + 1 > 1 && (
            <span
              className={`steps__step-tail ${
                step.isActive && !step.isPassed ? 'active' : ''
              } ${step.isPassed && !step.isActive ? 'passed' : ''}`}
            ></span>
          )}
          <span
            className={`steps__step-step ${
              step.isActive && !step.isPassed ? 'active' : ''
            } ${step.isPassed && !step.isActive ? 'passed' : ''}`}
          >
            {step.isPassed && (
              <Image name="passed.svg" width={15} height={15} />
            )}
          </span>
          <span
            className={`steps__step-tail ${
              step.isActive && !step.isPassed ? 'active' : ''
            } ${step.isPassed && !step.isActive ? 'passed' : ''}`}
          ></span>
        </div>
      ))}
    </div>
  );
};

export default Steps;
