import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const initialValues = {
  benefit_section_title: '',
  benefit_section_description: '',
  benefits: [
    {
      title: '',
      description: ''
    }
  ]
};

const validationSchema = (t: TFunction) =>{
 return Yup.object().shape({
    benefit_section_title: Yup.string().required(t('Field is required')),
    benefit_section_description: Yup.string().required(t('Field is required')),
    benefits: Yup.array()
    .of(
      Yup.object().shape({
          title: Yup.string().required(t('Field is required')),
          description: Yup.string().required(t('Field is required'))
        })
      )
      .min(1, t('At least one benefit is required'))
    });
  }

export { validationSchema };
