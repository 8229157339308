import React, { FC, useEffect, useState } from 'react';

import './Index.scss';
import Image from 'components/image/Image';
import { t } from 'i18next';
import { LinkedinShareButton, FacebookShareButton } from 'react-share';

const ShareJobPopup: FC = (): JSX.Element => {
  const [jobLink, setJobLink] = useState<string>('');

  useEffect(() => {
    setJobLink(window.location.href);
  }, []);

  return (
    <div className="sharejob">
      <div className="sharejob__content">
        <Image
          className="sharejob__content-share"
          name="socials.svg"
          width={18}
          height={18}
        />
        <span className="sharejob__content-label">Link</span>
        <input
          className="sharejob__content-link"
          readOnly
          type="text"
          value={jobLink}
        />
      </div>
      <div className="sharejob__share">
        <div className="sharejob__share-btn">
          <Image className="img-btn" name="fb.svg" />
          <FacebookShareButton className="button facebook" url={jobLink}>
            {t('share_facebook')}
          </FacebookShareButton>
        </div>
        <div className="sharejob__share-btn">
          <Image className="img-btn" name="in.svg" />
          <LinkedinShareButton className="button linkedin" url={jobLink}>
            {t('share_linkedin')}
          </LinkedinShareButton>
        </div>
      </div>
    </div>
  );
};

export default ShareJobPopup;
