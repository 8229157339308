import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { UserProfile } from 'core/interface/aboutMe';

const validation = (t: TFunction) => {
  return Yup.object({
    first_name: Yup.string()
      .min(1, `${t('least')} 1 ${t('characters')}`)
      .max(15, `${t('less_than')} 15 ${t('characters')}`)
      .trim()
      .required(t('required')),
    last_name: Yup.string()
      .trim()
      .min(1, `${t('least')} 1 ${t('characters')}`)
      .max(15, `${t('less_than')} 15 ${t('characters')}`)
      .required(t('required')),
    pitch: Yup.string()
      .trim()
      .min(1, `${t('least')} 1 ${t('characters')}`),
    headline: Yup.string()
      .trim()
      .min(1, `${t('least')} 1 ${t('characters')}`)
      .max(150, `${t('less_than')} 150 ${t('characters')}`),
    contact_info: Yup.string().trim(),
    contact_value: Yup.string()
      .min(5, `${t('least')} 5 ${t('characters')}`)
      .max(17, `${t('less than')} 17 ${t('characters')}`)
      .required(),
    location: Yup.object({
      id: Yup.number(),
      display_name: Yup.string().required()
    }).required(),
    birthday: Yup.object({
      day: Yup.number().min(1).max(31).required(),
      month: Yup.number().required(),
      year: Yup.number().required()
    }),
    show_in_profile: Yup.boolean(),
    gender: Yup.number(),
    blog_rss: Yup.string().trim(),
    interests: Yup.string().trim(),
    premium_code: Yup.string().trim()
  });
};

const initialValues: UserProfile = {
  first_name: '',
  last_name: '',
  pitch: '',
  headline: '',
  contact_info: '',
  contact_value: '',
  location: {
    display_name: '',
    id: 0
  },
  birthday: {
    day: 0,
    month: '',
    year: 0
  },
  gender: 1
};

export { validation, initialValues };
