import { IExtraActivitiesData } from 'core/interface/extraActivities';
import { store } from '../../redux/store';
import ApiBase from 'services/base/apiBase';
import { SaveExtraActivites } from '../../redux/slices/extraActivites';
import { prepareUpdatedData } from '../../helpers/stateChanges';

export class ExtraActivitieService {
  private static readonly baseApi = new ApiBase<IExtraActivitiesData[]>();

  public static async getExtraActivities(
    candidate_id: number
  ): Promise<IExtraActivitiesData[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.baseApi.getAsync(
        `/candidates/${candidate_id}/activities/`
      );
      dispatch(SaveExtraActivites(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async setExtraActivity(
    candidate_id: number,
    activity: IExtraActivitiesData
  ): Promise<IExtraActivitiesData[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.baseApi.postAsync(
        `/candidates/${candidate_id}/activities/`,
        activity
      );
      const updatedState = prepareUpdatedData(
        'create',
        'extraActivites',
        res,
        'extraActivities'
      );
      dispatch(SaveExtraActivites(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async updateExtraActivity(
    candidate_id: number,
    activity_id: number,
    activity: IExtraActivitiesData
  ): Promise<IExtraActivitiesData[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.baseApi.updateAsync(
        `/candidates/${candidate_id}/activities/${activity_id}/`,
        activity
      );
      const updatedState = prepareUpdatedData(
        'update',
        'extraActivites',
        res,
        'extraActivities'
      );
      dispatch(SaveExtraActivites(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  public static async deleteExtraActivity(
    candidate_id: number,
    activity_id: number
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.baseApi.deleteAsync(
        `/candidates/${candidate_id}/activities/${activity_id}/`
      );
      const updatedState = prepareUpdatedData(
        'delete',
        'extraActivites',
        {
          id: activity_id
        },
        'extraActivities'
      );
      dispatch(SaveExtraActivites(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
