import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { IMatchProfileReqBody } from 'core/interface/matchProfile';

const initialValues: IMatchProfileReqBody = {
  industry: '',
  name: '',
  contact_job_title: '',
  address: '',
  logo: ''
};

const validationSchema = (t: TFunction) => {
  return Yup.object({
    industry: Yup.number(),
    name: Yup.string(),
    contact_job_title: Yup.string(),
    address: Yup.string()
  });
};

export { validationSchema, initialValues };
