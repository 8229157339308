import React, { FC } from 'react';
import useLocales from 'hooks/useLocales';
import { IPrivacyPolicyTerms } from 'hooks/type';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer/Index';
import PrivacyPolicyTermsCard from 'components/PrivacyPolicyCard';
import { privacyPolicy } from 'constants/constants';

import './index.scss';

const PrivacyPolicy: FC = (): JSX.Element => {
  const { t } = useLocales();

  return (
    <div className="privacyPolicy">
      <Navbar />
      <div className="privacyPolicy_content">
        <h1 className="privacyPolicy_title">
          {t('Privacy Statement Recrout')}
        </h1>
        <div className="privacyPolicy_texts">
          {privacyPolicy.map((e: IPrivacyPolicyTerms, i: number) => (
            <PrivacyPolicyTermsCard
              title={t(e.title)}
              key={i}
              content={t(e.content)}
              className="terms_context_about"
            />
          ))}
          <div className="privacyPolicy_content_privacybeleid">
            {t(
              'Recrout Privacybeleid, © 2024 Recrout.com. All rights reserved.'
            )}
          </div>
        </div>
      </div>
      <div className="footers">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
