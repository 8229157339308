import React, { FC, useState } from 'react';
import { IPricingOption } from 'core/interface/pricingOption';
import Image from 'components/image/Image';
import UpgradePlanPopups from 'components/shared/Popups/UpgradePlans/Index';
import Popup from 'components/shared/Popups/Popup/Index';
import Button from 'components/Button/Index';
import { PlanEnum } from 'core/enum/plan';

import './Index.scss';

const PricingOption: FC<IPricingOption> = ({
  details,
  className,
  t
}): JSX.Element => {
  const [isUpgradePayment, setIsUpgradePayment] = useState<boolean>(false);
  const isActive = details.plan === PlanEnum.PREMIUM;

  const handleUpgradePayment = (): void => {
    setIsUpgradePayment(true);
  };

  return (
    <>
      <div className={`option ${isActive ? 'active' : ''} ${className ?? ''}`}>
        <span className={`option-title ${isActive ? 'active-title' : ''} `}>
          {t(details.plan)}
        </span>
        <span className="option-price">{details.price}</span>
        {details.plan !== PlanEnum.BASIC && (
          <p className="option-month">/ {t('month')}</p>
        )}
        {details.plan === PlanEnum.BASIC && (
          <span className="option-attention">{t('earn_free_premium')}</span>
        )}
        {details.plan === PlanEnum.PREMIUM && (
          <span className="option-attention">{t('receive_discount')}</span>
        )}
        {details.plan === PlanEnum.PRO && (
          <span className="option-attention">{t('yearly_payment')}</span>
        )}
        <ul className="option__options">
          {details.plan_info.map((info, index) => (
            <li key={index} className="option__options__list">
              <Image name="blue-check.svg" width={15} height={15} />
              <span className="option__options__list-option">{info}</span>
            </li>
          ))}
        </ul>
        {details.plan !== PlanEnum.BASIC &&
          (isActive ? (
            <Button
              className="option-button"
              t={t}
              title={t('extend')}
              onClick={handleUpgradePayment}
            />
          ) : (
            <Button
              className="option-button"
              t={t}
              title={t('get_it') + '!'}
              onClick={handleUpgradePayment}
            />
          ))}
      </div>
      {isUpgradePayment && (
        <Popup
          title="Upgrade your plan"
          onClose={() => setIsUpgradePayment(false)}
        >
          <UpgradePlanPopups plan={details.plan}/>
        </Popup>
      )}
    </>
  );
};

export default PricingOption;
