import { store } from '../../redux/store';
import ApiBase from 'services/base/apiBase';
import { SaveCandidates } from '../../redux/slices/candidates';
import { ICompany } from 'core/interface/companies';
import { SaveCompanies } from '../../redux/slices/company';

export class CompanyService {
  private static readonly apiURL = new ApiBase<ICompany>();

  public static async getCompanies(
    candidate_id: string
  ): Promise<ICompany | void> {
    const { dispatch } = store;
    try {
      const res = await this.apiURL.getAsync(`/companies/${candidate_id}/`);
      dispatch(SaveCompanies(res));
      return res;
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  }

  public static async updateCompanies(
    body: object,
    candidate_id: number
  ): Promise<ICompany | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiURL.updateAsync(
        `/companies/${candidate_id}/`,
        body
      );
      dispatch(SaveCompanies(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
