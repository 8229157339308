import { IndustryItemsI } from 'core/interface/Industry';
import { ICandidate } from 'core/interface/candidate';
import {
  availableAbout,
  availableRelocate,
  manageExperience,
  workExperience
} from './preferenceConstats';
import { getSalaryByValue } from 'constants/constants';
import { contractTypes } from './workType';

const industryItems = (candidate: any): IndustryItemsI[] => {
  return [
    {
      image: 'portfolio.svg',
      title: 'Industries preferences',
      desc:
        candidate?.industries?.map((industry: any) => industry.name).join(' ') ??
        ''
    },
    {
      image: 'computer.svg',
      title: 'Computer Skills',
      desc: candidate.skills
    },
    {
      image: '24-hours.svg',
      title: 'Availability',
      desc:
        '' +
          availableAbout?.find((item) => +item.value === candidate?.availability)
            ?.to_show ?? ''
    },
    {
      image: 'salary.svg',
      title: 'Salary',
      desc: getSalaryByValue(candidate?.salary)
    },
    {
      image: 'mortarboard.svg',
      title: 'Study',
      desc: candidate.educations
    },
    {
      image: 'expertise.svg',
      title: 'Management experience',
      desc:
        '' +
        manageExperience.find(
          (item) => +item.value === candidate.management_exp
        )?.to_show
    },
    {
      image: 'home-exchange.svg',
      title: 'Willing to relocate',
      desc:
        '' +
        availableRelocate.find((item) => +item.value === candidate.relocation)
          ?.to_show
    },
    {
      image: 'translate.svg',
      title: 'Languages',
      desc: candidate.languages
    },
    {
      image: 'contracts.svg',
      title: 'Preferred contract',
      desc: contractTypes
        .map((e) => {
          return candidate.contract.map((contract: any) => {
            if (contract === +e.value) {
              return e.to_show;
            }
          });
        })
        .join(' ')
    },
    {
      image: 'volume.svg',
      title: 'Career Level',
      desc: workExperience.find((e) => +e.value === candidate.work_exp)?.to_show
    },
    {
      image: 'calendar.svg',
      title: 'Birthdate',
      desc: candidate.bod
    }
  ];
};

export { industryItems };
