import { FC } from 'react';
import ReactPlayer from 'react-player';
import { ISharedVideos } from 'core/interface/sharedImages';
import { ISharedLinks } from 'core/interface/candidate';

const SharedVideos: FC<ISharedVideos> = ({
  content,
  className
}): JSX.Element => {
  return (
    <div className="tab__photos__content">
      {content?.map((item: ISharedLinks, index: number) => (
        <div
          className={`tab__photos__content-video ${className ?? ''}`}
          key={item.id + '_' + index}
        >
          <ReactPlayer url={item.url} controls width="290px" height="100%" />
        </div>
      ))}
    </div>
  );
};
export default SharedVideos;
