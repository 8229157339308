import { IAction } from 'core/interface/action';
import { IJobForm } from 'core/interface/job';

export const SAVE_VALUES = 'save-social-media-expertise';

const defaultState = {
  socialMedia: null
};

export const SaveMediaExpertise = (value: any | number[]) => ({
  type: SAVE_VALUES,
  payload: value
});

const socialMediaExpertiseReducer = (
  state = defaultState,
  action: IAction<any | number[]>
) => {
  switch (action.type) {
    case SAVE_VALUES:
      return {
        ...state,
        socialMedia: action.payload
      };
    default:
      return state;
  }
};

export default socialMediaExpertiseReducer;
