import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Select, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { IDropDown } from 'core/interface/dropDown';
import Image from 'components/image/Image';
import useLocales from 'hooks/useLocales';

import './Index.scss';

const DropDown: FC<IDropDown> = ({
  items,
  className,
  dropdownClassName,
  selectedValue,
  defaultValue,
  search,
  setSearch,
  placeholder,
  error,
  isReadonly,
  onReset,
  maxRange = 0,
  minRange = Number.POSITIVE_INFINITY,
  fieldName,
  disabled = false,
  showClose = true
}): JSX.Element => {
  const { t } = useLocales();
  const [value, setValue] = useState<string>(defaultValue ?? '');
  const selectRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (+value < maxRange) {
      handleReset();
    }
  }, [value, maxRange]);

  useEffect(() => {
    if (selectRef?.current && placeholder && !defaultValue) {
      const element = selectRef?.current.querySelectorAll(
        '.MuiOutlinedInput-input .notranslate'
      );
      element.forEach(
        (element: Element) => (element.textContent = placeholder)
      );
    }
  }, [selectRef, placeholder, defaultValue]);

  useEffect(() => {
    setValue(defaultValue ?? '');
  }, [defaultValue]);

  useEffect(() => {
    if (onReset) {
      handleReset();
    }
  }, [onReset]);

  const handleReset = () => {
    setValue('');
    selectedValue('');
  };

  const handleChange = (event: SelectChangeEvent<string>): void => {
    if (!isReadonly) {
      setValue(event.target.value);
    }
    selectedValue(event.target.value);
  };

  const handleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => setSearch && setSearch(e.target.value);

  return (
    <div
      className={`dropdown-cl closer-dropdown ${className ?? ''} ${error ? 'err' : ''
        }`}
    >
      <Select
        name={fieldName}
        disabled={disabled}
        maxRows={5}
        className={dropdownClassName}
        ref={selectRef}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        defaultValue={defaultValue}
        open={isOpen}
        onChange={handleChange}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        MenuProps={{ classes: { paper: 'paper-dropdown' } }}
        IconComponent={() => (
          <>
            <span onClick={() => setIsOpen(!isOpen)}>
              <Image
                className={`down-arrow ${isOpen ? 'active' : ''}`}
                name="down-arrow.svg"
              />
            </span>
          </>
        )}
      >
        {search && (
          <TextField
            value={search}
            onChange={handleSearch}
            className="dropdown-search"
            placeholder="Search"
          />
        )}
        <MenuItem className="disabled-placeholder" value="" disabled>
          {placeholder}
        </MenuItem>
        {items?.length &&
          items.map(
            (elem, index) =>
              elem.value && (
                <MenuItem
                  disabled={
                    elem.value === value ||
                    +elem.value < maxRange ||
                    +elem.value > minRange
                  }
                  key={index}
                  value={elem.value}
                >
                  {elem.to_show}
                </MenuItem>
              )
          )}
      </Select>
      {error && <span className="error-message"> {t('required')} </span>}
    </div>
  );
};

export default DropDown;
