import React, { useEffect, useState } from 'react';
import Image from 'components/image/Image';
import { availableAbout, availableRelocate, manageExperience, monthlySalary, workExperience } from 'core/constants/preferenceConstats';
import { contracts } from 'core/constants/jobCardConstant';
import {
  calculateMatchPercentage,
  extractCompetencyInfo,
  getComparedData,
} from 'helpers/teaserView';
import Button from 'components/Button/Index';
import useLocales from 'hooks/useLocales';
import CompareChart from '../CompareChart';
import { getDateFormatter } from 'helpers/dateConverter';

import './Index.scss';

const ProfilePopUp = ({ onClick, getOneRequest, getJob, id }: any): JSX.Element => {
  const [candidateInfo, setCandidateInfo] = useState<any>({
    contract: "",
    workExp: "",
    managementExp: "",
    availability: "",
    willingness: "",
    salary: ""
  })
  const [talentMatchPercent, setTalentMatchPercent] = useState<number | null | string>();
  const [cultureMatchPercent, setCultureMatchPercent] = useState<number | null | string>();
  const [competencyInfo, setCompetencyInfo] = useState<any>({
    names: [],
    descriptions: [],
    minScores: []
  });
  const [cultureInfo, setCultureInfo] = useState<any>({
    cultureName: [],
    cultureDescriptions: [],
    cultureMinScores: []
  });
  const { t } = useLocales();

  useEffect(() => {
    const contract = contracts.find((el: any) => el.value === getOneRequest?.contract?.[0])?.label
    const workExp = workExperience.find((el: any) => +el.value === getOneRequest?.work_exp)?.to_show
    const managementExp = manageExperience.find((el: any) => +el.value === getOneRequest?.management_exp)?.to_show
    const availability = availableAbout.find((el: any) => +el.value === getOneRequest?.availability)?.to_show
    const willingness = availableRelocate.find((item) => +item.value === getOneRequest?.relocation)?.to_show
    const salary = monthlySalary.find((item) => +item.value === getOneRequest?.salary)?.to_show
    setCandidateInfo({
      contract,
      workExp,
      managementExp,
      availability,
      willingness,
      salary
    });
    const { names, descriptions, minScores } = extractCompetencyInfo(getJob?.competencies);
    const { names: cultureName, descriptions: cultureDescriptions, minScores: cultureMinScores } = extractCompetencyInfo(getJob?.culture_profile?.competencies);

    setCompetencyInfo({
      names,
      descriptions,
      minScores
    });

    setCultureInfo({
      cultureName,
      cultureDescriptions,
      cultureMinScores
    });

    const compareScores = getComparedData(getOneRequest?.competencies, getJob?.competencies)?.map((el: any) => +el?.score);
    const compareScoresCulture = getComparedData(getOneRequest?.competencies, getJob?.culture_profile?.competencies)?.map((el: any) => +el?.score);

    const percentages = calculateMatchPercentage(compareScores, minScores);
    const percentagesCulture = calculateMatchPercentage(compareScoresCulture, cultureMinScores);

    setTalentMatchPercent(percentages);
    setCultureMatchPercent(percentagesCulture);

  }, [getOneRequest, getJob])


  return (
    <div className="teaser">
      <div className="teaser__top">
        <div className="teaser__top__profile teaser-block">
          <Image
            className="teaser__top__profile-avatar"
            url={getOneRequest?.avatar}
            name={!getOneRequest?.avatar ? "no-avatar.svg" : ""}
            width={115}
            height={115}
          />
          <span className="teaser__top__profile-role about-item">
            {getOneRequest?.name}
          </span>
          {talentMatchPercent && (
            <span className="teaser__top__profile-talent about-item">
              {isNaN(Number(talentMatchPercent)) ? 0 : talentMatchPercent}% Talent Match
            </span>
          )}
          {cultureMatchPercent && (
            <span className="teaser__top__profile-talent about-item sec">
              {cultureMatchPercent}% Cultural Fit
            </span>
          )}
          <p className="teaser__top__profile-about about-item">
            {getOneRequest?.pitch}
          </p>
        </div>
        <div className="teaser__top__second">
          <div className="teaser__top__second__cards">
            <Image
              name="industry.svg"
              width={30}
              height={30}
            />
            <div className="teaser__top__second__cards__description">
              <span className="teaser__top__second__cards__names">Industries preferences</span>
              {getOneRequest?.industries?.map((item: any) => (
                <span key={item.id} className="teaser__top__second__cards__cont-title">
                  {item.name}
                </span>
              ))}
            </div>
          </div>
          <div className="teaser__top__second__cards">
            <Image
              name="study.svg"
              width={30}
              height={30}
            />
            <div className="teaser__top__second__cards__description">
              <span className="teaser__top__second__cards__names">Study</span>
              {getOneRequest?.studies?.map((item: any) => (
                <span key={item.id} className="teaser__top__second__cards__cont-title">
                  {item.name}
                </span>
              ))}
            </div>
          </div>
          <div className="teaser__top__second__cards">
            <Image
              name="preferred.svg"
              width={30}
              height={30}
            />
            <div className="teaser__top__second__cards__description">
              <span className="teaser__top__second__cards__names">Preferred contract</span>
              <span className="teaser__top__second__cards__cont-title">
                {candidateInfo.contract}
              </span>
            </div>
          </div>
          <div className="teaser__top__second__cards">
            <Image
              name="compSkill.svg"
              width={30}
              height={30}
            />
            <div className="teaser__top__second__cards__description">
              <span className="teaser__top__second__cards__names">Computer Skills</span>
              {getOneRequest?.skills?.map((item: any) => (
                <span key={item.id} className="teaser__top__second__cards__cont-title">
                  {item.name}
                </span>
              ))}
            </div>
          </div>
          <div className="teaser__top__second__cards">
            <Image
              name="managment.svg"
              width={30}
              height={30}
            />
            <div className="teaser__top__second__cards__description">
              <span className="teaser__top__second__cards__names">Management experience</span>
              <span className="teaser__top__second__cards__cont-title">
                {candidateInfo.managementExp}
              </span>
            </div>
          </div>
          <div className="teaser__top__second__cards">
            <Image
              name="careerLevel.svg"
              width={30}
              height={30}
            />
            <div className="teaser__top__second__cards__description">
              <span className="teaser__top__second__cards__names">Career Level</span>
              <span className="teaser__top__second__cards__cont-title">
                {candidateInfo.workExp}
              </span>
            </div>
          </div>
          <div className="teaser__top__second__cards">
            <Image
              name="availability.svg"
              width={30}
              height={30}
            />
            <div className="teaser__top__second__cards__description">
              <span className="teaser__top__second__cards__names">Availability</span>
              <span className="teaser__top__second__cards__cont-title">
                {candidateInfo.availability}
              </span>
            </div>
          </div>
          <div className="teaser__top__second__cards">
            <Image
              name="willing.svg"
              width={30}
              height={30}
            />
            <div className="teaser__top__second__cards__description">
              <span className="teaser__top__second__cards__names">Willing to relocate</span>
              <span className="teaser__top__second__cards__cont-title">
                {candidateInfo.willingness}
              </span>
            </div>
          </div>
          <div className="teaser__top__second__cards">
            <Image
              name="study.svg"
              width={30}
              height={30}
            />
            <div className="teaser__top__second__cards__description">
              <span className="teaser__top__second__cards__names">Birthdate</span>
              <span className="teaser__top__second__cards__cont-title">
                {getDateFormatter(getOneRequest?.birthdate, 'MMM. DD, YYYY')}
              </span>
            </div>
          </div>
          <div className="teaser__top__second__cards">
            <Image
              name="salaries.svg"
              width={30}
              height={30}
            />
            <div className="teaser__top__second__cards__description">
              <span className="teaser__top__second__cards__names">Salary</span>
              <span className="teaser__top__second__cards__cont-title">
                {candidateInfo.salary}
              </span>
            </div>
          </div>
          <div className="teaser__top__second__cards">
            <Image
              name="languages.svg"
              width={30}
              height={30}
            />
            <div className="teaser__top__second__cards__description">
              <span className="teaser__top__second__cards__names">Languages</span>
              {getOneRequest?.languages?.map((item: any) => (
                <span key={item.id} className="teaser__top__second__cards__cont-title">
                  {item.name}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="teaser__bottom">
        <CompareChart
          names={competencyInfo?.names}
          className="jobdetails__chart-left  bg_styles"
          minScores={competencyInfo?.minScores}
          descriptions={competencyInfo?.descriptions}
          percent={talentMatchPercent}
          comparePoints={getComparedData(getOneRequest?.competencies, getJob?.competencies) || []}
          title={"Match job competencies"} />
        <CompareChart
          percent={cultureMatchPercent}
          className="jobdetails__chart-right bg_styles"
          names={cultureInfo?.cultureName}
          minScores={cultureInfo?.cultureMinScores}
          descriptions={cultureInfo?.cultureDescriptions}
          comparePoints={getComparedData(getOneRequest?.competencies, getJob?.culture_profile?.competencies) || []}
          title={"Match culture competencies"} />
      </div>
      {id &&
        <div className='btn_wrapper'>
          <Button className='cancel_request' title="Cancel Request" t={t} onClick={onClick} />
        </div>
      }
    </div>
  );
};

export default ProfilePopUp;
