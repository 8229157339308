import React, { FC } from 'react';
import { IConfirm } from 'core/interface/confirmModal';
import Button from 'components/Button/Index';

import './Index.scss';

const ConfirmPopup: FC<IConfirm> = ({
  description,
  onApprove,
  onCancel,
  approveLoading,
  cancelLoading,
  approveTitle,
  cancelTitle,
  t
}) => {
  const handleApprovement = () => {
    onApprove();
  };
  const handleDeclime = () => {
    onCancel();
  };

  return (
    <div className="confirm">
      <span className="confirm__desc">{description}</span>
      <div className="confirm__buttons">
        <Button
          isLoading={approveLoading}
          t={t}
          title={approveTitle ?? 'yes'}
          onClick={handleApprovement}
          className="confirm__buttons-btn no"
        />
        <Button
          disabled={approveLoading || cancelLoading}
          isLoading={cancelLoading}
          t={t}
          title={cancelTitle ?? 'No'}
          onClick={handleDeclime}
          className="confirm__buttons-btn yes"
        />
      </div>
    </div>
  );
};

export default ConfirmPopup;
