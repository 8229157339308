import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import {
  ISharedForm,
  ISharedImagesPopup
} from 'core/interface/sharedImagesPopup';
import Image from 'components/image/Image';
import ActionButton from 'components/ActionButton/Index';
import { Form, Formik, FormikHelpers } from 'formik';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import { initialValues, validation } from './form';
import Button from 'components/Button/Index';
import { AccountServce } from 'services/upload/file-upload';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import './Index.scss';

const SharedImagesPopup: FC<ISharedImagesPopup> = ({
  t,
  images,
  handleClose
}): JSX.Element => {
  const fileRef = useRef(null);
  const [imagesPreview, setImagesPreview] = useState<Array<any> | null>(null);
  const [isPrev, setIsPrev] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<any>();
  const user = useSelector((state: RootState) => state.user).user;
  const uploadedFiles = useSelector((state: RootState) => state.file.files);
  const handleRemoveImage = (item: any) => {
    if (!user) {
      return;
    }
    if (!item.isItem) {
      setImagesPreview((prevState) =>
        prevState
          ? prevState.filter((_, index) => index !== item.id)
          : prevState
      );
      setIsPrev(false);
    } else {
      AccountServce.removeUploadedImages(user.candidate_id, (item as any).id);
      handleClose();
    }
  };

  useEffect(() => {
    setImagesPreview(uploadedFiles);
  }, [uploadedFiles]);

  const handleImageChosen = (files: FileList): void => {
    const images = Array.from(files).map((file) => URL.createObjectURL(file));
    setFiles(files);
    setImagesPreview((prevState) =>
      prevState ? [...prevState, ...images] : images
    );
    setIsPrev(true);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    handleImageChosen(e.dataTransfer.files);
  };

  const handleSubmit = (
    values: ISharedForm,
    actions: FormikHelpers<ISharedForm>
  ) => {
    uploadBrowsedFiles(values.title);
    return;
  };

  async function uploadBrowsedFiles(title: string) {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append('title', title);

      const fileListArray = Array.from(files);

      if (fileListArray.length > 0) {
        fileListArray.forEach((file: any) => {
          formData.append('uploaded_file', file, file.name);
        });
      }

      if (user && formData) {
        await AccountServce.uploadImages(user.candidate_id, formData);
        handleClose();
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      setLoading(false);
    }
  }

  const chooseFile = () => {
    if (fileRef.current) {
      (fileRef.current as HTMLInputElement).click();
    }
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target;

    if (files) handleImageChosen(files);
  };

  return (
    <div className="shared">
      <div className="images">
        {images &&
          images.map((item, index) => (
            <div key={index} className="image">
              <ActionButton onClick={() => handleRemoveImage(item)} />
              <Image key={index} url={item.image} />
            </div>
          ))}
        {imagesPreview &&
          imagesPreview.map((item: any, index) => (
            <div key={index} className="image">
              <span className='trash-shared'  onClick={() =>
                  handleRemoveImage({
                    isItem: !!item.uploaded_file,
                    id: item.id ?? index
                  })
                }>
                <Image name='shared-remove.svg' width={36} height={36}/>
              </span>
              <Image
                key={index}
                url={item?.uploaded_file ?? item}
                className='shared-images'
              />
            </div>
          ))}
      </div>
      <div className={`about-side`}>
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={`drag-and-drop ${
            files?.length >= 5 ||
            isPrev ||
            (uploadedFiles && uploadedFiles?.length >= 5)
              ? 'disabled'
              : ''
          }`}
        >
          <div className="texts">
            <Image
              className="upload"
              name="upload-file.svg"
              width={40}
              height={40}
            />
            <span className="drag-title">Drag and drop your files here </span>
            <span className="supported">Files supported: JPG, PNG </span>
            <span className="or">Or</span>
            <Button
              t={t}
              onClick={chooseFile}
              title="browse_file"
              className="browse"
            />
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={() => validation(t)}
          onSubmit={handleSubmit}
        >
          {({ isValid }) => (
            <Form>
              <div className="field">
                <div className="label">Image title</div>
                <FormField
                  disabled={
                    (files && files?.length >= 5) ||
                    (uploadedFiles && uploadedFiles?.length >= 5)
                  }
                  className="input"
                  placeholder="Title"
                  fieldName="title"
                  type={InputType.text}
                />
              </div>
              <div className="field">
                <div className="label">Description</div>
                <FormField
                   disabled={
                    (files && files?.length >= 5) ||
                    (uploadedFiles && uploadedFiles?.length >= 5)
                  }
                  className="input"
                  placeholder="Description"
                  fieldName="description"
                  type={InputType.text}
                />{' '}
              </div>
              <Button
                 disabled={
                  (files && files?.length >= 5) ||
                  (uploadedFiles && uploadedFiles?.length >= 5)
                }
                t={t}
                className={`save ${!isValid ? 'invalid-btn' : ''} ${ 
                  (files && files?.length >= 5) ||
                  (uploadedFiles && uploadedFiles?.length >= 5) ? 'disabled' : ''
                } ${!isPrev ? 'disabled' : ''}`}
                title="Save"
                type="submit"
                isLoading={loading}
              />
            </Form>
          )}
        </Formik>
      </div>
      <input
        ref={fileRef}
        className="file"
        type="file"
        accept="image/png, image/jpeg"
        onChange={handleUpload}
        max={1}
      />
    </div>
  );
};

export default SharedImagesPopup;
