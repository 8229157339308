import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const initialValues = {
  contact_email: '',
  contact_phone: '',
  social_media_links: [
    {
      network: '',
      url: ''
    }
  ]
};

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    contact_phone: Yup.string()
      .trim()
      .min(5, `${t('least')} 5 ${t('characters')}`)
      .required(t('required')),
    contact_email: Yup.string()
      .required(t('Field is required'))
      .email(t('Invalid email format')),
    social_media_links: Yup.array()
      .of(
        Yup.object().shape({
          url: Yup.string()
          .required(t('required'))
          .test('isValidURL', t('Invalid URL'), (value) => {
            if (!value) {
              return true;
            }
            return /^(ftp|http|https):\/\/[^ "]+$/.test(value);
          }),
          network: Yup.string().required(t('Field is required'))
        })
      )
      .min(1, t('At least one social media link is required'))
  });

export { validationSchema };
