import React, { FC } from 'react';
import { ITranslate } from 'core/interface/translate';
import Activation from '../Index';
import Button from 'components/Button/Index';

import './Index.scss';

const ReadyToStart: FC<ITranslate> = ({ t }): JSX.Element => {
  return (
    <Activation title="Ready to get started?" currentStep={5}>
      <div className="starter">
        <p className="starter__text">
          Thank you for completing the details. The more we love youknow the
          better we can match you. Your details areIn addition, you are always
          protected with us. Employers will use your datacannot see without your
          explicit permission.Therefore, make sure you regularly visit your
          profile to see ifinterest has been shown, good luck on Recrout!
        </p>
        <div className="buttons">
          <Button className="btn back" t={t} title="back" />
          <Button className="btn next" t={t} title="go_to_profile" />
        </div>
      </div>
    </Activation>
  );
};

export default ReadyToStart;
