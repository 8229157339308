import { IAction } from 'core/interface/action';

export const SAVE_VALUES = 'save-work-experiences';

const defaultState = {
  experience: null,
};

export const SaveWorkExperiences = (value: any | number[]) => ({
  type: SAVE_VALUES,
  payload: value
});

const workExperiencesReducer = (
  state = defaultState,
  action: IAction<any | number[]>
) => {
  switch (action.type) {
    case SAVE_VALUES:   
      return {
        ...state,
        experience: action.payload
      };
    default:
      return state;
  }
};

export default workExperiencesReducer;
