import { ITerm } from 'core/interface/terms';

const GeneralTermsConstant: ITerm[] = [
  {
    title: '1.0 Definities',
    description: `In deze Algemene Voorwaarden worden de in
        dit artikel opgenomen begrippen met een
        hoofdletter gebruikt. Onder deze begrippen
        wordt het navolgende verstaan:
        `
  },
  {
    title: 'Algemene Voorwaarden',
    description: `Deze voorwaarden, ongeacht de vorm waarin
        deze kenbaar worden gemaakt.`
  },
  {
    title: 'Tegoed/Credits',
    description: `Een door Opdrachtgever aangeschaft tegoed of
        credit waarmee bepaalde Diensten kunnen
        worden aangeschaft.`
  },
  {
    title: 'Diensten',
    description: `De door Recrout aangeboden diensten, zoals
        onder meer het online plaatsen van vacatures,
        als ook het verkrijgen van toegang c.q. inzage
        te krijgen in de door Recrout aangelegde CV-
        database en profielen en alle andere diensten
        die voorwerp zijn van enige aanbieding, offerte,
        overeenkomst of andere rechtshandeling in de
        relatie tussen Recrout en Opdrachtgever.`
  }
];

const PrivacyTerms: ITerm[] = [
  {
    title: 'Privacybeleid',
    description: `Wij waarderen het vertrouwen dat u in ons stelt
        en wij zullen daarom zorgvuldig met uw
        gegevens omgaan. Wij respecteren de privacy
        van Gebruikers van onze site en dragen er zorg
        voor dat de persoonlijke informatie die u ons
        verschaft vertrouwelijk wordt behandeld. Dit
        privacybeleid is van toepassing op alle diensten
        en websites die Recrout aanbiedt, verder te
        noemen Aanbieder.
        `
  },
  {
    title: '1',
    description: `Wij gebruiken uw gegevens voor het leveren
        van onze diensten, aan u, inclusief de weergave
        van aangepaste inhoud en advertenties
        (‘behavioural advertising’); voor controle,
        onderzoek en analyse doeleinden om onze
        diensten te onderhouden, te beveiligen en te verbeteren; het veiligstellen van het technisch
        functioneren van ons netwerk; het beschermen
        van de rechten of het eigendom van Aanbieder
        of Gebruikers; en het ontwikkelen van nieuwe
        diensten. In bepaalde gevallen is het mogelijk
        dat we gegevens verwerken namens of volgens
        de instructies van derden, zoals onze
        advertentiepartners. Voor zover wij uw
        gegevens voor andere doeleinden dan hiervoor
        omschreven willen gebruiken doen wij dat
        slechts met uw voorafgaande toestemming.`
  },
  {
    title: '2',
    description: `Wij gebruiken uw gegevens voor het leveren
        van onze diensten, aan u, inclusief de weergave
        van aangepaste inhoud en advertenties
        (‘behavioural advertising’); voor controle,
        onderzoek en analyse doeleinden om onze
        diensten te onderhouden, te beveiligen en te verbeteren; het veiligstellen van het technisch
        functioneren van ons netwerk; het beschermen
        van de rechten of het eigendom van Aanbieder
        of Gebruikers; en het ontwikkelen van nieuwe
        diensten. In bepaalde gevallen is het mogelijk
        dat we gegevens verwerken namens of volgens
        de instructies van derden, zoals onze
        advertentiepartners. Voor zover wij uw
        gegevens voor andere doeleinden dan hiervoor
        omschreven willen gebruiken doen wij dat
        slechts met uw voorafgaande toestemming.`
  }
];

export { GeneralTermsConstant, PrivacyTerms };
