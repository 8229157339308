import { useTranslation } from 'react-i18next';

export function getTranslatedList() {
  const { t } = useTranslation();
  return {
    PERIOD_TYPES_INVITATIONS: [
      { value: 'recruiter', label: t('recruiter') },
      { value: 'candidate', label: t('candidate') },
    ]
  };
}
