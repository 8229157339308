import React from 'react';
import Popup from '../Popups/Popup/Index';
import Table from '../Table/Index';
import { ICompetencyInformation, tableColumns, tableData } from './types';

import './index.scss';

const CompetencyInformation = ({
  onClose
}: ICompetencyInformation): JSX.Element => {
  return (
    <Popup
      title="Competency information"
      onClose={onClose}
      className="compInfo"
    >
      <div className="compInfo__container">
        <p className="compInfo__container__subtitle">
          Please contact us if you have any questions
        </p>
        <Table
          columns={tableColumns}
          data={tableData}
          tableClassname="compInfo__container__table"
        />
      </div>
    </Popup>
  );
};

export default CompetencyInformation;
