export const getComparedData = (obj1: any, obj2: any) => {
  const resultArray: any[] = [];

  obj1?.lables?.forEach((label: any) => {
    const labelIndex = obj2?.lables?.indexOf(label);
    if (labelIndex !== -1) {
      const value = obj2?.data[labelIndex];
      resultArray.push({ label, value });
    }
  });

  return resultArray;
};

export const getComparedDataSecond = (obj1:any, obj2:any) => {
  const resultArray:any[] = [];
  obj2?.forEach((entry:any) => {
    const competencyId = entry?.competency?.id;
    const competencyName = entry?.competency?.name;
    const minScore = entry?.min_score;
    const scoreObj = obj1?.find((item: { competency_id: any; }) => item?.competency_id === competencyId);
    if (scoreObj) {
      resultArray.push({ label: competencyName, score: scoreObj.score, min_score: minScore });
    }
  });

  return resultArray;
};


const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
};

const rgbToHex = (r: number, g: number, b: number) => {
  const hex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
  return `#${hex}`;
};

export const generateRandomColor = (
  firstColor: string,
  secondColor: string
) => {
  const startColor = hexToRgb(firstColor);
  const endColor = hexToRgb(secondColor);
  const randomR =
    Math.floor(Math.random() * (endColor.r - startColor.r + 1)) + startColor.r;
  const randomG =
    Math.floor(Math.random() * (endColor.g - startColor.g + 1)) + startColor.g;
  const randomB =
    Math.floor(Math.random() * (endColor.b - startColor.b + 1)) + startColor.b;
  const randomColor = rgbToHex(randomR, randomG, randomB);

  return randomColor;
};

export const competencyScoresYearColors = [
  '#43bc90',
  '#a750af',
  '#00D0FF',
  '#cd5a32',
  '#25da3e',
  '#DA25C1',
  '#ECF906',
  '#0ac6f5',
  '#d22d3d',
  '#2DD2C2',
  '#00FF69',
  '#D4FF00',
  '#E000FF',
  '#46ADB9',
  '#B95246'
];
