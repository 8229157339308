export function consolidateCategories(data: any) {
  const consolidatedObject: any = {};
  for (const category in data) {
    data[category].lables.map((label: string, index: number) => {
      consolidatedObject[label] = data[category].data[index];
    });
  }
  return consolidatedObject;
}

export function getMatchingScores(competencies: any, userCompetencies: any) {
  const scores: number[] = [];

  competencies?.forEach((competency: any) => {
    const name = competency?.competency.name;
    if (userCompetencies[name]) {
      scores.push(userCompetencies[name]);
    }
  });

  return scores;
  
}

export function extractCompetencyInfo(competencies: any[]) {
  const names: any[] = [];
  const descriptions: any[] = [];
  const minScores: any[] = [];

  competencies?.forEach((competency: { competency?: any; min_score?: any; }) => {
      const { name, description } = competency.competency;
      const { min_score } = competency;

      names.push(name);
      descriptions.push(description);
      minScores.push(min_score);
  });

  return {
      names,
      descriptions,
      minScores
  };
}
export const getComparedData = (candidateComp: any[], profileComp: any[]) => {
  const profileCompIds = profileComp?.map((el: any) => el?.competency?.id);
  const arr: any = [];
  profileCompIds?.map((item) => {
    const element = candidateComp?.find((el) => el?.competency_id === item);
    arr.push(element);
  });
  return arr;
};



export function getCompetencyDescriptions(competencies: any) {
  const descriptions: { name: any; description: any; }[] = [];

  competencies.forEach((competency: { name: any; description: any; }) => {
      descriptions.push({
          name: competency.name,
          description: competency.description
      });
  });

  return descriptions;
}

export function calculateMatchPercentage(arr1: number[], arr2: number[]) {
  if (arr1?.length !== arr2?.length) {
    console.error('Arrays must be of the same length');
    return null;
  }

  const arrayLength = arr1.length;
  const basePercent = 100 / arrayLength;
  let totalPercent = 0;

  for (let i = 0; i < arrayLength; i++) {
    if (Number(arr1[i]) >= Number(arr2[i])) {
      totalPercent += basePercent;
    } else {
      totalPercent += (Number(arr1[i]) / Number(arr2[i])) * basePercent;
    }
  }

  return totalPercent.toFixed(0);
}

export const getSumOfArray = (array: number[]) => {
  return array.reduce((accumulator, currentValue) => {
    return Number(accumulator) + Number(currentValue);
  }, 0);
};
