import React, { FC, useRef, useState } from 'react';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import { IYoutubeVideosPopup } from 'core/interface/youtubeVideos';
import ActionButton from 'components/ActionButton/Index';
import useLocales from 'hooks/useLocales';
import { CandidateService } from 'services/candidate/candidate';
import { ISharedLinks } from 'core/interface/candidate';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import './Index.scss';

const YoutubeVideosPopup: FC<IYoutubeVideosPopup> = ({
  videos,
  closePopup
}): JSX.Element => {
  const { t } = useLocales();
  const { candidates, candidatesSharedLinks } = useSelector(
    (state: RootState) => state.candidates
  );
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [error, setError] = useState<string>('');

  const handleRemoveVideo = async (id: number): Promise<void> => {
    await CandidateService.deleteSharedLinks(candidates.id, id);
  };

  const handleSave = async () => {
    try {
      if (inputRef.current) {
        const { value } = inputRef.current;

        if (!value) {
          setError(t('Empty Url'));
          return;
        }
        setError('');
        const results = await CandidateService.saveSharedLinks(candidates.id, {
          url: `${value}`
        });
        if (results) {
          closePopup();
        }
      }
    } catch (e) {
      console.log(e, 'eeeeee');
    }
  };

  return (
    <div className="youtube">
      <div className="videos">
        {candidatesSharedLinks &&
          (candidatesSharedLinks as Array<ISharedLinks>).map((item, index) => (
            <div key={index} className="video">
              <ActionButton
                className="removeVideo"
                onClick={() => handleRemoveVideo(item.id)}
                iconName="trash.svg"
              />
              <img src={item.thumbnail} alt={item.title} className='video-preview-img'/>
            </div>
          ))}
      </div>
      <div className="search">
        <span className="label">Video title</span>
        <Image className="search-icon" name="searchs.svg" />
        <input
          ref={inputRef}
          type="text"
          className={`input ${error && 'error'} ${
            candidatesSharedLinks &&
            (candidatesSharedLinks as Array<ISharedLinks>).length >= 5
              ? 'disabled'
              : ''
          }`}
          placeholder="Steve Jobs Stanford Commencement, etc."
          disabled={candidatesSharedLinks?.length >= 5}
        />
      </div>
      {error && (
        <div className="error-wrapper">
          <span className="error-message">{error}</span>
        </div>
      )}
      <Button
        t={t}
        title="Save"
        className="save"
        onClick={handleSave}
        disabled={candidatesSharedLinks?.length >= 5}
      />
    </div>
  );
};

export default YoutubeVideosPopup;
