import { combineReducers } from 'redux';
import { BrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import errorsReducer from './slices/errors';
import userReducer from './slices/user';
import jobFormReducer from './slices/jobForm';
import candidateReducer from './slices/candidate';
import educationFormReducer from './slices/educationForm';
import locationReducer from './slices/location';
import degreeReducer from './slices/degrees';
import industriesReducer from './slices/industries';
import computerSkillsReducer from './slices/computer-skills';
import schoolReducer from './slices/schools';
import socialMediaExpertiseReducer from './slices/socialMediaExpertise';
import skillsReducer from './slices/skills';
import educationsReducer from './slices/educations';
import workExperiencesReducer from './slices/workExperience';
import certificationsReducer from './slices/certifications';
import languagesReducer from './slices/languages';
import extraActivitesReducer from './slices/extraActivites';
import candidatesReducer from './slices/candidates';
import socialMediaReducer from './slices/socialMedia';
import assessmentReducer from './slices/assessment';
import jobsReducer from './slices/jobs';
import applicationsReducer from './slices/apllications';
import notificationsReducer from './slices/notification';
import shareUrlReducer from './slices/shareURL';
import fileReducer from './slices/file';
import shareProfileURLReducer from './slices/preview-url';
import invitationReducer from './slices/invitations';
import companiesReducer from './slices/company';
import matchProfileReducer from './slices/match';
import requestReducer from './slices/requests';
import CMSReducer from './slices/cms';

const rootReducer = (history: BrowserHistory) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    errors: errorsReducer,
    jobForm: jobFormReducer,
    candidate: candidateReducer,
    educationForm: educationFormReducer,
    locations: locationReducer,
    study: degreeReducer,
    industries: industriesReducer,
    computerSkills: computerSkillsReducer,
    schools: schoolReducer,
    socialMediaExpertise: socialMediaExpertiseReducer,
    skills: skillsReducer,
    educations: educationsReducer,
    workExperiences: workExperiencesReducer,
    certifications: certificationsReducer,
    languages: languagesReducer,
    extraActivites: extraActivitesReducer,
    candidates: candidatesReducer,
    socialMedia: socialMediaReducer,
    assessment: assessmentReducer,
    jobs: jobsReducer,
    applications: applicationsReducer,
    notifications: notificationsReducer,
    url: shareUrlReducer,
    file: fileReducer,
    profileURL: shareProfileURLReducer,
    invitations: invitationReducer,
    compony: companiesReducer,
    match: matchProfileReducer,
    requests: requestReducer,
    cms: CMSReducer
  });

export { rootReducer };
