import { useNavigate } from 'react-router';
import useLocales from 'hooks/useLocales';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import { getDateFormatter } from 'helpers/dateConverter';
import { monthlySalary } from 'core/constants/preferenceConstats';

import './Index.scss';

const JobOpeningCart = ({ item }: any): JSX.Element => {
  const { t } = useLocales();
  const navigate = useNavigate();

  return (
    <div className="jobOpeningCart">
      <div className="cartHeader">
        <div className="dateContainer">{getDateFormatter(item?.created_at, 'DD MMM  YYYY')}</div>
        <Image url={item?.company?.logo} width={46} height={46} className="image" />
      </div>
      <div className="cartBody">
        <p className="typeText">{item?.is_remote && "Remote"} {item?.is_remote && item?.if_office_work && ","} {item?.if_office_work && " Office work"}</p>
        <p className="typeTitle">{item?.title?.length > 30 ? ` ${item?.title.substring(0, 30)}...` : item?.title}</p>
        <p className="typeDescription">{item?.description}</p>
        <div className="categories">
          {item?.competency_labels?.map((category: any, index: number) => (
            <div key={index} className="categoryItem">
              {category}
            </div>
          ))}
        </div>
      </div>
      <div className="cartFooter">
        <div>
          <p>{monthlySalary.find((el: any) => el.value == item?.salary)?.to_show}</p>
          <span>{item?.hourly_rate && `${item?.hourly_rate}/hour`}</span>
        </div>
        <Button className="next" title="Details" t={t} onClick={() => navigate(`/job/${item?.id}`)} />
      </div>
    </div>
  );
};

export default JobOpeningCart;
