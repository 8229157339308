import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { initialValues } from './form';
import DescriptionCard from 'components/shared/DescriptionCard/Index';
import Education from 'components/feature/activation/education/Index';
import ActionButton from 'components/ActionButton/Index';
import { IEducationPopup } from 'core/interface/education';
import { IActivationEducation } from 'core/interface/activation';
import { ProfileService } from 'services/profile/profile';
import { IMe } from 'core/interface/me';
import { IEducationSideItem } from 'core/interface/education-side';
import { getDateFormatter } from 'helpers/dateConverter';

import './Index.scss';

const EducationPopup: FC<IEducationPopup> = ({
  t,
  education,
  isShowAdd,
  onClose
}): JSX.Element => {
  const [initialValue, setInitialValue] = useState(initialValues);
  const [showForm, setShowForm] = useState<boolean>(false);

  const me = useSelector((state: IMe) => state.user).user;

  useEffect(() => {
    isShowAdd && isShowAdd(showForm);
  }, [showForm]);

  const handleRemove = (id: number): void => {
    ProfileService.removeEducation(me.candidate_id, id);
  };

  const handleEditItem = async (edu: IEducationSideItem): Promise<void> => {
    if (edu) {
      const body: IActivationEducation = {
        id: edu.id,
        average_graduation: edu.grade ? String(Number(edu.grade)) : '',
        graduation_grade: String(edu.grade),
        degree: String(edu.degree),
        study_field: edu.study.name,
        educational_attainment: String(edu.grade),
        school: edu.school.name,
        isGraduated: edu.graduate,
        isCurrent: edu.end ? false : true,
        start: {
          month: getDateFormatter(edu.start, 'MM'),
          year: getDateFormatter(edu.start, 'YYYY'),
          day: getDateFormatter(edu.start, 'D')
        },
        finish: {
          month: getDateFormatter(edu.end, 'MM'),
          year: getDateFormatter(edu.end, 'YYYY'),
          day: getDateFormatter(edu.end, 'D')
        }
      };
      setInitialValue(body);
      setShowForm(true);
    }
  };

  return (
    <div className="wexperience scrollable">
      {!showForm &&
        education.length !== 0 &&
        education.map((item, index) => (
          <DescriptionCard
            handleEdit={() => handleEditItem(item)}
            handleRemove={(e: number) => handleRemove(e)}
            key={index}
            id={item.id}
            description={item.school.name}
            title={item.study.name}
            year_from={item.start}
            year_to={item.end}
            details={[
              { icon: 'bachelor.svg', title: String(item.degree) },
              { icon: 'translate.svg', title: String(item.grade) },
              {
                icon: 'graduated.svg',
                title: item.graduate ? 'Graduated' : ''
              }
            ]}
          />
        ))}
      {!showForm && education.length !== 0 && (
        <div onClick={() => setShowForm(true)} className="add-experience">
          <ActionButton
            className="action-add"
            onClick={() => setShowForm(true)}
            iconName="plus.svg"
          />
          <span className="add-experience-title">{t('Add New Education')}</span>
        </div>
      )}
      {(showForm || education.length === 0) && (
        <div className="wexperience__add">
          <Education
            isPopup={true}
            isOneBtn={true}
            className="edu-experience"
            educationInitialValues={initialValue}
            showTopSide={false}
            closePopup={() => {
              onClose && onClose();
            }}
            onClick={() => {}}
            t={t}
          />
        </div>
      )}
    </div>
  );
};

export default EducationPopup;
