import React, { FC, useEffect, useState } from 'react';

import useLocales from 'hooks/useLocales';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import IncomingRequests from 'components/shared/IncomingRequests/Index';
import Popup from 'components/shared/Popups/Popup/Index';
import ProfilePopUp from 'components/shared/ProfilePopUp/Index';
import Navbar from 'components/Navbar';
import MatchResultHeader from 'components/matchResultHeader';
import { useParams } from 'react-router';
import Footer from 'components/Footer/Index';
import NoData from 'components/shared/NoData/Index';
import { MatchProfileService } from 'services/matchProfile/matchProfile';
import { CircularProgress } from '@mui/material';

import './Index.scss';

const MatchCandidate: FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { t } = useLocales();
  const { matchOverViewOne, matchProfileOne } = useSelector((state: RootState) => state.match);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [applicationCounts, setApplicationCounts] = useState<{ incomingCount: number; hiredCount: number }>({
    incomingCount: 0,
    hiredCount: 0
  });

  const handleModal = (open: boolean, request: any) => {
    setOpenModal(open);
    const oneCandidate = matchOverViewOne?.results?.find((el: { id: number }) => el.id === request)
    setSelectedRequest(oneCandidate);
  };

  const handleClosePopup = () => {
    setOpenModal(false);
    setSelectedRequest(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          await MatchProfileService.getCandidatesOverview(+id);
          await MatchProfileService.getMatchProfileById(+id);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (matchOverViewOne?.results) {
      const newApplications = matchOverViewOne?.results?.filter((el: { stage: number }) => el.stage === 0).length;
      const hired = matchOverViewOne?.results?.filter((el: { stage: number }) => el.stage === 5).length;
      setApplicationCounts({ incomingCount: newApplications, hiredCount: hired });
    }
  }, [matchOverViewOne]);

  const cancelHandler = (id: number) => {
    MatchProfileService.deleteCultureProfile(id)
  }

  return (
    <>
      <Navbar />
      <MatchResultHeader data={matchProfileOne} />
      <div className="candidateOver">
        <div className="candidateOver__right">
          <div className="candidateOver__right__top">
            <div className="candidateOver__right__top__counts">
              <button className="candidateOver__right__top__counts-warn btn">
                {t('New applicant ')}
                {(`(${applicationCounts.incomingCount})`)}
              </button>
              <button className="candidateOver__right__top__counts-pass btn">
                {t('Candidate Hired ')}
                {`(${applicationCounts.hiredCount})`}
              </button>
            </div>
          </div>
          <div className="candidateOver__right__bottom">
            {matchOverViewOne === null ?
              <div className="loadingContainer">
                <CircularProgress className="loading" />
              </div> : matchOverViewOne?.results?.length ? matchOverViewOne?.results?.map((item: any) => (
                <IncomingRequests
                  key={item.id}
                  id={item.id}
                  name={item.candidate.name}
                  email={item.candidate.email}
                  sub_title={item.candidate.pitch}
                  icon={item.candidate.avatar}
                  score={item.score}
                  culture_score={item.culture_score}
                  date={item.created_at}
                  hired_at={item.offered_at}
                  industry={item.candidate.industries}
                  stage={item.stage}
                  status={item.status}
                  // setStage={changeStage}
                  cancel={cancelHandler}
                  setOpen={handleModal}
                />
              )) : <div className="no_data">
                <NoData />
              </div>}
          </div>
        </div>
      </div>
      {openModal && selectedRequest && (
        <Popup
          className='popUp'
          title="Match Profile"
          description={selectedRequest?.job?.title}
          onClose={handleClosePopup}
        >
          <ProfilePopUp
            getOneRequest={selectedRequest?.candidate}
            getJob={selectedRequest?.profile}
            id={selectedRequest?.id}
            onClick={cancelHandler}
          />
        </Popup>
      )}
      <div className="footers">
        <Footer />
      </div>
    </>
  );
};

export default MatchCandidate;
