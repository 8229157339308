import React from 'react';
import { Zoom, Tooltip } from '@material-ui/core';
import { BarType } from 'core/interface/assessment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import useLocales from 'hooks/useLocales';
import Button from 'components/Button/Index';

import './Index.scss';

export const MAX_COMPETENCY_SLIDER_RANGE = 9;
export const MIN_COMPETENCY_SLIDER_RANGE = 1;

const TalentDescription = ({ bars }: BarType): JSX.Element => {
  const { t } = useLocales();
  return (
    <Accordion>
      <AccordionSummary id="panel1-header" className="accordion">
        <Button
          className="culture__right__buttons-comp btn button"
          title="Description"
          t={t}
        />
      </AccordionSummary>
      <AccordionDetails className="cultureContainer">
        <div className="overview__bar">
          {bars?.map((e: any, index: number) => (
            <div key={index}>
              {e?.competencies?.map((el: any, i: number) => (
                <Tooltip
                  arrow
                  placement="left"
                  TransitionComponent={Zoom}
                  title={el.competency.description}
                  key={i}
                >
                  <div key={i} className="bar">
                    <span className="bar__label">{el.competency?.name}</span>
                    <div className="bar_box">
                      <span className="min_max">
                        {MIN_COMPETENCY_SLIDER_RANGE}
                      </span>
                      <div className="bar__line">
                        <div
                          className="bar__line-progress"
                          style={{
                            width: `${
                              (el?.min_score / MAX_COMPETENCY_SLIDER_RANGE) *
                              100
                            }%`
                          }}
                        >
                          {el.value}
                        </div>
                      </div>
                      <span className="min_max">
                        {MAX_COMPETENCY_SLIDER_RANGE}
                      </span>
                    </div>
                  </div>
                </Tooltip>
              ))}
            </div>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default TalentDescription;
