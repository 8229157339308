import React, { ChangeEvent, useRef, useState } from 'react';
import useLocales from 'hooks/useLocales';
import { useSelector } from 'react-redux';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import { useSnackbar } from 'notistack';
import { isValidURL } from 'helpers/isValidURL';

import './Index.scss';

const ImagePicker = ({ setLogo, logo, isVideo, setSizes, max, setFiledValue }: any): JSX.Element => {
  const { t } = useLocales();
  const fileRef = useRef(null);
  const [files, setFiles] = useState<any>();
  const user = useSelector((state: any) => state.user).user;
  const uploadedFiles = useSelector((state: any) => state.file.files);
  const { enqueueSnackbar } = useSnackbar();

  const handleRemoveImage = (item: any) => {
    if (!user) {
      return;
    }
    setLogo((prevState: any) => {
      if (!Array.isArray(prevState)) {
        return null;
      }
      return prevState.filter((image: any, index: number) => index !== item.id);
    });
    setFiledValue && setFiledValue(null)
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleImageChosen = (files: FileList): void => {
    if ((files?.[0].size < 1 * 1024 * 1024)) {
      setFiles(files);
      setLogo((prevState: any) => (Array.isArray(prevState) ? [...prevState, files?.[0]] : files?.[0]));
      setFiledValue && setFiledValue(files?.[0])
    } else {
      enqueueSnackbar(t('Image is too large'), {
        variant: 'warning'
      });
    }
  };

  const chooseFile = () => {
    if (fileRef.current) {
      (fileRef.current as HTMLInputElement).click();
    }
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target;
    if (files) handleImageChosen(files);
  };

  return (
    <div className="sharedContainer">
      <div className="images">
        {logo && !setSizes &&
          [logo]?.map((item: any, index: any) => (
            <div key={index} className="image">
              <span
                className="trash-shared"
                onClick={() =>
                  handleRemoveImage({
                    id: item.id ?? index
                  })
                }
              >
                <Image name="shared-remove.svg" width={36} height={36} />
              </span>
              <Image
                key={index}
                url={isValidURL(item) ? item : URL.createObjectURL(item)}
                className="shared-images"
              />
            </div>
          ))}
      </div>
      <div className={`about-side`}>
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={`drag-and-drop_image ${(files?.length >= 5 || logo?.length >= max) ||
            (max == 1 && logo) ||
            (uploadedFiles && uploadedFiles?.length >= 5)
            ? 'disabled'
            : ''
            }`}
        >
          <div className="texts">
            <Image
              className="upload"
              name="upload-file.svg"
              width={40}
              height={40}
            />
            <span className="drag-title">
              Drag and drop your {!isVideo ? 'files' : 'video'} here
            </span>
            <span className="supported">
              Files supported: {!isVideo ? 'JPG, PNG' : 'MP4, AVI, WMV, AVCHD'}
            </span>
            <span className="or">Or</span>
            <Button
              t={t}
              onClick={chooseFile}
              title="browse_file"
              className="browse"
            />
          </div>
        </div>
      </div>
      <input
        ref={fileRef}
        className="file"
        type="file"
        accept="image/png, image/jpeg"
        onChange={handleUpload}
        max={5}
      />
    </div>
  );
};

export default ImagePicker;
