import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { ICompanyInfo } from 'core/interface/matchProfile';

const initialValues: ICompanyInfo = {
  address: '',
  contact_job_title: ''
};

const validationSchema = (t: TFunction) => {
  return Yup.object({
    address: Yup.string().required(t('required')),
    contact_job_title: Yup.string().required(t('required'))
  });
};

export { validationSchema, initialValues };
