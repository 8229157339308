export enum SocialMediaEnum {
  'pinterest' = 'pinterest.svg',
  'instagram' = 'instagram.svg',
  'linkedin' = 'linked-1.svg',
  'facebook' = 'fb-l.svg',
  'youtube' = 'youtube.png',
  'snapchat' = 'snapchat.jpg',
  'google_plus' = 'google_plus.svg',
  'twitter' = 'twitter.svg',
  'tiktok' = 'tiktok.svg'
}
