import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { IChangeEmail, IChangePassword } from 'core/interface/settings';

const emailValues: IChangeEmail = {
  email: ''
};

const passwordValues: IChangePassword = {
  oldPassword: '',
  newPassword: '',
  repeatPassword: ''
};

const emailValidation = (t: TFunction) => {
  return Yup.object({
    email: Yup.string().required(t('required'))
  });
};

const passwordValidation = (t: TFunction) => {
  return Yup.object({
    oldPassword: Yup.string().trim().required(t('required')),
    newPassword: Yup.string().trim().required(t('required')),
    repeatPassword: Yup.string().trim().required(t('required'))
  });
};

export { emailValues, passwordValues, emailValidation, passwordValidation };
