import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import useLocales from 'hooks/useLocales';
import { Form, Formik } from 'formik';
import FormField from 'components/FormField/Index';
import Button from 'components/Button/Index';
import { InputType } from 'core/enum/inputType';
import { initialValues, validation } from './form';
import { AuthService } from 'services/auth/auth';
import AuthContent from 'components/AuthContent';
import { Role } from 'core/enum/role';

export default function ConfirmNewPassword() {
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const navigate = useNavigate();

  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const userRole = searchParams.get('role');
  const [role, setRole] = useState<Role>((userRole as Role) ?? Role.CANDIDATE);

  const handleRole = (role: Role): void => {
    setRole(role);
  };

  const handleSubmit = async (values: any, actions: any): Promise<void> => {
    setLoading(true);
    if (values.password != values.repeatPassword) {
      enqueueSnackbar(t('New and repeat passwords should match'), {
        variant: 'error'
      });
      setLoading(false);
    } else {
      try {
        const res = await AuthService.changePassword({
          password: values.password,
          token
        }) as any;
        if (res?.status) {
          enqueueSnackbar(res?.status, {
            variant: 'success'
          })
          navigate('/login');
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <AuthContent
        backURL="/login"
        onRoleSubmit={handleRole}
        title="Reset password"
        role={role}
      >
        <div className="reset">
          <Formik
            initialValues={initialValues}
            validationSchema={validation(t)}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
          >
            {({ isValid }) => (
              <Form>
                <div className='reset_inputs'>

                  <FormField
                    type={InputType.password}
                    placeholder="New Password"
                    fieldName="password"
                    inputClassName="input"
                  />
                  <FormField
                    type={InputType.password}
                    placeholder="Repeat Password"
                    fieldName="repeatPassword"
                    inputClassName="input"
                  />
                </div>
                <Button
                  t={t}
                  isLoading={loading}
                  className={`login-btn ${!isValid ? 'invalid-btn' : ''}`}
                  title="send_password"
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </div>
      </AuthContent>
    </>
  );
}
