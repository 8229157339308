import * as Yup from 'yup';
import { DegreeEnum } from 'core/enum/degree';
import { IJobForm } from 'core/interface/job';
import { TFunction } from 'i18next';

const initialValues: any = {
  skills: [],
  degree: 0,
  industries: [],
  is_public: false,
  job_description: '',
  job_title: '',
  location: {
    id: -1,
    display_name: ''
  },
  prefered_salary: null,
  required_experience: null,
  requirements: '',
  culture_profile: {
    id: -1,
    title: '',
    is_culture: false
  },
  is_remote: false,
  if_office_work: false,
  status: '',
  duration: null,
  hourly_rate: null
};

const validation = (t: TFunction) => {
  return Yup.object().shape({
    skills: Yup.array()
      .min(1, t('Skills must have at least one item'))
      .required(t('Skills are required')),
    degree: Yup.number().required(t('Degree is required')),
    status: Yup.string().required(t('Status is required')),
    duration: Yup.number().required(t('Contract is required')),
    industries: Yup.array()
      .min(1, t('Industries must have at least one item'))
      .required(t('Industries are required')),
    is_public: Yup.bool().default(false).required(),
    is_remote: Yup.bool().default(false).required(),
    if_office_work: Yup.bool().default(false).required(),
    job_description: Yup.string()
      .trim()
      .min(3, t('Job description must be at least 3 characters'))
      .required(t('Job description is required')),
    job_title: Yup.string()
      .trim()
      .min(3, t('Job title must be at least 3 characters'))
      .max(300, t('Job title must be less than 300 characters'))
      .required(t('Job title is required')),
    location: Yup.object().shape({
      id: Yup.number().required(t('Location ID is required')),
      display_name: Yup.string()
        .trim()
        .required(t('Location display name is required'))
    }),
    prefered_salary: Yup.number().required(t('Preferred salary is required')),
    hourly_rate: Yup.number().required(t('Preferred salary is required')),
    required_experience: Yup.number().required(
      t('Required experience is required')
    ),
    requirements: Yup.array()
      .min(1, t('Requirements must have at least one item'))
      .required(t('Requirements are required')),
    culture_profile: Yup.object().shape({
      id: Yup.number().required(t('Location ID is required')),
      title: Yup.string().trim().required(t('Culture profile is required')),
      is_culture: Yup.boolean().required(t('is required'))
    })
  });
};

export { initialValues, validation };
