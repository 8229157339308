import { IAction } from 'core/interface/action';
import { ISharedLinks } from "core/interface/candidate";

export const SAVE_VALUES = 'save-candidates';
const SAVE_VALUES_OVERVIEW = 'save-candiate-overview';
const SAVE_CANDIDATES_SHARED_LINKS = 'save-candidates-shared-links';
const ADD_CANDIDATES_VIDEOS = 'add-candidates-videos';
const DELETE_CANDIDATES_VIDEOS = 'delete-candidates-videos';

const defaultState = {
  candidates: null,
  candidatesOverview: null,
  candidatesSharedLinks: null,
};

export const SaveCandidates = (value: any | number[]) => ({
  type: SAVE_VALUES,
  payload: value
});
export const SaveCandidatesOverwie = (value: any) => ({
  type: SAVE_VALUES_OVERVIEW,
  payload: value
});

export const SaveCandidatesSharedLinks = (value: Array<ISharedLinks>) => ({
  type: SAVE_CANDIDATES_SHARED_LINKS,
  payload: value
})

export const AddCandidatesVideos = (value: ISharedLinks) => ({
  type: ADD_CANDIDATES_VIDEOS,
  payload: value
})

export const DeleteCandidateVideos = (value: number) => ({
  type: DELETE_CANDIDATES_VIDEOS,
  payload: value
})

const candidatesReducer = (
  state = defaultState,
  action: IAction<any | number[]>
) => {
  switch (action.type) {
    case SAVE_VALUES:
      return {
        ...state,
        candidates: action.payload
      };
    case SAVE_VALUES_OVERVIEW:
      return {
        ...state,
        candidatesOverview: action.payload
      };
    case SAVE_CANDIDATES_SHARED_LINKS:
      return {
        ...state,
        candidatesSharedLinks: action.payload
      }
    case ADD_CANDIDATES_VIDEOS:
      return {
        ...state,
        candidatesSharedLinks: [
          ...(state.candidatesSharedLinks ? state.candidatesSharedLinks : []),
          action.payload
        ]
      }
    case DELETE_CANDIDATES_VIDEOS: {
      const candidatesSharedLinks = state.candidatesSharedLinks ?
        (state.candidatesSharedLinks as Array<ISharedLinks>).filter(item => item.id !== action.payload)
       : state.candidatesSharedLinks
      return {
        ...state,
        candidatesSharedLinks
      }
    }
    default:
      return state;
  }
};

export default candidatesReducer;
