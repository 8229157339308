import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useParams } from 'react-router';
import { MatchProfileService } from 'services/matchProfile/matchProfile';
import Navbar from 'components/Navbar';
import MatchResultHeader from 'components/matchResultHeader';
import MatchFilter from 'components/matchFilter';
import MatchResults from 'components/matchResults';
import Footer from 'components/Footer/Index';
import Popup from 'components/shared/Popups/Popup/Index';
import ProfilePopUp from 'components/shared/ProfilePopUp/Index';
import { JobService } from 'services/jobs/job';

import './index.scss';

interface MatchResultProps { }

const MatchResult: FC<MatchResultProps> = (): JSX.Element => {
  const [filter, setFilter] = useState<any>();
  const { id, type } = useParams<{ id: string, type: string }>();
  const { matchProfileOne, matchProfileCandidate }: any = useSelector(
    (state: RootState) => state.match
  );
  const { jobProfileCandidate, singleJob }: any = useSelector(
    (state: RootState) => state.jobs
  );
  const [openModal, setOpenModal] = useState(false);
  const [onUser, setOneUser] = useState([])


  const handleClosePopup = () => {
    setOpenModal(false);
  };

  const handleModal = (open: boolean, id: number) => {
    setOpenModal(open);
    console.log(id);
    const oneElement = (matchProfileCandidate || jobProfileCandidate)?.results?.find((el: any) => el.id === id)
    setOneUser(oneElement)
  };

  const getMatchCandidates = (offset: number = 0, limit: number = 10) => {
    if (id) {
      if (type === "find-candidate") {
        MatchProfileService.getMatchProfileCandidatesById(id, { offset, limit }, filter);
      } else {
        JobService.getJobProfileCandidatesById(id, { offset, limit }, filter)
      }
    }
  };

  const getInformationAboutProfile = () => {
    if (type === "find-candidate") {
      MatchProfileService.getMatchProfileById(id ? +id : 0);
    } else {
      JobService.getJobByIdOrSlug(id ? +id : 0)
    }

  };

  useEffect(() => {
    getInformationAboutProfile();
  }, [id]);

  useEffect(() => {
    getMatchCandidates();
  }, [id, filter]);

  return (
    <>
      <div className="match">
        <Navbar />
        <div className="match-content">
          <MatchResultHeader data={matchProfileOne || singleJob} />
          <div className="content-container">
            <MatchFilter applyFilter={setFilter} />
            <MatchResults
              filter={filter}
              handleModal={handleModal}
              getMatchCandidates={getMatchCandidates}
              matchProfileCandidate={matchProfileCandidate || jobProfileCandidate}
            />
          </div>
        </div>
        <div className="footers">
          <Footer />
        </div>
      </div>
      {openModal && (matchProfileOne || singleJob) && (
        <Popup
          className="popUp"
          title="Match Profile"
          description={(matchProfileOne || singleJob)?.title}
          onClose={handleClosePopup}
        >
          <ProfilePopUp getOneRequest={onUser} getJob={matchProfileOne || singleJob} />
        </Popup>
      )}
    </>
  );
};

export default MatchResult;
