import React, { FC } from 'react';
import { ImageI } from 'core/interface/image';

const Image: FC<ImageI> = ({
  className,
  name,
  url,
  width = 15,
  height = 15,
  onClick
}) => {
  return (
    <img
      onClick={() => onClick}
      className={className}
      src={url ?? `/static/images/${name}`}
      alt={name ?? 'img'}
      width={width}
      height={height}
    />
  );
};

export default Image;
