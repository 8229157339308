import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { ISocialNetwork } from 'core/interface/aboutMe';

const initialValues: ISocialNetwork = {
  network: '',
  url: ''
};

const validationSchema = (t: TFunction) => {
  return Yup.object({
    id: Yup.number().optional(),
    network: Yup.string().required(t('required')),
    url: Yup.string()
      .required(t('required'))
      .test('isValidURL', t('Invalid URL'), (value) => {
        if (!value) {
          return true;
        }
        return /^(ftp|http|https):\/\/[^ "]+$/.test(value);
      })
  });
};

export { initialValues, validationSchema };
