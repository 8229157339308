const formatLabelsArray = (array: string[]) => {
  const result: string[] = [];
  for (let i = 0; i < array.length; i += 2) {
    if (i + 1 < array.length) {
      result.push(`${array[i]} ${array[i + 1]}`);
    } else {
      result.push(array[i]);
    }
  }
  return result;
};

export function chartLabelTruncate(pointLabel: string) {
  if (pointLabel.length > 20) {
    const res = (pointLabel.substring(0, 20) + '...')
      .split(' ')
      .map((item: string) => item) as any;
    return formatLabelsArray(res);
  } else {
    const res = pointLabel.split(' ').map((item: string) => item);
    return formatLabelsArray(res);
  }
}
