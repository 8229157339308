import { IAction } from 'core/interface/action';
import { IAutocompleteItem } from 'core/interface/autocompleteItem';

export const SAVE_SCHOOLS = 'save-schools';

const defaultState = {
  schools: null
};

export const SaveSchools = (value: IAutocompleteItem[]) => ({
  type: SAVE_SCHOOLS,
  payload: value
});

const schoolReducer = (
  state = defaultState,
  action: IAction<IAutocompleteItem[]>
) => {
  switch (action.type) {
    case SAVE_SCHOOLS:
      return {
        ...state,
        schools: action.payload
      };
    default:
      return state;
  }
};

export default schoolReducer;
