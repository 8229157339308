import React, { FC, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';
import { ChartData } from 'chart.js';
import { useSelector } from 'react-redux';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import useLocales from 'hooks/useLocales';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer/Index';
import Popup from 'components/shared/Popups/Popup/Index';
import ShareJobPopup from 'components/shared/Popups/ShareJob/Index';
import CompetenciesBlock from 'components/shared/CompetenciesBlock/Index';
import { teaserCultureChart, teaserJobChart } from 'core/constants/teaserChart';
import { IJobCompetencies } from 'core/interface/job';
import { JobService } from 'services/jobs/job';
import { DegreeEnum } from 'core/enum/degree';
import { Industries } from 'core/interface/education-side';
import { ProfileService } from 'services/profile/profile';
import { getCareerLevelByValue, getSalaryByValue } from 'constants/constants';
import { CandidateService } from 'services/candidate/candidate';
import { IMe } from 'core/interface/me';
import { ICompetencies } from 'core/interface/competencyOverview';
import { calculateMatchPercentage } from 'helpers/teaserView';

import './Index.scss';

const JobDetails: FC = (): JSX.Element => {
  const { t } = useLocales();
  const { id } = useParams();
  const me = useSelector((state: IMe) => state.user).user;
  const navigate = useNavigate();
  const [showSharePopup, setShowSharePopup] = useState<boolean>(false);
  const [jobIndustry, setJobIndustry] = useState<string[]>([]);
  const [jobChartData, setJobChartData] = useState<ChartData>();
  const [cultureChartData, setCultureChartData] = useState<ChartData>();
  const [jobLocation, setJobLocation] = useState<string[]>([]);
  const [talantMatchPercent, setTalantMatchPercent] = useState<
    number | undefined | string
  >();
  const [cultureMatchPercent, setCultureMatchPercent] = useState<
    number | undefined | string
  >();
  const { singleJob: job } = useSelector((state: RootState) => state.jobs);
  const { industry: industries } = useSelector(
    (state: RootState) => state.industries
  );
  const { cities } = useSelector((state: RootState) => state.locations);
  const { candidatesOverview: candidateCompetencies } = useSelector(
    (state: RootState) => state.candidates
  );

  useEffect(() => {
    if (id) {
      getJobByIdOrSlug(id);
    }
  }, [id]);

  useEffect(() => {
    if (me && me.candidate_id) {
      getCandidateOverview(me.candidate_id);
    }
  }, [me]);

  useEffect(() => {
    getIndustries();
    getCities();
  }, []);

  useEffect(() => {
    if (industries?.length && job?.industries?.length) {
      getJobIndustries();
    }
  }, [industries, job]);

  useEffect(() => {
    if (cities?.length && job?.locations?.length) {
      getJobCities();
    }
  }, [cities, job]);

  const handleClose = (): void => {
    setShowSharePopup(false);
  };

  const getIndustries = (): void => {
    ProfileService.getIndustries();
  };

  const getCities = (limit: number = 20919, q?: string) => {
    JobService.getLocations({
      limit,
      q
    });
  };

  const getCandidateOverview = async (candidate_id: number): Promise<void> => {
    try {
      CandidateService.getCandidateOverview(candidate_id);
    } catch (error) {
      console.log(error);
    }
  };

  const getJobIndustries = (): void => {
    const foundIndustries: string[] = [];
    industries?.forEach((industries: Industries) => {
      job?.industries?.forEach((jobIndustries: number) => {
        if (industries.id === jobIndustries) {
          foundIndustries.push(industries.name);
          setJobIndustry(foundIndustries);
        }
      });
    });
  };

  const getProfileCompetencies = (): number[] => {
    const scores: number[] = [];
    if (candidateCompetencies) {
      candidateCompetencies?.competencies?.forEach(
        (candidateComp: ICompetencies) => {
          job?.competencies?.forEach((jobScores: IJobCompetencies) => {
            if (candidateComp.competency_id === jobScores.id) {
              scores.push(Number(candidateComp.score));
            }
          });
        }
      );
    }

    return scores;
  };

  const getCultureProfileCompetencies = (): number[] => {
    const scores: number[] = [];
    if (candidateCompetencies) {
      candidateCompetencies?.competencies?.forEach(
        (candidateComp: ICompetencies) => {
          job?.culture_profile.competencies?.forEach(
            (jobScores: IJobCompetencies) => {
              if (candidateComp.competency_id === jobScores.id) {
                scores.push(Number(candidateComp.score));
              }
            }
          );
        }
      );
    }

    return scores;
  };

  const getJobCities = async (): Promise<void> => {
    const matchedJobs: string[] = [];
    cities?.forEach((city: any) => {
      job?.locations?.forEach((jobCity: number) => {
        if (city.id === jobCity) {
          matchedJobs.push(city.display_name);
          setJobLocation(matchedJobs);
        }
      });
    });
  };

  const getJobCompeteciesChart = (): ChartData => {
    if (job && job?.competency_labels?.length) {
      const talantMatch = calculateMatchPercentage(
        getProfileCompetencies(),
        job?.competency_min_scores
      );

      if (talantMatch && talantMatch !== talantMatchPercent)
        setTalantMatchPercent(talantMatch);

      return {
        labels: job?.competency_labels,
        datasets: [
          {
            backgroundColor: '#33b1e85c',
            borderColor: 'rgba(0, 158, 226, 0.50)',
            data: job?.competency_min_scores
          },
          {
            borderColor: '#522583',
            backgroundColor: 'rgba(82, 37, 131, 0.50)',
            data: getProfileCompetencies()
          }
        ]
      };
    }
    const jobMatch = calculateMatchPercentage(
      teaserJobChart.datasets[0].data as number[],
      teaserJobChart.datasets[1].data as number[]
    );

    if (jobMatch) setTalantMatchPercent(jobMatch);
    return teaserJobChart;
  };

  const getCultureProfileCompeteciesChart = (): ChartData => {
    if (
      job &&
      job?.culture_profile &&
      job?.culture_profile?.competencies?.length
    ) {
      const cultureMatch = calculateMatchPercentage(
        getCultureProfileCompetencies(),
        job?.culture_profile?.competencies.map(
          (item: IJobCompetencies) => item.min_score
        )
      );

      if (cultureMatch && cultureMatchPercent !== cultureMatch)
        setCultureMatchPercent(cultureMatch);
      return {
        labels: job?.culture_profile?.competencies.map(
          (data: IJobCompetencies) => data.competency.name
        ),
        datasets: [
          {
            backgroundColor: 'rgba(0, 158, 226, 0.50)',
            borderColor: '#33b1e8',
            data: job?.culture_profile?.competencies.map(
              (item: IJobCompetencies) => item.min_score
            )
          },
          {
            borderColor: '#522583',
            backgroundColor: 'rgba(82, 37, 131, 0.50)',
            data: getCultureProfileCompetencies()
          }
        ]
      };
    }
    const cultureMatch = calculateMatchPercentage(
      teaserCultureChart.datasets[0].data as number[],
      teaserCultureChart.datasets[1].data as number[]
    );

    if (cultureMatch) setCultureMatchPercent(cultureMatch);

    return teaserCultureChart;
  };

  useEffect(() => {
    const jobtData = getJobCompeteciesChart();
    if (JSON.stringify(jobtData) !== JSON.stringify(jobChartData))
      setJobChartData(jobtData);
    const cultureData = getCultureProfileCompeteciesChart();
    if (JSON.stringify(cultureData) !== JSON.stringify(cultureChartData))
      setCultureChartData(cultureData);
  }, [job, candidateCompetencies]);

  const handleOpenPopup = (): void => {
    setShowSharePopup(true);
  };

  const handleBackClick = (): void => {
    navigate(-1);
  };

  const getJobByIdOrSlug = (param: number | string): void => {
    JobService.getJobByIdOrSlug(param);
  };

  const handleApply = () => {
    JobService.applyForAJob(job?.id);
  };

  return (
    <>
      <Navbar />
      <div className="jobdetails">
        <div className="jobdetails__top">
          <div onClick={handleBackClick} className="jobdetails__top__back">
            <Image name="back.svg" width={16} height={16} />
            <span className="jobdetails__top__back-overview">
              Back to overview
            </span>
          </div>
          <Button
            onClick={handleOpenPopup}
            iconName="socials.svg"
            className="jobdetails__top-share"
            title="Share"
            t={t}
          />
        </div>
        <div className="jobdetails__about">
          <div className="jobdetails__about__sides left">
            <div className="jobdetails__about__sides__left">
              <Image
                className="jobdetails__about__sides__left-image"
                name="amazon.svg"
                width={120}
                height={120}
              />
              <div className="jobdetails__about__sides__left__content">
                <span className="jobdetails__about__sides__left__content-title">
                  {job?.title?.length > 30 ? ` ${job?.title.substring(0, 30)}...` : job?.title}
                </span>
                <div className="jobdetails__about__sides__left__content__location">
                  <span className="jobdetails__about__sides__left__content__location-icon">
                    <Image
                      className="location"
                      name="location.svg"
                      width={12}
                      height={12}
                    />
                  </span>
                  <div className="job-side">
                    {jobLocation?.map((item, index) => (
                      <span
                        key={index}
                        className="jobdetails__about__sides__left__content__location-title"
                      >
                        {item}
                      </span>
                    ))}
                  </div>
                </div>
                <p className="jobdetails__about__sides__left__content-desc">
                  {job?.description}
                </p>
              </div>
            </div>
          </div>
          <div className="jobdetails__about__sides right">
            <div className="jobdetails__about__sides__right">
              {job?.industries && (
                <div className="jobdetails__about__sides__right__item">
                  <span className="jobdetails__about__sides__right__item__image">
                    <Image
                      className="jobdetails__about__sides__right__item__image-img"
                      name="portfolio.svg"
                      width={14}
                      height={14}
                    />
                  </span>
                  <div className="jobdetails__about__sides__right__item__content">
                    <span className="jobdetails__about__sides__right__item__content-title">
                      Industries
                    </span>
                    {jobIndustry.map((industry, index) => (
                      <span
                        key={index}
                        className="jobdetails__about__sides__right__item__content-desc"
                      >
                        {industry}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              {job?.degree && (
                <div className="jobdetails__about__sides__right__item">
                  <span className="jobdetails__about__sides__right__item__image">
                    <Image
                      className="jobdetails__about__sides__right__item__image-img"
                      name="bachelor.svg"
                      width={14}
                      height={14}
                    />
                  </span>
                  <div className="jobdetails__about__sides__right__item__content">
                    <span className="jobdetails__about__sides__right__item__content-title">
                      Required Degree
                    </span>
                    <span className="jobdetails__about__sides__right__item__content-desc">
                      {DegreeEnum[job.degree]}
                    </span>
                  </div>
                </div>
              )}
              {job?.work_exp && (
                <div className="jobdetails__about__sides__right__item">
                  <span className="jobdetails__about__sides__right__item__image">
                    <Image
                      className="jobdetails__about__sides__right__item__image-img"
                      name="volume.svg"
                      width={14}
                      height={14}
                    />
                  </span>
                  <div className="jobdetails__about__sides__right__item__content">
                    <span className="jobdetails__about__sides__right__item__content-title">
                      Career Level
                    </span>
                    <span className="jobdetails__about__sides__right__item__content-desc">
                      {getCareerLevelByValue(job.work_exp)}
                    </span>
                  </div>
                </div>
              )}

              {job?.salary && (
                <div className="jobdetails__about__sides__right__item">
                  <span className="jobdetails__about__sides__right__item__image">
                    <Image
                      className="jobdetails__about__sides__right__item__image-img"
                      name="salary.svg"
                      width={14}
                      height={14}
                    />
                  </span>
                  <div className="jobdetails__about__sides__right__item__content">
                    <span className="jobdetails__about__sides__right__item__content-title">
                      Salary
                    </span>
                    <span className="jobdetails__about__sides__right__item__content-desc">
                      {getSalaryByValue(job.salary)}
                    </span>
                  </div>
                </div>
              )}

              {job?.musts && (
                <div className="jobdetails__about__sides__right__item">
                  <span className="jobdetails__about__sides__right__item__image">
                    <Image
                      className="jobdetails__about__sides__right__item__image-img"
                      name="verified.svg"
                      width={14}
                      height={14}
                    />
                  </span>
                  <div className="jobdetails__about__sides__right__item__content">
                    <span className="jobdetails__about__sides__right__item__content-title">
                      Must haves
                    </span>
                    {job?.musts?.map((must: string, index: number) => (
                      <span
                        key={index}
                        className="jobdetails__about__sides__right__item__content-desc"
                      >
                        {must}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="jobdetails__chart">
          {job && jobChartData && (
            <CompetenciesBlock
              className="jobdetails__chart-left"
              percent={talantMatchPercent}
              chartData={jobChartData}
              chartWidth={500}
              chartHeight={500}
              title={'Match job competencies'}
              descriptions={job.competencies.map((el: any) => ({
                description: el.competency.description,
                name: el.competency.name
              }))}
            />
          )}
          {job && cultureChartData && (
            <CompetenciesBlock
              className="jobdetails__chart-right"
              percent={cultureMatchPercent}
              title="Match culture competencies"
              chartWidth={500}
              chartHeight={500}
              careerPage={true}
              candidateId={candidateCompetencies?.id}
              job={job?.company?.id}
              chartData={cultureChartData}
              descriptions={job?.culture_profile?.competencies.map(
                (el: any) => ({
                  description: el.competency.description,
                  name: el.competency.name
                })
              )}
            />
          )}
        </div>
        <div className="jobdetails__btn">
          <div onClick={handleBackClick} className="jobdetails__top__back">
            <Image name="back.svg" width={16} height={16} />
            <span className="jobdetails__top__back-overview">
              Back to overview
            </span>
          </div>
          <Button
            onClick={handleApply}
            t={t}
            className="jobdetails__btn__rigth"
            title="Apply"
          />
        </div>
      </div>
      <Footer />
      {showSharePopup && (
        <Popup title="Share this job opening" onClose={handleClose}>
          <ShareJobPopup />
        </Popup>
      )}
    </>
  );
};

export default JobDetails;
