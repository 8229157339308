import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import ActionButton from 'components/ActionButton/Index';
import { IMatchingProfileBlock } from 'core/interface/matchingProfileBlock';
import { getDateFormatter } from 'helpers/dateConverter';
import Popup from 'components/shared/Popups/Popup/Index';
import ConfirmPopup from 'components/shared/Popups/Confirm/Index';
import useLocales from 'hooks/useLocales';

import './Index.scss';

const MatchingProfileBlock: FC<IMatchingProfileBlock> = ({
  id,
  title,
  date,
  name,
  setEditPopUp,
  setDeletedItem,
  setSelectedMatchProfileId,
  getOnePost,
  getList,
  type
}): JSX.Element => {
  const { t } = useLocales();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleEdit = (id: number): void => {
    setEditPopUp(true);
    if (setSelectedMatchProfileId) {
      setSelectedMatchProfileId(id);
    }
  };
  const handleDelete = (): void => {
    setShowPopup(true);
  };

  const handleFindList = (): void => {
    getList && getList(id, type)
  };
  const handleViewList = (): void => {
    getOnePost && getOnePost(id)
  }

  const handleClose = (): void => {
    setShowPopup(false);
  };

  const handleApprove = async (): Promise<void> => {
    setDeletedItem(id);
    setShowPopup(false);
  };

  return (
    <>
      <div className="matchblock">
        <div className="matchblock__header">
          <span className="matchblock__header-title">{title}</span>
          <span className="matchblock__header-date">
            {getDateFormatter(date, 'DD.MM.YYYY')}
          </span>
        </div>
        <span className="matchblock-name">{name}</span>
        <div className="matchblock__actions">
          <ActionButton
            onClick={() => handleEdit(id)}
            className="matchblock__actions-action"
            iconName="pen.png"
          />
          <ActionButton
            onClick={handleDelete}
            className="matchblock__actions-action"
            iconName="trash.svg"
          />
          <ActionButton
            onClick={handleViewList}
            className="matchblock__actions-action"
            iconName="list.svg"
          />
          <ActionButton
            onClick={handleFindList}
            className="matchblock__actions-action"
            iconName="search.svg"
          />
        </div>
      </div>
      {showPopup && (
        <Popup title="Are you sure?" onClose={handleClose}>
          <ConfirmPopup
            approveTitle="Delete"
            description="Do you really want to delete this?"
            onApprove={handleApprove}
            onCancel={handleClose}
            approveLoading={approveLoading}
            t={t}
          />
        </Popup>
      )}
    </>
  );
};

export default MatchingProfileBlock;
