import { store } from '../redux/store';

export const prepareUpdatedData = (
  method: string,
  reducerName: string,
  data: any,
  name: string,
  tagName?: string
) => {
  const { getState } = store;
  const currentState = getState();
  const prevState = (currentState as any)[reducerName][name];

  switch (method) {
    case 'create':
      return [...prevState, data];
    case 'update':
      if (prevState && prevState?.results) {
        return {
          ...prevState,
          count: prevState?.count ? prevState?.count - 1 : 0,
          results: prevState?.results?.map((el: any) =>
            el.id === data.id ? data : el
          )
        };
      } else {
        if (Array.isArray(prevState)) {
          return prevState?.map((el: any) => (el.id === data.id ? data : el));
        } else {
          if (tagName) {
            const index = prevState[tagName].findIndex(
              (el: any) => el.id === data.id
            );
            if (index !== -1) {
              const updatedArray = [...prevState[tagName]];
              updatedArray[index] = data;
              return {
                ...prevState,
                [tagName]: updatedArray
              };
            } else {
              return {
                ...prevState,
                [tagName]: [...prevState[tagName], data]
              };
            }
          }
          return { ...prevState, ...data };
        }
      }
    case 'delete':
      if (prevState && prevState?.results) {
        return {
          ...prevState,
          count: prevState?.count ? prevState?.count - 1 : 0,
          results: prevState.results?.filter((el: any) => el.id !== data.id)
        };
      } else {
        if (tagName) {
          return {
            ...prevState,
            [tagName]: prevState[tagName]?.filter(
              (el: any) => el.id !== data.id
            )
          };
        }
        return prevState?.filter((el: any) => el.id !== data.id);
      }
    default:
      return prevState;
  }
};
