import { ICandidateLanguage } from 'core/interface/candidate-language';
import { JobCitiesResultsType } from 'core/interface/job';

export interface MathFilterValues {
  industry: string;
  career_level: string[];
  degree_type: string;
  computer_skill: string;
  city: { id: number; name: string };
  salary: string[];
  languages: any;
  [key: string]:
    | string
    | any
    | string[]
    | any
    | JobCitiesResultsType
    | null;
}

export const initialValues: MathFilterValues = {
  industry: '',
  education: [],
  degree_type: '',
  computer_skill: '',
  city: { id: -1, name: '' },
  salary: [],
  career_level: [],
  languages: [],
};

export interface MatchProfileProps {
  setInitialValue: (values: any) => void;
  initialValue: MathFilterValues;
}
