import { IAction } from 'core/interface/action';

export const SAVE_VALUES = 'save-certifications';

const defaultState = {
  certifications: null,
};

export const SaveCertifications = (value: any | number[]) => ({
  type: SAVE_VALUES,
  payload: value
});

const certificationsReducer = (
  state = defaultState,
  action: IAction<any | number[]>
) => {
  switch (action.type) {
    case SAVE_VALUES:      
      return {
        ...state,
        certifications: action.payload
      };
    default:
      return state;
  }
};

export default certificationsReducer;
