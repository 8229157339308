import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import useLocales from 'hooks/useLocales';
import ProfileSettings from './Profile/Index';
import SocialMedia from './SocialMedia/Index';
import Popup from '../Popup/Index';
import GeneralAndPrivacy from './GeneralTerms/Index';
import AvatarPopup from '../Avatar/Index';
import { settingsHeader } from 'core/constants/settings';
import { GeneralTermsConstant, PrivacyTerms } from 'core/constants/terms';
import { ISettingsHeader } from 'core/interface/headerItems';
import { SettingsHeaderEnum } from 'core/enum/settings';
import { ISettingsPopup } from 'core/interface/profileSettings';
import { IMe } from 'core/interface/me';

import './Index.scss';

const SettingsPopup: FC<ISettingsPopup> = ({
  handleClosePopup
}): JSX.Element => {
  const { t } = useLocales();
  const me = useSelector((state: IMe) => state.user).user;
  const [tab, setTab] = useState<string>(t(SettingsHeaderEnum.SETTINGS));
  const [tabHeader, setTabHeader] = useState<ISettingsHeader[]>(
    settingsHeader(t)
  );
  const [avatarEditModal, setAvatarEditModal] = useState<boolean>(false);

  const handleTabChange = (header: ISettingsHeader): void => {
    setTab(header.title);
    setTabHeader((prevValues) =>
      prevValues.map((item) =>
        item.title === header.title
          ? { ...item, isActive: true }
          : { ...item, isActive: false }
      )
    );
    header.isActive = true;
  };

  const openAvatarModal = (): void => {
    setAvatarEditModal(!avatarEditModal);
  };

  const showSelectedTab = (): JSX.Element | null | undefined => {
    if (!avatarEditModal) {
      switch (tab) {
        case t(SettingsHeaderEnum.SETTINGS):
          return (
            <ProfileSettings
              handleClosePopup={handleClosePopup}
              openAvatarModal={openAvatarModal}
            />
          );
        case t(SettingsHeaderEnum.SOCIAL_MEDIA):
          return <SocialMedia t={t} />;
        case t(SettingsHeaderEnum.GENERAL_TERMS):
          return <GeneralAndPrivacy term={GeneralTermsConstant} />;
        case t(SettingsHeaderEnum.PRIVACY_STATEMENT):
          return <GeneralAndPrivacy term={PrivacyTerms} />;
        default:
          return (
            <ProfileSettings
              handleClosePopup={handleClosePopup}
              openAvatarModal={openAvatarModal}
            />
          );
      }
    }
  };

  return (
    <div className="settings">
      <div className="settings__header">
        <ul className="settings__header__ul list">
          {tabHeader.map((item, idx) => (
            <li
              onClick={() => handleTabChange(item)}
              key={idx}
              className="settings__header__ul__li"
            >
              <span
                className={`settings__header__ul__li-title ${
                  item.isActive ? 'active' : ''
                }`}
              >
                {item.title}
                <span className="line"></span>
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="settings__content">{showSelectedTab()}</div>
      {avatarEditModal && (
        <Popup onClose={openAvatarModal} title={t('Edit Your Profile Image')}>
          <AvatarPopup id={me.id} onPopupClose={openAvatarModal} t={t} />
        </Popup>
      )}
    </div>
  );
};

export default SettingsPopup;
