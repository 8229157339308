import { IShareURL } from 'core/interface/shareURL';
import { store } from '../../redux/store';
import ApiBase from 'services/base/apiBase';
import { SaveSharProfileeURL } from '../../redux/slices/preview-url';

export class ShareUrlService {
  private static readonly apiBase = new ApiBase<IShareURL[]>();

  public static async getShareURL(candidate_id: number): Promise<void> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/candidates/${candidate_id}/share-url/`
      );
      dispatch(SaveSharProfileeURL(res.share_url));
    } catch (error) {
      console.log(error);
    }
  }

  public static async createShareURL(candidate_id: number): Promise<void> {
    try {
      const res = await this.apiBase.postAsync(
        `/candidates/${candidate_id}/share-url/`
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  public static async expireURL(
    candidate_id: number,
    url_id: number
  ): Promise<IShareURL> {
    return await this.apiBase.postAsync(
      `/candidates/${candidate_id}/share-url/${url_id}/expire/`,
      {}
    );
  }
  public static async renewURL(
    candidate_id: number,
    url_id: number
  ): Promise<IShareURL> {
    return await this.apiBase.postAsync(
      `/candidates/${candidate_id}/share-url/${url_id}/renew/`,
      {}
    );
  }
  public static async removeURL(
    candidate_id: number,
    url_id: number
  ): Promise<boolean> {
    return await this.apiBase.deleteAsync(
      `/candidates/${candidate_id}/share-url/${url_id}/`
    );
  }
}
