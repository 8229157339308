import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Button as MuiButton,
  Typography,
  Popover
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { InputType } from 'core/enum/inputType';
import useLocales from 'hooks/useLocales';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import SingleSelect from '../SelectSingle';
import Button from 'components/Button/Index';

import './index.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none'
    },
    paper: {
      padding: theme.spacing(2),
      maxWidth: '400px'
    }
  })
);

const MouseOverPopover = ({ children, popUpText }: any) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          display: 'inline-block',
          marginRight: '10px',
          marginBottom: '9px'
        }}
      >
        {children}
      </Box>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography color="GrayText" variant="caption">
          {popUpText}
        </Typography>
      </Popover>
    </>
  );
};

const CompetenciesSelect = ({
  competencies,
  selectedList,
  onSelect,
  next,
  setFormValues,
  formValues,
  matchProfile,
  fromCulture,
  setQuery
}: any) => {
  const { t } = useLocales();
  const handleInputChange = (fieldName: string, value: any) => {
    setFormValues({ ...formValues, [fieldName]: value });
  };
  const { matchSearch } = useSelector((state: RootState) => state.match);
  const handleSubmit = () => {
    next();
  };

  return (
    <Box
      sx={{ mt: 2, maxHeight: '550px', overflow: 'auto' }}
      className="scrollable"
    >
      {fromCulture ? (
        <div className="field many">
          <span className="label">{t('Culture profile title')}</span>
          <input
            className="input"
            type={InputType.text}
            placeholder={t('Text')}
            value={formValues.matching}
            onChange={(e) => handleInputChange('matching', e.target.value)}
          />
        </div>
      ) : (
        matchProfile && (
          <div className="fields">
            <div className="field many">
              <span className="label">{t('Matching profile title')}</span>
              <input
                className="input"
                type={InputType.text}
                placeholder={t('Text')}
                value={formValues.matching}
                onChange={(e) => handleInputChange('matching', e.target.value)}
              />
            </div>
            <div className="field many autocomplete">
              <span className="label">{t('Select culture profile')}</span>
              <SingleSelect
                onInputChange={setQuery}
                onChange={(e: any) => {
                  if (typeof e != 'string') {
                    handleInputChange('culture_profile', e);
                  }
                }}
                onBlur={(e: any) => {
                  if (!matchSearch?.length) {
                    handleInputChange('culture_profile', null);
                  }
                }}
                options={matchSearch}
                show_location={false}
                placeholder={''}
                value={formValues.culture_profile}
              />
            </div>
          </div>
        )
      )}
      {Array.isArray(competencies) &&
        competencies.length &&
        competencies.map((group: any) => (
          <Box key={group.id} sx={{ mt: 2 }}>
            <Card
              sx={{
                p: '0',
                boxShadow: 'none'
              }}
            >
              <Typography
                sx={{
                  m: 1,
                  ml: '0',
                  mr: '0',
                  fontFamily: 'Rubik',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '20px',
                  color: '#131417',
                  textAlign: 'center'
                }}
              >
                {group?.name}
              </Typography>
              {group?.competencies?.map((competency: any) => {
                const inArray = selectedList?.includes(competency.name);
                return (
                  <MouseOverPopover
                    key={competency.id}
                    popUpText={competency.description}
                  >
                    <MuiButton
                      sx={
                        inArray
                          ? {
                            width: '100%',
                            color: '#fff',
                            height: '36px',
                            fontWeight: '700',
                            borderRadius: '30px',
                            fontSize: '14px',
                            background: '#522583',
                            fontFamily: 'Rubik',
                            letterSpacing: 0,
                            textTransform: 'none',
                            '&:hover': {
                              background: '#CCC7D8',
                              color: '#522583'
                            }
                          }

                          : {
                            width: '100%',
                            color: '#522583',
                            background: '#EEEAF3',
                            height: '36px',
                            fontWeight: '700',
                            borderRadius: '30px',
                            fontSize: '14px',
                            fontFamily: 'Rubik',
                            letterSpacing: 0,
                            textTransform: 'none'
                          }
                      }
                      onClick={() =>
                        onSelect(
                          competency.name,
                          competency.id,
                          competency.description
                        )
                      }
                    >
                      {competency.name}
                    </MuiButton>
                  </MouseOverPopover>
                );
              })}
            </Card>
          </Box>
        ))}
      <div className="buttonContainer">
        <Button
          className="next button"
          t={t}
          title="Next"
          onClick={handleSubmit}
        />
      </div>
    </Box>
  );
};

export default CompetenciesSelect;
