import useLocales from 'hooks/useLocales';
import React, { FC } from 'react';
import { Field, useFormikContext } from 'formik';
import { FormFieldType } from 'core/interface/formFieldType';

import './Index.scss';

const FormField: FC<FormFieldType> = ({
  fieldName,
  type,
  placeholder,
  className,
  inputClassName,
  disabled = false
}): JSX.Element => {
  const formik = useFormikContext<{ [key: string]: string }>();
  const { t } = useLocales();

  return (
    <div className={className ?? 'field'}>
      <Field
        disabled={disabled}
        className={`${inputClassName ?? ''} ${formik.errors[fieldName] && formik.touched[fieldName] ? 'error' : ''
          }`}
        type={type}
        name={fieldName}
        placeholder={placeholder}
      />
      {(formik.errors[fieldName] && formik.touched[fieldName]) && (
        <span className="error-message"> {t('required')} </span>
      )}
    </div>
  );
};

export default FormField;
