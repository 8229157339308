import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Form, Formik, FormikHelpers } from 'formik';
import { extractCountryCode, extractPhoneNumber } from 'helpers/phoneExtractor';
import { getDateFormatter } from 'helpers/dateConverter';
import { ICandidateStore } from 'core/interface/candidate';
import { IMe } from 'core/interface/me';
import {
  IAboutMePopUp,
  UserProfile,
  UserProfileBody
} from 'core/interface/aboutMe';
import {
  phoneValues,
  days,
  months,
  years
} from 'core/constants/aboutMePopUpConstants';
import { CandidateService } from 'services/candidate/candidate';
import { LanguageService } from 'services/language';
import { JobService } from 'services/jobs/job';
import { AccountServce } from 'services/upload/file-upload';
import { ProfileService } from 'services/profile/profile';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import DropDown from 'components/Dropdown';
import RadioButton from 'components/RadioGroup/Index';
import Button from 'components/Button/Index';
import Autocomplete from 'components/shared/Autocomplete/Index';
import { ILocationRedux } from 'core/interface/education';
import { initialValues, validation } from './form';

import './Index.scss';

const AboutMePopUp: FC<IAboutMePopUp> = ({ t, onModalClose }): JSX.Element => {
  const [initialValue, setInitialValue] = useState<UserProfile>(initialValues);
  const [loading, setLoading] = useState<boolean>(false);
  const me = useSelector((state: IMe) => state.user).user;
  const candidate = useSelector(
    (state: RootState) => state.candidate
  ).candidate;
  const cities = useSelector(
    (selector: ILocationRedux) => selector.locations?.cities || []
  );
  const candidates = useSelector(
    (state: ICandidateStore) => state.candidate
  ).candidate;
  const [timeoutFunc, setTimeoutFunc] = useState<NodeJS.Timeout | null>(null);

  const { socialMedia } = useSelector((state: RootState) => state.socialMedia);

  useEffect(() => {
    if (me && me.candidate_id) {
      getCandidateById();
      getSocialMedias();
    }
  }, [me]);

  useEffect(() => {
    setInitial();
    getCandidateLanguageById();
  }, [candidates, socialMedia]);

  const getCandidateById = (): void => {
    CandidateService.getCandidate(me.candidate_id);
  };

  const getSocialMedias = (): void => {
    ProfileService.getSocialMedias(me.candidate_id);
  };

  const setInitial = (): void => {
    const newDate = me.birthday || '2000-01-01';
    const body: UserProfile = {
      first_name: me?.name?.split(' ')[0] ?? '',
      last_name: me?.name?.split(' ')[1] ?? '',
      pitch: candidate?.pitch ?? '',
      contact_info: extractCountryCode(me.phone ?? ''),
      contact_value: extractPhoneNumber(me.phone ?? ''),
      gender: me.gender,
      headline: '',
      phone: '',
      location: {
        id: candidates.locations[0].id,
        display_name: candidates.locations[0].display_name
      },
      birthday: {
        day: Number(getDateFormatter(newDate, 'D')),
        month: getDateFormatter(newDate, 'MM'),
        year: Number(getDateFormatter(newDate, 'YYYY'))
      }
    };
    setInitialValue(body);
  };

  useEffect(() => {
    getCities();
  }, []);

  const getCandidateLanguageById = (): void => {
    LanguageService.getCandidateLanguage(+candidates.id);
  };

  const handleSubmit = async (
    values: UserProfile,
    actions: FormikHelpers<UserProfile>
  ) => {
    const body: UserProfileBody = {
      ...values,
      phone: values.contact_info + values.contact_value,
      birthday: `${values.birthday.year}-${values.birthday.month}-${values.birthday.day}`
    };
    setLoading(true);
    const formData = new FormData();
    for (const key in body) {
      if (key !== 'id') {
        formData.append(key, (body as any)[key]);
      }
    }
    try {
      await AccountServce.updateAccount(me.id, formData);
      await CandidateService.updateCandidate(
        {
          locations: [
            {
              id: values.location.id,
              display_name: values.location.display_name
            }
          ],
          pitch: values.pitch
        },
        me.candidate_id
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      onModalClose();
    }
    return;
  };

  const getCities = (q: string = '') => {
    JobService.getLocations({ q });
  };

  const handleInputChange = async (e: string): Promise<void> => {
    if (timeoutFunc) {
      clearTimeout(timeoutFunc);
    }
    const timeout = setTimeout(() => {
      JobService.getLocations({ q: e ?? '' });
    }, 500);
    setTimeoutFunc(timeout);
  };

  const handleLoadMoreCities = () => {
    const params = { limit: 10, offset: cities.length };
    JobService.getLocations(params, true);
  };

  return (
    <div className="about__popup">
      <Formik
        enableReinitialize
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        initialValues={initialValue}
        validationSchema={() => validation(t)}
      >
        {({
          setFieldValue,
          isValid,
          values,
          errors,
          touched,
          setFieldTouched
        }) => {
          return (
            <Form>
              <div className="names">
                <div className="names__first">
                  <span className="label">First name</span>
                  <FormField
                    inputClassName="about-input input"
                    fieldName="first_name"
                    type={InputType.text}
                    placeholder="First name"
                  />
                </div>
                <div className="names__first">
                  <span className="label">Last name</span>
                  <FormField
                    inputClassName="about-input input"
                    fieldName="last_name"
                    type={InputType.text}
                    placeholder="Last name"
                  />
                </div>
              </div>
              <div className="pitch">
                <span className="label">Pitch</span>
                <FormField
                  inputClassName="about-input pitch input"
                  fieldName="pitch"
                  type={InputType.text}
                  placeholder="Pitch"
                />
              </div>
              <div className="headline">
                <span className="label">Headline</span>
                <FormField
                  inputClassName="about-input input"
                  fieldName="headline"
                  type={InputType.text}
                  placeholder="headline"
                />
              </div>
              <div className="contactForm">
                <div className="phone">
                  <span className="label"> Contact info</span>
                  <DropDown
                    dropdownClassName="phone_dropdown dropdown"
                    defaultValue={values.contact_info}
                    placeholder="Phone"
                    items={phoneValues}
                    selectedValue={(e) => {
                      setFieldTouched('contact_info', true);
                      setInitialValue(() => ({ ...values, contact_info: e }));
                      setFieldValue('contact_info', e);
                    }}
                  />
                </div>
                <div className="phone contact__val">
                  <span className="label">Contact Value</span>
                  <FormField
                    inputClassName="about-input contact-value input"
                    fieldName="contact_value"
                    type={InputType.number}
                    placeholder="000000000"
                  />
                </div>
              </div>
              <div className="locations">
                <span className="label">Current Location</span>
                <Autocomplete
                  fieldName="location"
                  className={`inputs ${
                    touched.location && errors.location ? 'error' : ''
                  }`}
                  value={values.location.display_name}
                  options={
                    cities.map((item, index) => ({
                      key: index,
                      id: item.id,
                      title: item.display_name
                    })) || []
                  }
                  placeholder="Location"
                  show_location={true}
                  onInputChange={(e) => {
                    handleInputChange(e);
                    if (cities.find((cit) => cit.display_name === e)) {
                      setFieldValue('location', {
                        id: cities.find((city) => city.display_name === e)?.id,
                        display_name: cities.find(
                          (city) => city.display_name === e
                        )?.display_name
                      });
                    }
                  }}
                  values={cities}
                  loadMore={handleLoadMoreCities}
                />{' '}
              </div>
              <div className="birthday">
                <span className="label">Birthday</span>
                <div className="fields">
                  <DropDown
                    className="day"
                    error={
                      touched.birthday?.day && errors.birthday?.day
                        ? errors.birthday?.day
                        : ''
                    }
                    dropdownClassName="birthday_dropdown day dropdown"
                    defaultValue={String(values.birthday.day)}
                    items={days}
                    placeholder="Day"
                    selectedValue={(e) => {
                      setInitialValue(() => ({
                        ...values,
                        birthday: {
                          ...values.birthday,
                          day: +e
                        }
                      }));
                      setFieldTouched('birthday.day', true);
                      setFieldValue('birthday.day', e);
                    }}
                  />
                  <DropDown
                    error={
                      touched.birthday?.month && errors.birthday?.month
                        ? errors.birthday?.month
                        : ''
                    }
                    className="month"
                    dropdownClassName="birthday_dropdown dropdown"
                    items={months}
                    placeholder="Month"
                    defaultValue={values.birthday.month}
                    selectedValue={(e) => {
                      setFieldTouched('birthday.month', true);
                      setFieldValue('birthday.month', e);
                    }}
                  />
                  <DropDown
                    error={
                      touched.birthday?.year && errors.birthday?.year
                        ? errors.birthday?.year
                        : ''
                    }
                    className="year"
                    placeholder="Year"
                    dropdownClassName="birthday_dropdown dropdown"
                    items={years}
                    minRange={new Date().getFullYear() - 18}
                    defaultValue={String(values.birthday.year)}
                    selectedValue={(e) => {
                      setFieldTouched('birthday.year', true);
                      setFieldValue('birthday.year', e);
                    }}
                  />
                </div>
              </div>

              <div className="gender">
                <span className="label">Gender</span>
                <RadioButton
                  buttons={[
                    {
                      label: 'Male',
                      value: '1',
                      isChecked: +values.gender === 1
                    },
                    {
                      label: 'Female',
                      value: '2',
                      isChecked: +values.gender === 2
                    }
                  ]}
                  onChange={(e) => {
                    setFieldValue('gender', e);
                  }}
                />
              </div>
              <Button
                isLoading={loading}
                t={t}
                type="submit"
                className={`save-btn-a ${!isValid ? 'invalid-btn' : ''}`}
                title="Save"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AboutMePopUp;
