import { IDropDownItem } from '../interface/dropDown';

const availableWithin: IDropDownItem[] = [
  {
    to_show: '1-3 month',
    value: '1-3 month'
  },
  {
    to_show: '2-6 month',
    value: '2-6 month'
  }
];

const availableRelocate: IDropDownItem[] = [
  { value: '1', to_show: 'Yes' },
  { value: '2', to_show: 'No' },
  { value: '3', to_show: 'Maybe' }
];

const workExperience: IDropDownItem[] = [
  { value: '1', to_show: '0-1 Years' },
  { value: '2', to_show: '1-3 Years' },
  { value: '3', to_show: '3-5 Years' },
  { value: '4', to_show: '5+ Years' }
];

const monthlySalary: IDropDownItem[] = [
  { value: '1', to_show: '0-2000' },
  { value: '2', to_show: '2000-3000' },
  { value: '3', to_show: '3000-4000' },
  { value: '4', to_show: '4000-5000' },
  { value: '5', to_show: '5000+' }
];

const freelanceSalary = [
  { value: "1", to_show: '0 - 250' },
  { value: "2", to_show: '50 - 75' },
  { value: "3", to_show: '75 - 100' },
  { value: "4", to_show: '100+' }
];

const manageExperience: IDropDownItem[] = [
  { value: '1', to_show: '0-1 Years' },
  { value: '2', to_show: '1-3 Years' },
  { value: '3', to_show: '3-5 Years' },
  { value: '4', to_show: '5+ Years' }
];

const industryPreferences: IDropDownItem[] = [
  {
    to_show: 'Text',
    value: '0-1 Year'
  },
  {
    to_show: 'Text',
    value: '2-3 Year'
  }
];

const industryText: IDropDownItem[] = [
  {
    to_show: 'Text',
    value: 'Text'
  },
  {
    to_show: '2-3 Year',
    value: '2-3 Year'
  }
];

//Steps
const availableAbout: IDropDownItem[] = [
  { value: '1', to_show: '0-1 Months' },
  { value: '2', to_show: '1-3 Months' },
  { value: '3', to_show: '3-6 Months' },
  { value: '4', to_show: 'More than 6 months' }
];

const candidateWorkHours = [
  { value: '1', to_show: '0-10 hours per week' },
  { value: '2', to_show: '10-20 hours per week' },
  { value: '3', to_show: '20-30 hours per week' },
  { value: '4', to_show: '30-40 hours per week' }
];
export {
  availableWithin,
  availableRelocate,
  workExperience,
  monthlySalary,
  manageExperience,
  industryPreferences,
  industryText,
  availableAbout,
  candidateWorkHours,
  freelanceSalary
};
