import React, { FC, useEffect, useState } from 'react';
import { IPagination, IPaginationEvent } from 'core/interface/pagination';
import Image from 'components/image/Image';

import './Index.scss';

const Pagination: FC<IPagination> = ({
  onNext,
  onPrev,
  dataLength,
  itemsPerPage,
  hasNext
}): JSX.Element => {
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [pageEvent, setPageEvent] = useState<IPaginationEvent>({
    currentPage: 1,
    nextPage: 2,
    prevPage: 0
  });

  useEffect(() => {
    nextPageChecker();
  }, [dataLength, itemsPerPage, pageEvent.currentPage]);

  const handleNextPage = (): void => {
    if (pageEvent.nextPage) {
      const nextPage = (pageEvent.nextPage += 1);
      const currentPage = (pageEvent.currentPage += 1);
      const prevPage = (pageEvent.prevPage = pageEvent.currentPage - 1);
      setPageEvent(() => ({
        nextPage,
        currentPage,
        prevPage
      }));
      onNext(pageEvent);
    }
  };

  const nextPageChecker = (): void => {
    const totalPages = Math.ceil(dataLength / itemsPerPage);
    const hasNext = pageEvent.currentPage < totalPages;
    setHasNextPage(hasNext);
  };

  const handlePrevPage = (): void => {
    if (pageEvent.nextPage) {
      const nextPage = (pageEvent.nextPage -= 1);
      const currentPage = (pageEvent.currentPage -= 1);
      const prevPage = (pageEvent.prevPage = pageEvent.currentPage - 1);
      setPageEvent(() => ({
        nextPage,
        currentPage,
        prevPage
      }));
      onPrev(pageEvent);
    }
  };

  return (
    <div className="pagination">
      <span
        onClick={handlePrevPage}
        className={`pagination-item ${
          pageEvent.prevPage === 0 || pageEvent.prevPage === 0 ? 'disabled' : ''
        }`}
      >
        <Image className="arrow prev" name="arrow.svg" />
      </span>
      <span className="pagination-item page-item">
        <span className="arrow">{pageEvent.currentPage}</span>
      </span>
      <span
        onClick={handleNextPage}
        className={`pagination-item ${
          !hasNextPage || !hasNext ? 'disabled' : ''
        }`}
      >
        <Image className="arrow next" name="arrow.svg" />
      </span>
    </div>
  );
};

export default Pagination;
