import { IAction } from 'core/interface/action';

export const SAVE_VALUES = 'save-cms';

const defaultState = {
  cms: null,
};

export const SaveCMS = (value: any | number[]) => ({
  type: SAVE_VALUES,
  payload: value
});

const CMSReducer = (
  state = defaultState,
  action: IAction<any | number[]>
) => {
  switch (action.type) {
    case SAVE_VALUES:      
      return {
        ...state,
        cms: action.payload
      };
    default:
      return state;
  }
};

export default CMSReducer;
