import { IAction } from 'core/interface/action';

export const SAVE_URL = 'save-social-media';

const defaultState = {
  socialMedia: null
};

export const SaveShareURL = (value: string) => ({
  type: SAVE_URL,
  payload: value
});

const shareUrlReducer = (state = defaultState, action: IAction<string>) => {
  switch (action.type) {
    case SAVE_URL:
      return {
        ...state,
        shareURL: action.payload
      };
    default:
      return state;
  }
};

export default shareUrlReducer;
