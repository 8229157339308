import React, { FC, useEffect, useRef, useState } from 'react';
import { ILanguage } from 'core/interface/language';
import DefaultImage from 'components/DefaultImage/Index';
import Footer from 'components/Footer/Index';
import Navbar from 'components/Navbar';
import { SwitcherEnum } from 'core/enum/switcherEnum';
import { ISocialMediaData } from 'core/interface/socialMedia';
import { IDropDownItem } from 'core/interface/dropDown';
import SkeletonLoader from '../Skeleton/Index';
import AboutMeTab from 'components/Tabs/AboutMe/Index';
import CompenecyPreferencesTab from 'components/Tabs/CompetencyPreferences/Index';
import EducationExperienceTab from 'components/Tabs/EducationAndExperience/Index';
import ImageAndYoutubeTab from 'components/Tabs/ImageAndYoutube/Index';
import SkillsTab from 'components/Tabs/Skills/Index';
import { headerItems } from 'core/constants/header';
import { DashboardTabEnum } from 'core/enum/dashboardTab';
import { IHeaderItems } from 'core/interface/headerItems';
import { IMe } from 'core/interface/me';
import useLocales from 'hooks/useLocales';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'redux/store';
import { CandidateService } from 'services/candidate/candidate';
import { ShareUrlService } from 'services/shareUrl/shareURL';
import { isValidEmail } from 'utils/isValidEmail';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import DropDown from 'components/Dropdown';
import { isAuthorized } from 'utils/isAuthorized';

import './Index.scss';

const CandidatePreview: FC<ILanguage> = ({ params }): JSX.Element => {
  const { t } = useLocales();
  const { socialMedia } = useSelector((state: RootState) => state.socialMedia);
  const [candidate, setCandidate] = useState<any>();
  const [tabHeader, setTabHeader] = useState<IHeaderItems[]>(headerItems(t));
  const [tabName, setTabName] = useState<string>(DashboardTabEnum.ABOUT_ME);
  const me = useSelector((state: IMe) => state.user).user;
  const uploadFile = useRef<HTMLInputElement>(null);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [inviteEmail, setInviteEmail] = useState<string>('');
  const [inviteEmailError, setInviteEmailError] = useState<string>('');
  const dropDownItems: IDropDownItem[] = [
    { value: t('teaser'), to_show: t('teaser') }
  ];
  const handleInviteInputChange = (e: string): void => {
    setInviteEmail(e);
    if (!isValidEmail(e)) {
      setInviteEmailError(t('Invalid email'));
    } else {
      setInviteEmailError('');
    }
  };

  useEffect(() => {
    getCandidateById();
  }, []);

  useEffect(() => {
    if (me && me.candidate_id) {
      getCandidate();
      getProfileLink();
    }
  }, [me]);

  const getCandidateById = async () => {
    const id = window.location.pathname.split('/').reverse()[0];
    const candidate = await CandidateService.getCandidate(+id);
    setCandidate(candidate);
  };

  const updateCandidate = async (isAnonym: number): Promise<void> => {
    const body = {
      access: Boolean(isAnonym)
    };
    CandidateService.updateCandidate(body, me.candidate_id);
  };

  const getCandidate = async (): Promise<void> => {
    CandidateService.getCandidate(me.candidate_id);
  };

  const handleLinkClick = async (): Promise<void> => {
    // try {
    //   const response = await ShareUrlService.createShareURL(me.candidate_id);
    //   console.log(response);
    // } catch (error) {
    //   try {
    //     const resp = await ShareUrlService.getShareURL(me.candidate_id);
    //     console.log(resp);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
  };

  const handleTabChange = (clickedItem: IHeaderItems): void => {
    setTabHeader((prevValues) =>
      prevValues.map((item) =>
        item === clickedItem
          ? { ...item, isActive: true }
          : { ...item, isActive: false }
      )
    );
    if (clickedItem.value) {
      setTabName(clickedItem.value);
    }
  };

  const handleSwitchChange = (e: SwitcherEnum): void => {
    updateCandidate(e);
  };

  const getProfileLink = async (): Promise<void> => {
    try {
      const response = await ShareUrlService.getShareURL(me.id);
    } catch (error) {
      console.log(error);
    }
  };

  const showSelectedTab = (): JSX.Element | null => {
    if (me) {
      switch (tabName) {
        case DashboardTabEnum.ABOUT_ME:
          return <AboutMeTab t={t} candidate_id={me.candidate_id} />;
        case DashboardTabEnum.PREFERENCES:
          return <CompenecyPreferencesTab t={t} />;
        case DashboardTabEnum.SKILLS:
          return <SkillsTab t={t} candidate_id={me.candidate_id} />;
        case DashboardTabEnum.IMAGE_YOUTUBE:
          return <ImageAndYoutubeTab t={t} />;
        case DashboardTabEnum.EDUCATION_EXPERIENCE:
          return (
            <EducationExperienceTab t={t} candidate_id={me.candidate_id} />
          );
        default:
          return <AboutMeTab t={t} candidate_id={me.candidate_id} />;
      }
    }
    return <></>;
  };

  return (
    <div className="preview">
      <Navbar />
      <div className="dashboard">
        <div className="dashboard__main">
          <div className="dashboard__left">
            <div className="dashboard__left__image">
              {!me ? (
                <SkeletonLoader />
              ) : (
                <>
                  {me && me.avatar ? (
                    <Image
                      className="dashboard__left__image-img"
                      name="profile-image.png"
                      width={236}
                      height={236}
                      url={me?.avatar}
                    />
                  ) : (
                    <DefaultImage
                      width={236}
                      height={236}
                      className="dashboard__left__image-img"
                      letter={me.first_name}
                    />
                  )}
                </>
              )}
            </div>
            <div className="dashboard__left__content">
              <div className="dashboard__left__swiper disable-block">
                <span
                  onClick={() => handleSwitchChange(SwitcherEnum.SHOW_ME)}
                  className={`dashboard__left__swiper-btn ${
                    !candidate?.access ? 'active' : ''
                  }`}
                >
                  {t('show_me')}
                </span>
                <span
                  onClick={() => handleSwitchChange(SwitcherEnum.HIDE_ME)}
                  className={`dashboard__left__swiper-btn ${
                    candidate?.access ? 'active' : ''
                  }`}
                >
                  {t('hide_me')}
                </span>
              </div>
              <div className="dashboard__left__link disable-block">
                <input
                  className="dashboard__left__link-input"
                  type="text"
                  onClick={(e) => {
                    navigator.clipboard.writeText(
                      (e.target as HTMLInputElement).value
                    );
                  }}
                  readOnly
                  placeholder={t('profile_link')}
                  value={window.location.origin + `/preview/${candidate?.id}`}
                />
                <span onClick={handleLinkClick}>
                  <Image
                    className="dashboard__left__link-refresh"
                    name="refresh.svg"
                    width={12}
                    height={12}
                  />
                </span>
              </div>

              {!isAuthorized() ? (
                <a
                  onClick={() => navigate('/dashboard', { replace: true })}
                  className="dashboard__left-back"
                >
                  <Image
                    width={20}
                    height={20}
                    className="arrow-left"
                    name="back.svg"
                  />{' '}
                  Back to profile
                </a>
              ) : (
                <DropDown
                  isReadonly={true}
                  showClose={false}
                  defaultValue="1"
                  dropdownClassName="dashboard__left-dropdown view-drop"
                  items={dropDownItems}
                  selectedValue={() => {}}
                />
              )}

              <div className="dashboard__left__contact">
                <div className="dashboard__left__contact__user">
                  <div className="dashboard__left__contact__user__icon">
                    <Image
                      className="dashboard__left__contact__user__icon-img"
                      name="email.svg"
                      width={12}
                      height={12}
                    />
                  </div>
                  <span className="dashboard__left__contact__user-contact">
                    {me?.email}
                  </span>
                </div>
                {me?.phone && (
                  <div className="dashboard__left__contact__user">
                    <div className="dashboard__left__contact__user__icon">
                      <Image
                        className="dashboard__left__contact__user__icon-img"
                        name="phone.svg"
                        width={12}
                        height={12}
                      />
                    </div>
                    <span className="dashboard__left__contact__user-contact">
                      {me.phone}
                    </span>
                  </div>
                )}
              </div>
              <div className="dashboard__left__social">
                {socialMedia &&
                  socialMedia.map((item: ISocialMediaData) => (
                    <a
                      className="disable-block"
                      href={item.url}
                      key={item.id}
                      target="blank"
                    >
                      <Image
                        key={item.id}
                        className="dashboard__left__social-icons"
                        width={31}
                        height={31}
                        name={`${item.network}.svg`}
                      />
                    </a>
                  ))}
              </div>
              <div className="dashboard__left__actions">
                <Button
                  isLoading={uploadLoading}
                  onClick={() =>
                    (uploadFile.current as HTMLInputElement).click()
                  }
                  t={t}
                  iconName="upload.svg"
                  className="dashboard__left__actions-purple disable-block"
                  title={t('upload_cv')}
                />
                <Button
                  disabled={uploadLoading || !candidate?.cv}
                  onClick={() => true}
                  t={t}
                  iconName="resume.svg"
                  className="dashboard__left__actions-sky disable-block"
                  title={t('recrout_resume')}
                />
                <Button
                  t={t}
                  iconName="report.svg"
                  className="dashboard__left__actions-green disable-block"
                  title={t('assessment_report')}
                />
              </div>
            </div>
          </div>
          <div className="dashboard__right">
            <div className="dashboard__right__top">
              <div className="dashboard__right__top__left">
                <h1
                  className="dashboard__right__top__left-title"
                  onClick={() => {}}
                >
                  {t('invite_friends')} <Image name="attention.svg" />
                </h1>
                <p className="dashboard__right__top__left-desc">
                  {t(
                    'Upgrade or expand your account by inviting at least three of your friends to join you at recrout'
                  )}
                </p>
                <div className="dashboard__right__top__left__email">
                  <div className="inp">
                    <input
                      onInput={(e) =>
                        handleInviteInputChange(
                          (e.target as HTMLInputElement).value
                        )
                      }
                      className="dashboard__right__top__left__email-email disable-block"
                      type="email"
                      placeholder={t('email_address')}
                    />
                    {inviteEmailError && (
                      <span className="error">{inviteEmailError}</span>
                    )}
                  </div>

                  <Button
                    disabled={inviteEmail === '' || inviteEmailError !== ''}
                    t={t}
                    title="invite"
                    type="submit"
                    className={`dashboard__right__top__left__email-submit disable-block${
                      inviteEmail === '' || inviteEmailError !== ''
                        ? 'deactive'
                        : ''
                    }`}
                  />
                </div>
              </div>
              <div className="dashboard__right__top__right">
                <Button
                  onClick={() => navigate('/applications')}
                  t={t}
                  className="dashboard__right__top__right__button app disable-block"
                  title="my_applications"
                />
                <Button
                  onClick={() => navigate('/filter-job')}
                  t={t}
                  className="dashboard__right__top__right__button job disable-block"
                  title="find_job"
                  iconName="search.svg"
                />
              </div>
            </div>
            <div className="dashboard__right__bottom">
              <div className="dashboard__right__bottom__tabs">
                <div className="dashboard__right__bottom__tabs__header">
                  {tabHeader.map((item, index) => (
                    <div key={index}>
                      <div className="dashboard__right__bottom__tabs__header__item disable-block">
                        <span
                          onClick={() => handleTabChange(item)}
                          className={`dashboard__right__bottom__tabs__header__item-title ${
                            item.isActive ? 'active' : ''
                          }`}
                        >
                          {t(item.title)}
                          <span className="line"></span>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="dashboard__right__bottom-tab">
                {showSelectedTab()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CandidatePreview;
