import { IAction } from 'core/interface/action';

export const SAVE_EDUCATIONS = 'save-educations';

const defaultState = {
  educations: null
};

export const SaveEducations = (value: any[]) => ({
  type: SAVE_EDUCATIONS,
  payload: value
});

const educationsReducer = (state = defaultState, action: IAction<any[]>) => {
  switch (action.type) {
    case SAVE_EDUCATIONS:
      return {
        ...state,
        educations: action.payload
      };
    default:
      return state;
  }
};

export default educationsReducer;
