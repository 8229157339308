import React, { FC, useRef, useState } from 'react';
import { CropperRef, Cropper } from 'react-advanced-cropper';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import { ICompanylogo } from 'core/interface/companyLogo';
import Activation from '../Index';

import './index.scss';

const ActivationCompanyLogo: FC<ICompanylogo> = ({
  t,
  onSubmit,
  setCompanyData,
  onBack
}): JSX.Element => {
  const fileRef = useRef(null);
  const [companyLogo, setCompanyLogo] = useState<any>();
  const [fileContent, setFileContent] = useState<string | ArrayBuffer | null>(
    ''
  );
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    handleImageChosen(e.dataTransfer.files[0]);
  };
  const handleImageRead = (e: ProgressEvent<FileReader>) => {
    const content = (e.target as FileReader).result;
    setFileContent(content);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target as HTMLInputElement;

    if (fileInput.files && fileInput.files.length > 0) {
      const file: File = fileInput.files[0];
      handleImageChosen(file);
    } else {
      console.error('No file selected');
    }
  };

  const handleImageChosen = async (file: File) => {
    const reader = new FileReader();
    reader.onloadend = handleImageRead;
    reader.readAsDataURL(file);
    setCompanyLogo(file);
  };

  const onChange = async (cropper: CropperRef) => {
    const canvas = cropper.getCanvas();
    const dataURL = canvas?.toDataURL();
    const base64 = dataURL?.replace(/^data:image\/[a-z]+;base64,/, '');
    try {
      const blob = await fetch(`data:image/png;base64,${base64}`).then((res) =>
        res.blob()
      );
      const fileName = `avatarImage${Math.random()}.png`;
      const fileType = 'image/png';
      const file = new File([blob], fileName, { type: fileType });
      setCompanyLogo(file);
    } catch (error) {
      console.log(error);
    }
  };

  const chooseFile = () => {
    if (fileRef.current) {
      (fileRef.current as HTMLInputElement).click();
    }
  };

  const handleLogoSave = async (): Promise<void> => {
    setCompanyData({
      logo: companyLogo
    });
    onSubmit();
  };

  return (
    <Activation title="Company logo" currentStep={1} totalSteps={5}>
      <div className="av">
        <div className="av__content">
          <Cropper
            src={fileContent as string}
            onChange={onChange}
            className={'cropper'}
          />
          <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            className="av__content__upload"
          >
            <div className="av__content__upload__top">
              <Image name="upload-file.svg" width={40} height={40} />
              <span className="av__content__upload__top-title">
                Drag and drop your files here
              </span>
              <span className="av__content__upload__top-support">
                Files supported: JPG, PNG
              </span>
              <span className="av__content__upload__top-or">or</span>
            </div>
            <div className="av__content__upload__bottom">
              <Button
                t={t}
                onClick={chooseFile}
                className="av__content__upload__bottom-browse"
                title="Browse File"
              />
            </div>
          </div>
          <input
            onChange={handleInputChange}
            ref={fileRef}
            className="file"
            type="file"
            accept="image/png, image/jpeg"
          />
          <div className="av__content__buttons">
            <Button
              onClick={onBack}
              t={t}
              title="Back"
              className="av__content__buttons-back"
            />
            <Button
              onClick={handleLogoSave}
              t={t}
              title="Next"
              className={`av__content__buttons-next ${
                !companyLogo ? 'deactive' : ''
              }`}
              disabled={!companyLogo}
            />
          </div>
        </div>
      </div>
    </Activation>
  );
};

export default ActivationCompanyLogo;
