import React, { useEffect, useRef, useState } from 'react';
import Button from 'components/Button/Index';
import Activation from 'components/feature/activation/Index';
import Image from 'components/image/Image';
import useLocales from 'hooks/useLocales';
import ActionButton from 'components/ActionButton/Index';
import ReactPlayer from 'react-player';
import { isValidURL } from 'helpers/isValidURL';

import './Index.scss';

const CompanyVideo = ({
  onSubmit,
  onBack,
  onClose,
  setCompanyData,
  companyData,
  isPopup = false,
  className,
  isOneBtn = false,
  showTopSide,
  setNewData,
  showTitle
}: any): JSX.Element => {
  const { t } = useLocales();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [videos, setVideos] = useState<string[]>(
    companyData?.video_url ? [companyData?.video_url] : []
  );
  const [videoTitle, setVideoTitle] = useState<string>('');
  const [error, setError] = useState<string>('');
  const handleRemoveVideo = (index: number): void => {
    const updatedVideos = [...videos];
    updatedVideos.splice(index, 1);
    setVideos(updatedVideos);
  };

  const handleSave = async () => {
    if (videos.length !== 0) {
      if (setCompanyData) {
        setCompanyData((prev: any) => ({
          ...prev,
          video_url: videos[0]
        }));
        onSubmit();
      } else {
        setNewData({
          video_url: videos[0]
        });
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVideoTitle(event.target.value);
    setError('');
  };

  const handleAddVideo = () => {
    if (videoTitle.trim() === '') {
      setError('Please enter a video title');
      return;
    }
    const updatedVideos = [...videos, videoTitle];
    setVideos(updatedVideos);
    setVideoTitle('');
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isValidURL(videoTitle)) {
      event.preventDefault();
      handleAddVideo();
    }
  };

  return (
    <div className="who">
      <Activation
        showTopSide={showTopSide}
        title="Embed your company video"
        currentStep={5}
        className={
          isPopup ? '' : `activation-edu ${className ?? ''} ${className}`
        }
        headerIconDisable
        totalSteps={8}
        isCareerPage={true}
        handleModalClose={onClose}
        isPopup={true}
        showTitle={showTitle}
      >
        <>
          <div className="organization">
            {videos?.map((item, index) => (
              <div key={index} className="video">
                <ActionButton
                  className="removeVideo"
                  onClick={() => handleRemoveVideo(index)}
                  iconName="trash.svg"
                />
                <ReactPlayer
                  playing={false}
                  light={true}
                  controls={false}
                  url={item}
                  width="100%"
                />
              </div>
            ))}
          </div>
          <div className="search">
            <span className="label">Insert you URL to embed your company video</span>
            <Image className="search-icon" name="searchs.svg" />
            <input
              ref={inputRef}
              disabled={videos.length >= 1}
              type="text"
              className={`input search-input ${error && 'error'}`}
              placeholder="Steve Jobs Stanford Commencement, etc."
              value={videoTitle}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
          </div>
          {!isOneBtn ? (
            <div className="button_container">
              <Button
                onClick={onBack}
                t={t}
                title="Back"
                className="av__content__buttons-back"
              />
              <Button
                onClick={videos.length !== 0 ? handleSave : () => {}}
                className={`next `}
                type="submit"
                title="Next"
                t={t}
              />
            </div>
          ) : (
            <div className="btn_container">
              <Button
                className="next button one-btn"
                onClick={videos.length !== 0 ? handleSave : () => {}}
                type="submit"
                t={t}
                title={'Save'}
              />
            </div>
          )}
        </>
      </Activation>
    </div>
  );
};

export default CompanyVideo;
