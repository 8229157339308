import { store } from '../../redux/store';
import {
  IWorkExperienceBody,
  IWorkExperiences
} from 'core/interface/work-experience-side';
import ApiBase from 'services/base/apiBase';
import { SaveWorkExperiences } from '../../redux/slices/workExperience';
import { prepareUpdatedData } from '../../helpers/stateChanges';

export class ExperienceService {
  private static readonly apiBase = new ApiBase<IWorkExperiences>();

  public static async getExperience(
    candidate_id: number
  ): Promise<IWorkExperiences[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/candidates/${candidate_id}/work-experiences/`
      );
      if( Array.isArray(res)) {
        dispatch(SaveWorkExperiences(res));
      }
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async setExperience(
    candidate_id: number,
    experience: IWorkExperienceBody
  ): Promise<IWorkExperiences[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.postAsync(
        `/candidates/${candidate_id}/work-experiences/`,
        experience
      );
      const updatedState = prepareUpdatedData(
        'create',
        'workExperiences',
        res,
        'experience'
      );
      dispatch(SaveWorkExperiences(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async removeExperience(
    candidate_id: number,
    experience_id: number
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.deleteAsync(
        `/candidates/${candidate_id}/work-experiences/${experience_id}/`
      );
      const updatedState = prepareUpdatedData(
        'delete',
        'workExperiences',
        {
          id: experience_id
        },
        'experience'
      );
      dispatch(SaveWorkExperiences(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async updateExperience(
    candidate_id: number,
    experience_id: number,
    values: IWorkExperienceBody
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.putAsync(
        `/candidates/${candidate_id}/work-experiences/${experience_id}/`,
        values
      );
      const updatedState = prepareUpdatedData(
        'update',
        'workExperiences',
        res,
        'experience'
      );
      dispatch(SaveWorkExperiences(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
