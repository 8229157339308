import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Image from 'components/image/Image';
import useLocales from 'hooks/useLocales';
import Popup from 'components/shared/Popups/Popup/Index';
import OurBenefits from 'components/ourBenefits';
import { CMSService } from 'services/cms/cms';
import { IMe } from 'core/interface/me';
import { callForAllElement } from 'helpers/callAll';
import { useParams } from 'react-router';

function Benefit() {
  const [openModal, setOpenModal] = useState(false);
  const { cms } = useSelector((state: RootState) => state.cms);
  const me = useSelector((state: IMe) => state.user).user;
  const { id } = useParams();

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleChange = (companyData: any) => {
    const { benefit_section_title, benefit_section_description } = companyData;
    const formData = new FormData();
    benefit_section_title &&
      formData.append('benefit_section_title', benefit_section_title);
    benefit_section_description &&
      formData.append(
        'benefit_section_description',
        benefit_section_description
      );
    CMSService.updateCMS(me?.company, formData);
    handleModal();
  };

  const handleBenefitChange = (data: any, isNew: boolean) => {
    if (isNew) {
      callForAllElement(data, (benefit) => {
        return CMSService.updateCMSBenefits(me?.company, benefit);
      });
    } else {
      callForAllElement(data, (benefit) => {
        return CMSService.updateCMSBenefit(
          benefit.page,
          benefit.changedFields,
          benefit.id
        );
      });
    }
    handleModal();
  };

  return (
    <div>
      <div className="benefitsContainer">
        <div>
          <h2 className="benefit_section_title">
            {cms?.benefit_section_title}
          </h2>
          <h1>Our benefits and why you should work with us</h1>
        </div>
        {!id && (
          <div className="pencil_icon" onClick={handleModal}>
            <div className="pencil_circle" />
            <Image
              className="pencil"
              name="pencil.svg"
              width={13}
              height={13}
            />
          </div>
        )}
      </div>
      <div className="benefitsDescription">
        <p className="description">{cms?.benefit_section_description}</p>
      </div>
      <div className="benefitsCartContainer">
        {cms?.benefits?.map((e: any) => (
          <div key={e.id} className="benefitsCart">
            <Image
              className="checked"
              name="checkedBenefits.svg"
              width={40}
              height={40}
            />
            <div>
              <p className="benefitsTitle">{e.title}</p>
              <p className="benefitsDescription">{e.description}</p>
            </div>
          </div>
        ))}
      </div>
      {openModal && (
        <Popup
          title="Create career page"
          className="expertise"
          onClose={handleModal}
        >
          <OurBenefits
            companyData={cms}
            setBenefitData={handleBenefitChange}
            setNewData={handleChange}
            isOneBtn={true}
            onClose={handleModal}
            showTopSide={false}
            isPopup={true}
            showTitle
          />
        </Popup>
      )}
    </div>
  );
}

export default Benefit;
