import { useTranslation } from 'react-i18next';
import { LANGS } from '../constants/constants';
import { ICurrentLang } from './type';

export default function useLocales() {
  const { i18n, t } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const globalLang = LANGS[1].icon1;
  const currentLang: ICurrentLang =
    LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = async (newlang: string) => {
    localStorage.setItem('i18nextLng', newlang);
    await i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    t,
    currentLang,
    allLang: LANGS,
    globalLang
  };
}
