import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { IResetPassword } from 'core/interface/resetPassword';

const initialValues: IResetPassword = {
  email: ''
};

const validation = (t: TFunction) => {
  return Yup.object({
    email: Yup.string().trim().email().required(t('required'))
  });
};

export { initialValues, validation };
