import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { ChartData } from 'chart.js';
import useLocales from 'hooks/useLocales';
import RangeButton from 'components/shared/RangeButton/Index';
import ChartComponent from 'components/RadarChart/Index';
import Button from 'components/Button/Index';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer/Index';
import { ICompetenciesSlider } from 'core/interface/competencyOverview';
import { IRangeButton } from 'core/interface/rangeButton';
import Image from 'components/image/Image';
import CompetencyInformation from 'components/shared/CompetencyInformation';
import { MatchProfileService } from 'services/matchProfile/matchProfile';
import { JobService } from 'services/jobs/job';
import CompetenciesSelect from 'components/shared/CompetenciesSelect';
import Popup from 'components/shared/Popups/Popup/Index';
import { RootState } from 'redux/store';

import './Index.scss';

const CultureProfile = (): JSX.Element => {
  const { t } = useLocales();

  const [items, setItems] = useState<any>();
  const [showPopUp, setShowUp] = useState(false);
  const [showCompetencies, setShowCompetencies] = useState(false);
  const navigate = useNavigate();

  const [competencies, setCompetencies] = useState<ICompetenciesSlider[]>([]);
  const { assessmentByGroup } = useSelector(
    (state: RootState) => state.assessment
  );


  const getAssessment = () => {
    JobService.getAssessmentByGroup();
  };
  useEffect(() => {
    getAssessment()
  }, [])

  useEffect(() => {
    const savedUsername = localStorage.getItem('selected');
    if (savedUsername) {
      const allItems = JSON.parse(savedUsername);
      setItems(allItems);
    }
  }, []);

  useEffect(() => {
    setSelectedCompetencies();
  }, [items]);

  const setSelectedCompetencies = () => {
    const arr: ICompetenciesSlider[] = [];
    items?.competencies?.forEach((item: any, index: number) => {
      const obj = {
        id: item.id,
        score: items?.scores ? items?.scores?.[index] : 0,
        competency: item.competency
      } as ICompetenciesSlider;
      arr.push(obj);
    });
    setCompetencies(arr);
  };

  const chartData = (): ChartData => ({
    labels: competencies.map(
      (competency) => competency.competency?.split(' ') ?? competency
    ),
    datasets: [
      {
        label: '',
        data: competencies.map((competency) => competency.score),
        backgroundColor: 'rgba(0, 158, 226, 0.50)',
        borderColor: '#009EE2',
        borderWidth: 1
      }
    ]
  });

  const handleRadioChange = (btn: IRangeButton) => {
    setCompetencies((prevItems) =>
      prevItems.map((item) =>
        item.competency === btn.label
          ? { ...item, competency: btn.label, score: Number(btn.value) }
          : item
      )
    );
  };

  const handleSave = async () => {
    let reqBody;
    if (items?.is_culture) {
      reqBody = {
        title: items?.name,
        is_culture: items?.is_culture,
        competencies: competencies?.map((comp) => ({
          competency: comp.id,
          min_score: comp.score
        }))
      };
    } else if (items?.firstStepData || items?.is_culture == undefined) {
      reqBody = {
        title: items?.firstStepData?.job_title || items?.job_title,
        culture_profile: items?.firstStepData?.culture_profile.id || items?.culture_profile.id,
        description: items?.firstStepData?.job_description || items?.job_description,
        is_public: items?.is_public || items?.firstStepData?.is_public,
        degree: items?.firstStepData?.degree || items?.degree,
        work_exp: +items?.firstStepData?.required_experience || items?.required_experience,
        salary: +items?.firstStepData?.prefered_salary || items?.prefered_salary,
        locations: items?.firstStepData?.location ? [items?.firstStepData?.location] : [items?.location],
        industries: items?.firstStepData?.industries || items?.industries,
        skills: items?.firstStepData?.skills || items?.skills,
        musts: items?.firstStepData?.requirements || items?.requirements,
        is_remote: items?.is_remote || items?.firstStepData?.is_remote,
        if_office_work: items?.if_office_work || items?.firstStepData?.if_office_work,
        status: items?.firstStepData?.status || items?.status,
        duration: items?.firstStepData?.duration || items?.duration,
        hourly_rate: +items?.firstStepData?.hourly_rate || items?.hourly_rate,
        competencies: competencies?.map((comp) => ({
          competency: comp.id,
          min_score: comp.score
        })) || items?.competencies
      };
    } else {
      reqBody = {
        title: items?.name,
        culture_profile: items?.culture_profile?.id,
        competencies: competencies?.map((comp) => ({
          competency: comp.id,
          min_score: comp.score
        }))
      };
    }

    if (items.edit && !items.postAJob && items?.is_culture !== undefined) {
      MatchProfileService.editMatchProfile(
        items?.edit,
        items?.is_culture,
        reqBody
      );
      if (items?.is_culture) {
        navigate('/company');
      } else {
        navigate(`/match-result/find-candidate/${items?.edit}`);
      }
    } else if (items.firstStepData || items?.is_culture == undefined) {
      if (items.edit) {
        JobService.saveJobEdit(items?.edit, reqBody);
      } else {
        JobService.saveJob(reqBody);
      }
      navigate('/company');
    } else {
      if (items?.is_culture) {

        MatchProfileService.createMatchProfile(items?.is_culture, reqBody);
        navigate('/company');
      } else {
        const res = await MatchProfileService.createMatchProfile(items?.is_culture, reqBody)
        navigate(`/match-result/find-candidate/${res?.id}`);
      }

    }
  };

  const handleClosePopup = (): void => {
    setShowUp(false);
  };
  const handleSetSelected = (label: string, id: number, text: string) => {
    const selectedIndex = competencies.findIndex(comp => comp.competency === label);
    if (selectedIndex !== -1) {
      const updatedCompetencies: any = [...competencies];
      updatedCompetencies.splice(selectedIndex, 1);
      setCompetencies(updatedCompetencies);
    } else {
      if (competencies.length < 10) {
        const newCompetency = {
          id: id,
          score: 0,
          competency: label
        };
        const updatedCompetencies = [...competencies, newCompetency];

        setCompetencies(updatedCompetencies);
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="culture">
        <div className="culture__left block">
          <div className="culture__left_title_container">
            <span className="culture__left-title">{t('Match Profile')}</span>
            <span onClick={() => setShowUp(true)}>
              <Image className="" name="morInfo.svg" width={36} height={36} />
            </span>
          </div>
          <div className="culture__left-ranges">
            {competencies.map((item, index) => (
              <div key={index} className="culture__left-ranges-range">
                <RangeButton
                  onChange={(e) => handleRadioChange(e)}
                  rangeButton={{ label: item.competency }}
                  values={item?.score}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="culture__right block">
          <ChartComponent
            className="culture__right-chart"
            width={616}
            height={490}
            data={chartData()}
          />
          <div className="culture__right__buttons">
            <Button
              className="culture__right__buttons-comp btn"
              t={t}
              onClick={() => setShowCompetencies(true)}
              title="Select competencies"
            />
            <Button
              onClick={handleSave}
              className="culture__right__buttons-save btn"
              t={t}
              title={(!items?.firstStepData && !items?.is_culture) ? "Next" : "Save"}
            />
          </div>
        </div>
      </div>
      <Footer />
      {showPopUp && <CompetencyInformation onClose={handleClosePopup} />}
      {showCompetencies &&
        <Popup
          className="popup-add-new"
          title={!items?.firstStepData ?
            "Please select the most important competencies for your job opening."
            : "Create your new job post"}
          description={!items?.firstStepData ?
            "We advise you to select no more than 10 competencies. And you should only go for the once that really matter to achieve success in the work the candidate needs to execute. The average is 7 competencies per matching profile. But depending on your results you can always add or delete competencies. This way you can fine-tune your ideal profile." :
            "Please select the most important competencies for your job opening."}
          onClose={() => setShowCompetencies(false)}
        >
          <CompetenciesSelect
            competencies={assessmentByGroup}
            selectedList={competencies.map((el: { competency: string }) => el.competency)}
            onSelect={handleSetSelected}
            next={() => competencies.length < 7 ? {} : setShowCompetencies(false)}
            matchProfile={false}
          />
        </Popup>
      }
    </>
  );
};

export default CultureProfile;
