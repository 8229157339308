const faqItems = [
  {
    title: 'What can I do with a credit?',
    description:
      'As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.'
  },
  {
    title: 'What is a credit refund?',
    description:
      'As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.'
  },
  {
    title: 'When will you write of a credit?',
    description:
      'As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.'
  },
  {
    title: 'What about those 3 free credits?',
    description:
      'As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.'
  },
  {
    title: 'Can I use Recrout as an internal recruitment tool?',
    description:
      'As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.'
  },
  {
    title: 'What if we need more credits?',
    description:
      'As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.'
  }
];

export { faqItems };
