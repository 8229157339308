import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import RadarChartComp from '../RadarChart/RadarChartComp';
import { ICompetenciesBlock } from 'core/interface/competenciesBlock';
import ChartComponent from 'components/RadarChart/Index';
import { ChartData } from 'chart.js';
import Button from 'components/Button/Index';
import useLocales from 'hooks/useLocales';

import './Index.scss';

const CompetenciesBlock: FC<ICompetenciesBlock> = ({
  title,
  percent,
  className,
  labels,
  points,
  descriptions = null,
  chartWidth,
  chartHeight,
  chartData,
  careerPage = false,
  job,
  candidateId
}): JSX.Element => {
  const { t } = useLocales();
  const navigate = useNavigate();

  return (
    <div className={`competenciesb ${className ?? ''}`}>
      <div className="competenciesb__top">
        <div className="competenciesb__top__left">
          <span className="competenciesb__top__left-title">{title}</span>
          {percent && (
            <span className="competenciesb__top__left-percent">{percent}%</span>
          )}
        </div>
        <div className="competenciesb__top__right">
          <div className="competenciesb__top__right__item">
            <span className="competenciesb__top__right__item-dot"></span>
            <span className="competenciesb__top__right__item-title">Job</span>
          </div>
          <div className="competenciesb__top__right__item">
            <span className="competenciesb__top__right__item-dot purple"></span>
            <span className="competenciesb__top__right__item-title">
              Candidate
            </span>
          </div>
        </div>
      </div>
      <div className="competenciesb__chart">
        {labels && points && (
          <RadarChartComp
            title={'Match job competencies'}
            labels={labels}
            points={points}
            descriptions={descriptions}
            chartWidth={chartWidth}
            chartHeight={chartHeight}
          />
        )}
        {chartData && (
          <ChartComponent
            width={chartWidth}
            height={chartHeight}
            data={chartData as ChartData}
            showTicks={false}
          />
        )}
      </div>
      {careerPage && (
        <Button
          onClick={() => {
            navigate(`/company/career/${job}/${candidateId}`);
          }}
          t={t}
          className="career_page"
          title={t('View our career page')}
        />
      )}
    </div>
  );
};

export default CompetenciesBlock;
