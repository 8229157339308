import React, { FC } from 'react';
import { ITerm } from 'core/interface/terms';

import './Index.scss';

const TermStatement: FC<ITerm> = ({ title, description }): JSX.Element => {
  return (
    <div className="term">
      <span className="term__title">{title}</span>
      <p className="term__desc">{description}</p>
    </div>
  );
};

export default TermStatement;
