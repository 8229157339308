import React, { FC, useEffect, useState } from 'react';
import useLocales from 'hooks/useLocales';
import { useNavigate } from 'react-router';
import Button from 'components/Button/Index';
import Popup from 'components/shared/Popups/Popup/Index';
import CreateCareer from 'components/createCareerPage';
import JobOpenings from 'components/jobOpenings';
import OrganizationCulture from 'components/feature/organizationCulture';
import WhatPeopleSay from 'components/whatPeopleSay';
import OurBenefits from 'components/ourBenefits';
import BeInTouch from 'components/beInTouch';
import Congrats from 'components/congrats/Index';
import CareerInfo from 'components/CareerInfo';
import { ActivationPageEnum } from 'core/enum/activationPage';
import { CareerPageModalEnum } from './types';
import { CMSService } from 'services/cms/cms';
import CompanyVideo from 'components/companyVideo';
import { IMe } from 'core/interface/me';
import { useSelector } from 'react-redux';
import { callForAllElement } from 'helpers/callAll';
import { RootState } from 'redux/store';

import './Index.scss';

const CareerPage: FC = (): JSX.Element => {
  const { t } = useLocales();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [contentToShow, setContentToShow] = useState<JSX.Element>();
  const [link, setLink] = useState<string>(CareerPageModalEnum.CREATE_CAREER);
  const navigate = useNavigate();
  const handleOnNextPage = (page: string): void => {
    setLink(() => page);
  };
  const me = useSelector((state: IMe) => state.user).user;
  const [companyData, setCompanyData] = useState<any>();
  const [companyDataTestimonials, setCompanyDataTestimonials] = useState<any>();
  const [companyDataGallery, setCompanyDataGallery] = useState<any>();
  const [companyDataBenefits, setCompanyDataBenefits] = useState<any>();
  const [companyDataSocialMediaLinks, setCompanyDataSocialMediaLinks] = useState<any>();
  const { cms } = useSelector((state: RootState) => state.cms);

  const handleOnSubmit = async (): Promise<void> => {
    const formData = new FormData();
    for (const key in companyData) {
      if (key !== 'id') {
        formData.append(key, (companyData as any)[key]);
      }
    }
    CMSService.updateCMS(me?.company, formData)

    callForAllElement(companyDataBenefits?.benefits, (benefit) => {
      return CMSService.updateCMSBenefits(me?.company, benefit);
    });

    callForAllElement(companyDataTestimonials?.testimonials, (testimonial) => {
      const formData = new FormData();
      for (const key in testimonial) {
        if (key !== 'id') {
          formData.append(key, (testimonial as any)[key]);
        }
      }
      return CMSService.updateCMSTestimonials(me?.company, formData)
    });

    callForAllElement(companyDataGallery?.logo, (image) => {
      const formData = new FormData();
      formData.append("image", image);
      return CMSService.updateCMSGallery(me?.company, formData)
    });

    callForAllElement(companyDataSocialMediaLinks?.social_media_links, (link) => {
      return CMSService.updateCMSSocialMediaLinks(me?.company, link)
    });
    setOpenModal(!openModal);
  };

  useEffect(() => {
    contentToShowHandler();
  }, [link, openModal]);


  const contentToShowHandler = (): void => {
    switch (link) {
      case CareerPageModalEnum.CREATE_CAREER:
        setContentToShow(
          <CreateCareer
            t={t}
            companyData={companyData}
            setCompanyData={setCompanyData}
            onSubmit={() => handleOnNextPage(CareerPageModalEnum.JOB_OPENINGS)}
            onClose={() => setOpenModal(!openModal)}
          />
        );
        break;
      case CareerPageModalEnum.JOB_OPENINGS:
        setContentToShow(
          <JobOpenings
            t={t}
            companyData={companyData}
            setCompanyData={setCompanyData}
            onBack={() => handleOnNextPage(CareerPageModalEnum.CREATE_CAREER)}
            onSubmit={() =>
              handleOnNextPage(CareerPageModalEnum.ORGANIZATION_CULTURE)
            }
            onClose={() => setOpenModal(!openModal)}
          />
        );
        break;
      case CareerPageModalEnum.ORGANIZATION_CULTURE:
        setContentToShow(
          <OrganizationCulture
            t={t}
            companyData={companyDataGallery}
            setCompanyData={setCompanyDataGallery}
            onBack={() => handleOnNextPage(CareerPageModalEnum.JOB_OPENINGS)}
            onSubmit={() =>
              handleOnNextPage(CareerPageModalEnum.WHAT_PEOPLE_SAY)
            }
            onClose={() => setOpenModal(!openModal)}
          />
        );
        break;
      case CareerPageModalEnum.WHAT_PEOPLE_SAY:
        setContentToShow(
          <WhatPeopleSay
            t={t}
            companyData={companyDataTestimonials}
            setCompanyData={setCompanyDataTestimonials}
            onBack={() =>
              handleOnNextPage(CareerPageModalEnum.ORGANIZATION_CULTURE)
            }
            onSubmit={() => handleOnNextPage(CareerPageModalEnum.UPLOAD_VIDEO)}
            onClose={() => setOpenModal(!openModal)}
          />
        );
        break;
      case CareerPageModalEnum.UPLOAD_VIDEO:
        setContentToShow(
          <CompanyVideo
            t={t}
            companyData={companyData}
            setCompanyData={setCompanyData}
            popup={true}
            onBack={() => handleOnNextPage(CareerPageModalEnum.WHAT_PEOPLE_SAY)}
            onSubmit={() => handleOnNextPage(CareerPageModalEnum.OUR_BENEFITS)}
            onClose={() => setOpenModal(!openModal)}
          />
        );
        break;
      case CareerPageModalEnum.OUR_BENEFITS:
        setContentToShow(
          <OurBenefits
            t={t}
            benefitData={companyDataBenefits}
            setBenFits={setCompanyDataBenefits}
            companyData={companyData}
            setCompanyData={setCompanyData}
            onBack={() => handleOnNextPage(CareerPageModalEnum.UPLOAD_VIDEO)}
            onSubmit={() => handleOnNextPage(CareerPageModalEnum.BE_IN_TOUCH)}
            onClose={() => setOpenModal(!openModal)}
          />
        );
        break;
      case CareerPageModalEnum.BE_IN_TOUCH:
        setContentToShow(
          <BeInTouch
            t={t}
            companyDataSocial={companyDataSocialMediaLinks}
            setCompanyDataSocial={setCompanyDataSocialMediaLinks}
            companyData={companyData}
            setCompanyData={setCompanyData}
            onBack={() => handleOnNextPage(CareerPageModalEnum.OUR_BENEFITS)}
            onSubmit={() => handleOnNextPage(CareerPageModalEnum.CONGRATS)}
            onClose={() => setOpenModal(!openModal)}
          />
        );
        break;
      case ActivationPageEnum.CONGRATS:
        setContentToShow(<Congrats t={t} onSubmit={() => handleOnSubmit()} />);
        break;
    }
  };

  
  useEffect(() => {
    CMSService.getCMS(me?.company);
  }, [me, openModal])

  return (
    <div>
      <div className="matching">
        <div className="matching__header">
          <span className="matching__header-title">Career page</span>
          <Button
            t={t}
            title="Сreate career page "
            className="matching__header-btn"
            onClick={() => setOpenModal(!openModal)}
          />
        </div>
        {openModal && (
          <Popup
            title=""
            onClose={() => {
              setOpenModal(!openModal);
            }}
          >
            <>{openModal && contentToShow}</>
          </Popup>
        )}
      </div>
      {cms?.title && <CareerInfo />}
    </div>
  );
};

export default CareerPage;
