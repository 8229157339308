import React, { FC } from 'react';
import Button from 'components/Button/Index';
import Activation from 'components/feature/activation/Index';

import './Index.scss';

const CompanyReady: FC<any> = ({ t, onSubmit, onBack }): JSX.Element => {
  return (
    <Activation title="Make culture profile" totalSteps={5} currentStep={5}>
      <div className="starter">
        <p className="starter__text">
          Di cattuurproiel word in de matches metkanciaten gebruik om ot even es
          3 tor compatenis ai net meer ae cul bpalen bnnen het bei. Vervlgens
          hurt u mat oe sigers de onderrenebepalen isu ragen of opmertingen nett
          oren wij sat orang!
        </p>
        <div className="buttons">
          <Button className="btn back" t={t} title="back" onClick={onBack} />
          <Button
            className="btn next"
            t={t}
            title="Complete"
            onClick={onSubmit}
          />
        </div>
      </div>
    </Activation>
  );
};

export default CompanyReady;
