export const extractCountryCode = (phone: string): string => {
  const pattern = /^\+(\d{2})(\d+)$/;
  const match = phone.match(pattern);

  if (match) {
    const countryCode = match[1];
    return '+' + countryCode;
  }
  return 'Phone';
};

export const extractPhoneNumber = (phone: string): string => {
  
  return phone.slice(3);
};
