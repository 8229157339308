export function arrayToObject(keys: string[], values: string[] | number[]) {
  const obj: { [key: string]: string | number } = {};
  for (let i = 0; i < keys.length; i++) {
    obj[keys[i]] = values[i];
  }
  return obj;
}

export const containsNestedArray = (arr: any) => {
  for (const element of arr) {
    if (Array.isArray(element)) {
      return true
    } else if (typeof element === 'object' && element !== null) {
      if (containsNestedArray(Object.values(element))) {
        return true;
      }
    }
  }
  return false;
}
