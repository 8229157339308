import React, { memo, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { RootState } from 'redux/store';
import NotificationCard from 'components/NotificationCard/Index';
import Image from '../image/Image';
import Button from '../Button/Index';
import ProfileImage from 'components/ProfileImage';
import useLocales from 'hooks/useLocales';
import { headerDropdownItems } from 'core/constants/header';
import { CompanyService } from 'services/companes/companies';
import Popup from 'components/shared/Popups/Popup/Index';
import SettingsPopup from 'components/shared/Popups/Settings/Index';
import { headerDropdownItemsEnum } from 'core/enum/header-dropdown';
import { LANGS } from 'constants/constants';
import { AuthService } from 'services/auth/auth';
import { NavbarActionEnum } from 'core/enum/navbar-action';
import { NotificationService } from 'services/notifications/notifications';
import DefaultImage from 'components/DefaultImage/Index';
import { IMe } from 'core/interface/me';
import UpgradePlanPopup from 'components/shared/Popups/UpgradePlan/Index';
import { CompanyTabEnum } from 'core/enum/companyTab';

import './index.scss';

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const { t, onChangeLang } = useLocales();
  const me = useSelector((state: IMe) => state.user).user;
  const { companies } = useSelector((state: RootState) => state.compony);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showUpgrade, setShowUpgrade] = useState<boolean>(false);
  const [showDropdown, setShowDropDown] = useState<boolean>(false);

  const [showAction, setShowAction] = useState<string>(
    NavbarActionEnum.SETTINGS
  );
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { unReadCount }: any = useSelector(
    (state: RootState) => state.notifications
  );

  useEffect(() => {
    CompanyService.getCompanies(me?.company);
    getAllNotifications();
  }, []);

  useEffect(() => {
    getNotifications();
  }, [unReadCount]);

  const toggleDropDown = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ): void => {
    e.stopPropagation();
    setShowAction(NavbarActionEnum.SETTINGS);
    setShowDropDown((prev) => !prev);
  };

  const handleLogOut = async (): Promise<void> => {
    try {
      await AuthService.logOut();
      Cookies.remove('accesstoken');
      localStorage.setItem('activeTab', CompanyTabEnum.FIND_CANDIDATES);
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };

  const getNotifications = (): void => {
    NotificationService.getUnreadNotifications();
  };

  const getAllNotifications = (): void => {
    NotificationService.getAllNotifications();
  };

  const handleMembership = () => {
    navigate('/membership');
  };

  const handleDropdownClick = (title: string): void => {
    switch (title.toLocaleLowerCase()) {
      case t(headerDropdownItemsEnum.SETTINGS).toLowerCase():
        setShowSettings(true);
        break;
      case t(headerDropdownItemsEnum.MEMBERSHIP).toLowerCase():
        handleMembership();
        break;
      case t(headerDropdownItemsEnum.SIGN_OUT).toLowerCase():
        handleLogOut();
        break;
      case t(headerDropdownItemsEnum.UPGRADE).toLowerCase():
        setShowUpgrade(true);
        break;
    }
  };

  const handleClosePopup = async (): Promise<void> => {
    setShowSettings(false);
    setShowUpgrade(false);
    AuthService.getAccount();
  };

  const handleLanguageChange = (language: string) => {
    onChangeLang(language);
  };

  const handleOpenNotifications = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ): void => {
    e.stopPropagation();
    setShowAction(NavbarActionEnum.NOTIFICATIONS);
    setShowDropDown((prev) => !prev);
    getAllNotifications();
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        showDropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropDown(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showDropdown]);

  return (
    <nav>
      <div className="navbar">
        <a href="/" className="navbar-logo">
          <Image name={'new-rec-logos.svg'} />
        </a>
        <div className="navbar__left">
          <Button
            onClick={() => setShowUpgrade(true)}
            t={t}
            className="navbar__left-btn"
            title="Upgrade"
          />
          <div className="navbar__left__dropdown">
            <div className="navbar__left__dropdown-btn flex items-center justify-between">
              <span onClick={handleOpenNotifications}>
                {me && me.avatar ? (
                  <ProfileImage
                    notificationCount={
                      unReadCount?.unread_count === 0
                        ? ''
                        : unReadCount?.unread_count
                    }
                    height={44}
                    width={44}
                    name="profile.svg"
                    url={me.avatar}
                  />
                ) : (
                  <DefaultImage
                    className="default-img"
                    height={44}
                    width={44}
                    letter={me?.first_name}
                  />
                )}
              </span>

              <span
                onClick={(e) => toggleDropDown(e)}
                className="navbar__left__dropdown-name"
              >
                {me && me.name ? me.name : ''}
              </span>
              <span onClick={(e) => toggleDropDown(e)}>
                <Image
                  className={`navbar__left__dropdown-arrow ${showDropdown ? 'active' : ''
                    }`}
                  name="arrow.svg"
                />
              </span>
            </div>
            {showDropdown && (
              <>
                <div
                  ref={dropdownRef}
                  className={`navbar__left__dropdown__content ${showAction === NavbarActionEnum.NOTIFICATIONS
                      ? 'notification'
                      : ''
                    }`}
                >
                  {showAction === NavbarActionEnum.SETTINGS && (
                    <div className="navbar__left__dropdown__content__cards">
                      <ul className="navbar__left__dropdown__content__cards__ul">
                        {headerDropdownItems(t).map((item, index) => (
                          <li
                            onClick={() => handleDropdownClick(item.title)}
                            key={index}
                            className={`navbar__left__dropdown__content__cards__ul__li ${item.title === headerDropdownItemsEnum.UPGRADE
                                ? 'upgrade-li'
                                : ''
                              }`}
                          >
                            <Image name={item.icon} width={16} height={16} />
                            <span className="navbar__left__dropdown__content__cards__ul__li-title">
                              {item.title}
                            </span>
                          </li>
                        ))}

                        <li className="navbar__left__dropdown__content__cards__ul__li lang-li">
                          {LANGS.map((language, index) => (
                            <div
                              onClick={() =>
                                handleLanguageChange(language.value)
                              }
                              key={index}
                              className="navbar__left__dropdown__content__cards__ul__li__lang"
                            >
                              <Image
                                name={language.icon1}
                                width={16}
                                height={16}
                              />
                              <span className="navbar__left__dropdown__content__cards__ul__li-title">
                                {language.label}
                              </span>
                            </div>
                          ))}
                        </li>
                      </ul>
                    </div>
                  )}
                  {showAction === NavbarActionEnum.NOTIFICATIONS && (
                    <NotificationCard />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {showSettings && (
        <Popup title={t('settings')} onClose={handleClosePopup}>
          <SettingsPopup handleClosePopup={handleClosePopup} />
        </Popup>
      )}
      {showUpgrade && (
        <Popup
          className="upgrade-plan-nav upgrade-popup-nav"
          title={t('Upgrade your plan')}
          onClose={handleClosePopup}
        >
          <UpgradePlanPopup t={t} />
        </Popup>
      )}
    </nav>
  );
};

export default memo(Navbar);
