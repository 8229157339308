import React, { FC, useEffect, useState } from 'react';
import useLocales from 'hooks/useLocales';
import {
  Table as MUITable,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import OrderDetails from 'components/OrderDetails/Index';
import Image from 'components/image/Image';

import './Index.scss';

const mochData = [
  {
    id: '1',
    date: '10.11.2023 10:10',
    orderNumber: '465316467',
    total: '895',
    paymentStatus: 'payment_open',
    paymentType: 'card'
  },
  {
    id: '2',
    date: '10.11.2023 10:10',
    orderNumber: '465316467',
    total: '895',
    paymentStatus: 'payment_open',
    paymentType: 'card'
  },
  {
    id: '3',
    date: '10.11.2023 10:10',
    orderNumber: '465316467',
    total: '895',
    paymentStatus: 'payment_open',
    paymentType: 'card'
  },
  {
    id: '4',
    date: '10.11.2023 10:10',
    orderNumber: '465316467',
    total: '895',
    paymentStatus: 'payment_open',
    paymentType: 'card'
  },
  {
    id: '5',
    date: '10.11.2023 10:10',
    orderNumber: '465316467',
    total: '895',
    paymentStatus: 'payment_open',
    paymentType: 'card'
  }
];

const OrderHistory: FC = (): JSX.Element => {
  const [data] = useState(mochData);
  const { t } = useLocales();
  const [isPaymentDetail, setIsPaymentDetail] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState({});

  const handleGetOrderHistory = async () => {};

  useEffect(() => {
    handleGetOrderHistory();
  }, []);

  const headerStyle = {
    backgroundColor: '#33B1E8',
    fontSize: 16,
    fontWeight: 600,
    color: '#FFFFFF',
    whiteSpace: 'nowrap'
  };

  const bodyStyle = {
    background: '#EEF5F8',
    boxShadow: 'none',
    color: '#2A2A2A',
    fontSize: 16
  };

  const handlePaymentDetails = (item: any): void => {
    setIsPaymentDetail(true);
    setSelectedItem(item);
  };

  const handleBack = (): void => {
    setIsPaymentDetail(false);
    setSelectedItem({});
  };

  return (
    <>
      {isPaymentDetail ? (
        <OrderDetails item={selectedItem} handleBack={handleBack} />
      ) : (
        <div className="container">
          <div className="title">{t('order_history')}</div>
          <TableContainer
            component={Paper}
            sx={{ border: 'none', boxShadow: 'none' }}
          >
            <MUITable>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerStyle}>{t('date')}</TableCell>
                  <TableCell sx={headerStyle}>{t('order_number')}</TableCell>
                  <TableCell sx={headerStyle}>{t('total')}</TableCell>
                  <TableCell sx={headerStyle}>{t('payment_status')}</TableCell>
                  <TableCell sx={headerStyle}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell sx={bodyStyle}>{row.date}</TableCell>
                    <TableCell
                      sx={{
                        ...bodyStyle,
                        color: '#33B1E8'
                      }}
                    >
                      {row.orderNumber}
                    </TableCell>
                    <TableCell sx={bodyStyle}>€{row.total}</TableCell>
                    <TableCell sx={bodyStyle}>{row.paymentStatus}</TableCell>
                    <TableCell align="center" sx={bodyStyle}>
                      {row.paymentType === 'card' ? (
                        <div onClick={() => handlePaymentDetails(row)} className='card'>
                          <Image
                            width={20}
                            height={20}
                            name="credit-card.svg"
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MUITable>
          </TableContainer>
        </div>
      )}
    </>
  );
};

export default OrderHistory;
