import React, { FC, useState } from 'react';
import Image from 'components/image/Image';
import Level from 'components/Level/Index';
import DropDown from 'components/Dropdown';
import Button from 'components/Button/Index';
import { socialNetwork } from 'core/constants/aboutMePopUpConstants';
import {
  SocialMediaExpertiseType,
  SocialMediaType
} from 'core/interface/socialMediaExpertise';
import { ProfileService } from 'services/profile/profile';
import { useSelector } from 'react-redux';
import { IMe } from 'core/interface/me';
import { ISocialMediaItems } from 'core/interface/socialMedia';
import { SocialMediaExpertiseService } from 'services/socialMediaExpertise/socialMediaExpertise';

import './Index.scss';

const SocialMediaExpertisePopup: FC<SocialMediaExpertiseType> = ({
  socialMedias,
  t,
  onClose
}): JSX.Element => {
  const [loader, setLoader] = useState<boolean>(false);
  const me = useSelector((state: IMe) => state.user).user;
  const [expertise, setExpertise] = useState<SocialMediaType>({
    network: '',
    proficiency: 0
  });

  const handleDelete = (id?: number): void => {
    if (id) {
      SocialMediaExpertiseService.removeSocialMediaExpertise(
        me.candidate_id,
        id
      );
    }
  };

  const handleproficiency = (item: ISocialMediaItems) => {
    return;
  };
  const handleNewproficiency = (value: number) => {
    setExpertise((prev) => (prev = { ...prev, proficiency: value }));
  };
  const handleDropdown = (value: string) => {
    setExpertise((prev) => (prev = { ...prev, network: value }));
  };

  const handleAddExpertise = async (): Promise<void> => {
    setLoader(true);
    await ProfileService.setSocialMediaExpertise(me.candidate_id, expertise);
    onClose && onClose();
    setLoader(false);
  };

  return (
    <div className="medias">
      <div className="medias_existing scrollable">
        {socialMedias.map((item, index) => (
          <div key={index} className="medias_existing__media">
            <div className="medias_existing__media__left">
              <span className="medias_existing__media__left-label">
                Social network
              </span>
              <div className="medias_existing__media__left-social">
                {item.title}
              </div>
            </div>
            <div className="medias_existing__media__middle">
              <span className="medias_existing__media__middle-label">
                Proficiency
              </span>
              <Level
                isReadonly={true}
                level={item.level}
                onClick={() => handleproficiency(item)}
              />
            </div>
            <span
              onClick={() => handleDelete(item.id)}
              className="medias_existing-trash"
            >
              <Image name="trash.svg" width={16} height={16} />
            </span>
          </div>
        ))}
      </div>
      <div className="medias__add">
        <span className="medias__add-title">Add new social network</span>
        <div className="cont">
          <div className="left">
            <span className="medias_existing__media__middle-label">
              Social network
            </span>
            <DropDown
              dropdownClassName="social_dropdown"
              items={socialNetwork}
              selectedValue={(e) => handleDropdown(e)}
            />
          </div>
          <div className="right">
            <span className="medias_existing__media__middle-label">
              Proficiency
            </span>
            <Level
              isReadonly={false}
              level={0}
              onClick={handleNewproficiency}
            />
          </div>
        </div>
      </div>
      <Button
        isLoading={loader}
        onClick={handleAddExpertise}
        t={t}
        className={`save-btn ${
          Object.values(expertise).some((e) => e === '' || e === 0)
            ? 'invalid-btn'
            : ''
        }`}
        title="Save"
      />
    </div>
  );
};

export default SocialMediaExpertisePopup;
