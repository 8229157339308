import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { withTranslation } from 'react-i18next';
import routes, { renderRoutes } from './routes';
import { store } from './redux/store';
import { NotificationService } from 'services/notifications/notifications';
import JwtProvider from 'components/authentication/JwtProvider';
import { JobService } from 'services/jobs/job';
import { ProfileService } from 'services/profile/profile';
import { SkillService } from 'services/skills/skillService';
import { AuthService } from 'services/auth/auth';
import { IUser } from 'core/interface/user';
import { isAuthorized } from 'utils/isAuthorized';
import NotistackProvider from 'components/NotistackProvider';

import './App.scss';

function App({ tReady }: { tReady?: boolean }) {
  const [newUserData, setNewUserData] = useState<IUser | null>(null);
  const [intervalValue, setIntervalValue] = useState<NodeJS.Timeout | null>(
    null
  );
  const isAuth = isAuthorized();
  useEffect(() => {
    getCities();
    getDegrees();
    getIndustries();
    getSkills();
    getSchools();
    getAccount();
  }, []);

  const getUnRead = async () => {
    if (isAuth) {
      await NotificationService.getUnreadCount();
    }
  };

  useEffect(() => {
    getUnRead();
  }, [isAuth]);

  useEffect(() => {
    const interval = setInterval(() => {
      getUnRead();
    }, 300000);

    setIntervalValue(interval);

    return () => {
      if (intervalValue) {
        clearInterval(intervalValue);
      }
    };
  }, []);

  const getAccount = async () => {
    AuthService.getAccount();
  };

  const getCities = async (limit: number = 10, q: string = '') => {
    JobService.getLocations({ limit, q });
  };

  const getIndustries = async (): Promise<void> => {
    ProfileService.getIndustries();
  };

  const getSchools = async (): Promise<void> => {
    ProfileService.getSchools('');
  };

  const getSkills = async (): Promise<void> => {
    SkillService.getSkillsList('');
  };

  const getDegrees = async (): Promise<void> => {
    ProfileService.getDegrees('');
  };

  useEffect(() => {
    store.subscribe(() => {
      setNewUserData(store.getState()?.user?.user);
    });
  }, [store]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <HelmetProvider>
      {/*  eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ReduxProvider store={store}>
        {/*  eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {tReady ? (
          <NotistackProvider>
            <JwtProvider>{renderRoutes(routes, newUserData)}</JwtProvider>
          </NotistackProvider>
        ) : (
          <></>
        )}
      </ReduxProvider>
    </HelmetProvider>
  );
}
export default withTranslation()(App);
