import { IAction } from 'core/interface/action';

export const SAVE_URL = 'save-share-url';

const defaultState = {
  shareURLprofile: null
};

export const SaveSharProfileeURL = (value: string) => ({
  type: SAVE_URL,
  payload: value
});

const shareProfileURLReducer = (state = defaultState, action: IAction<string>) => {
  switch (action.type) {
    case SAVE_URL:
      return {
        ...state,
        shareURLprofile: action.payload
      };
    default:
      return state;
  }
};

export default shareProfileURLReducer;
