import React, { FC, useState } from 'react';
import { useSnackbar } from 'notistack';
import useLocales from 'hooks/useLocales';
import { Form, Formik, FormikHelpers } from 'formik';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import {
  emailValues,
  emailValidation,
  passwordValidation,
  passwordValues
} from './form';
import { IChangeEmail, IChangePassword } from 'core/interface/settings';
import Button from 'components/Button/Index';
import Image from 'components/image/Image';
import { isValidEmail } from 'utils/isValidEmail';
import { AccountServce } from 'services/upload/file-upload';
import { IMe } from 'core/interface/me';
import { useSelector } from 'react-redux';
import { ISettingsPopup } from 'core/interface/profileSettings';
import { AuthService } from 'services/auth/auth';

import './Index.scss';

const ProfileSettings: FC<ISettingsPopup> = ({
  handleClosePopup,
  openAvatarModal
}): JSX.Element => {
  const { t } = useLocales();
  const me = useSelector((state: IMe) => state.user).user;
  const { enqueueSnackbar } = useSnackbar();

  const [loader, setLoader] = useState<boolean>(false);

  const handleEmailChange = (
    values: IChangeEmail,
    actions: FormikHelpers<IChangeEmail>
  ) => {
    if (!isValidEmail(values.email)) {
      actions.setErrors({ email: t('invalid_email') });
    } else {
      updateAccount(values.email);
    }
  };

  const updateAccount = async (newEmail: string): Promise<void> => {
    setLoader(true);
    const body = {
      email: newEmail
    };
    const formData = new FormData();
    for (const key in body) {
      if (key !== 'id') {
        formData.append(key, (body as any)[key]);
      }
    }
    try {
      await AccountServce.updateAccount(me.id, formData);
      handleClosePopup();
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const handlePasswordChange = async (
    values: IChangePassword,
    actions: FormikHelpers<IChangePassword>
  ) => {
    if (values.newPassword !== values.repeatPassword) {
      actions.setErrors({ repeatPassword: t('password_doesnt_match') });
    } else {
      setLoader(true);
      const body = {
        password: values.newPassword
      };
      const formData = new FormData();
      for (const key in body) {
        if (key !== 'id') {
          formData.append(key, (body as any)[key]);
        }
      }
      try {
        if (values.oldPassword && values.newPassword == values.repeatPassword) {
          await AuthService.changePassword({
            old_password: values.oldPassword,
            new_password: values.newPassword
          });
        } else {
          enqueueSnackbar(t('New and repeat passwords should match'), {
            variant: 'error'
          });
        }
        await AccountServce.updateAccount(me.id, formData);
        handleClosePopup();
      } catch (error) {
        console.log(error);
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <div className="profileForm  scrollable ">
      {me?.company && <div className="imageContainer">
        <div className="company__top__left__image">
          <div className="editButton" onClick={openAvatarModal}>
            <Image
              className="edit-img"
              name="penWhite.png"
              width={15}
              height={15}
            />
          </div>
          <Image
            className="company__top__left-image"
            url={me?.avatar}
            name="aws.svg"
            width={243}
            height={145}
          />
        </div>
      </div>}
      <div className="profileForm__email">
        <span className="section-label">{t('change_email')}</span>
        <span className="profileForm__email-email">{me.email}</span>
      </div>
      <div className="profileForm__eform">
        <Formik
          validationSchema={emailValidation(t)}
          initialValues={emailValues}
          onSubmit={(values, actions) => handleEmailChange(values, actions)}
        >
          {({ isValid }) => (
            <Form>
              <div className="mail">
                <span className="label">{t('new_email')}</span>
                <FormField
                  inputClassName="mail__input"
                  fieldName="email"
                  type={InputType.email}
                  placeholder={t('new_email')}
                />
              </div>
              <Button
                isLoading={loader}
                t={t}
                className={`save ${!isValid ? 'invalid-btn' : ''}`}
                type="submit"
                title="Save"
              />
            </Form>
          )}
        </Formik>
      </div>
      <div className="profileForm__pass">
        <span className="section-label">{t('change_pass')}</span>

        <Formik
          validationSchema={passwordValidation(t)}
          initialValues={passwordValues}
          onSubmit={(values, actions) => handlePasswordChange(values, actions)}
        >
          <Form>
            <div className="passForm">
              <span className="label">{t('old_password')}</span>
              <FormField
                inputClassName="passForm__input"
                fieldName="oldPassword"
                type={InputType.password}
                placeholder={t('old_password')}
              />
            </div>
            <div className="repeats">
              <div className="repeats__field">
                <span className="label">{t('new_password')}</span>
                <FormField
                  inputClassName="passForm__input"
                  fieldName="newPassword"
                  type={InputType.password}
                  placeholder={t('new_password')}
                />
              </div>
              <div className="repeats__field">
                <span className="label">{t('repeat_password')}</span>
                <FormField
                  inputClassName="passForm__input"
                  fieldName="repeatPassword"
                  type={InputType.password}
                  placeholder={t('repeat_password')}
                />
              </div>
            </div>
            <Button t={t} className="change_pass" type="submit" title="Save" />
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ProfileSettings;
