import React, { FC, useEffect, useState } from 'react';
import ContentBlock from 'components/ContentBlock/Index';
import AboutMeContent from 'components/shared/AboutMe/Index';
import AboutAndShare from 'components/shared/AboutAndShare/Index';
import ExtraActivities from 'components/shared/ExtraActivities';
import SocialMediaExpertise from 'components/shared/SocialMediaExpertise';
import AboutMePopUp from 'components/shared/Popups/AboutMe/Index';
import Popup from 'components/shared/Popups/Popup/Index';
import SocialMediaExpertisePopup from 'components/shared/Popups/SocialMediaExpertise/Index';
import ExtraActivitiesPopup from 'components/shared/Popups/ExtraActivities/Index';
import { IAboutMeTab, IAboutUser } from 'core/interface/aboutMe';
import { LanguageService } from 'services/language';
import { CandidateService } from 'services/candidate/candidate';
import { useSelector } from 'react-redux';
import { IMe } from 'core/interface/me';
import { Gender } from 'core/enum/gender';
import { ExtraActivitieService } from 'services/extraActivities/extraActivities';
import { SocialMediaExpertiseService } from 'services/socialMediaExpertise/socialMediaExpertise';
import {
  ISocialMediaData,
  ISocialMediaItems
} from 'core/interface/socialMedia';
import { ProfileService } from 'services/profile/profile';
import { SocialMediaEnum } from 'core/enum/social-media';
import Tooltip from 'components/shared/Tooltip/Index';
import { AuthService } from 'services/auth/auth';
import { SocialMediaRedux } from 'core/interface/socialMediaExpertise';
import { RootState } from 'redux/store';

import './Index.scss';

const AboutMeTab: FC<IAboutMeTab> = ({ t, candidate_id }): JSX.Element => {
  const me = useSelector((state: IMe) => state.user).user;
  const { socialMedia } = useSelector(
    (state: SocialMediaRedux) => state.socialMediaExpertise
  );
  const { candidate_language } = useSelector(
    (state: RootState) => state.languages
  );
  const { extraActivities } = useSelector(
    (state: RootState) => state.extraActivites
  );
  const { candidates } = useSelector((state: RootState) => state.candidates);
  const [userAbout, setUserAbout] = useState<IAboutUser[]>([]);
  const [socialMediaExpertise, setSocialMediaExpertise] = useState<
    ISocialMediaItems[]
  >([]);
  const [socialNetwork, setSocialNetwork] = useState<string>('');
  const [showAboutMePopup, setShowAboutMePopup] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showExpertisePopup, setShowExpertisePopup] = useState<boolean>(false);
  const [showExtraActivitiesPopup, setShowExtraActivitiesPopup] =
    useState<boolean>(false);
  const { socialMedia: media } = useSelector(
    (state: RootState) => state.socialMedia
  );

  useEffect(() => {
    if (candidate_id) {
      getLanguages();
      getCandidate();
      getSocialMedias();
      getExtraActivities();
      getSocialMediaExpertise();
    }
  }, [candidate_id]);

  useEffect(() => {
    setMyData();
  }, [me, socialNetwork, candidate_language]);

  const getCandidate = () => {
    CandidateService.getCandidate(candidate_id);
  };

  const handleToggle = (
    stateSetter: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    return () => stateSetter((prevState) => !prevState);
  };

  const setMyData = async () => {
    const candidate = [
      {
        icon: 'email.svg',
        value: me.email,
        iconSize: 12
      },
      {
        icon: 'calendar.svg',
        value: me.birthday,
        iconSize: 12
      },
      {
        icon: 'gender.svg',
        value: Gender[me.gender],
        iconSize: 12
      },
      {
        icon: 'location.svg',
        value: candidates?.locations?.[0]?.display_name,
        iconSize: 12
      },
      {
        icon: 'phone.svg',
        value: me.phone,
        iconSize: 12
      }
      // {
      //   icon: 'computer.svg',
      //   value: 'Blog RRS alan.akhlal+0098@gmail.com',
      //   iconSize: 12
      // }
    ].filter((item) => item.value) as IAboutUser[];
    setUserAbout(candidate);
  };

  const onClose = (): void => {
    setShowAboutMePopup(false);
    setShowExpertisePopup(false);
    setShowExtraActivitiesPopup(false);
    setIsEdit(false);
    AuthService.getAccount();
  };

  const getExtraActivities = (): void => {
    ExtraActivitieService.getExtraActivities(candidate_id);
  };

  const getSocialMediaExpertise = () => {
    SocialMediaExpertiseService.getSocialMediaExpertise(candidate_id);
  };

  useEffect(() => {
    const items = [] as ISocialMediaItems[];
    socialMedia?.forEach((item: any) => {
      const socialItem = {
        id: item.id,
        icon: SocialMediaEnum[
          item.network.toLocaleLowerCase() as keyof typeof SocialMediaEnum
        ],
        level: item.proficiency,
        title: item.network
      } as ISocialMediaItems;
      items.push(socialItem);
    });
    setSocialMediaExpertise(items);
  }, [socialMedia]);

  const getLanguages = (): void => {
    LanguageService.getCandidateLanguage(candidate_id);
  };

  useEffect(() => {
    const socialNetworks = media?.reduce(
      (acc: string, elem: ISocialMediaData) => {
        acc += elem.network + ',';
        return acc;
      },
      ''
    );
    setSocialNetwork(socialNetworks);
  }, [media]);

  const getSocialMedias = async () => {
    ProfileService.getSocialMedias(candidate_id);
  };

  return (
    <div className="about">
      <ContentBlock
        className="about__left"
        title={t('about_me')}
        onActionClick={handleToggle(setShowAboutMePopup)}
        noData={userAbout.length === 0 || !userAbout}
      >
        <AboutMeContent
          surname={me.last_name}
          name={me.first_name}
          about={candidates?.pitch ?? ''}
          aboutMe={userAbout}
        />
      </ContentBlock>

      <div className="about__right">
        <ContentBlock
          showButton={false}
          title="Jouw kaartje om te delen"
          className="about__right__top"
        >
          <AboutAndShare
            description="These are the top skills of Karim de tester. To find your own talents visit Recrout.com "
            t={t}
          />
        </ContentBlock>

        <div className="about__right__bottom">
          <ContentBlock
            className="about__right__bottom__left"
            title={t('extra_activities')}
            onActionClick={handleToggle(setShowExtraActivitiesPopup)}
            noData={extraActivities?.length === 0 || !extraActivities}
          >
            <ExtraActivities />
          </ContentBlock>
          <ContentBlock
            className="about__right__bottom__right"
            title={t('social_media_expertise')}
            isScrollable={true}
            heigth="120px"
            onActionClick={handleToggle(setShowExpertisePopup)}
            noData={socialMediaExpertise.length === 0 || !socialMediaExpertise}
          >
            <SocialMediaExpertise socialMedia={socialMediaExpertise} />
          </ContentBlock>
        </div>
      </div>
      {showAboutMePopup && (
        <Popup onClose={onClose} title="About Me">
          <AboutMePopUp t={t} onModalClose={() => onClose()} />
        </Popup>
      )}
      {showExpertisePopup && (
        <Popup
          className="expertise"
          onClose={onClose}
          title="Social media expertise"
        >
          <SocialMediaExpertisePopup
            t={t}
            socialMedias={socialMediaExpertise}
            onClose={onClose}
          />
        </Popup>
      )}
      {showExtraActivitiesPopup && (
        <Popup
          className={`${isEdit ? 'extra' : ''}`}
          title="Extra activities"
          onClose={onClose}
        >
          <ExtraActivitiesPopup isExtra={setIsEdit} t={t} onClose={onClose} />
        </Popup>
      )}
      {/* <Popup className='confirm' title='Are you sure?' onClose={onClose}>
        <ConfirmPopup description='Do you really want to delete this? De vacature?' onApprove={() => {}} onCancel={() => {}}/>
      </Popup> */}
    </div>
  );
};

export default AboutMeTab;
