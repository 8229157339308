import React, { FC } from 'react';
import { ISingleMessage } from 'core/interface/message';

import './Index.scss';

const SingleMessage: FC<ISingleMessage> = ({
  date,
  message,
  subtitle,
  className
}): JSX.Element => {
  return (
    <div className={`message ${className}`}>
      <span className="message-title">{message}</span>
      <span className="message-subtitle">{subtitle}</span>
      <span className="message-date">{date}</span>
    </div>
  );
};

export default SingleMessage;
