import { IHeaderDropdownItems, IHeaderItems } from '../interface/headerItems';
import { DashboardTabEnum, ProfileTabEnum } from '../enum/dashboardTab';
import { TFunction } from 'i18next';
import { headerDropdownItemsEnum } from 'core/enum/header-dropdown';

const headerItems = (
  t: TFunction<'translation', undefined>
): IHeaderItems[] => {
  return [
    {
      title: 'about_me',
      isActive: true,
      value: DashboardTabEnum.ABOUT_ME
    },
    {
      title: t('Talentprofile'),
      isActive: false,
      value: DashboardTabEnum.PREFERENCES
    },
    {
      title: 'Skills',
      isActive: false,
      value: DashboardTabEnum.SKILLS
    },
    {
      title: 'Career and School',
      isActive: false,
      value: DashboardTabEnum.EDUCATION_EXPERIENCE
    },
    {
      title: 'Personal Branding',
      isActive: false,
      value: DashboardTabEnum.IMAGE_YOUTUBE
    }
  ];
};

const headerItemsProfile = (
  t: TFunction<'translation', undefined>
): IHeaderItems[] => {
  return [
    {
      title: 'about_me',
      isActive: true,
      value: ProfileTabEnum.ABOUT_ME
    },
    {
      title: t('Competency scores and Preferences'),
      isActive: false,
      value: ProfileTabEnum.COMPETENCY_SCORES
    },
    {
      title: 'Skills',
      isActive: false,
      value: ProfileTabEnum.SKILLS
    },
    {
      title: 'Education and experience',
      isActive: false,
      value: ProfileTabEnum.EDUCATION_EXPERIENCE
    },
    {
      title: 'Image and You Tube video’s ',
      isActive: false,
      value: ProfileTabEnum.IMAGE_YOUTUBE
    }
  ];
};

const headerDropdownItems = (
  t: TFunction<string, undefined>
): IHeaderDropdownItems[] => {
  return [
    {
      icon: 'settings.svg',
      title: t(headerDropdownItemsEnum.SETTINGS)
    },
    {
      icon: 'member.svg',
      title: t(headerDropdownItemsEnum.MEMBERSHIP)
    },
    {
      icon: 'out.svg',
      title: t(headerDropdownItemsEnum.SIGN_OUT)
    },
    {
      icon: 'upgrade.svg',
      title: t(headerDropdownItemsEnum.UPGRADE)
    }
  ];
};

export { headerItems, headerDropdownItems, headerItemsProfile };
