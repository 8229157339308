import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Navbar from 'components/Navbar';
import Image from 'components/image/Image';
import DropDown from 'components/Dropdown';
import Button from 'components/Button/Index';
import AboutMeTab from 'components/Tabs/AboutMe/Index';
import { IDropDownItem } from 'core/interface/dropDown';
import { ILanguage } from 'core/interface/language';
import CompenecyPreferencesTab from 'components/Tabs/CompetencyPreferences/Index';
import SkillsTab from 'components/Tabs/Skills/Index';
import ImageAndYoutubeTab from 'components/Tabs/ImageAndYoutube/Index';
import EducationExperienceTab from 'components/Tabs/EducationAndExperience/Index';
import { headerItems } from 'core/constants/header';
import { IHeaderItems } from 'core/interface/headerItems';
import { DashboardTabEnum } from 'core/enum/dashboardTab';
import AvatarPopup from 'components/shared/Popups/Avatar/Index';
import { Popup } from 'components/shared/Popups/Popup/Index';
import useLocales from '../../hooks/useLocales';
import Footer from 'components/Footer/Index';
import { useSelector } from 'react-redux';
import { IMe } from 'core/interface/me';
import FreeUpgradePopup from 'components/shared/Popups/FreeUpgrade/Index';
import Tooltip from 'components/shared/Tooltip/Index';
import TeaserViewPopup from 'components/shared/Popups/TeaserView/Index';
import { ISocialMediaData } from 'core/interface/socialMedia';
import { SwitcherEnum } from 'core/enum/switcherEnum';
import SkeletonLoader from 'components/shared/Skeleton/Index';
import { AccountServce } from 'services/upload/file-upload';
import { CandidateService } from 'services/candidate/candidate';
import DefaultImage from 'components/DefaultImage/Index';
import DownloadPDFPopup from 'components/shared/Popups/DownloadPDF/Index';
import Modal from 'components/shared/modal';
import { isValidEmail } from 'utils/isValidEmail';
import { RootState } from '../../redux/store';
import { ShareUrlService } from 'services/shareUrl/shareURL';
import { InvitationsService } from 'services/invitations/invitations';

import './index.scss';
import { Link } from 'react-router-dom';
import { JobService } from 'services/jobs/job';

const Dashboard: FC<ILanguage> = (): JSX.Element => {
  const { t } = useLocales();
  const { socialMedia } = useSelector((state: RootState) => state.socialMedia);
  const { candidate } = useSelector((state: RootState) => state.candidate);
  const [showAvatarPopup, setShowAvatarPopup] = useState<boolean>(false);
  const [showTeaserPopup, setShowTeaserPopup] = useState<boolean>(false);
  const [showDownload, setShowDownload] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [showFreeUpgradePopup, setShowFreeUpgradePopup] =
    useState<boolean>(false);
  const [tabHeader, setTabHeader] = useState<IHeaderItems[]>(headerItems(t));
  const [tabName, setTabName] = useState<string>(DashboardTabEnum.ABOUT_ME);
  const me = useSelector((state: IMe) => state.user).user;
  const uploadFile = useRef<HTMLInputElement>(null);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [inviteEmail, setInviteEmail] = useState<string>('');
  const [inviteEmailError, setInviteEmailError] = useState<string>('');
  const [tooltipProfile, setTooltipProfile] = useState<string>(
    'Share your Recrout profile with this link'
  );
  const dropDownItems: IDropDownItem[] = [
    { value: t('teaser'), to_show: t('teaser') }
  ];
  const [tooltips, setTooltips] = useState({
    hide: false,
    link: false
  });

  const candidates = useSelector(
    (state: RootState) => state.candidates
  ).candidates;

  const { shareURLprofile } = useSelector(
    (state: RootState) => state.profileURL
  );

  const [snack, setSnack] = useState({
    message: '',
    isOpen: false
  });

  const handleInviteInputChange = (e: string): void => {
    setInviteEmail(e);
    if (!isValidEmail(e)) {
      setInviteEmailError(t('Invalid email'));
    } else {
      setInviteEmailError('');
    }
  };

  useEffect(() => {
    if (me && me.candidate_id) {
      getCandidate();
      getProfileLink();
      getJobById(28)
    }
  }, [me]);

  useEffect(() => {
    getURL();
  }, [me]);

  const getURL = async () => {
    await ShareUrlService.createShareURL(me.candidate_id);
    await ShareUrlService.getShareURL(me.candidate_id);
  };

  const updateCandidate = async (isAnonym: number): Promise<void> => {
    const body = {
      access: Boolean(isAnonym)
    };
    CandidateService.updateCandidate(body, me.candidate_id);
  };

  const handleCloseFreeUpdragePopup = (): void => {
    setShowFreeUpgradePopup(false);
  };

  const handleOpenFreeUpgradePopup = (): void => {
    setShowFreeUpgradePopup(true);
  };

  const handleDropdownChange = (event: string | number): void => {
    if (event === t('teaser')) {
      setShowTeaserPopup(true);
    }
  };

  const handleClosePopup = (): void => {
    setShowAvatarPopup(false);
    setShowTeaserPopup(false);
  };

  const handleOpenPopup = (): void => {
    setShowAvatarPopup(true);
  };

  const getCandidate = async (): Promise<void> => {
    CandidateService.getCandidate(me.candidate_id);
  };

  const handleLinkClick = async (): Promise<void> => {
    // try {
    //   const response = await ShareUrlService.createShareURL(me.candidate_id);
    //   console.log(response);
    // } catch (error) {
    //   try {
    //     const resp = await ShareUrlService.getShareURL(me.candidate_id);
    //     console.log(resp);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
  };

  const handleTabChange = (clickedItem: IHeaderItems): void => {
    setTabHeader((prevValues) =>
      prevValues.map((item) =>
        item === clickedItem
          ? { ...item, isActive: true }
          : { ...item, isActive: false }
      )
    );
    if (clickedItem.value) {
      setTabName(clickedItem.value);
    }
  };

  const handleSwitchChange = (e: SwitcherEnum): void => {
    updateCandidate(e);
  };

  const getProfileLink = async (): Promise<void> => {
    // try {
    //   const response = await ShareUrlService.createShareURL(
    //     Number(me?.id)
    //   );
    //   console.log(response);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file: File = fileInput.files[0];
      if (me && me.candidate_id) {
        setUploadLoading(true);
        const cvFormData = new FormData();
        cvFormData.append('cv', file);
        await AccountServce.uploadCV(me.candidate_id, cvFormData, t);
        setUploadLoading(false);
      } else {
        console.error('No file selected');
      }
    }
  };

  const showSelectedTab = (): JSX.Element | null => {
    if (me) {
      switch (tabName) {
        case DashboardTabEnum.ABOUT_ME:
          return <AboutMeTab t={t} candidate_id={me.candidate_id} />;
        case DashboardTabEnum.PREFERENCES:
          return <CompenecyPreferencesTab t={t} />;
        case DashboardTabEnum.SKILLS:
          return <SkillsTab t={t} candidate_id={me.candidate_id} />;
        case DashboardTabEnum.IMAGE_YOUTUBE:
          return <ImageAndYoutubeTab t={t} />;
        case DashboardTabEnum.EDUCATION_EXPERIENCE:
          return (
            <EducationExperienceTab t={t} candidate_id={me.candidate_id} />
          );
        default:
          return <AboutMeTab t={t} candidate_id={me.candidate_id} />;
      }
    }
    return <></>;
  };

  const handleTooltipVisibility = (tooltipName: string, isShow: boolean) => {
    setTooltips((prevState) => ({
      ...prevState,
      [tooltipName]: isShow
    }));
    // setShowTooltip(isShow);
  };

  const getJobById = (jobId: number | string) => {
    JobService.getJobByIdOrSlug(jobId);
  }

  const handleSendInvitataion = async () => {
    await InvitationsService.setInvitations({ email: inviteEmail });
    setInviteEmail('');
  };

  return (
    <>
      <Navbar />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        className="modal_container"
      >
        <div className="modal_content">
          <div
            className="close_section"
            onClick={() => setOpenModal(!openModal)}
          >
            <Image
              name="close.svg"
              width={20}
              height={20}
              className="close_image"
            />
          </div>
          <div>
            <h1 className="title">Earn your free upgrade!</h1>
            <div className="line" />
            <p className="description">
              We value the growth of our community of Young Professionals more
              than earning lots of money. That is why we have a unique action
              for our current members: If you help us grow this great talent
              community by inviting your friends to join, we will reward you
              with free upgrades of your account.
            </p>
            <p className="description">
              Invite only 3 of your friends and you will receive a free three
              month premium upgrade
            </p>
            <p className="description">
              Invite just 6 friends and receive a free six months premium
              upgrade
            </p>
            <p className="description">
              Invite 10 of your friends and you will be rewarder with a free
              year prescription!
            </p>
            <p className="description">
              To earn your free upgrades it does not matter if your friends take
              a free account or premium account. As soon as they sign up we add
              the points to your name. It will only cost you a few seconds of
              investment so start inviting right now!
            </p>
          </div>
        </div>
      </Modal>
      <div className="dashboard">
        <div className="dashboard__main">
          <div className="dashboard__left">
            <div className="dashboard__left__image">
              {!me ? (
                <SkeletonLoader />
              ) : (
                <>
                  {me && me.avatar ? (
                    <Image
                      className="dashboard__left__image-img"
                      name="profile-image.png"
                      width={236}
                      height={236}
                      url={me?.avatar}
                    />
                  ) : (
                    <DefaultImage
                      width={236}
                      height={236}
                      className="dashboard__left__image-img"
                      letter={me.first_name}
                    />
                  )}

                  <div
                    onClick={handleOpenPopup}
                    className="dashboard__left__image-edit"
                  >
                    <Image
                      className="dashboard__left__image-edit-pen"
                      name="pen.png"
                    />
                  </div>
                </>
              )}
            </div>
            <div className="dashboard__left__content">
              <Tooltip
                className="dashboard__left__hide_show__tool"
                message="Determine the visibility of your profile"
                isShow={tooltips.hide}
              />
              <div
                className="dashboard__left__swiper"
                onMouseEnter={() => handleTooltipVisibility('hide', true)}
                onMouseLeave={() => handleTooltipVisibility('hide', false)}
              >
                <span
                  onClick={() => handleSwitchChange(SwitcherEnum.SHOW_ME)}
                  className={`dashboard__left__swiper-btn ${
                    !candidates?.access ? 'active' : ''
                  }`}
                >
                  {t('show_me')}
                </span>
                <span
                  onClick={() => handleSwitchChange(SwitcherEnum.HIDE_ME)}
                  className={`dashboard__left__swiper-btn ${
                    candidates?.access ? 'active' : ''
                  }`}
                >
                  {t('hide_me')}
                </span>
              </div>
              <div
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => {
                  setShowTooltip(false);
                  setTooltipProfile(
                    'Share your Recrout profile with this link'
                  );
                }}
                className="dashboard__left__link"
              >
                <Tooltip
                  className="dashboard__left__link__tool"
                  message={tooltipProfile}
                  isShow={showTooltip}
                />
                <input
                  className="dashboard__left__link-input"
                  type="text"
                  readOnly
                  value={shareURLprofile ?? ''}
                  placeholder={t('profile_link')}
                  onClick={(e) => {
                    navigator.clipboard.writeText(
                      (e.target as HTMLInputElement).value
                    );
                    setTooltipProfile('Copied !');
                  }}
                />
                <span onClick={handleLinkClick}>
                  <Image
                    className="dashboard__left__link-refresh"
                    name="refresh.svg"
                    width={12}
                    height={12}
                  />
                </span>
              </div>
              <DropDown
                isReadonly={true}
                showClose={false}
                defaultValue="1"
                dropdownClassName="dashboard__left-dropdown view-drop"
                items={dropDownItems}
                selectedValue={handleDropdownChange}
              />
              <div className="dashboard__left__contact">
                <div className="dashboard__left__contact__user">
                  <div className="dashboard__left__contact__user__icon">
                    <Image
                      className="dashboard__left__contact__user__icon-img"
                      name="email.svg"
                      width={12}
                      height={12}
                    />
                  </div>
                  <a
                    href={`mailto:?subject=Share%20user%20URL&body=Dear%20colleague%0D%0A%0D%0APlease%20review%20this%20candidate%20profile%20and%20let%20me%20know%20what%20you%20think%3F%0D%0A%0D%0A${encodeURIComponent(
                      shareURLprofile ?? ''
                    )}%0D%0A%0D%0AThank%20you!`}
                    target="_blank"
                    rel="noreferrer"
                    className="dashboard__left__contact__user-contact"
                  >
                    {me?.email}
                  </a>
                </div>
                {me?.phone && (
                  <div className="dashboard__left__contact__user">
                    <div className="dashboard__left__contact__user__icon">
                      <Image
                        className="dashboard__left__contact__user__icon-img"
                        name="phone.svg"
                        width={12}
                        height={12}
                      />
                    </div>
                    <span className="dashboard__left__contact__user-contact">
                      {me.phone}
                    </span>
                  </div>
                )}
              </div>
              <div className="dashboard__left__social">
                {socialMedia &&
                  socialMedia.map((item: ISocialMediaData) => (
                    <a href={item.url} key={item.id} target="blank">
                      <Image
                        key={item.id}
                        className="dashboard__left__social-icons"
                        width={31}
                        height={31}
                        name={`${item.network}.svg`}
                      />
                    </a>
                  ))}
              </div>
              <div className="dashboard__left__actions">
                <Button
                  isLoading={uploadLoading}
                  onClick={() =>
                    (uploadFile.current as HTMLInputElement).click()
                  }
                  t={t}
                  iconName="upload.svg"
                  className="dashboard__left__actions-purple"
                  title={t('upload_cv')}
                />
                <Button
                  // disabled={uploadLoading || !candidate?.cv}
                  onClick={() => setShowDownload(true)}
                  t={t}
                  iconName="resume.svg"
                  className="dashboard__left__actions-sky"
                  title={t('recrout_resume')}
                />
                <Button
                  t={t}
                  iconName="report.svg"
                  className="dashboard__left__actions-green"
                  onClick={() => {
                    if (candidate?.report_urls?.feedback) {
                      window.open(candidate?.report_urls?.feedback, 'blank');
                    }
                  }}
                  title={t('assessment_report')}
                />
              </div>
            </div>
          </div>
          <div className="dashboard__right">
            <div className="dashboard__right__top">
              <div className="dashboard__right__top__left">
                <div className="dashboard_info_title">
                  <h1
                    className="dashboard__right__top__left-title"
                    onClick={handleOpenFreeUpgradePopup}
                  >
                    {t('invite_friends')}
                  </h1>
                  <div
                    onClick={() => setOpenModal(!openModal)}
                    className="info_image_container"
                  >
                    <Image className="info_image" name="attention.svg" />
                  </div>
                </div>
                <p className="dashboard__right__top__left-desc">
                  {t(
                    'Upgrade or expand your account by inviting at least three of your friends to join you at recrout'
                  )}
                </p>
                <div className="dashboard__right__top__left__email">
                  <div className="inp">
                    <input
                      onInput={(e) =>
                        handleInviteInputChange(
                          (e.target as HTMLInputElement).value
                        )
                      }
                      className="dashboard__right__top__left__email-email"
                      type="email"
                      value={inviteEmail}
                      placeholder={t('email_address')}
                    />
                    {inviteEmailError && (
                      <span className="error">{inviteEmailError}</span>
                    )}
                  </div>

                  <Button
                    disabled={inviteEmail === '' || inviteEmailError !== ''}
                    t={t}
                    onClick={() => handleSendInvitataion()}
                    title="invite"
                    type="submit"
                    className={`dashboard__right__top__left__email-submit ${
                      inviteEmail === '' || inviteEmailError !== ''
                        ? 'deactive'
                        : ''
                    }`}
                  />
                </div>
                <Link
                  className="dashboard__right__top__left__email-invitations"
                  to={'/invitations/'}
                >
                  All invitations
                </Link>
              </div>
              <div className="dashboard__right__top__right">
                <Button
                  onClick={() => navigate('/applications')}
                  t={t}
                  className="dashboard__right__top__right__button app"
                  title="my_applications"
                />
                <Button
                  onClick={() => navigate('/filter-job')}
                  t={t}
                  className="dashboard__right__top__right__button job"
                  title="find_job"
                  iconName="search.svg"
                />
              </div>
            </div>
            <div className="dashboard__right__bottom">
              <div className="dashboard__right__bottom__tabs">
                <div className="dashboard__right__bottom__tabs__header">
                  {tabHeader.map((item, index) => (
                    <div key={index}>
                      <div className="dashboard__right__bottom__tabs__header__item">
                        <span
                          onClick={() => handleTabChange(item)}
                          className={`dashboard__right__bottom__tabs__header__item-title ${
                            item.isActive ? 'active' : ''
                          }`}
                        >
                          {t(item.title)}
                          <span className="line"></span>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="dashboard__right__bottom-tab">
                {showSelectedTab()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAvatarPopup && (
        <Popup title="Edit Your Profile Image" onClose={handleClosePopup}>
          <AvatarPopup
            id={me.candidate_id}
            t={t}
            onPopupClose={handleClosePopup}
          />
        </Popup>
      )}
      {showTeaserPopup && (
        <Popup
          className="view_as"
          title="This is the teaser that company get to see before they decide to contact you or accept your application. It’s your first business card, keep that in mind when creating your profile!"
          onClose={handleClosePopup}
        >
          <TeaserViewPopup />
        </Popup>
      )}

      {showFreeUpgradePopup && (
        <Popup title={t('free_upgrade')} onClose={handleCloseFreeUpdragePopup}>
          <FreeUpgradePopup />
        </Popup>
      )}

      {showDownload && (
        <Popup
          title={t('Download PDF')}
          description={t('Please select the file you would like to download')}
          onClose={() => setShowDownload(false)}
        >
          <DownloadPDFPopup setIsClose={() => setShowDownload(false)} />
        </Popup>
      )}

      <input
        className="invisible"
        onChange={handleInputChange}
        ref={uploadFile}
        type="file"
        accept=".pdf"
      />
      <Footer />
    </>
  );
};

export default Dashboard;
