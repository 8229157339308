import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import useLocales from 'hooks/useLocales';
import Navbar from 'components/Navbar';
import Image from 'components/image/Image';
import Footer from 'components/Footer/Index';
import Popup from 'components/shared/Popups/Popup/Index';
import UpgradePlanPopup from 'components/shared/Popups/UpgradePlan/Index';
import RecruitMembership from 'components/RecruitMembership/Index';
import OrderHistory from 'components/OrderHistory/Index';
import { SubscribtionPlansService } from 'services/subscribtionPlans/subscribtionPlans';
import { PageType } from 'core/enum/page';
import { IMe } from 'core/interface/me';

import './Index.scss';

const Membership: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useLocales();
  const me = useSelector((state: IMe) => state.user).user;
  const [pageType, setPageType] = useState<PageType>(
    PageType.RECRUIT_MEMBERSHIP
  );
  const [isPlanModalOpen, setIsPlanModalOpne] = useState<boolean>(false);

  const changePageType = (type: PageType) => {
    setPageType(type);
  };

  const handleGetPlanData = async () => {
    try {
      const response = await SubscribtionPlansService.getPlansData();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleGetPlanData();
  }, []);

  return (
    <>
      <Navbar />
      <div className="membership">
        <div className="navigation_section">
          <div
            className="back_button"
            onClick={() =>
              navigate(me?.role == 'admin' ? '/company' : '/dashboard')
            }
          >
            <Image name="arrow-left.svg" />
            {t('back_to_dashboard')}
          </div>
          <div className="membership_navigate">
            <div
              className="membership_item"
              onClick={() => changePageType(PageType.RECRUIT_MEMBERSHIP)}
            >
              <Image name="member.svg" />
              {t('my_recruit_membership')}
            </div>
            <div
              className="membership_item"
              onClick={() => setIsPlanModalOpne(true)}
            >
              <Image name="topRadioArrow.svg" />
              {t('upgrade')}
            </div>
            <div
              className="membership_item"
              onClick={() => changePageType(PageType.ORDER_HISTORY)}
            >
              <Image name="document.svg" />
              {t('order_history')}
            </div>
          </div>
        </div>
        <div className="main_section">
          {pageType === PageType.RECRUIT_MEMBERSHIP ? (
            <RecruitMembership />
          ) : (
            <OrderHistory />
          )}
        </div>
      </div>
      {isPlanModalOpen && (
        <Popup
          title="Upgrade your plan"
          onClose={() => setIsPlanModalOpne(false)}
          className="plan-modal"
        >
          <UpgradePlanPopup t={t} />
        </Popup>
      )}
      <div className="footer_section">
        <Footer />
      </div>
    </>
  );
};

export default Membership;
