import React, { FC, useEffect, useState } from 'react';
import { ICheckbox, ICheckboxItem } from 'core/interface/checkboxes';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, FormGroup, FormControlLabel } from '@mui/material';

import './index.scss';

const Checkboxes: FC<ICheckbox> = ({
  checkboxes: initialCheckboxes,
  className,
  onArrayChange
}): JSX.Element => {
  const [boxes, setBoxes] = useState<ICheckboxItem | ICheckboxItem[]>(
    initialCheckboxes
  );

  const handleCheckboxChange = (item: ICheckboxItem) => {
    if (Array.isArray(boxes)) {
      const newState = boxes.map((box) => {
        if (item.label === box.label) {
          
          return { ...box, isChecked: !item.isChecked };
        }
        return box;
      });
      setBoxes(newState);
      onArrayChange && onArrayChange(newState);
    } else {
      setBoxes({ ...boxes, isChecked: !boxes.isChecked });
    }
  };

  useEffect(() => {
    setBoxes(initialCheckboxes);
  }, [initialCheckboxes]);

  return (
    <div className="box">
      <FormControl className={className} component="fieldset">
        <FormGroup aria-label="position" row>
          {Array.isArray(boxes) ? (
            boxes.map((item, index) => (
              <div key={index} className="item">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      color="secondary"
                      checked={item.isChecked}
                      onChange={() => handleCheckboxChange(item)}
                    />
                  }
                  label={item.label}
                  labelPlacement="end"
                />
              </div>
            ))
          ) : (
            <div className="item">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    color="secondary"
                    checked={boxes.isChecked}
                    onChange={() => handleCheckboxChange(boxes)}
                  />
                }
                label={boxes.label}
                labelPlacement="end"
              />
            </div>
          )}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default Checkboxes;
