import { IAction } from 'core/interface/action';
import { INotificationData } from 'core/interface/notificationCard';

export const SAVE_VALUES = 'save-notifications';
export const SAVE_VALUES_All = 'save-notifications-all';
export const SAVE_VALUES_COUNT = 'save-notifications-count';

const defaultState = {
  notifications: null,
  allNotifications: [],
  unReadCount: 0
};

export const Savenotifications = (value: INotificationData) => ({
  type: SAVE_VALUES,
  payload: value
});

export const SavenotificationsAll = (value: INotificationData) => ({
  type: SAVE_VALUES_All,
  payload: value
});

export const SavenotificationsCount = (value: number) => ({
  type: SAVE_VALUES_COUNT,
  payload: value
});

const notificationsReducer = (
  state = defaultState,
  action: IAction<INotificationData | number>
) => {
  switch (action.type) {
    case SAVE_VALUES:
      return {
        ...state,
        notifications: action.payload
      };
    case SAVE_VALUES_All:
      return {
        ...state,
        allNotifications: action.payload
      };
    case SAVE_VALUES_COUNT:
      return {
        ...state,
        unReadCount: action.payload
      };
    default:
      return state;
  }
};

export default notificationsReducer;
