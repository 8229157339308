import { enqueueSnackbar } from 'notistack';
import ApiBase from '../base/apiBase';
import { store } from '../../redux/store';
import { SaveRequest, SaveSingleRequest } from '../../redux/slices/requests';
import { prepareUpdatedData } from 'helpers/stateChanges';

export class RequestsService {
  private static apiBase = new ApiBase<any>();

  public static async getRequest(
    idOrSlug?: string | number
  ): Promise<any | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/candidates/profile-requests/?jobID=${idOrSlug || ''}`
      );
      if (idOrSlug) {
        dispatch(SaveSingleRequest(res));
      } else {
        dispatch(SaveRequest(res));
      }
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async changeRequest(
    body: any,
    idOrSlug?: string | number,
    single?: string
  ): Promise<any | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.updateAsync(
        `/candidates/profile-requests/${idOrSlug || ''}/`,
        body
      );
      if (single) {
        const updatedState = prepareUpdatedData(
          'update',
          'requests',
          res,
          'singleRequest'
        );

        dispatch(SaveSingleRequest(updatedState));
      } else {
        const updatedState = prepareUpdatedData(
          'update',
          'requests',
          res,
          'request'
        );
        dispatch(SaveRequest(updatedState));
      }
      if (body?.status === 4) {
        enqueueSnackbar('Request Cancelled', {
          variant: 'success'
        });
      }
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async askRequest(body?: any): Promise<any | null> {
    try {
      const res = await this.apiBase.postAsync(
        `/candidates/profile-requests/`,
        body
      );
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
