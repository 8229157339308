import React, { FC, useEffect, useState } from 'react';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import ActionButton from 'components/ActionButton/Index';
import ContentBlock from 'components/ContentBlock/Index';
import { ISkillsTab } from 'core/interface/skills';
import { IMe } from 'core/interface/me';
import { SkillService } from 'services/skills/skillService';
import Popup from 'components/shared/Popups/Popup/Index';
import { LanguageService } from 'services/language';
import SkillsBlock from 'components/shared/SkillsBlock/Index';
import SkillPopup from 'components/shared/Popups/Skills/Index';
import ITSkills from 'components/shared/ITskill/Index';

import './Index.scss';

const SkillsTab: FC<ISkillsTab> = ({
  t,
  candidate_id,
  fromGuest
}): JSX.Element => {
  const me = useSelector((state: IMe) => state.user).user;
  const [showAddHardSkill, setShowAddHardSkill] = useState<boolean>(false);
  const [showAddSoftSkill, setShowAddSoftSkill] = useState<boolean>(false);
  const [showEditHardSkill, setShowEditHardSkill] = useState<boolean>(false);
  const [showEditSoftSkill, setShowEditSoftSkill] = useState<boolean>(false);
  const [resend, setResend] = useState<boolean>(false);
  const { skills } = useSelector((state: RootState) => state.skills);
  const { candidates } = useSelector((state: RootState) => state.candidates);

  useEffect(() => {
    getSkills();
  }, []);

  useEffect(() => {
    if (resend) {
      getSkills();
      handleClose();
      setResend(false);
    }
  }, [resend]);

  useEffect(() => {
    getLanguages();
  }, [me]);

  const handleClose = (): void => {
    setShowAddHardSkill(false);
    setShowAddSoftSkill(false);
    setShowEditHardSkill(false);
    setShowEditSoftSkill(false);
    return;
  };

  const getSkills = async (): Promise<void> => {
    if (candidate_id) {
      await SkillService.getSkills(candidate_id);
    }
  };

  const getLanguages = async (): Promise<void> => {
    await LanguageService.getCandidateLanguage(me.candidate_id);
  };

  return (
    <div className="skills-block-side">
      <ContentBlock
        className="skill-block"
        title={t('Hard Skills')}
        noData={candidates?.hard_skills === 0}
        onActionClick={() => setShowEditHardSkill(true)}
        showButton={fromGuest ? false : true}
        secondButton={
          fromGuest ? (
            <></>
          ) : (
            <ActionButton
              onClick={() => setShowAddHardSkill(true)}
              iconName="plus.svg"
            />
          )
        }
      >
        <div className="skill-block-content">
          {candidates?.hard_skills?.map((skill: any) => (
            <div key={skill.id} className="blocks">
              <SkillsBlock title={skill} />
            </div>
          ))}
        </div>
      </ContentBlock>
      <ContentBlock
        className="skill-block soft-skills"
        title={t('Soft Skills')}
        noData={candidates?.soft_skills === 0}
        onActionClick={() => setShowEditSoftSkill(true)}
        showButton={fromGuest ? false : true}
        secondButton={
          fromGuest ? (
            <></>
          ) : (
            <ActionButton
              onClick={() => setShowAddSoftSkill(true)}
              iconName="plus.svg"
            />
          )
        }
      >
        <div className="skill-block-content">
          {candidates?.soft_skills?.map((skill: any) => (
            <div key={skill.id} className="blocks">
              <SkillsBlock title={skill} />
            </div>
          ))}
        </div>
      </ContentBlock>
      {(showEditHardSkill || showEditSoftSkill) && (
        <Popup title="Edit Skill" onClose={handleClose}>
          <ITSkills
            skill={skills}
            t={t}
            skillType={showEditHardSkill ? 'Hard Skills' : 'Soft Skills'}
          />
        </Popup>
      )}
      {(showAddHardSkill || showAddSoftSkill) && (
        <Popup
          title={showAddHardSkill ? 'Hard Skills' : 'Soft Skills'}
          className="expertise"
          onClose={handleClose}
        >
          <SkillPopup
            handleClose={handleClose}
            t={t}
            showAddButton={true}
            skill={[
              {
                description: '',
                id: 0,
                proficiency: 0,
                skill: { id: '0', name: '' }
              }
            ]}
            skillType={showAddHardSkill ? 'Hard Skills' : 'Soft Skills'}
          />
        </Popup>
      )}
    </div>
  );
};

export default SkillsTab;
