import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IFilterJob, IJobCities, IJobFilters } from 'core/interface/job';
import useLocales from '../../../hooks/useLocales';
import { JobService } from 'services/jobs/job';
import { getCareerLevelLabels, getSalaryLabels } from 'constants/constants';
import Checkboxes from 'components/shared/Checkbox/Index';
import Autocomplete from 'components/shared/Autocomplete/Index';
import Button from 'components/Button/Index';
import { ProfileService } from 'services/profile/profile';
import DropDown from 'components/Dropdown';
import { degreeTypes } from 'core/constants/aboutMePopUpConstants';
import { RootState } from 'redux/store';
import { ILocationRedux, IndustryRedux } from 'core/interface/education';
import { SkillService } from 'services/skills/skillService';
import { SearchInitialValue } from 'core/interface/searchInitialValue';
import { Industries } from 'core/interface/education-side';
import { IAutocompleteItem } from 'core/interface/autocompleteItem';
import { Form, Formik } from 'formik';

import styles from './index.module.scss';

const searchInitialValue: SearchInitialValue<
  Industries[] | IAutocompleteItem[] | IJobCities | null
> = {
  industry: {
    value: '',
    interval: null,
    func: (query: string) => ProfileService.getIndustries(query)
  },
  computer_skill: {
    value: '',
    interval: null,
    func: (query: string) => SkillService.getSkillsList(query)
  },
  city: {
    value: '',
    interval: null,
    func: (query: string) =>
      JobService.getLocations({
        q: query
      })
  }
};
const JobFilters: FC<IFilterJob> = ({ applyFilter }): JSX.Element => {
  const { t } = useLocales();
  const industries = useSelector(
    (selector: IndustryRedux) => selector.industries?.industry || []
  );
  const { computerSkills: skills } = useSelector(
    (selector: RootState) => selector.computerSkills || []
  );
  const cities = useSelector(
    (selector: ILocationRedux) => selector.locations?.cities || []
  );

  const [resetDropdown, setResetDropdown] = useState<boolean>(false);
  const [values, setValues] = useState<IJobFilters>({
    industry: '',
    degree_type: '',
    computer_skill: '',
    city: {
      id: 0,
      name: ''
    },
    salary: [],
    career_level: []
  });
  const [search, setSearch] =
    useState<
      SearchInitialValue<
        Industries[] | IAutocompleteItem[] | IJobCities | IJobCities | null
      >
    >(searchInitialValue);

  const handleCheckbox = (e: string, key: string): void => {
    const value = values[key] as string[];
    const index = value.indexOf(e);
    if (index !== -1) {
      value.splice(index, 1);
    } else {
      value.push(e);
    }

    setValues({
      ...values,
      [key]: value
    });
  };

  const onInputChange = (e: string, type: string): void => {
    const searchNewData = { ...searchInitialValue };
    searchNewData[type].value = e;
    if (searchNewData[type].interval) {
      clearTimeout(searchNewData[type].interval as NodeJS.Timeout);
    }
    searchNewData[type].interval = setTimeout(() => {
      searchNewData[type].func(searchNewData[type].value);
    }, 500);
    setSearch(searchNewData);
  };

  useEffect(() => {
    Object.values(search).map((e) => {
      e.func(e.value);
    });
  }, []);

  const handleFilter = (): void => {
    const val = {
      ...values
    };
    applyFilter(val);
  };

  const handleLoadMoreCities = () => {
    const params = { limit: 10, offset: cities.length };
    JobService.getLocations(params, true);
  };

  const handleResetFilters = () => {
    const val = {
      industry: '',
      degree_type: '',
      computer_skill: '',
      city: {
        id: 0,
        name: ''
      },
      salary: [],
      career_level: [],
      location: ''
    };

    setValues(val);
    applyFilter(val);
    setResetDropdown(true);
    setSearch(searchInitialValue);
  };

  return (
    <Formik
      className={styles.filters}
      initialValues={{}}
      onSubmit={(e) => console.log(e)}
    >
      <Form className={styles.filters}>
        <div className={styles.filters}>
          <h1>{t('Filter')}</h1>
          <div className={styles.filters_checkbox}>
            <h1 className="label">{t('Industry')}</h1>
            <Autocomplete
              fieldName="industry"
              show_arrow={true}
              value={
                industries.find((industry) => industry.id === +values.industry)
                  ?.name ?? ''
              }
              options={
                industries?.map((e) => ({
                  id: e?.id,
                  title: e?.name
                })) || []
              }
              show_location={false}
              placeholder={t('Industry')}
              onChange={(e) => {
                const activeItem = String(
                  industries.find((industry) => industry.name === e)?.id
                );
                setValues((prev) => ({
                  ...prev,
                  industry: activeItem
                }));
                onInputChange(e, 'industry');
              }}
              onInputChange={(e) => onInputChange(e, 'industry')}
              values={industries}
            />
          </div>
          <div className={styles.filters_checkbox}>
            <h1 className="label">{t('Degree Type')}</h1>
            <DropDown
              onReset={resetDropdown}
              defaultValue={values.degree_type}
              placeholder="Degree type"
              dropdownClassName={`dashboard__left-dropdown ${styles.filters_dropdown}`}
              items={degreeTypes}
              selectedValue={(e) =>
                setValues((prev) => ({ ...prev, degree_type: e }))
              }
            />
          </div>

          <div className={styles.filters_checkbox}>
            <h1 className="label">{t('Computer skill')}</h1>
            <Autocomplete
              fieldName="computer_skill"
              show_arrow={true}
              value={
                skills?.find(
                  (skill: IAutocompleteItem) =>
                    skill.id === +values.computer_skill
                )?.name ?? ''
              }
              options={
                skills?.map((e: IAutocompleteItem) => ({
                  id: e?.id,
                  title: e?.name
                })) || []
              }
              show_location={false}
              placeholder={t('Computer skill')}
              onChange={(e) => {
                const activeItem = String(
                  skills?.find((skill: IAutocompleteItem) => skill.name === e)
                    ?.id
                );
                setValues((prev) => ({
                  ...prev,
                  computer_skill: activeItem
                }));
                onInputChange(e, 'computer_skill');
              }}
              onInputChange={(e) => onInputChange(e, 'computer_skill')}
              values={skills}
            />
          </div>
          <div className={styles.filters_checkbox}>
            <h1 className="label">{t('City')}</h1>
            <Autocomplete
              fieldName="city"
              show_arrow={true}
              value={values.city.name}
              options={
                cities?.map((e) => ({
                  id: e?.id,
                  title: e?.display_name
                })) || []
              }
              show_location={true}
              placeholder={t('Location')}
              onChange={(city) => {
                const activeItem = cities.find(
                  (cities) => cities.display_name === city
                );
                setValues({
                  ...values,
                  city: {
                    id: activeItem?.id,
                    name: activeItem?.display_name
                  }
                });
                onInputChange(city, 'city');
              }}
              onInputChange={(e) => onInputChange(e, 'city')}
              values={cities}
              loadMore={handleLoadMoreCities}
            />
          </div>
          <div className={styles.filters_checkbox}>
            <h1>{t('Salary')}</h1>
            {getSalaryLabels(values.salary).map((e, i) => (
              <Checkboxes
                checkboxes={e}
                onArrayChange={(e) => {
                  if (Array.isArray(e)) {
                    handleCheckbox(e[0].value ?? e[0].label, 'salary');
                  }
                }}
                key={i}
              />
            ))}
          </div>
          <div className={styles.filters_checkbox}>
            <h1>{t('Career level')}</h1>
            {getCareerLevelLabels(values.career_level).map((e, i) => (
              <Checkboxes
                checkboxes={e}
                onArrayChange={(e) => {
                  if (Array.isArray(e)) {
                    handleCheckbox(e[0].value ?? e[0].label, 'career_level');
                  }
                }}
                key={i}
              />
            ))}
          </div>
          <div>
            <Button
              t={t}
              className={`navbar__left-btn ${styles.filters_submit} ${styles.filters_buttons}`}
              title="Filter"
              onClick={handleFilter}
            />
          </div>
          <div className={styles.filters_reset_button}>
            <Button
              t={t}
              className={`navbar__left-btn ${styles.filters_reset} ${styles.filters_buttons}`}
              title="Reset Filters"
              onClick={handleResetFilters}
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default JobFilters;
