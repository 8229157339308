import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik, FormikHelpers } from 'formik';
import { initialValues, validationSchema } from './form';
import { ICompanyInfo } from 'core/interface/matchProfile';
import useLocales from 'hooks/useLocales';
import Autocomplete from 'components/shared/Autocomplete/Index';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import Button from 'components/Button/Index';
import { JobService } from 'services/jobs/job';
import { RootState } from '../../../../redux/store';
import Activation from '../Index';

import './Index.scss';

const CompanyInfoSecond = ({
  onBack,
  onSubmit,
  setCompanyData
}: any): JSX.Element => {
  const { t } = useLocales();

  const { cities } = useSelector((state: RootState) => state.locations);

  const handleSubmit = async (
    values: ICompanyInfo,
    actions: FormikHelpers<ICompanyInfo>
  ): Promise<void> => {
    setCompanyData((prev: any) => ({
      ...prev,
      ...values
    }));
    onSubmit();
  };

  const getCities = async (q: string, limit: number = 10) => {
    JobService.getLocations({
      limit,
      q
    });
  };

  return (
    <Activation title="Nice to meet you" currentStep={4} totalSteps={5}>
      <>
        <h4 className="company-info">
          Please tell us a little bit more about you and your company
        </h4>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={validationSchema(t)}
        >
          {({ setFieldValue, values }) => (
            <Form className="form">
              <div className="field">
                <span className="label">Job title</span>
                <FormField
                  inputClassName="input"
                  fieldName="contact_job_title"
                  placeholder="title"
                  type={InputType.text}
                />
              </div>
              <div className="field">
                <span className="label">Company address</span>
                <Autocomplete
                  fieldName="address"
                  className="auto"
                  value={values.company_address}
                  options={
                    cities?.map((e: any) => ({
                      id: e?.id,
                      title: e?.display_name
                    })) || []
                  }
                  show_location={true}
                  placeholder={t('Company adress')}
                  onInputChange={(e) => {
                    getCities(e);
                    setFieldValue(
                      'address',
                      cities?.find((city: any) => city.display_name === e)
                        ?.display_name ?? ''
                    );
                  }}
                  values={cities}
                  onFocus={(e) => {
                    getCities(e);
                  }}
                />
              </div>
              <div className="av__content__buttons ">
                <Button
                  onClick={onBack}
                  t={t}
                  title="Back"
                  className="av__content__buttons-back"
                />
                <Button
                  type="submit"
                  t={t}
                  title="Next"
                  className="av__content__buttons-next"
                />
              </div>
            </Form>
          )}
        </Formik>
      </>
    </Activation>
  );
};

export default CompanyInfoSecond;
