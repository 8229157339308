import { IAction } from 'core/interface/action';
import { IAutocompleteItem } from 'core/interface/autocompleteItem';

export const SAVE_DEGREE = 'save-degrees';

const defaultState = {
  degrees: null
};

export const SaveDegrees = (value: IAutocompleteItem[]) => ({
  type: SAVE_DEGREE,
  payload: value
});

const degreeReducer = (
  state = defaultState,
  action: IAction<IAutocompleteItem[]>
) => {
  switch (action.type) {
    case SAVE_DEGREE:
      return {
        ...state,
        degrees: action.payload
      };
    default:
      return state;
  }
};

export default degreeReducer;
