import { ICandidateInvite } from 'core/interface/contact';
import ApiBase from '../base/apiBase';
import { IAutocompleteItem } from 'core/interface/autocompleteItem';
import { IEducation, IEducationBody } from 'core/interface/education';
import { SocialMediaType } from 'core/interface/socialMediaExpertise';
import { Industries } from 'core/interface/education-side';
import { ISocialMediaData } from 'core/interface/socialMedia';
import { IPreferencesData } from 'core/interface/preferences';
import { store } from '../../redux/store';
import { SaveIndustries } from '../../redux/slices/industries';
import { SaveSchools } from '../../redux/slices/schools';
import { SaveDegrees } from '../../redux/slices/degrees';
import { prepareUpdatedData } from '../../helpers/stateChanges';
import { SaveMediaExpertise } from '../../redux/slices/socialMediaExpertise';
import { SaveSocialMedia } from '../../redux/slices/socialMedia';
import { SaveEducations } from '../../redux/slices/educations';

export class ProfileService {
  public static apiBase = new ApiBase<FormData | ICandidateInvite>();

  public static async getIndustries(
    industry?: string
  ): Promise<Industries[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/fixtures/industries/?q=${industry?.trim() || ''}&lang=en`
      );
      dispatch(SaveIndustries(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async getDegrees(
    degree: string
  ): Promise<IAutocompleteItem[] | null> {
    const { dispatch } = store;
    try {
      const response = await this.apiBase.getAsync(
        `/fixtures/degrees/?q=${degree}`
      );
      dispatch(SaveDegrees(response));
      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async getSchools(
    school: string
  ): Promise<IAutocompleteItem[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/fixtures/schools/?q=${school}`
      );
      dispatch(SaveSchools(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async editEducation(
    candidateId: number,
    education_id: number,
    education: IEducationBody
  ): Promise<IEducation | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.updateAsync(
        `/candidates/${candidateId}/educations/${education_id}/`,
        education
      );
      const updatedState = prepareUpdatedData(
        'update',
        'educations',
        res,
        'educations'
      );
      dispatch(SaveEducations(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async removeEducation(
    candidateId: number,
    education_id: number
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.deleteAsync(
        `/candidates/${candidateId}/educations/${education_id}/`
      );
      const updatedState = prepareUpdatedData(
        'delete',
        'educations',
        {
          id: education_id
        },
        'educations'
      );
      dispatch(SaveEducations(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async setSocialMediaExpertise(
    candidateId: number,
    expertise: SocialMediaType
  ): Promise<SocialMediaType | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.postAsync(
        `/candidates/${candidateId}/social-media-expertises/`,
        expertise
      );
      const updatedState = prepareUpdatedData(
        'create',
        'socialMediaExpertise',
        res,
        'socialMedia'
      );
      dispatch(SaveMediaExpertise(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async search(body: FormData): Promise<any> {
    return await this.apiBase.postAsync('en/accounts/youtube/search/', body);
  }

  public static async getSocialMedias(
    candidateId: number
  ): Promise<ISocialMediaData[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/candidates/${candidateId}/social-media/`
      );
      dispatch(SaveSocialMedia(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async postSocialMedias(
    candidateId: number,
    params: ISocialMediaData
  ): Promise<ISocialMediaData | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.postAsync(
        `/candidates/${candidateId}/social-media/`,
        params
      );
      const updatedState = prepareUpdatedData(
        'create',
        'socialMedia',
        res,
        'socialMedia'
      );
      dispatch(SaveSocialMedia(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async updateSocialMedias(
    candidateId: number,
    network_id: number,
    params: ISocialMediaData
  ): Promise<ISocialMediaData | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.updateAsync(
        `/candidates/${candidateId}/social-media/${network_id}/`,
        params
      );
      const updatedState = prepareUpdatedData(
        'update',
        'socialMedia',
        res,
        'socialMedia'
      );
      dispatch(SaveSocialMedia(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async deleteSocialMedia(
    candidateId: number,
    network_id: number
  ): Promise<void> {
    const { dispatch } = store;
    try {
      await this.apiBase.deleteAsync(
        `/candidates/${candidateId}/social-media/${network_id}/`
      );
      const updatedState = prepareUpdatedData(
        'delete',
        'socialMedia',
        { id: network_id },
        'socialMedia'
      );
      dispatch(SaveSocialMedia(updatedState));
    } catch (error) {
      console.log(error);
    }
  }

  public static async getPreferences(
    accountId: number,
    body: IPreferencesData
  ): Promise<void> {
    return await this.apiBase.updateAsync(`/candidates/${accountId}/`, body);
  }
}
