import React, { FC, useState } from 'react';
import { IFaqItem } from 'core/interface/fAQItems';
import Image from 'components/image/Image';

import './index.scss';

const FAQItems: FC<IFaqItem> = ({
  title,
  description,
  isCollapsed = false,
  number,
  className
}): JSX.Element => {
  const [collapse, setCollapse] = useState<boolean>(isCollapsed);

  const handleToggle = (): void => {
    setCollapse((prev) => !prev);
  };

  return (
    <div className={`faqItem ${className ?? ''} ${collapse ? 'active' : ''}`}>
      <div className="faqItem__item">
        <span className="faqItem__item-index">{number}</span>
        <div className="faqItem__item__between">
          <span
            className={`faqItem__item__between-header ${collapse ? 'active' : ''
              } ${collapse ? 'faqItem__item__between-header-active' : ''
              }`}
          >
            {title}
          </span>
          {collapse && (
            <p className="faqItem__item__between-desc">{description}</p>
          )}
        </div>
        <span
          onClick={handleToggle}
          className={`faqItem__item__between-plus ${collapse ? 'active' : ''}`}
        >
          {!collapse ? (
            <Image name="pluse.svg" width={14} height={14} />
          ) : (
            <Image name="closes.svg" width={14} height={14} />
          )}
        </span>
      </div>
    </div>
  );
};

export default FAQItems;
