import { IAction } from 'core/interface/action';
import { ISharedLinks } from 'core/interface/candidate';

export const SAVE_VALUES = 'save-matchProfile';
export const SAVE_VALUES_CULTURE = 'save-cultureProfile';
export const SAVE_ONE = 'save-matchProfile-one';
export const SAVE_OVERVIEW = 'save-overview';
export const SAVE_OVERVIEW_ONE = 'save-overview-one';
export const SAVE_SEARCH = 'save-search';
export const SAVE_MATCH_PROFILE_CANDIDATE = 'save-match-profile-candidate';

interface DefaultState {
  matchProfile: any | null;
  matchProfileOne: any | null;
  matchOverView: any | null;
  matchOverViewOne: any | null;
  matchSearch: any | null;
  cultureProfile: any | null;
  matchProfileCandidate: any | null;
}

const defaultState: DefaultState = {
  matchProfile: null,
  matchProfileOne: null,
  matchOverView: null,
  matchOverViewOne: null,
  matchSearch: null,
  cultureProfile: null,
  matchProfileCandidate: null
};

export const SaveMatchProfile = (value: any) => ({
  type: SAVE_VALUES,
  payload: value
});

export const SaveCultureProfile = (value: any) => ({
  type: SAVE_VALUES_CULTURE,
  payload: value
});

export const SaveMatchProfileOne = (value: any) => ({
  type: SAVE_ONE,
  payload: value
});

export const SaveOverview = (value: any) => ({
  type: SAVE_OVERVIEW,
  payload: value
});

export const SaveOverviewOne = (value: any) => ({
  type: SAVE_OVERVIEW_ONE,
  payload: value
});

export const SaveSearch = (value: any) => ({
  type: SAVE_SEARCH,
  payload: value
});

export const SaveMatchProfileCandidate = (value: any) => ({
  type: SAVE_MATCH_PROFILE_CANDIDATE,
  payload: value
});

const matchProfileReducer = (
  state: DefaultState = defaultState,
  action: IAction<any>
): DefaultState => {
  switch (action.type) {
    case SAVE_VALUES:
      return {
        ...state,
        matchProfile: action.payload
      };
    case SAVE_VALUES_CULTURE:
      return {
        ...state,
        cultureProfile: action.payload
      };
    case SAVE_ONE:
      return {
        ...state,
        matchProfileOne: action.payload
      };
    case SAVE_OVERVIEW:
      return {
        ...state,
        matchOverView: action.payload
      };
    case SAVE_OVERVIEW_ONE:
      return {
        ...state,
        matchOverViewOne: action.payload
      };
    case SAVE_SEARCH:
      return {
        ...state,
        matchSearch: action.payload
      };
    case SAVE_MATCH_PROFILE_CANDIDATE:
      return {
        ...state,
        matchProfileCandidate: action.payload
      };
    default:
      return state;
  }
};

export default matchProfileReducer;
