import React, { FC, useEffect, useState } from 'react';
import Image from 'components/image/Image';
import { industryItems } from 'core/constants/industryItems';
import CompetenciesBlock from 'components/shared/CompetenciesBlock/Index';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { CompetencieService } from 'services/competencies/competencies';
import { ISkills } from 'core/interface/skills';
import { ChartData } from 'chart.js';

import './Index.scss';
import {
  calculateMatchPercentage,
  consolidateCategories,
  getMatchingScores
} from 'helpers/teaserView';

const TeaserViewPopup: FC = (): JSX.Element => {
  const [candidateView, setCandidateView] = useState<any>();
  const [teaserChart, setTeaserChart] = useState<ChartData>();
  const [teaserCultureChart, setTeaserCultureChart] = useState<ChartData>();
  const [talantMatchPercent, setTalantMatchPercent] = useState<
    number | undefined | string
  >();
  const [cultureMatchPercent, setCultureMatchPercent] = useState<
    number | undefined | string
  >();

  const candidate = useSelector(
    (state: RootState) => state.candidate
  ).candidate;
  const languages = useSelector(
    (state: RootState) => state.languages
  ).candidate_language;
  const educations = useSelector(
    (state: RootState) => state.educations
  ).educations;
  const skills = useSelector((state: RootState) => state.skills).skills;

  const { singleJob } = useSelector((state: RootState) => state.jobs);
  const assessment = useSelector((state: RootState) => state.assessment);

  useEffect(() => {
    const cultureCompetencies = singleJob?.culture_profile?.competencies;
    let lastAssessment = null;
    for (let i = 0; i < assessment?.assessmentData?.length; i++) {
      const assessmentReport = assessment?.assessmentData[i]?.report;
      if (assessmentReport) {
        lastAssessment = assessmentReport;
        break;
      }
    }

    if (!lastAssessment) return;

    const userCompetencies = consolidateCategories(lastAssessment.competencies);

    const jobsMatchDataSet = getMatchingScores(
      singleJob?.competencies,
      userCompetencies
    );


    const cultureMatchDataSet = getMatchingScores(
      singleJob?.culture_profile?.competencies,
      userCompetencies
    );

    const cultureCompetenciesDataSet = cultureCompetencies?.map(
      (item: any) => item.min_score
    );
    
    const talantMatch = calculateMatchPercentage(
      jobsMatchDataSet,
      singleJob?.competency_min_scores
    );

    const cultureMatch = calculateMatchPercentage(
      cultureMatchDataSet,
      cultureCompetenciesDataSet
    );

    if (talantMatch) setTalantMatchPercent(talantMatch);
    if (cultureMatch) setCultureMatchPercent(cultureMatch);

    const teaserChart: ChartData = {
      labels: singleJob?.competency_labels.map((item: string) =>
        item.split(' ')
      ),

      datasets: [
        {
          label: 'Job',
          data: singleJob?.competency_min_scores,
          backgroundColor: 'rgba(0, 158, 226, 0.50)',
          borderColor: '#009EE2',
          borderWidth: 1
        },
        {
          label: 'Candidate',
          data: jobsMatchDataSet,
          backgroundColor: 'rgba(82, 37, 131, 0.50)',
          borderColor: '#522583',
          borderWidth: 1
        }
      ]
    };
    const teaserCultureChart: ChartData = {
      labels: singleJob?.culture_profile?.competencies?.map((competency: any) =>
        competency.competency.name.split(' ')
      ),
      datasets: [
        {
          label: 'Job',
          data: cultureCompetenciesDataSet,
          backgroundColor: 'rgba(0, 158, 226, 0.50)',
          borderColor: '#009EE2',
          borderWidth: 1
        },
        {
          label: 'Candidate',
          data: cultureMatchDataSet,
          backgroundColor: 'rgba(82, 37, 131, 0.50)',
          borderColor: '#522583',
          borderWidth: 1
        }
      ]
    };
    setTeaserChart(teaserChart);
    setTeaserCultureChart(teaserCultureChart);
  }, [singleJob]);

  useEffect(() => {
    setCandidateView((prev: any) => ({
      ...prev,
      ...candidate,
      languages: languages?.map((item) => item.language.name).join(', '),
      educations: educations?.map((e) => e.study.name).join(', '),
      skills: skills?.map((skill: ISkills) => skill.skill.name).join(', ')
    }));
    getCompetencies();
  }, [candidate, languages, educations]);

  const getCompetencies = () => {
    CompetencieService.getCompetencies();
  };

  return (
    <div className="teaser">
      <div className="teaser__top">
        <div className="teaser__top__profile teaser-block">
          <Image
            className="teaser__top__profile-avatar"
            url={candidate?.avatar}
            width={115}
            height={115}
          />
          <span className="teaser__top__profile-role about-item">
            {candidate?.name}
          </span>
          {talantMatchPercent && (
            <span className="teaser__top__profile-talent about-item">
              {talantMatchPercent}% Talent Match
            </span>
          )}
          {cultureMatchPercent && (
            <span className="teaser__top__profile-talent about-item sec">
              {cultureMatchPercent}% Cultural Fit
            </span>
          )}

          <p className="teaser__top__profile-about about-item">
            {candidate?.pitch}
          </p>
        </div>
        <div className="teaser__top__industry teaser-block">
          {candidateView &&
            industryItems(candidateView).map((item, index) => (
              <div key={index} className="teaser__top__industry__item">
                <span className="teaser__top__industry__item-img">
                  <Image
                    className="industry-image"
                    name={item.image}
                    width={14}
                    height={14}
                  />
                </span>
                <div className="teaser__top__industry__item__cont">
                  <span className="teaser__top__industry__item__cont-title">
                    {item.title}
                  </span>
                  <span className="teaser__top__industry__item__cont-desc">
                    {item.desc}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="teaser__bottom">
        <CompetenciesBlock
          className="teaser__bottom-left"
          percent={talantMatchPercent}
          title="Match job competencies"
          chartData={teaserChart}
        />

        <CompetenciesBlock
          className="teaser__bottom-right"
          percent={cultureMatchPercent}
          title="Match culture competencies"
          chartData={teaserCultureChart}
        />
      </div>
    </div>
  );
};

export default TeaserViewPopup;
