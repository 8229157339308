import React, { FC } from 'react';
import Button from 'components/Button/Index';
import { ITranslate } from 'core/interface/translate';

import './Index.scss';

const SocialMedia: FC<ITranslate> = ({ t }): JSX.Element => {
  return (
    <div className="social">
      <Button
        t={t}
        className="linkedin"
        iconName="in.svg"
        title={t('Connect with Linkedin')}
      />
      <Button
        t={t}
        className="facebook"
        iconName="fb.svg"
        title={t('Connect with Facebook')}
      />
    </div>
  );
};

export default SocialMedia;
