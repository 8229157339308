import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik, FormikHelpers } from 'formik';
import {
  ICertifications,
  ICertificationsForm
} from 'core/interface/certifications';
import { RootState } from 'redux/store';
import { initialValues, validations } from './form';
import Image from 'components/image/Image';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import DropDown from 'components/Dropdown';
import { years } from 'core/constants/aboutMePopUpConstants';
import Checkboxes from '../../Checkbox/Index';
import Button from 'components/Button/Index';
import { CertificateService } from 'services/certificates/certificateService';
import { IMe } from 'core/interface/me';
import {
  ICertificationBody,
  ICertificationData
} from 'core/interface/certification';
import ActionButton from 'components/ActionButton/Index';

import './Index.scss';

const CertificationsPopup: FC<ICertifications> = ({
  t,
  handleClose
}): JSX.Element => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const me = useSelector((state: IMe) => state.user).user;
  const [initialValue, setInitialValue] =
    useState<ICertificationsForm>(initialValues);
  const [receivedCertificate, setReceivedCertificate] = useState<boolean>(
    initialValue.receivedCertificate
  );
  const [showForm, setShowForm] = useState<boolean>(false);
  const { certifications } = useSelector(
    (state: RootState) => state.certifications
  );

  const handleSubmit = async (
    values: ICertificationsForm,
    actions: FormikHelpers<ICertificationsForm>
  ) => {
    setShowLoader(true);

    const body: ICertificationBody = {
      title: values.courseTitle,
      institute: values.institute,
      recieved: values.receivedCertificate,
      year: values.yearAttended
    };

    const initial = Object.values(initialValue).filter(
      (item) => item !== false || item === true
    );

    if (initial.every((item) => item !== '' && initialValue.id)) {
      await CertificateService.updateCertification(
        me.candidate_id,
        Number(initialValue.id),
        body
      );
    } else {
      await CertificateService.addCertificate(me.candidate_id, body);
    }
    if (handleClose) handleClose(true);
    setShowLoader(false);
  };

  const removeCertification = async (id: number): Promise<void> => {
    setShowLoader(true);
    await CertificateService.removeCertification(me.candidate_id, id);
    if (handleClose) handleClose(true);
    setShowLoader(false);
  };

  const handleEdit = (item?: ICertificationData): void => {
    if (item) {
      setReceivedCertificate(item.recieved);
      const selectedItem: ICertificationsForm = {
        id: item.id,
        courseTitle: item.title,
        institute: item.institute,
        yearAttended: String(item.year),
        receivedCertificate: item.recieved
      };
      setInitialValue(selectedItem);
      setShowForm(true);
    }
  };

  return (
    <div className="certification scrollable">
      {!showForm &&
        certifications.length !== 0 &&
        certifications?.map((item: ICertificationData) => (
          <div key={item.id} className="certification__all">
            <div className="certification__all__left">
              <span className="certification__all__left-title s-hidden ">
                {item.title}
                <span className="certification__all__left-title-year">
                  {item.year}
                </span>
              </span>
              {item.recieved && (
                <span className="certification__all__left-type">
                  Received certificate!
                </span>
              )}
            </div>
            <div className="certification__all__right">
              <span
                onClick={() => removeCertification(item.id)}
                className="certification__all__right-action"
              >
                <Image name="trash.svg" />
              </span>
              <span
                onClick={() => handleEdit(item)}
                className="certification__all__right-action"
              >
                <Image name="edit-pen.svg" />
              </span>
            </div>
          </div>
        ))}

      {!showForm && certifications.length !== 0 && (
        <div onClick={() => setShowForm(true)} className="add-experience">
          <ActionButton
            className="action-add"
            onClick={() => setShowForm(true)}
            iconName="plus.svg"
          />
          <span className="add-experience-title">
            {t('Add New Certificate')}
          </span>
        </div>
      )}
      {(showForm || certifications.length === 0) && (
        <div className="certification__form">
          <Formik
            enableReinitialize
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            initialValues={initialValue}
            validationSchema={validations(t)}
          >
            {({
              setFieldValue,
              isValid,
              values,
              errors,
              touched,
              setFieldTouched
            }) => (
              <Form>
                <div className="field">
                  <span className="label">Course title</span>
                  <FormField
                    inputClassName="input"
                    fieldName="courseTitle"
                    placeholder="Course title"
                    type={InputType.text}
                  />
                </div>
                <div className="field">
                  <span className="label">Institute</span>
                  <FormField
                    inputClassName="input"
                    fieldName="institute"
                    placeholder="Institute"
                    type={InputType.text}
                  />
                </div>
                <div className="field">
                  <span className="label">Year attended</span>
                  <DropDown
                    className={`${
                      errors.yearAttended && touched.yearAttended ? 'err' : ''
                    }`}
                    error={
                      errors.yearAttended && touched.yearAttended
                        ? errors.yearAttended
                        : ''
                    }
                    defaultValue={values.yearAttended}
                    placeholder="Year attended"
                    items={years}
                    selectedValue={(e) => {
                      setFieldTouched('yearAttended', true);
                      setFieldValue('yearAttended', e);
                    }}
                  />
                </div>
                <div className="field">
                  <Checkboxes
                    onArrayChange={(item) => {
                      setReceivedCertificate(
                        Boolean(Array.isArray(item) ? item[0].isChecked : false)
                      );

                      setFieldValue(
                        'receivedCertificate',
                        Array.isArray(item) ? item[0].isChecked : false
                      );
                      setInitialValue(() => ({
                        ...values,
                        receivedCertificate: Boolean(
                          Array.isArray(item) ? item[0].isChecked : false
                        )
                      }));
                    }}
                    checkboxes={[
                      {
                        label: 'Received certificate?',
                        isChecked: receivedCertificate
                      }
                    ]}
                  />
                </div>
                <Button
                  isLoading={showLoader}
                  t={t}
                  title="Save"
                  className={`save ${!isValid ? 'invalid-btn' : ''}`}
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default CertificationsPopup;
