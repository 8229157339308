import {
  IMatchProfile,
  IMatchProfileCandidates,
  IMatchProfileCandidatesBody,
  IMatchProfileCandidatesParams
} from 'core/interface/matchProfile';
import { IresponseDTO } from 'core/interface/responseDTO';
import { store } from '../../redux/store';
import ApiBase from 'services/base/apiBase';
import {
  SaveCultureProfile,
  SaveMatchProfile,
  SaveMatchProfileCandidate,
  SaveMatchProfileOne,
  SaveOverview,
  SaveOverviewOne,
  SaveSearch
} from '../../redux/slices/match';
import { prepareUpdatedData } from 'helpers/stateChanges';

export class MatchProfileService {
  public static apiBase = new ApiBase<FormData | IresponseDTO<IMatchProfile>>();

  public static async getMatchProfiles(
    query?: boolean
  ): Promise<IresponseDTO<IMatchProfile[]> | void> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/match/profiles/?culture=${query}`
      );
      if (query) {
        dispatch(SaveCultureProfile(res));
      } else {
        dispatch(SaveMatchProfile(res));
      }
      return res;
    } catch (error) {
      console.error('Error fetching:', error);
    }
  }

  public static async getMatchProfilesSearch(
    query: any
  ): Promise<IresponseDTO<IMatchProfile[]> | void> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/match/profiles/search/?category=culture&q=${query}`
      );
      dispatch(SaveSearch(res));
      return res;
    } catch (error) {
      console.error('Error fetching:', error);
    }
  }

  public static async getMatchProfileById(
    id: number
  ): Promise<IMatchProfile | void> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(`/match/profiles/${id}/`);
      dispatch(SaveMatchProfileOne(res));
      return res;
    } catch (error) {
      console.error('Error fetching:', error);
    }
  }

  public static async getCandidatesOverview(
    id?: number
  ): Promise<IresponseDTO<IMatchProfile[]> | void> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/match/selected_candidates/?profileID=${id}`
      );
      if (id) {
        dispatch(SaveOverviewOne(res));
      } else {
        dispatch(SaveOverview(res));
      }
      return res;
    } catch (error) {
      console.error('Error fetching:', error);
    }
  }

  public static async deleteCultureProfile(
    id: number,
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.deleteAsync(
        `/match/selected_candidates/${id}/`
      );
      const updatedState = prepareUpdatedData(
        'delete',
        'match',
        {
          id
        },
        'matchOverViewOne'
      );
      dispatch(SaveOverviewOne(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async getMatchProfileCandidatesById(
    id: string,
    params: IMatchProfileCandidatesParams,
    body: IMatchProfileCandidatesBody
  ): Promise<IMatchProfileCandidates | void> {
    try {
      const { dispatch } = store;

      const res = await this.apiBase.postAsync(
        `/match/profiles/${id}/find_candidates/`,
        body,
        params
      );

      dispatch(SaveMatchProfileCandidate(res));

      return res;
    } catch (error) {
      console.error('Error fetching:', error);
    }
  }

  public static async removeMatchProfileCandidatesById(
    id: string
  ): Promise<IMatchProfileCandidates> {
    return this.apiBase.postAsync(`/match/profiles/${id}/remove_candidate/`);
  }

  public static async deleteMatchProfile(
    id: number,
    isCulture: boolean
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.deleteAsync(`/match/profiles/${id}/`);

      if (isCulture) {
        const updatedState = prepareUpdatedData(
          'delete',
          'match',
          {
            id
          },
          'cultureProfile'
        );
        dispatch(SaveCultureProfile(updatedState));
      } else {
        const updatedState = prepareUpdatedData(
          'delete',
          'match',
          {
            id
          },
          'matchProfile'
        );
        dispatch(SaveMatchProfile(updatedState));
      }

      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async createMatchProfile(
    query: boolean,
    body: any
  ): Promise<any> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.postAsync(
        `/match/profiles/?culture=${query}`,
        body
      );
      if (body?.is_culture) {
        const updatedState = prepareUpdatedData(
          'create',
          'match',
          res,
          'cultureProfile'
        );
        dispatch(SaveCultureProfile(updatedState));
      }
      return res;
    } catch (error) {
      console.error('Error fetching:', error);
    }
  }

  public static async editMatchProfile(
    id: number,
    query: boolean,
    body: any
  ): Promise<any> {
    const { dispatch } = store;
    try {
      const res = this.apiBase.updateAsync(
        `/match/profiles/${id}/`,
        body
      );
      const updatedState = prepareUpdatedData(
        'update',
        'match',
        res,
        'matchProfile'
      );
      dispatch(SaveMatchProfile(updatedState));
      return res;
    } catch (error) {
      console.error('Error fetching:', error);
    }
  }
}
