import React, { useEffect, useState } from 'react';
import useLocales from 'hooks/useLocales';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { IMe } from 'core/interface/me';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CMSService } from 'services/cms/cms';
import { RootState } from 'redux/store';
import { CompanyService } from 'services/companes/companies';
import ReactPlayer from 'react-player';
import Image from 'components/image/Image';
import AboutComp from './about';
import JobOpening from './jobOpening';
import Organization from './organization';
import PeopleSay from './peopleSay';
import Culture from './culture';
import Popup from 'components/shared/Popups/Popup/Index';
import CompanyVideo from 'components/companyVideo';
import Benefit from './benefit';
import BeInTouch from 'components/beInTouch';
import Button from 'components/Button/Index';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import Footer from 'components/Footer/Index';
import { initialValues, validation } from './form';
import { ISocialMediaData } from 'core/interface/socialMedia';
import { callForAllElement } from 'helpers/callAll';
import { CircularProgress } from '@mui/material';

import './Index.scss';

const CareerInfo = (): JSX.Element => {
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [openModalContact, setOpenModalContact] = useState(false);
  const { id } = useParams();
  const me = useSelector((state: IMe) => state.user).user;
  const { cms } = useSelector((state: RootState) => state.cms);

  const handleSubmit = async (values: any, actions: any) => {
    const valuesWithCompanyID = { ...values, company: me.company || id };
    const res = await CMSService.sendContacts(valuesWithCompanyID);
    if (res) {
      enqueueSnackbar(t('Email have been sent'), {
        variant: 'success'
      });
    }
  };

  const handleChange = (companyData: any) => {
    const formData = new FormData();
    for (const key in companyData) {
      if (key !== 'id') {
        formData.append(key, (companyData as any)[key]);
      }
    }
    CMSService.updateCMS(me?.company, formData);
    handleModal(setOpenModal, openModal);
  };

  const handleModal = (setModal: (value: boolean) => void, toggle: boolean) => {
    setModal(!toggle);
  };

  const handleChangeLinks = (companyData: any) => {
    const { contact_email, contact_phone } = companyData;
    const formData = new FormData();
    contact_email && formData.append('contact_email', contact_email);
    contact_phone && formData.append('contact_phone', contact_phone);
    CMSService.updateCMS(me?.company, formData);
    handleModal(setOpenModalContact, openModalContact);
  };

  const handleSocialLinkChange = (data: any, isNew: boolean) => {
    if (isNew) {
      callForAllElement(data, (link) => {
        return CMSService.updateCMSSocialMediaLinks(me?.company, link);
      });
    } else {
      callForAllElement(data, (link) => {
        return CMSService.updateCMSSocialMediaLink(
          link.page,
          link.changedFields,
          link.id
        );
      });
    }
    handleModal(setOpenModalContact, openModalContact);
  };

  useEffect(() => {
    CMSService.getCMS(id ? id : me?.company);
    !id && CompanyService.getCompanies(me?.company);
  }, [id, me]);



  return (
    <>
      <div className="careerContainer">
        {!cms ?
          <div className="loadingContainer">
            <CircularProgress className="loading" />
          </div> :
          <div className="aboutContainer">
            <AboutComp />
            <JobOpening />
            <Organization />
            <PeopleSay />
            <Culture />
            <div className="videoWrapper">
              {!id && <div
                className="edit-info"
                onClick={() => handleModal(setOpenModal, openModal)}
              >
                <Image className="edit" name="edit.svg" width={13} height={13} />
              </div>}
              <ReactPlayer
                className="react-player"
                playing={false}
                light={true}
                controls={false}
                url={cms?.video_url}
                width="100%"
                height="642px"
              />
              {openModal && (
                <Popup
                  title=""
                  className="expertise"
                  onClose={() => handleModal(setOpenModal, openModal)}
                >
                  <CompanyVideo
                    companyData={cms}
                    setNewData={handleChange}
                    isOneBtn={true}
                    onClose={() => handleModal(setOpenModal, openModal)}
                    showTopSide={false}
                    isPopup={true}
                  />
                </Popup>
              )}
            </div>
            <Benefit />
            <div className="contactContainer">
              <div className="bgImageWrapper">
                {!id && <div
                  className="edit-info"
                  onClick={() =>
                    handleModal(setOpenModalContact, openModalContact)
                  }
                >
                  <Image
                    className="edit"
                    name="edit.svg"
                    width={13}
                    height={13}
                  />
                </div>}
                <Image className="bgPhoto" name="bg.png" />
              </div>
              {openModalContact && (
                <Popup
                  title="Create career page"
                  className="expertise"
                  onClose={() =>
                    handleModal(setOpenModalContact, openModalContact)
                  }
                >
                  <BeInTouch
                    companyData={cms}
                    setNewData={handleChangeLinks}
                    setLinkData={handleSocialLinkChange}
                    isOneBtn={true}
                    onClose={() =>
                      handleModal(setOpenModalContact, openModalContact)
                    }
                    showTopSide={false}
                    isPopup={true}
                    showTitle={true}
                  />
                </Popup>
              )}
              <div className="formContainer">
                <div className="contactForm">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={(values, actions) => handleSubmit(values, actions)}
                    validationSchema={validation(t)}
                  >
                    {({ setFieldValue, errors }) => {
                      return (
                        <Form>
                          <h1>Fill out the form and we’ll be in touch!</h1>
                          <FormField
                            inputClassName="input"
                            fieldName="name"
                            placeholder="Name"
                            type={InputType.text}
                          />
                          <FormField
                            inputClassName="input"
                            fieldName="email"
                            placeholder="Email"
                            type={InputType.text}
                          />
                          <div className="field">
                            <textarea
                              className={`input-formik area ${errors.message ? 'error' : ''
                                }`}
                              placeholder="Message"
                              onChange={(e) =>
                                setFieldValue('message', e.target.value)
                              }
                            />
                          </div>
                          <Button
                            t={t}
                            title="Send"
                            className="send"
                            type="submit"
                          />
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="infoSection">
                  <div className="pencil_icon">
                    <div className="pencil_circle" />
                    <Image
                      className="email"
                      name="email.svg"
                      width={13}
                      height={13}
                    />
                  </div>
                  <p className='email_text'>{cms?.contact_email}</p>
                  <div className="pencil_icon">
                    <div className="pencil_circle" />
                    <Image
                      className="phone"
                      name="phone.svg"
                      width={13}
                      height={13}
                    />
                  </div>
                  <p>{cms?.contact_phone}</p>
                  <div className="socialMediaContainer">
                    {cms?.social_media_links &&
                      cms?.social_media_links.map((item: ISocialMediaData) => (
                        <a href={item.url} key={item.id} target="blank">
                          <Image
                            key={item.id}
                            className="dashboard__left__social-icons"
                            width={31}
                            height={31}
                            name={`${item.network}.svg`}
                          />
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default CareerInfo;
