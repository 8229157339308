import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { CircularProgress } from '@mui/material';
import useLocales from 'hooks/useLocales';
import Button from 'components/Button/Index';
import Image from 'components/image/Image';
import CultureCart from 'components/cultureCart';
import Popup from 'components/shared/Popups/Popup/Index';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { JobService } from 'services/jobs/job';
import { MatchProfileService } from 'services/matchProfile/matchProfile';
import CompetenciesSelect from 'components/shared/CompetenciesSelect';
import NoData from 'components/shared/NoData/Index';
import { useDebounce } from 'helpers/useDebounce';

import './Index.scss';

const CultureProfile = (): JSX.Element => {
  const { t } = useLocales();
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [ids, setIds] = useState<string[]>([]);
  const [selectedCultureProfileId, setSelectedCultureProfileId] =
    useState<any>();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    matching: ''
  });
  const [selectedCultureProfile, setSelectedCultureProfile] = useState<any>();

  const { cultureProfile }: any = useSelector(
    (state: RootState) => state.match
  );
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState<number>(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const filtered = cultureProfile?.results?.filter(
      (profile: any) => profile?.title?.toLowerCase().includes(debouncedQuery)
    );

    setFilteredData(filtered);
    setPage(1);
  }, [cultureProfile, debouncedQuery]);

  const handleCloseOpenPopup = (): void => {
    setSelectedCultureProfile(null);
    setSelectedCultureProfileId(null);
    setSelectedLabels([]);
    setFormValues({ matching: '' });
    setOpenPopup(!openPopup);
  };

  useEffect(() => {
    const selectedProfile = cultureProfile?.results?.find(
      (profile: any) => profile.id === selectedCultureProfileId
    );

    if (selectedProfile) {
      setSelectedCultureProfile(selectedProfile);
    }
  }, [cultureProfile, selectedCultureProfileId]);

  useEffect(() => {
    if (selectedCultureProfile) {
      setFormValues({ matching: selectedCultureProfile.title });
      setSelectedLabels(selectedCultureProfile?.competency_labels);
      const a = selectedCultureProfile?.competencies?.map(
        (e: any) => e?.competency?.id
      );
      setIds(a);
    }
  }, [selectedCultureProfile]);

  const { assessmentByGroup } = useSelector(
    (state: RootState) => state.assessment
  );
  const getApplications = () => {
    JobService.getAssessmentByGroup();
  };

  useEffect(() => {
    getMatchProfiles();
  }, []);

  const getMatchProfiles = async (): Promise<void> => {
    try {
      setLoading(true)
      setTimeout(async() => {
        await MatchProfileService.getMatchProfiles(true);
        setLoading(false)
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getApplications();
  }, []);

  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPopup = () => {
    if ((selectedLabels.length >= 7 && selectedLabels.length <= 10) && formValues.matching) {
      const idLabelArray = ids.map((id, index) => ({
        id,
        competency: selectedLabels[index]
      }));
      const existingLabels = selectedCultureProfile?.competency_labels || [];
      const competencyScores = selectedCultureProfile?.competency_min_scores || [];

      const data = {
        name: formValues.matching,
        competencies: idLabelArray,
        is_culture: true,
        edit: selectedCultureProfile ? selectedCultureProfile?.id : false,
        scores: idLabelArray.map(({ competency }) =>
          existingLabels.includes(competency)
            ? competencyScores[existingLabels.indexOf(competency)]
            : 0
        ),
      }

      localStorage.setItem('selected', JSON.stringify(data));
      navigate('/culture');
    }
  };

  const handleSetSelected = (label: string, id: any, text: string) => {
    const newSelected: string[] = [...selectedLabels];
    const newId: string[] = [...ids];

    const index = selectedLabels.indexOf(label);

    const indexID = ids.indexOf(id);

    if (index !== -1 && indexID !== -1) {
      newSelected.splice(index, 1);
      newId.splice(indexID, 1);
    } else if (newSelected.length < 10 && newId.length < 10) {
      newSelected.push(label);
      newId.push(id);
    } else if (newSelected.length < 7) {
      return;
    }
    setSelectedLabels(newSelected);
    setIds(newId);
  };

  return (
    <div className="container_block">
      <div className="culture_block">
        <span className="culture__left-title">{t('Culture Profile')}</span>
        <div className="search_bar">
          <div className="search_input">
            <Image
              className="btn-icon"
              name="search.svg"
              width={20}
              height={20}
            />
            <input
              type="text"
              className="search_input_field"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <Button
            onClick={handleCloseOpenPopup}
            t={t}
            title="Create Culture Profile"
            className="postjob__header-btn"
          />
        </div>
      </div>
      {loading ? (
        <div className="loadingContainer">
          <CircularProgress className="loading" />
        </div>
      ) : filteredData.length === 0 ? (
        <NoData />
      ) : (
        <div className="cart_item">
          {filteredData
            ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
            ?.map((e: any) => (
              <CultureCart
                item={e}
                key={e.title}
                handleCloseOpenPopup={handleCloseOpenPopup}
                setSelectedCultureProfile={setSelectedCultureProfileId}
              />
            ))}
        </div>
      )}
      {filteredData?.length > itemsPerPage && (
        <div className="pagination">
          <div
            className={`arrowPrev ${page === 1 ? 'disabled' : ''}`}
            onClick={handlePrev}
          >
            <h1>{'<'}</h1>
          </div>
          <div className="page">{page}</div>
          <div
            className={`arrowNext ${page * itemsPerPage >= filteredData.length ? 'disabled' : ''
              }`}
            onClick={handleNext}
          >
            <h1>{'>'}</h1>
          </div>
        </div>
      )}
      {openPopup && (
        <Popup
          className="popup-add-new"
          title="Please select the most important competencies for your job opening."
          description="We advise you to select no more than 10 competencies. And you should only go for the once that really matter to achieve success in the work the candidate needs to execute. The average is 7 competencies per matching profile. But depending on your results you can always add or delete competencies. This way you can fine-tune your ideal profile."
          onClose={handleCloseOpenPopup}
        >
          <CompetenciesSelect
            competencies={assessmentByGroup}
            selectedList={selectedLabels}
            onSelect={handleSetSelected}
            next={handleNextPopup}
            fromCulture
            matchProfile={true}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </Popup>
      )}
    </div>
  );
};

export default CultureProfile;
