import React, { FC, useState } from 'react';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import useLocales from 'hooks/useLocales';
import Navbar from 'components/Navbar';
import ActionButton from 'components/ActionButton/Index';
import ChartComponent from 'components/RadarChart/Index';
import { chartData } from 'core/constants/competencyTabConstants';
import MatchingProfile from 'components/CompanyTabs/MatchingProfile/Index';
import { companyHeader } from 'core/constants/companyHeader';
import { ICompanyHeaderItems } from 'core/interface/headerItems';
import { CompanyTabEnum } from 'core/enum/companyTab';
import PostAJob from 'components/CompanyTabs/PostAJob/Index';
import CultureProfile from 'components/CompanyTabs/CultureProfile/Index';
import CandidateOverview from 'components/CompanyTabs/CandidateOverview/Index';
import Popup from 'components/shared/Popups/Popup/Index';
import AvatarPopup from 'components/shared/Popups/Avatar/Index';
import CareerPage from 'components/CareerPage/Index';
import { IMe } from 'core/interface/me';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import MatchProfilePopup from 'components/shared/Popups/MatchProfile/Index';
import UpgradePlanPopup from 'components/shared/Popups/UpgradePlan/Index';
import CompanyAvatar from 'components/shared/CompanyAvatar/Index';
import Footer from 'components/Footer/Index';

import './Index.scss';

const Company: FC = (): JSX.Element => {
  const { t } = useLocales();
  const me = useSelector((state: IMe) => state.user).user;
  const { companies } = useSelector((state: RootState) => state.compony);
  const [activeTab, setActiveTab] = useState<string>(
    localStorage.getItem('activeTab') ?? CompanyTabEnum.FIND_CANDIDATES
  );
  const [showEditPopup, setShowEditPopup] = useState<boolean>();
  const [showAvatarPopup, setShowAvatarPopup] = useState<boolean>();
  const [showUpgrade, setShowUpgrade] = useState<boolean>(false);
  const [companyEditModal, setCompanyEditModal] = useState<boolean>(false);

  const handleTabChange = (item: ICompanyHeaderItems) => {
    localStorage.setItem('activeTab', item.value);
    setActiveTab(item.value);
    componentToShow();
  };

  const handleEdit = () => {
    setShowEditPopup(true);
  };

  const handleClosePopup = () => {
    setShowEditPopup(false);
    setShowAvatarPopup(false);
  };

  const componentToShow = (): JSX.Element => {
    switch (activeTab) {
      case CompanyTabEnum.FIND_CANDIDATES:
        return <MatchingProfile />;
      case CompanyTabEnum.POST_A_JOB:
        return <PostAJob />;
      case CompanyTabEnum.CULTURE_PROFILE:
        return <CultureProfile />;
      case CompanyTabEnum.CANDIDATE_OVERVIEW:
        return <CandidateOverview />;
      default:
        return <CareerPage />;
    }
  };

  const onSubmit = (): void => {
    setShowEditPopup(!setShowEditPopup);
  };

  const handleOpenAvatarChange = () => {
    setCompanyEditModal(!companyEditModal);
  };

  return (
    <>
      <Navbar />
      <div className="company">
        <div className="company__top">
          <div className="company__top__left">
            <div className="company__top__left__image">
              <ActionButton
                className="company__top__left__image-edit"
                iconName="pen.png"
                onClick={handleOpenAvatarChange}
              />
              <Image
                className="company__top__left-image"
                url={companies?.logo}
                name={'aws.svg'}
                width={243}
                height={145}
              />
            </div>
            <div className="company__top__left__info">
              <span className="company__top__left__info-title">
                Welcome {me?.name}
              </span>
              <span className="company__top__left__info-subtitle">
                {companies?.name}
              </span>
              <span className="company__top__left__info-credit">
                You are on the Recrout Basic plan
              </span>
              <div className="company__top__left__info__buttons">
                <Button
                  onClick={handleEdit}
                  className="company__top__left__info__buttons-edit button"
                  title="Edit Profile"
                  t={t}
                />
                <Button
                  className="company__top__left__info__buttons-upgrade button"
                  onClick={() => setShowUpgrade(true)}
                  title="Upgrade"
                  t={t}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="company__header">
          {companyHeader.map((item, index) => (
            <span
              onClick={() => handleTabChange(item)}
              className={`company__header__item ${item.value === activeTab ? 'active' : ''
                }`}
              key={index}
            >
              {item.title}
              <span className="company__header__item-line line"></span>
            </span>
          ))}
        </div>
      </div>
      {componentToShow()}
      {showEditPopup && (
        <Popup
          title="Nice to meet you"
          description="Please tell us a little bit more about you and company"
          onClose={handleClosePopup}
        >
          <MatchProfilePopup onSubmit={onSubmit} />
        </Popup>
      )}
      {showAvatarPopup && (
        <Popup onClose={handleClosePopup} title={t('Edit Your Profile Image')}>
          <AvatarPopup id={me.id} onPopupClose={handleClosePopup} t={t} />
        </Popup>
      )}
      {companyEditModal && (
        <Popup
          onClose={handleOpenAvatarChange}
          title={t('Edit Your Profile Image')}
        >
          <CompanyAvatar
            id={me.id}
            onPopupClose={handleOpenAvatarChange}
            t={t}
            selectedCompanyEdit={companies}
          />
        </Popup>
      )}
      {showUpgrade && (
        <Popup
          className="upgrade-plan-nav upgrade-popup-nav"
          title={t('Upgrade your plan')}
          onClose={() => setShowUpgrade(false)}
        >
          <UpgradePlanPopup t={t} />
        </Popup>
      )}
      <Footer />
    </>
  );
};

export default Company;
