import React, { FC, useEffect, useRef, useState } from 'react';
import PdfHeader from 'components/shared/PdfHeader/Index';
import { PdfComponents } from 'core/interface/pdfComponents';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { fetchImageAndConvertToBase64 } from 'utils/fetchImageAndConvertToBase64';
import Level from 'components/Level/Index';
import { IPreferencesItem } from 'core/interface/preferences';
import { setPreferenceValues } from 'components/Tabs/CompetencyPreferences/candidatePreferences';
import Preferences from 'components/shared/Preferences/Index';
import SkillsBlock from 'components/shared/SkillsBlock/Index';
import { SkillService } from 'services/skills/skillService';
import { EducationService } from 'services/education/educationService';
import { DegreeEnum } from 'core/enum/degree';
import { ExperienceService } from 'services/experience/experienceService';
import { ContractEnum } from 'core/enum/contract';
import { ExtraActivitieService } from 'services/extraActivities/extraActivities';
import { useParams } from 'react-router';

import './Index.scss';

export const CandidateBasic: FC<PdfComponents> = ({
  isAnonym,
  setTemplate
}): JSX.Element => {
  const canidateRef = useRef<HTMLDivElement>(null);
  const { candidates } = useSelector((state: RootState) => state.candidates);
  const [language, setLanguage] = useState<any>([]);
  const { skills } = useSelector((state: RootState) => state.skills);
  const me = useSelector((state: RootState) => state.user).user;
  const { id } = useParams()
  const educations = useSelector(
    (state: RootState) => state.educations
  ).educations;
  const { candidate_language } = useSelector(
    (state: RootState) => state.languages
  );
  const experience = useSelector(
    (state: RootState) => state.workExperiences
  ).experience;
  const activities = useSelector(
    (state: RootState) => state.extraActivites
  ).extraActivities;
  const [preferenceItems, setPreferenceItems] = useState<IPreferencesItem[]>(
    []
  );

  useEffect(() => {
    setPreferenceItems(setPreferenceValues(candidates));
  }, [candidates]);

  useEffect(() => {
    convertCandidateLanguage();
  }, [candidate_language]);

  useEffect(() => {
    if (me) {
      SkillService.getSkills(id ? +id : me?.candidate_id);
      EducationService.getEducations(id ? +id : me?.candidate_id);
      ExperienceService.getExperience(id ? +id : me?.candidate_id);
      ExtraActivitieService.getExtraActivities(id ? +id : me?.candidate_id);
    }
  }, [me, id]);

  const convertCandidateLanguage = async () => {
    const langs = await Promise.all(
      candidate_language?.map(async (item) => {
        const res = await handleFlagAndConvert(
          `http://purecatamphetamine.github.io/country-flag-icons/3x2/${item?.language?.flag?.toUpperCase()}.svg`
        );
        if (res) {
          item.language.flag = res;
        }
        return item;
      }) || []
    );
    setLanguage(langs);
  };

  const handleFlagAndConvert = async (flagURL: string): Promise<string> => {
    return await fetchImageAndConvertToBase64(flagURL);
  };

  useEffect(() => {
    if (canidateRef.current) {
      setTemplate(canidateRef.current);
    }
  }, [canidateRef]);
  

  return (
    <div ref={canidateRef} className="full">
      <PdfHeader isAnonym={isAnonym} />
      <div className="top">
        <div className="langs">
          <h6 className="title">Languages</h6>
          {language && language.length > 0 && (
            <div className="languages">
              {language?.map((item: any, index: number) => (
                <div key={index} className="lang">
                  <Level level={item.proficiency} />
                  <span className="lang-and-flag">
                    <span className="names">{item.language.name}</span>
                    <img src={item.language.flag} width={15} height={15} />
                  </span>
                </div>
              ))}
            </div>
          )}
          {skills && skills.length > 0 && (
            <div className="skills">
              <div className="hard">
                <h6 className="title">Hard Skills</h6>
                <div className="sk-block-side">
                  {skills?.map((skill: any) => (
                    <div key={skill.id} className="blocks">
                      <SkillsBlock title={skill.skill.name} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="hard">
                <h6 className="title">Soft Skills</h6>
                <div className="sk-block-side">
                  {skills?.map((skill: any) => (
                    <div key={skill.id} className="blocks">
                      <SkillsBlock title={skill.skill.name} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {activities && activities.length > 0 && (
            <ul>
              <h6 className="title">Extra Activities</h6>

              {activities.map((activity: any, index: number) => (
                <li className="wexper" key={index}>
                  <div className="school-item">
                    <span className="items">Title:</span>
                    <span className="value">{activity.title}</span>
                  </div>
                  <div className="school-item">
                    <span className="items">Description:</span>
                    <span className="value">{activity.description}</span>
                  </div>
                  <div className="school-item">
                    <span className="items">Time period:</span>
                    <span className="value">{activity.start}</span>
                    <span className="value">{activity.end ?? '- Present'}</span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="prefs">
          <h6 className="title">Preferences</h6>
          <Preferences preferences={preferenceItems} />
          {educations && educations.length > 0 && (
            <div className="edus">
              <h6 className="title">Educations</h6>
              <ul>
                {educations?.map((education, index) => (
                  <li className="wexper" key={index}>
                    <div className="school-item">
                      <span className="items">School:</span>
                      <span className="value">{education.school.name}</span>
                    </div>
                    <div className="school-item">
                      <span className="items">Study:</span>
                      <span className="value">{education.study.name}</span>
                    </div>
                    <div className="school-item">
                      <span className="items">Time period:</span>
                      <span className="value">{education.start}</span>
                      <span className="value">
                        {education.end ?? '- Present'}
                      </span>
                    </div>
                    <div className="school-item">
                      <span className="items">Degree:</span>
                      <span className="value">
                        {DegreeEnum[education.degree]}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {experience && experience.length > 0 && (
            <div className="edus">
              <h6 className="title">Work Experience</h6>
              <ul>
                {experience.map((exp: any, index: number) => (
                  <li className="wexper" key={index}>
                    <div className="school-item">
                      <span className="items">Company:</span>
                      <span className="value">{exp.company}</span>
                    </div>
                    <div className="school-item">
                      <span className="items">Role:</span>
                      <span className="value">{exp.industry.name}</span>
                    </div>
                    <div className="school-item">
                      <span className="items">Time period:</span>
                      <span className="value">{exp.start}</span>
                      <span className="value">{exp.end ?? '- Present'}</span>
                    </div>
                    <div className="school-item">
                      <span className="items">Contract:</span>
                      <span className="value">
                        {ContractEnum[exp.contract]}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateBasic;
