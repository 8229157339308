import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import React, { useEffect, useState } from 'react';
import Button from 'components/Button/Index';
import Image from 'components/image/Image';
import Popup from 'components/shared/Popups/Popup/Index';
import useLocales from 'hooks/useLocales';
import JobOpenings from 'components/jobOpenings';
import { CMSService } from 'services/cms/cms';
import { IMe } from 'core/interface/me';
import { useParams } from 'react-router';
import { CandidateService } from 'services/candidate/candidate';
import RadarChartComp from 'components/shared/RadarChart/RadarChartComp';
import { getComparedDataSecond } from 'helpers/getCompared';

function Culture() {
  const { t } = useLocales();
  const [openModal, setOpenModal] = useState(false);
  const { cms } = useSelector((state: RootState) => state.cms);
  const me = useSelector((state: IMe) => state.user).user;
  const { id, candidate_Id } = useParams();
  const { candidatesOverview } = useSelector(
    (state: RootState) => state.candidates
  );
  const [showMatch, setShowMatch] = useState(false);
  const [showChart, setShowChart] = useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowChart(true);
    }, 700); 
    return () => clearTimeout(timer);
  }, [openModal]);


  const chartData = () => ({
    labels: cms?.primary_culture_profile?.competencies.map(
      (el: any) => el?.competency?.name
    ),
    datasets: [
      {
        label: '',
        data: cms?.primary_culture_profile?.competencies.map(
          (competency: { min_score: number }) => competency.min_score
        ),
        description: cms?.primary_culture_profile?.competencies.map(
          (competency: { competency: any }) =>
            competency?.competency?.description
        ),
        backgroundColor: 'rgba(0, 158, 226, 0.50)',
        borderColor: '#009EE2',
        borderWidth: 1
      }
    ]
  });

  const handleChange = (companyData: any) => {
    const formData = new FormData();
    for (const key in companyData) {
      if (key !== 'id') {
        formData.append(key, (companyData as any)[key]);
      }
    }
    CMSService.updateCMS(me?.company, formData);
    handleModal();
    setShowChart(false)
  };


  const getCandidateOverview = () => {
    CandidateService.getCandidateOverview(candidate_Id ? +candidate_Id : -1);
  };

  useEffect(() => {
    candidate_Id && getCandidateOverview();
  }, [candidate_Id]);




  return (
    <div className="cultureProfileContainer">
      <div className="cultureProfileContainer">
        <div className="cultureProfile">
          <h1>Culture profile</h1>
          {!id && (
            <div className="pencil_icon" onClick={handleModal}>
              <div className="pencil_circle" />
              <Image
                className="pencil"
                name="pencil.svg"
                width={13}
                height={13}
              />
            </div>
          )}
        </div>{me?.candidate_id &&
          <>
            <span>Do you have a match with us?</span>
            <Button
              className="next"
              title="Match now"
              t={t}
              onClick={() => setShowMatch(true)}
            />
          </>
        }
      </div>
      <div className="cultureProfileChart">
      {showChart && (
          <RadarChartComp
            boxSx={{ width: '100%' }}
            labels={chartData()?.labels}
            points={chartData().datasets?.[0]?.data || []}
            descriptions={chartData().datasets?.[0]?.description || []}
            comparePoints={
              (showMatch &&
                getComparedDataSecond(
                  candidatesOverview?.competencies,
                  cms?.primary_culture_profile?.competencies
                )) ||
              []
            }
            assessment={true}
            chartHeight={500}
            chartWidth={500}
          />
        )}
      </div>
      {openModal && (
        <Popup
          title="Create career page"
          className="expertise"
          onClose={handleModal}
        >
          <JobOpenings
            companyData={cms}
            setNewData={handleChange}
            isOneBtn={true}
            onClose={handleModal}
            showTopSide={false}
            isPopup={true}
            showTitle
          />
        </Popup>
      )}
    </div>
  );
}

export default Culture;
