import {
  ISignIn,
  RegisterCompanyType,
  SignInType,
  SignUpType
} from 'core/interface/auth';
import ApiBase from '../base/apiBase';
import { ApiError } from '../base/types';
import Cookies from 'js-cookie';
import { IResetPassword } from 'core/interface/resetPassword';
import { IUser } from 'core/interface/user';
import { ISignUpCandidate } from 'core/interface/candidate';
import { store } from '../../redux/store';
import { SaveUser } from '../../redux/slices/user';
import { CandidateService } from 'services/candidate/candidate';
import { SaveCandidate } from '../../redux/slices/candidate';
import { SaveCompanies } from '../../redux/slices/company';

export class AuthService {
  private static apiBase = new ApiBase<
    ISignIn | SignInType | IResetPassword | IUser
  >();

  public static async signIn(
    email: string,
    password: string
  ): Promise<ISignIn | SignInType | ApiError | undefined | IResetPassword> {
    try {
      const res = await this.apiBase.postAsync(`/account/auth/login/`, {
        email,
        password
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  public static async companyRegister(
    creedentials: RegisterCompanyType
  ): Promise<RegisterCompanyType | undefined> {
    try {
      const res = await this.apiBase.postAsync(
        `/companies/register/`,
        creedentials
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  public static async signUp(
    creedentials: SignUpType
  ): Promise<ISignUpCandidate | undefined> {
    try {
      const res = await this.apiBase.postAsync(
        `/account/auth/register/`,
        creedentials
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  public static async resetPassword(
    creedentials: IResetPassword
  ): Promise<void> {
    try {
      const res = await this.apiBase.postAsync(
        `account/auth/password/reset/`,
        creedentials
      );
    } catch (error) {
      console.log(error);
    }
  }

  public static async changePassword(creedentials: any): Promise<void> {
    try {
      return await this.apiBase.putAsync(
        `/account/auth/password/change/`,
        creedentials
      );
    } catch (error) {
      console.log(error);
    }
  }

  public static async changePasswordUser(creedentials: any): Promise<void> {
    try {
      return await this.apiBase.putAsync(
        `/account/auth/password/reset/confirm/`,
        creedentials
      );
    } catch (error) {
      console.log(error);
    }
  }

  public static async logOut(): Promise<void> {
    try {
      return await this.apiBase.getAsync('/account/logout/');
    } catch (error: any) {
      console.log(error);
    }
  }

  public static async getAccount(): Promise<IUser> {
    const { dispatch } = store;
    let user;
    let candidate;
    try {
      const accessToken = Cookies.get('accesstoken');
      if (accessToken) {
        user = await this.apiBase.getAsync(`/account/me/`);
        candidate = await CandidateService.getCandidate(user.candidate_id);
        dispatch(SaveUser(user));
        if (candidate) {
          dispatch(SaveCandidate(candidate));
        }
      }
    } catch (error) {
      console.log(error);
    }
    return user;
  }

  public static async getAccountCompany(
    company_id: string,
    body: any
  ): Promise<any> {
    const { dispatch } = store;
    let user;
    let candidate;
    try {
      const accessToken = Cookies.get('accesstoken');
      if (accessToken) {
        user = await this.apiBase.updateAsync(
          `/companies/${company_id}/`,
          body
        );
        candidate = await CandidateService.getCandidate(user.candidate_id);
        dispatch(SaveCompanies(user));
        if (candidate) {
          dispatch(SaveCandidate(candidate));
        }
      }
    } catch (error) {
      console.log(error);
    }

    return user;
  }
}
