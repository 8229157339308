import React from 'react';
import './Index.scss';
import Table from 'components/shared/Table/Index';
import { orderColumns, orderData } from 'core/constants/orderConstants';
import Image from 'components/image/Image';
import { IOrderDetails } from './types';

const OrderDetails = ({ item, handleBack }: IOrderDetails): JSX.Element => {
  return (
    <div className="details">
      <div>
        <div className='back' onClick={handleBack}>
          <Image name="arrow-left.svg" />
        </div>
        <h1 className="details-title">Your order details: 2023100011</h1>
      </div>
      <Table columns={orderColumns} data={orderData} />
      <div className="details__bottom">
        <div className="details__bottom__left">
          <div className="details__bottom__left__items">
            <div className="details__bottom__left__items__item">
              <span className="details__bottom__left__items__item-span title">
                Payment method:
              </span>
              <span className="details__bottom__left__items__item-span val">
                Bank transfer
              </span>
            </div>
            <div className="details__bottom__left__items__item">
              <span className="details__bottom__left__items__item-span title">
                Payment status:
              </span>
              <span className="details__bottom__left__items__item-span val">
                payment_open
              </span>
            </div>
          </div>
        </div>
        <div className="details__bottom__left">
          <div className="details__bottom__left__items">
            <div className="details__bottom__left__items__item">
              <span className="details__bottom__left__items__item-span titles">
                Subtotal:
              </span>
              <span className="details__bottom__left__items__item-span val">
                € 895.00
              </span>
            </div>
            <div className="details__bottom__left__items__item">
              <span className="details__bottom__left__items__item-span titles">
                VAT (21%):
              </span>
              <span className="details__bottom__left__items__item-span val">
                € 187.95
              </span>
            </div>
            <div className="details__bottom__left__items__item">
              <span className="details__bottom__left__items__item-span titles total">
                Total:
              </span>
              <span className="details__bottom__left__items__item-span val total">
                € 187.95
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
