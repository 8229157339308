import React, { FC } from 'react';
import { IDefaultImage } from 'core/interface/DefaultImage';

import './Index.scss';

const DefaultImage: FC<IDefaultImage> = ({
  className,
  height = 150,
  width = 150,
  letter
}): JSX.Element => {
  return (
    <div
      style={{ width: width + 'px', height: height + 'px' }}
      className={`default ${className}`}
    >
      <span className="letter">{letter?.split('')[0]}</span>
    </div>
  );
};

export default DefaultImage;
