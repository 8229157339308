import { IApiBase } from './types';
import axios from '../../utils/axios';
import { store } from '../../redux/store';
import { setRequestError } from '../../redux/slices/errors';

export default class ApiBase<T> implements IApiBase<T> {
  public getAsync = async <P>(url: string, params?: P) => {
    const { dispatch } = store;
    try {
      const reqParams = params ? { params } : {};
      const response = await axios.get(url, reqParams);
      dispatch(setRequestError([{ response: { status: 200 } }]));
      return response.data;
    } catch (error: any) {
      dispatch(setRequestError(error));
      throw error[0]?.response?.data[0];
    }
  };

  public postAsync = async <R, P>(url: string, data?: R, params?: P) => {
    const { dispatch } = store;
    try {
      const reqParams = params ? { params } : {};
      const response = await axios.post(url, data, reqParams);
      dispatch(setRequestError([{ response: { status: 200 } }]));
      return response.data;
    } catch (error: any) {
      dispatch(setRequestError(error));
      throw error[0]?.response?.data[0];
    }
  };

  public updateAsync = async <R, P>(url: string, data: R, params?: P) => {
    const { dispatch } = store;
    try {
      const reqParams = params ? { params } : {};
      const response = await axios.patch(url, data, reqParams);
      dispatch(setRequestError([{ response: { status: 200 } }]));
      return response.data;
    } catch (error: any) {
      dispatch(setRequestError(error));
      throw error[0]?.response?.data[0];
    }
  };

  public putAsync = async <R, P>(url: string, data: R, params?: P) => {
    const { dispatch } = store;
    try {
      const reqParams = params ? { params } : {};
      const response = await axios.put(url, data, reqParams);
      dispatch(setRequestError([{ response: { status: 200 } }]));
      return response.data;
    } catch (error: any) {
      dispatch(setRequestError(error));
      throw error[0]?.response?.data[0];
    }
  };

  public deleteAsync = async (url: string) => {
    const { dispatch } = store;
    try {
      const response = await axios.delete(url);
      dispatch(setRequestError([{ response: { status: 200 } }]));
      return response.status === 204;
    } catch (error: any) {
      dispatch(setRequestError(error));
      throw error[0]?.response?.data[0];
    }
  };
}
