import { IAction } from 'core/interface/action';
import { Industries } from 'core/interface/education-side';

export const SAVE_LOCATION = 'save-industries';

const defaultState = {
  industry: null
};

export const SaveIndustries = (value: Industries[]) => ({
  type: SAVE_LOCATION,
  payload: value
});

const industriesReducer = (
  state = defaultState,
  action: IAction<Industries[]>
) => {
  switch (action.type) {
    case SAVE_LOCATION:
      return {
        ...state,
        industry: action.payload
      };
    default:
      return state;
  }
};

export default industriesReducer;
