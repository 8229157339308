import React, { FC, useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers, useField } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import useLocales from 'hooks/useLocales';
import { IJobForm } from 'core/interface/job';
import { InputType } from 'core/enum/inputType';
import { degreeTypes, statusTypes } from 'core/constants/aboutMePopUpConstants';
import {
  freelanceSalary,
  monthlySalary,
  workExperience
} from 'core/constants/preferenceConstats';
import { JobService } from 'services/jobs/job';
import { ProfileService } from 'services/profile/profile';
import { SkillService } from 'services/skills/skillService';
import FormField from 'components/FormField/Index';
import Checkboxes from 'components/shared/Checkbox/Index';
import DropDown from 'components/Dropdown';
import Autocomplete from 'components/shared/Autocomplete/Index';
import Button from 'components/Button/Index';
import Image from 'components/image/Image';
import { initialValues, validation } from './form';
import Multiselect from 'components/shared/Multiselect/Index';
import { OptionType } from 'core/interface/autocomplete';
import WritableChip from 'components/shared/WritableChip/Index';
import { MatchProfileService } from 'services/matchProfile/matchProfile';
import SingleSelectFormik from 'components/shared/SelectSingleFormik';
import { jobContracts } from 'core/constants/workExperience';

import './Index.scss';

const NewJobPopup: FC<any> = ({ nextStep, selectedJob }): JSX.Element => {
  const { t } = useLocales();
  const { industry: industries } = useSelector(
    (state: RootState) => state.industries
  );
  const { cities } = useSelector((state: RootState) => state.locations);
  const [selectedIndustries, setSelectedIndustries] = useState<OptionType[]>(
    []
  );
  const [skills, setSkills] = useState<any[]>([]);
  const [errorsForFields, setErrors] = useState({
    industriesError: false,
    requirementsError: false,
    skillsError: false
  });
  const { computerSkills } = useSelector(
    (state: RootState) => state.computerSkills
  );
  const getCities = async (q: string = '', limit: number = 10) => {
    JobService.getLocations({
      limit,
      q
    });
  };
  const { matchSearch } = useSelector((state: RootState) => state.match);

  const getSkills = (query: string = ''): void => {
    SkillService.getSkillsList(query);
  };


  const getSearchProfile = (query: string = '') => {
    MatchProfileService.getMatchProfilesSearch(query);
  };

  useEffect(() => {
    getSearchProfile();
    getSkills();
    getIndustries();
    getCities();
  }, []);

  const getIndustries = (query: string = ''): void => {
    ProfileService.getIndustries(query);
  };

  const handleSubmit = (
    values: IJobForm,
    action: FormikHelpers<IJobForm>
  ): void => {
    const obj = {
      ...values,
      industries: getIndustriesId(),
      skills: getSkillsId()
    };
    nextStep(obj as any | number[]);
    action.resetForm();
  };

  const getIndustriesId = (): number[] => {
    const selectedIndustry: number[] = [];
    industries?.forEach((ind) => {
      selectedIndustries.forEach((valInd) => {
        if (valInd.id === ind.id) {
          selectedIndustry.push(ind.id);
        }
      });
    });
    return selectedIndustry;
  };

  const getSkillsId = (): number[] => {
    const skill: number[] = [];
    computerSkills?.forEach((ind) => {
      skills.forEach((valInd) => {
        if (valInd.id === ind.id) {
          skill.push(ind.id);
        }
      });
    });
    return skill;
  };

  const handleIndustryChange = (industry: OptionType[]): void => {
    setSelectedIndustries((prev) => {
      const newIndustries = industry.filter((newIndustry) => {
        return !prev.some(
          (existingIndustry) => existingIndustry.id === newIndustry.id
        );
      });
      return [...prev, ...newIndustries];
    });
    if (!selectedIndustries.length) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industriesError: true
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industriesError: false
      }));
    }
  };

  const handleRemoveIndustry = (e: any): void => {
    setSelectedIndustries((prev) =>
      prev.filter((ind) => (ind.title || ind.name || ind) !== e)
    );
  };

  const handleSkillChange = (selectedSkills: OptionType[]): void => {
    setSkills((prevSkills) => {
      const newSkills = selectedSkills.filter((skill) => {
        return !prevSkills.some(
          (existingSkill) => existingSkill.id === skill.id
        );
      });
      return [...prevSkills, ...newSkills];
    });
    if (!skills.length) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        skillsError: true
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        skillsError: false
      }));
    }
  };
  const handleRemoveSkill = (e: any): void => {
    setSkills((prev) => prev.filter((ind) => (ind.title || ind.name || ind) !== e));
  };

  const filterIndustries = (data: any, filterData: any) => {
    const selectedIndustriesArray: any = [];

    filterData?.forEach((id: any) => {
      const industry = data.find((item: any) => item.id === id);
      if (industry) {
        selectedIndustriesArray.push(industry);
      }
    });

    return selectedIndustriesArray;
  };

  useEffect(() => {
    if (selectedJob) {
      setSelectedIndustries(
        filterIndustries(industries, selectedJob?.industries)
      );
      setSkills(filterIndustries(computerSkills, selectedJob?.skills));
    }
  }, [selectedJob]);

  const initialValuesForEdit: any = {
    skills,
    degree: selectedJob?.degree,
    industries: selectedIndustries,
    is_public: selectedJob?.is_public,
    job_description: selectedJob?.description,
    job_title: selectedJob?.title,
    location: cities?.find((e: any) => {
      if (e?.id == selectedJob?.locations?.[0]) {
        return e?.display_name;
      }
    }),
    prefered_salary: selectedJob?.salary,
    required_experience: selectedJob?.work_exp,
    requirements: selectedJob?.musts,
    culture_profile: selectedJob?.culture_profile,
    is_remote: selectedJob?.is_remote,
    if_office_work: selectedJob?.if_office_work,
    status: selectedJob?.status,
    duration: selectedJob?.duration,
    hourly_rate: +selectedJob?.hourly_rate,
  };

  const handleLoadMoreCities = () => {
    const params = { limit: 10, offset: cities.length };
    JobService.getLocations(params, true);
  };

  return (
    <div className="njob scrollable">
      <Formik
        initialValues={selectedJob?.id ? initialValuesForEdit : initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnChange
        validationSchema={validation(t)}
      >
        {({
          setFieldValue,
          setFieldError,
          setFieldTouched,
          isValid,
          errors,
          touched,
          values
        }) => {
          return (
            <Form>
              <div className="fields">
                <div className="field many">
                  <span className="label">{t('Job title')}</span>
                  <FormField
                    fieldName="job_title"
                    placeholder={t('Job title')}
                    type={InputType.text}
                    inputClassName="input"
                  />
                </div>
                <div className="field many autocomplete">
                  <span className="label">{t('Select culture profile')}</span>
                  <SingleSelectFormik
                    fieldName="culture_profile"
                    onChange={(e: any) => {
                      if (typeof e != 'string') {
                        setFieldValue('culture_profile', e);
                      }
                    }}
                    onBlur={(e: any) => {
                      if (
                        matchSearch?.length &&
                        !matchSearch.some(
                          (el: any) => el.title === e.target.value
                        )
                      ) {
                        setFieldValue('culture_profile', null);
                        setFieldError('culture_profile', 'required');
                        setFieldTouched('culture_profile', true);
                      }
                    }}
                    options={matchSearch}
                    show_location={false}
                    placeholder={''}
                    onInputChange={(e: any) => getSearchProfile(e)}
                    value={values?.culture_profile || ''}
                  />
                </div>
              </div>
              <div className="field">
                <span className="label">{t('Job description')}</span>
                <FormField
                  fieldName="job_description"
                  placeholder={t('Job description')}
                  type={InputType.text}
                  inputClassName="input"
                />
              </div>
              <div className="field">
                <Checkboxes
                  onArrayChange={(e) => {
                    if (Array.isArray(e)) {
                      setFieldValue('is_public', e[0].isChecked);
                    }
                  }}
                  checkboxes={[
                    {
                      label: t('Show this job on my careerpage'),
                      isChecked: values?.is_public
                    }
                  ]}
                />
              </div>
              <div className="fields">
                <div className="field many">
                  <span className="label">{t('Required Degree')}</span>
                  <DropDown
                    placeholder={t('Degree')}
                    items={degreeTypes}
                    selectedValue={(e) => setFieldValue('degree', e)}
                    defaultValue={initialValuesForEdit?.degree}
                  />
                </div>
                <div className="field many autocomplete">
                  <span className="label">{t('Location')}</span>
                  <Autocomplete
                    className={`${touched.location?.display_name &&
                      errors?.location?.display_name
                      ? ''
                      : ''
                      } auto`}
                    value={values?.location?.display_name}
                    options={
                      cities?.map((e: any) => ({
                        id: e?.id,
                        title: e?.display_name
                      })) || []
                    }
                    show_location={true}
                    placeholder={t('City')}
                    onChange={(e) => {
                      const response = cities?.find(
                        (city: any) => city.display_name === e
                      );
                      setFieldValue('location', response);
                    }}
                    fieldName="location"
                    onInputChange={(e) => getCities(e)}
                    values={cities}
                    onFocus={(e) => {
                      getCities(e);
                    }}
                    loadMore={handleLoadMoreCities}
                  />
                  <span className="error-msg">
                    {touched.location?.display_name &&
                      errors?.location?.display_name}
                  </span>
                </div>
              </div>
              <div className="fields">
                <div className="field many">
                  <span className="label">{t('Industries')}</span>
                  <Multiselect
                    fieldName="industries"
                    value={initialValuesForEdit?.industries ?? selectedIndustries}
                    className={`${errors.industries && errorsForFields.industriesError
                      ? 'error'
                      : ''
                      }`}
                    placeholder={t('Industry')}
                    show_location={false}
                    onChange={(e: string | OptionType[]) => {
                      if (typeof e != 'string') {
                        handleIndustryChange(e);
                        setFieldValue('industries', [e[0].id]);
                      }
                    }}
                    onInputChange={(e) => getIndustries(e ?? '')}
                    options={
                      industries?.map((e) => ({
                        id: e?.id,
                        title: e?.name
                      })) || []
                    }
                  />
                  {selectedIndustries?.map((industry, index) => (
                    <span className="industry-selected" key={index}>
                      {industry.name ?? industry?.title}{' '}
                      <span
                        onClick={() => {
                          setFieldValue(
                            'industries',
                            selectedIndustries.filter((e) => e.id !== industry.id)
                          );
                          handleRemoveIndustry(
                            industry.name ?? industry?.title ?? industry ?? ""
                          );
                        }}
                      >
                        <Image
                          className="remove"
                          name="removes.svg"
                          width={10}
                          height={10}
                        />
                      </span>
                    </span>
                  ))}
                  <span className="error-msg">
                    {errorsForFields.industriesError && errors.industries}
                  </span>
                </div>
                <div className="field many" >
                  <span className="label">{t('Requirements')}</span>
                  <WritableChip
                    className={`${errors.requirements &&
                      errorsForFields.requirementsError &&
                      touched.requirements
                      ? 'error'
                      : ''
                      }`}
                    inputClassName="input"
                    placeholder={t('')}
                    handleError={setErrors}
                    onSelectedValues={(e: string[]) =>
                      setFieldValue('requirements', e)
                    }
                    defaultValues={values?.requirements}
                    setTouch={() => setFieldTouched('requirements', true)}
                  />
                  <span className="error-msg">
                    {errorsForFields.requirementsError &&
                      touched.requirements &&
                      errors.requirements}
                  </span>
                </div>
              </div>
              <div className="checkboxes">
                <div className="field ">
                  <Checkboxes
                    onArrayChange={(e) => {
                      if (Array.isArray(e)) {
                        setFieldValue('is_remote', e[0].isChecked);
                      }
                    }}
                    checkboxes={[
                      {
                        label: t('Remote work'),
                        isChecked: values?.is_remote
                      }
                    ]}
                  />
                </div>
                <div className="field many">
                  <Checkboxes
                    onArrayChange={(e) => {
                      if (Array.isArray(e)) {
                        setFieldValue('if_office_work', e[0].isChecked);
                      }
                    }}
                    checkboxes={[
                      {
                        label: t('Office work'),
                        isChecked: values?.if_office_work
                      }
                    ]}
                  />
                </div>
              </div>
              <div className='fields'>
                <div className="field many">
                  <span className="label">{t('Computer skills')}</span>
                  <Multiselect
                    fieldName="skills"
                    value={skills}
                    className={`${errors.skills && errorsForFields.skillsError ? 'error' : ''
                      }`}
                    placeholder={t('Text')}
                    show_location={false}
                    onChange={(e: string | OptionType[]) => {
                      if (typeof e != 'string') {
                        handleSkillChange(e);
                        setFieldValue('skills', [e[0].id]);
                      }
                    }}
                    onInputChange={(e) => getIndustries(e ?? '')}
                    options={
                      computerSkills?.map((e) => ({
                        id: e?.id,
                        title: e?.name
                      })) || []
                    }
                  />
                  {skills?.map((skill, index) => (
                    <span className="industry-selected" key={index}>
                      {skill.title ?? skill.name}{' '}
                      <span
                        onClick={() => {
                          setFieldValue(
                            'skills',
                            skills.filter((e) => e.id !== skill.id)
                          );
                          handleRemoveSkill(skill.title ?? skill.name ?? skill ?? '');
                        }}
                      >
                        <Image
                          className="remove"
                          name="removes.svg"
                          width={10}
                          height={10}
                        />
                      </span>
                    </span>
                  ))}
                  <span className="error-msg">
                    {errorsForFields.skillsError && errors.skills}
                  </span>
                </div>
                <div className="field many">
                  <span className="label">{t('Status')}</span>
                  <DropDown
                    placeholder={t('Published')}
                    items={statusTypes}
                    selectedValue={(e) => setFieldValue('status', e)}
                    defaultValue={initialValuesForEdit?.status}
                  />
                </div>
              </div>
              <div className="fields">
                <div className="field many">
                  <span className="label">{t('Required work experience')}</span>
                  <DropDown
                    items={workExperience}
                    selectedValue={(e) =>
                      setFieldValue('required_experience', e)
                    }
                    defaultValue={initialValuesForEdit?.required_experience}
                  />
                </div>
                <div className="field many autocomplete">
                  <span className="label">{t('Prefered monthly salary')}</span>
                  <DropDown
                    dropdownClassName={`${errors.prefered_salary && touched.prefered_salary
                      ? 'error'
                      : ''
                      }`}
                    items={monthlySalary}
                    selectedValue={(e) => setFieldValue('prefered_salary', e)}
                    defaultValue={initialValuesForEdit?.prefered_salary}
                  />
                </div>
              </div>
              <div className="fields">
                <div className="field many">
                  <span className="label">{t('Contract')}</span>
                  <DropDown
                    items={jobContracts}
                    selectedValue={(e) =>
                      setFieldValue('duration', e)
                    }
                    defaultValue={initialValuesForEdit?.duration}
                  />
                </div>
                <div className="field many autocomplete">
                  <span className="label">{values?.duration === 4 ? t('Hourly Rate (EUR)') : t('Salary Range(EUR)')}</span>
                  <DropDown
                    dropdownClassName={`${errors.hourly_rate && touched.hourly_rate
                      ? 'error'
                      : ''
                      }`}
                    items={values?.duration === 4 ? freelanceSalary : monthlySalary}
                    selectedValue={(e) => setFieldValue('hourly_rate', e)}
                    defaultValue={initialValuesForEdit?.hourly_rate}
                  />
                </div>
              </div>
              <Button
                t={t}
                type="submit"
                title="Next"
                className="save "
                disabled={!isValid}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NewJobPopup;
