import { IAction } from 'core/interface/action';
import {
  ICandidateLanguage,
  ICandidateLanguageData
} from 'core/interface/candidate-language';

export const SAVE_LANGUAGES = 'save-languages';
export const SAVE_CANDIDATE_LANGUAGES = 'save-candidate-languages';

const defaultState = {
  languages: null,
  candidate_language: null
};

export const SaveLanguages = (value: ICandidateLanguage[]) => ({
  type: SAVE_LANGUAGES,
  payload: value
});

export const SaveCandidateLanguages = (value: ICandidateLanguageData[]) => ({
  type: SAVE_CANDIDATE_LANGUAGES,
  payload: value
});

const languagesReducer = (
  state = defaultState,
  action: IAction<ICandidateLanguage[] | ICandidateLanguageData[]>
) => {
  switch (action.type) {
    case SAVE_LANGUAGES:
      return {
        ...state,
        languages: action.payload
      };
    case SAVE_CANDIDATE_LANGUAGES:
      return {
        ...state,
        candidate_language: action.payload as ICandidateLanguageData[]
      };
    default:
      return state;
  }
};

export default languagesReducer;
