import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers, FieldArray, ErrorMessage } from 'formik';
import Button from 'components/Button/Index';
import Activation from 'components/feature/activation/Index';
import ActionButton from 'components/ActionButton/Index';
import FormField from 'components/FormField/Index';
import useLocales from 'hooks/useLocales';
import { InputType } from 'core/enum/inputType';
import { validationSchema } from './form';
import { CMSService } from 'services/cms/cms';

import './Index.scss';

const benefitHasChanged = (currentBenefit: any, originalBenefit: any) => {
  let hasChanged = false;
  const changedFields: any = {};
  if (currentBenefit.description !== originalBenefit.description) {
    hasChanged = true;
  }
  if (currentBenefit.title !== originalBenefit.title) {
    hasChanged = true;
  }
  if (hasChanged) {
    changedFields.description = currentBenefit.description;
    changedFields.title = currentBenefit.title;
    changedFields.page = currentBenefit.page;

    return {
      id: currentBenefit.id,
      page: currentBenefit.page,
      changedFields: changedFields
    };
  }
  return null;
};

const OurBenefits = ({
  onSubmit,
  onBack,
  onClose,
  setCompanyData,
  companyData,
  isPopup = false,
  className,
  isOneBtn = false,
  benefitData,
  setBenFits,
  showTopSide,
  setNewData,
  setBenefitData,
  showTitle
}: any) => {
  const { t } = useLocales();
  const [formValues, setFormValues] = useState({
    benefit_section_title: '',
    benefit_section_description: '',
    benefits: [
      {
        title: '',
        description: ''
      }
    ]
  });

  useEffect(() => {
    setFormValues({
      benefit_section_title: companyData?.benefit_section_title || '',
      benefit_section_description:
        companyData?.benefit_section_description || '',
      benefits: benefitData?.benefits ||
        companyData?.benefits || [
          {
            title: '',
            description: ''
          }
        ]
    });
  }, [companyData]);

  const handleRemove = (benefit: any) => {
    const { page, id } = benefit;
    CMSService.removeBenefits(page, id);
  };

  const handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
    const { benefit_section_title, benefit_section_description, benefits } =
      values;
    if (setCompanyData) {
      setCompanyData((prev: any) => ({
        ...prev,
        benefit_section_title,
        benefit_section_description
      }));
      setBenFits((prev: any) => ({
        ...prev,
        benefits
      }));
      onSubmit();
    } else {
      const data: any = {};
      if (benefit_section_title !== companyData.benefit_section_title) {
        data.benefit_section_title = benefit_section_title;
      }
      if (
        benefit_section_description !== companyData.benefit_section_description
      ) {
        data.benefit_section_description = benefit_section_description;
      }
      const newBenefits: any[] = [];
      const changedBenefits: any[] = [];
      benefits.forEach((benefit: any, index: number) => {
        const originalBenefit = (companyData.benefits || [])[index] || {};
        if (benefit.isNew) {
          newBenefits.push(benefit);
        } else {
          const result = benefitHasChanged(benefit, originalBenefit);
          if (result) {
            changedBenefits.push(result);
          }
        }
      });
      data && setNewData(data);
      changedBenefits && setBenefitData(changedBenefits, false);
      newBenefits && setBenefitData(newBenefits, true);
    }
  };

  return (
    <div className="who">
      <Activation
        showTopSide={showTopSide}
        title="Our benefits"
        currentStep={7}
        className={
          isPopup ? '' : `activation-edu ${className ?? ''} ${className}`
        }
        headerIconDisable
        totalSteps={8}
        isCareerPage={true}
        handleModalClose={onClose}
        isPopup={true}
        showTitle={showTitle}
      >
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={validationSchema(t)}
        >
          {({ isValid, values, touched, errors }) => (
            <Form>
              <div className="organization scrollable">
                <div className="fields">
                  <span className="label">Title</span>
                  <FormField
                    inputClassName="input"
                    fieldName="benefit_section_title"
                    placeholder="Text"
                    type={InputType.text}
                  />
                </div>
                <div className="fields">
                  <span className="label">Description</span>
                  <FormField
                    inputClassName="input"
                    fieldName="benefit_section_description"
                    placeholder="Text"
                    type={InputType.text}
                  />
                </div>
                <FieldArray name="benefits">
                  {({ push, remove }) => (
                    <>
                      {values?.benefits &&
                        values?.benefits?.map((benefit: any, index: number) => (
                          <div key={index}>
                            {isPopup && (
                              <div className="addJob">
                                <ActionButton
                                  iconName="remove.svg"
                                  onClick={() => handleRemove(benefit)}
                                />
                                <span>Remove</span>
                              </div>
                            )}
                            <div className="field">
                              <span className="label">Name of benefit</span>
                              <FormField
                                inputClassName={`input ${
                                  (errors as any)?.benefits?.[index]?.title &&
                                  (touched as any).benefits?.[index]?.title
                                    ? 'error'
                                    : ''
                                }`}
                                fieldName={`benefits.${index}.title`}
                                placeholder="Name"
                                type={InputType.text}
                              />
                              <ErrorMessage name={`benefits.${index}.title`}>
                                {(msg) => <div className="error-ms">{msg}</div>}
                              </ErrorMessage>
                            </div>
                            <div className="field">
                              <span className="label">Description</span>
                              <FormField
                                inputClassName={`input ${
                                  (errors as any)?.benefits?.[index]
                                    ?.description &&
                                  (touched as any).benefits?.[index]
                                    ?.description
                                    ? 'error'
                                    : ''
                                }`}
                                fieldName={`benefits.${index}.description`}
                                placeholder="Description"
                                type={InputType.text}
                              />
                              <ErrorMessage
                                name={`benefits.${index}.description`}
                              >
                                {(msg) => <div className="error-ms">{msg}</div>}
                              </ErrorMessage>
                            </div>
                          </div>
                        ))}
                      <div className="addJob">
                        <ActionButton
                          iconName="plus.svg"
                          onClick={() => push({ isNew: true })}
                        />
                        <span>Add Another Benefit</span>
                      </div>
                    </>
                  )}
                </FieldArray>
                {!isOneBtn ? (
                  <div className="button_container">
                    <Button
                      onClick={onBack}
                      t={t}
                      title="Back"
                      className="av__content__buttons-back"
                    />
                    <Button
                      className={`next ${!isValid ? 'invalid' : `${isValid}`}`}
                      type="submit"
                      title="Next"
                      t={t}
                    />
                  </div>
                ) : (
                  <div className="btn_container">
                    <Button
                      className="next button one-btn"
                      type="submit"
                      t={t}
                      title={'Save'}
                    />
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Activation>
    </div>
  );
};

export default OurBenefits;
