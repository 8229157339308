import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik, FormikHelpers } from 'formik';
import { initialValues, validationSchema } from './form';
import useLocales from 'hooks/useLocales';
import DropDown from 'components/Dropdown';
import FormField from 'components/FormField/Index';
import Button from 'components/Button/Index';
import { ISocialNetwork, ISocialNetworkPopup } from 'core/interface/aboutMe';
import { socialNetwork } from 'core/constants/aboutMePopUpConstants';
import { IMe } from 'core/interface/me';
import { InputType } from 'core/enum/inputType';
import { ProfileService } from 'services/profile/profile';

import './Index.scss';

const AddSocialMediaPopup: FC<ISocialNetworkPopup> = ({
  onClose
}): JSX.Element => {
  const { t } = useLocales();
  const me = useSelector((state: IMe) => state.user).user;

  const handleSubmit = (
    values: ISocialNetwork,
    actions: FormikHelpers<ISocialNetwork>
  ): void => {
    ProfileService.postSocialMedias(me.candidate_id, values);
    onClose();
  };

  return (
    <div className="socialMedia">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema(t)}
        onSubmit={handleSubmit}
      >
        {({ isValid, values, errors, setFieldValue }) => {
          return (
            <Form>
              <div className="formData">
                <div className="fieldset">
                  <DropDown
                    dropdownClassName={`${errors.network ? 'error-side' : ''}`}
                    error={errors.network}
                    defaultValue={values.network}
                    placeholder={t('Social network')}
                    items={socialNetwork}
                    selectedValue={(e) => {
                      setFieldValue('network', e);
                    }}
                  />
                  <FormField
                    inputClassName="input"
                    fieldName="url"
                    type={InputType.text}
                    placeholder="URL"
                  />
                </div>
              </div>
              <Button
                t={t}
                title="Save"
                type="submit"
                className={`save-btn ${!isValid ? 'invalid-btn' : ''}`}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddSocialMediaPopup;
