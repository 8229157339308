import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Formik, Form, FormikHelpers } from 'formik';
import { RegisterCompanyType, SignUpType } from 'core/interface/auth';
import { InputType } from 'core/enum/inputType';
import { IMe } from 'core/interface/me';
import FormField from 'components/FormField/Index';
import Button from 'components/Button/Index';
import { candidateValidation, initialValues, talentValidation } from './form';
import { AuthService } from '../../services/auth/auth';
import AuthContent from 'components/AuthContent';
import Checkboxes from 'components/shared/Checkbox/Index';
import useLocales from 'hooks/useLocales';
import { SaveUser, UpdateUser } from '../../redux/slices/user';
import { store } from '../../redux/store';
import Activation from 'components/feature/activation/Index';
import { ISignUpCandidate } from 'core/interface/candidate';
import { ActivateCompanyEnum } from 'core/enum/activationPage';
import ActivationWelcome from 'components/feature/activation/welcome/Index';
import ActivationCompanyLogo from 'components/feature/activation/companyLogo';
import CompanyInfo from 'components/feature/activation/companyInfo/Index';
import CompanyInfoSecond from 'components/feature/activation/companyInfoSecond/Index';
import { Role } from 'core/enum/role';

import './index.scss';

const SignUp: FC = () => {
  const [searchParams] = useSearchParams();
  const userRole = searchParams.get('role');
  const [loading, setLoading] = useState<boolean>(false);
  const [isActivated, setIsActivated] = useState<boolean>(false);
  const [role, setRole] = useState<Role>((userRole as Role) ?? Role.CANDIDATE);
  const [isCandidate, setIsCandidate] = useState<boolean>(false);
  const [link, setLink] = useState<string>(ActivateCompanyEnum.WELCOME);
  const [contentToShows, setContentToShow] = useState<JSX.Element>();
  const [enableBTN, setEnableBTN] = useState<boolean>(false);
  const { t } = useLocales();
  const me = useSelector((state: IMe) => state.user).user;

  const formData = new FormData();

  useEffect(() => {
    setIsCandidate(role === Role.CANDIDATE);
  }, [role]);

  useEffect(() => {
    contentToShow();
  }, [link]);

  const handleSubmit = async (
    values: SignUpType | RegisterCompanyType,
    e: FormikHelpers<SignUpType>
  ): Promise<void> => {
    setLoading(true);
    try {
      const response = isCandidate
        ? await AuthService.signUp(values as SignUpType)
        : await AuthService.companyRegister(values as RegisterCompanyType);

      if (isCandidate && response && (response as ISignUpCandidate)?.token) {
        Cookies.set('accesstoken', (response as ISignUpCandidate)?.token);
        getMe();
      }
      setIsActivated(true);
      e.resetForm();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getMe = async () => {
    const { dispatch } = store;
    try {
      const response = await AuthService.getAccount();
      if (me) {
        dispatch(UpdateUser(response));
      } else {
        dispatch(SaveUser(response));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRoleSubmit = (role: Role): void => {
    setRole(role);
  };

  const handleOnNextPage = (page: string): void => {
    setLink(() => page);
  };

  const contentToShow = (): void => {
    switch (link) {
      case ActivateCompanyEnum.WELCOME:
        setContentToShow(
          <ActivationWelcome
            t={t}
            title={t(
              'Thank you for your trust. We will send you an e-mail as soon as we reviewed your request.'
            )}
            message={t(
              'An email has been sent to the provided email address. As soon as we have performed our checks we will be in touch with you. Have a great day!'
            )}
          />
        );
        break;

      default:
        setContentToShow(
          <ActivationWelcome
            title={`${t('Welcome')}, ${me?.first_name} ${me?.last_name}`}
            message={t('activation_congrats')}
            onGetStarted={() =>
              handleOnNextPage(ActivateCompanyEnum.COMPANY_LOGO)
            }
            t={t}
          />
        );
    }
  };

  const candidateForm = (isValid: boolean): JSX.Element => {
    return (
      <Form>
        <div className="field">
          <FormField
            inputClassName="inputs"
            fieldName="first_name"
            type={InputType.text}
            placeholder={t('first_name')}
          />
        </div>
        <div className="field">
          <FormField
            inputClassName="inputs"
            fieldName="last_name"
            type={InputType.text}
            placeholder={t('last_name')}
          />
        </div>
        <div className="field">
          <FormField
            inputClassName="inputs"
            fieldName="email"
            type={InputType.text}
            placeholder={t('email')}
          />
        </div>
        <div className="field">
          <FormField
            inputClassName="inputs"
            fieldName="password"
            type={InputType.password}
            placeholder={t('password')}
          />
        </div>
        <Checkboxes
          onArrayChange={(e) => {
            if (Array.isArray(e)) {
              setEnableBTN(Boolean(e[0].isChecked));
            }
          }}
          checkboxes={[
            {
              label: t('I have read and agree to the Terms of Service'),
              isChecked: enableBTN
            }
          ]}
        />
        <Button
          t={t}
          isLoading={loading}
          title="sign_up"
          type="submit"
          className={`submit-button ${
            isValid && enableBTN ? '' : 'invalid-btn'
          }`}
        />
      </Form>
    );
  };

  const talentForm = (isValid: boolean): JSX.Element => {
    return (
      <Form>
        <div className="field">
          <FormField
            inputClassName="inputs"
            fieldName="name"
            type={InputType.text}
            placeholder={t('Name of the company')}
          />
        </div>
        <div className="field">
          <FormField
            inputClassName="inputs"
            fieldName="website"
            type={InputType.text}
            placeholder={t('Company Website')}
          />
        </div>
        <div className="field">
          <FormField
            inputClassName="inputs"
            fieldName="contact_name"
            type={InputType.text}
            placeholder={t('Your full name')}
          />
        </div>
        <div className="field">
          <FormField
            inputClassName="inputs"
            fieldName="contact_email"
            type={InputType.text}
            placeholder={t('email')}
          />
        </div>
        <div className="field">
          <FormField
            inputClassName="inputs"
            fieldName="voucher_code"
            type={InputType.text}
            placeholder={t('Voucher code')}
          />
        </div>
        <Button
          t={t}
          isLoading={loading}
          title={t('Try it for free!')}
          type="submit"
          className={`submit-button ${!isValid ? 'invalid-btn' : ''}`}
        />
      </Form>
    );
  };

  return (
    <>
      {!isActivated ? (
        <AuthContent
          title={
            isCandidate ? t('Let’s get started!') : t('Welcome to Recrout')
          }
          onRoleSubmit={handleRoleSubmit}
          role={role}
          backURL="/login"
        >
          <div className="up">
            <p className="up__desc">
              {isCandidate
                ? t('Recruit is Talent Platform designed for young professionals with a bachelor or masters degree')
                : t('Please sign up and we will get you going in a heartbeat')}
            </p>

            <Formik
              initialValues={initialValues}
              validationSchema={
                isCandidate ? candidateValidation(t) : talentValidation(t)
              }
              onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
              {({ isValid }) =>
                isCandidate ? candidateForm(isValid) : talentForm(isValid)
              }
            </Formik>
          </div>
        </AuthContent>
      ) : isCandidate ? (
        <Activation
          title={t('activate_account')}
          sub_title={t('activation_subtitle')}
        />
      ) : (
        <div className="activations">{contentToShows}</div>
      )}
    </>
  );
};

export default SignUp;
