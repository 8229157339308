import React, { FC } from 'react';
import useLocales from 'hooks/useLocales';

import './Index.scss';

const NoData: FC = (): JSX.Element => {
  const { t } = useLocales();

  return (
    <div className="noData">
      <span className="noData__text">{t('no_data')}</span>
    </div>
  );
};

export default NoData;
