import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Button from 'components/Button/Index';
import Activation from 'components/feature/activation/Index';
import useLocales from 'hooks/useLocales';
import { MatchProfileService } from 'services/matchProfile/matchProfile';
import SingleSelectFormik from 'components/shared/SelectSingleFormik';
import Checkboxes from 'components/shared/Checkbox/Index';
import { validationSchema } from './form';

import './Index.scss';

const JobOpenings: any = ({
  onSubmit,
  onBack,
  onClose,
  setCompanyData,
  companyData,
  isPopup = false,
  className,
  isOneBtn = false,
  showTopSide,
  setNewData,
  showTitle
}: any): JSX.Element => {
  const { t } = useLocales();
  const [formValues, setFormValues] = useState<any>({
    show_jobs: companyData?.show_jobs || false,
    primary_culture_profile:
      companyData?.primary_culture_profile?.id ||
      companyData?.primary_culture_profile ||
      null
  });

  const handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
    if (setCompanyData) {
      setCompanyData((prev: any) => ({
        ...prev,
        ...values
      }));
      onSubmit && onSubmit();
    } else {
      const data = {
        ...values
      };
      setNewData && setNewData(data);
    }
  };
  const { matchSearch } = useSelector((state: RootState) => state.match);

  const getSearchProfile = (query: string = '') => {
    MatchProfileService.getMatchProfilesSearch(query);
  };

  useEffect(() => {
    getSearchProfile();
  }, []);

  return (
    <div className="who">
      <Activation
        title="Job openings"
        showTopSide={showTopSide}
        currentStep={2}
        className={
          isPopup ? '' : `activation-edu ${className ?? ''} ${className}`
        }
        headerIconDisable
        totalSteps={8}
        isCareerPage={true}
        handleModalClose={onClose}
        isPopup={true}
        showTitle={showTitle}
      >
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={validationSchema(t)}
        >
          {({
            setFieldValue,
            isValid,
            setFieldError,
            values,
            errors,
            touched,
            setFieldTouched
          }) => {
            return (
              <Form>
                <div className="containerJob">
                  <div className="addJob">
                    <div className="field">
                      <Checkboxes
                        onArrayChange={(e) => {
                          if (Array.isArray(e)) {
                            setFieldValue('show_jobs', e[0].isChecked);
                          }
                        }}
                        checkboxes={[
                          {
                            label: 'Show all jobs on career page',
                            isChecked: values.show_jobs
                          }
                        ]}
                      />
                    </div>
                  </div>
                  <div className="autocomplete">
                    <span className="label">{t('Select culture profile')}</span>
                    <SingleSelectFormik
                      fieldName="primary_culture_profile"
                      onChange={(e: any) => {
                        if (typeof e != 'string') {
                          setFieldValue('primary_culture_profile', e?.id);
                        }
                      }}
                      onBlur={(e: any) => {
                        if (
                          matchSearch?.length &&
                          !matchSearch.some(
                            (el: any) => el.title === e.target.value
                          )
                        ) {
                          setFieldValue('primary_culture_profile', null);
                          setFieldError('primary_culture_profile', 'required');
                          setFieldTouched('primary_culture_profile', true);
                        }
                      }}
                      options={matchSearch}
                      show_location={false}
                      placeholder={''}
                      value={matchSearch?.find(
                        (el: { id: number }) =>
                          el.id === values?.primary_culture_profile
                      )}
                    />
                  </div>
                  {!isOneBtn ? (
                    <div className="button_container">
                      <Button
                        onClick={onBack}
                        t={t}
                        title="Back"
                        className="av__content__buttons-back"
                      />
                      <Button
                        className={`next ${
                          !isValid ? 'invalid' : `${isValid}`
                        }`}
                        type="submit"
                        title="Next"
                        t={t}
                      />
                    </div>
                  ) : (
                    <div className="btn_container">
                      <Button
                        className="next button one-btn"
                        type="submit"
                        t={t}
                        title={'Save'}
                      />
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Activation>
    </div>
  );
};

export default JobOpenings;
