import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { ImageModalProps } from './types';

import './Index.scss';

export default function ImageModal({
  open,
  handleClose,
  image
}: ImageModalProps): JSX.Element {
  const [opacity, setOpacity] = useState(0);
  
  return (
    <Modal
      style={{ opacity }}
      open={open}
      onClose={handleClose}
      className="image-modal"
    >
      <div className="image_modal-content">
        <img src={image ?? ''} alt="modal_img" onLoad={() => setOpacity(1)} />
      </div>
    </Modal>
  );
}
