import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { CareerForm } from './types';

const initialValues: CareerForm = {
  name: '',
  email: '',
  message: ''
};

const validation = (t: TFunction) => {
  return Yup.object({
    name: Yup.string().required(t('required')),
    email: Yup.string().email().trim().required(t('required')),
    message: Yup.string().required(t('required'))
  });
};

export { initialValues, validation };
