import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'hooks/useLocales';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import { JobService } from 'services/jobs/job';

import './index.scss';

const MatchResultHeader = ({ data }: any): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useLocales();
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [ids, setIds] = useState<string[]>([]);
  const { cities } = useSelector((state: RootState) => state.locations);

  const getCities = async (q: string = '', limit: number = 10) => {
    JobService.getLocations({
      limit,
      q
    });
  };
  useEffect(() => {
    getCities()
  }, [])

  const handleNext = () => {
    let info
    if (
      selectedLabels.length >= 7 && selectedLabels.length <= 10
    ) {
      const idLabelArray = ids.map((id, index) => ({
        id,
        competency: selectedLabels[index]
      }));
      if (data.is_culture !== undefined) {
        info = {
          job_title: data?.title,
          culture_profile: data?.culture_profile,
          competencies: idLabelArray,
          is_culture: false,
          edit: data ? data?.id : false,
          scores: data?.competency_min_scores
        };
      } else {
        info = {
          skills: data?.skills,
          culture_profile: data?.culture_profile,
          degree: data?.degree,
          industries: data?.industries,
          is_public: data?.is_public,
          job_description: data?.description,
          job_title: data?.title,
          location: cities?.find((e: any) => {
            if (e?.id == data?.locations?.[0]) {
              return e?.display_name;
            }
          }),
          prefered_salary: data?.salary,
          required_experience: data?.work_exp,
          requirements: data?.musts,
          scores: data?.competency_min_scores,
          competencies: idLabelArray,
          edit: data ? data?.id : false,
          is_remote: data?.is_remote,
          if_office_work: data?.if_office_work,
          status: data?.status,
          duration: data?.duration,
          hourly_rate: data?.hourly_rate
        }

      }
      navigate('/culture');

      localStorage.setItem('selected', JSON.stringify(info));
    }
  };

  useEffect(() => {
    if (data) {
      setSelectedLabels(data?.competency_labels);
      const a = data?.competencies?.map(
        (e: any) => e?.competency?.id
      );
      setIds(a);
    }
  }, [data]);

  return (
    <div className="result-header">
      <div className="back_button" onClick={() => navigate('/company')}>
        <Image name="arrow-left.svg" />
        Back to Mach Profile
      </div>
      <div className="match-settings">
        <div className="top">
          <div className="match-settings-text">
            <p className="title">Requested competencies</p>
            <span className="text">{data?.title}</span>
          </div>
          <Button
            onClick={handleNext}
            title="Edit match settings"
            type="button"
            t={t}
            className="edit-mach"
          />
        </div>
        <div className="bottom">
          {data?.competencies?.map((e: any) => (
            <div key={e.text} className="settings-item">
              {e?.competency?.name} ({e?.min_score} - 9)
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MatchResultHeader;
