import { TFunction } from 'i18next';
import * as Yup from 'yup';

const validationSchema = (t: TFunction) => {
  return Yup.object({
    primary_culture_profile: Yup.number().required(t('is required')),
    show_jobs: Yup.boolean().default(false)
  });
};

export { validationSchema };
