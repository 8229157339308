import { IAutocompleteItem } from 'core/interface/autocompleteItem';
import { InitialValueBody } from 'core/interface/itSkill';
import { SaveSkills } from '../../redux/slices/skills';
import { store } from '../../redux/store';
import ApiBase from 'services/base/apiBase';
import { prepareUpdatedData } from '../../helpers/stateChanges';
import { SaveCMS } from '../../redux/slices/cms';

export class CMSService {
  private static apiBase = new ApiBase();

  public static async getCMS(candidateId: string): Promise<any[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(`/cms/${candidateId}/`);
      dispatch(SaveCMS(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async sendContacts(
    body: any
  ): Promise<IAutocompleteItem[] | null> {
    try {
      const res = await this.apiBase.postAsync(`/cms/contact/`, body);

      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async updateCMS(
    candidateId: string,
    body: any
  ): Promise<IAutocompleteItem[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.updateAsync(`/cms/${candidateId}/`, body);
      const updatedState = prepareUpdatedData('update', 'cms', res, 'cms' );
      dispatch(SaveCMS(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async updateCMSTestimonials(
    candidateId: string,
    body: any,
    tagName?: string
  ): Promise<IAutocompleteItem[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.postAsync(
        `/cms/${candidateId}/testimonials/`,
        body
      );
      const updatedState = prepareUpdatedData(
        'update',
        'cms',
        res,
        'cms',
        tagName
      );
      dispatch(SaveCMS(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async updateCMSTestimonial(
    candidateId: string,
    body: any,
    id: number
  ): Promise<IAutocompleteItem[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.updateAsync(
        `/cms/${candidateId}/testimonials/${id}/`,
        body
      );
      const updatedState = prepareUpdatedData(
        'update',
        'cms',
        res,
        'cms',
        'testimonials'
      );
      dispatch(SaveCMS(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async removeTestimonials(
    page_id: number,
    image_id: number
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.deleteAsync(
        `/cms/${page_id}/testimonials/${image_id}/`
      );
      const updatedState = prepareUpdatedData(
        'delete',
        'cms',
        {
          id: image_id
        },
        'cms',
        'testimonials'
      );
      dispatch(SaveCMS(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async updateCMSGallery(
    candidateId: string,
    body: any,
    tagName?: string
  ): Promise<IAutocompleteItem[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.postAsync(
        `/cms/${candidateId}/gallery/`,
        body
      );
      const updatedState = prepareUpdatedData(
        'update',
        'cms',
        res,
        'cms',
        tagName || ''
      );
      dispatch(SaveCMS(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async removeImage(
    page_id: number,
    image_id: number
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.deleteAsync(
        `/cms/${page_id}/gallery/${image_id}/`
      );
      const updatedState = prepareUpdatedData(
        'delete',
        'cms',
        {
          id: image_id
        },
        'cms',
        'gallery'
      );
      dispatch(SaveCMS(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async updateCMSSocialMediaLinks(
    candidateId: string,
    body: any
  ): Promise<IAutocompleteItem[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.postAsync(
        `/cms/${candidateId}/social-media-links/`,
        body
      );
      const updatedState = prepareUpdatedData('update', 'cms', res, 'cms');
      dispatch(SaveCMS(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  public static async updateCMSSocialMediaLink(
    candidateId: string,
    body: any,
    id: number
  ): Promise<IAutocompleteItem[] | null> {
    const { dispatch } = store;
    try {
      console.log(body, "bodys");
      const res = await this.apiBase.updateAsync(
        `/cms/${candidateId}/social-media-links/${id}/`,
        body
      );
      const updatedState = prepareUpdatedData(
        'update',
        'cms',
        res,
        'cms',
        'social-media-links'
      );
      dispatch(SaveCMS(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  public static async removeSocialMediaLink(
    page_id: number,
    benefit_id: number
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.deleteAsync(
        `/cms/${page_id}/social-media-links/${benefit_id}/`
      );
      const updatedState = prepareUpdatedData(
        'delete',
        'cms',
        {
          id: benefit_id
        },
        'cms',
        'social_media_links'
      );
      dispatch(SaveCMS(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  

  public static async updateCMSBenefits(
    candidateId: string,
    body: any
  ): Promise<IAutocompleteItem[] | null> {
    const { dispatch } = store;
    try {
      console.log(body, "body");
      const res = await this.apiBase.postAsync(
        `/cms/${candidateId}/benefits/`,
        body
      );
      const updatedState = prepareUpdatedData('update', 'cms', res, 'cms',"benefits" );
      dispatch(SaveCMS(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  
  public static async updateCMSBenefit(
    candidateId: string,
    body: any,
    id: number
  ): Promise<IAutocompleteItem[] | null> {
    const { dispatch } = store;
    try {
      console.log(body, "bodys");
      const res = await this.apiBase.updateAsync(
        `/cms/${candidateId}/benefits/${id}/`,
        body
      );
      const updatedState = prepareUpdatedData(
        'update',
        'cms',
        res,
        'cms',
        'benefits'
      );
      dispatch(SaveCMS(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async removeBenefits(
    page_id: number,
    benefit_id: number
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.deleteAsync(
        `/cms/${page_id}/benefits/${benefit_id}/`
      );
      const updatedState = prepareUpdatedData(
        'delete',
        'cms',
        {
          id: benefit_id
        },
        'cms',
        'benefits'
      );
      dispatch(SaveCMS(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
