import { CompanyTabEnum } from 'core/enum/companyTab';
import { ICompanyHeaderItems } from 'core/interface/headerItems';

const companyHeader: ICompanyHeaderItems[] = [
  {
    isActive: false,
    title: 'Find Candidates',
    value: CompanyTabEnum.FIND_CANDIDATES
  },
  {
    isActive: false,
    title: 'Post a job',
    value: CompanyTabEnum.POST_A_JOB
  },
  {
    isActive: false,
    title: 'Culture Profile',
    value: CompanyTabEnum.CULTURE_PROFILE
  },
  {
    isActive: false,
    title: 'Candidate overview',
    value: CompanyTabEnum.CANDIDATE_OVERVIEW
  },
  {
    isActive: false,
    title: 'Career page',
    value: CompanyTabEnum.CAREER_PAGE
  }
];

export { companyHeader };
