import React, { FC, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import Cookies from 'js-cookie';
import useLocales from 'hooks/useLocales';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { Link, useSearchParams } from 'react-router-dom';
import Button from 'components/Button/Index';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import { IUser } from 'core/interface/user';
import { AuthService } from '../../services/auth/auth';
import { initialValues, validation } from './form';
import { ISignIn, SignInType } from 'core/interface/auth';
import AuthContent from 'components/AuthContent';
import { Role } from 'core/enum/role';
import Image from 'components/image/Image';
import Checkboxes from 'components/shared/Checkbox/Index';
import { SaveUser } from '../../redux/slices/user';
import { store } from '../../redux/store';

import './index.scss';

const SignIn: FC = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const userRole = searchParams.get('role');
  const { t } = useLocales();
  const [loading, setLoading] = useState<boolean>(false);
  const [role, setRole] = useState<Role>((userRole as Role) ?? Role.CANDIDATE);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleSignIn = async (
    values: SignInType,
    actions: FormikHelpers<SignInType>
  ) => {
    setLoading(true);
    try {
      const response = await AuthService.signIn(values.email, values.password);

      if ((response as ISignIn).access) {
        Cookies.set('accesstoken', (response as ISignIn).access);
      }
      const me = await getMe();
      if (role == me.role) {
        if (me.is_new) {
          navigate('/activation', { replace: true });
        } else if (me.role != 'admin') {
          navigate('/dashboard', { replace: true });
        } else {
          navigate('/company', { replace: true });
        }
        setLoading(false);
        actions.resetForm();
      } else if (me.role === 'admin' && role === 'findTalent') {
        if (me.is_new) {
          navigate('/activation', { replace: true });
        } else if (me.role != 'admin') {
          navigate('/dashboard', { replace: true });
        } else {
          navigate('/company', { replace: true });
        }
        setLoading(false);
        actions.resetForm();
      } else {
        if ((response as ISignIn).access) {
          Cookies.remove('accesstoken');
        }
        enqueueSnackbar(t('Your login credentials are incorrect'), {
          variant: 'error'
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };


  const getMe = async (): Promise<IUser> => {
    const { dispatch } = store;
    let response = {} as IUser;
    try {
      response = await AuthService.getAccount();
      dispatch(SaveUser(response));
      return response;
    } catch (error) {
      console.log(error);
    }
    return response;
  };

  const handleRole = (role: Role) => {
    setRole(role);
  };

  return (
    <AuthContent title="Welcome back!" onRoleSubmit={handleRole} role={role}>
      <div className="login">
        {role === Role.CANDIDATE && (
          <>
            <a
              href={`${window.location.origin}/social-auth/login/facebook/`}
              className="login__linked fb"
            >
              <Image name="fb.svg" />
              {t('sign_up_with_facebook')}
            </a>
            <a
              href={`${window.location.origin}/social-auth/login/linkedin-openidconnect/`}
              className="login__linked in"
            >
              <Image name="in.svg" />
              {t('sign_up_with_linkedin')}
            </a>
          </>
        )}
        <span className="or">Or</span>
        {role === Role.CANDIDATE && (
          <div className="up__social">
            <p className="up__social__terms">
              {t('By creating an account, you have read and agreed to our')}
              <a className="up__social__terms-term" href="">
                {t('Terms of Service')}
              </a>
            </p>
            <p className="up__social__relevent">
              {t(
                'Relevent information can be imported from your Linkedin or Facebook account'
              )}
            </p>
          </div>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={validation(t)}
          onSubmit={(values, actions) => handleSignIn(values, actions)}
        >
          {({ isValid }) => (
            <Form>
              <FormField
                fieldName="email"
                type={InputType.email}
                placeholder="Login"
              />
              <FormField
                fieldName="password"
                type={InputType.password}
                placeholder="Password"
              />
              <Link to={`/reset-password?role=${role}`} className="forgot">
                Forgot password?
              </Link>
              <Checkboxes
                onChange={(e) => console.log(e)}
                checkboxes={[{ label: 'Remember me' }]}
              />
              <Button
                t={t}
                isLoading={loading}
                className={`login-btn ${!isValid ? 'invalid-btn' : ''}`}
                title="Login"
                type="submit"
              />
            </Form>
          )}
        </Formik>
        <div className="login__actions">
          <span className="login__actions-no">No account yet?</span>
          <Link to={`/signUp?role=${role}`} className="login__actions-up">
            Sign up
          </Link>
        </div>
      </div>
    </AuthContent>
  );
};

export default SignIn;
