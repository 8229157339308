import { TFunction } from 'i18next';
import * as Yup from 'yup';

const initialValues = {
  title: '',
  about_us: ''
};

const validationSchema = (t: TFunction) => {
  return Yup.object({
    title: Yup.string().required(t('required')),
    about_us: Yup.string().required(t('required'))
  });
};

export { validationSchema, initialValues };
