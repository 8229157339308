import React, { FC, useState } from 'react';
import { RootState } from 'redux/store';
import { Formik, Form, FormikHelpers } from 'formik';
import { initialValues, validation } from './form';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import DropDown from 'components/Dropdown';
import { days, months, years } from 'core/constants/aboutMePopUpConstants';
import Button from 'components/Button/Index';
import {
  IExtraActivitiesData,
  IExtraActivitiesForm
} from 'core/interface/extraActivities';
import { getDateFormatter } from 'helpers/dateConverter';
import { ExtraActivitieService } from 'services/extraActivities/extraActivities';
import { IMe } from 'core/interface/me';
import { useSelector } from 'react-redux';
import ActionButton from 'components/ActionButton/Index';
import { IExtraActivationPopup } from 'core/interface/extraActivitiesPopup';
import Image from 'components/image/Image';

import './Index.scss';

const ExtraActivitiesPopup: FC<IExtraActivationPopup> = ({
  t,
  onClose,
  isExtra
}): JSX.Element => {
  const [initialForm, setInitialForm] =
    useState<IExtraActivitiesForm>(initialValues);
  const me = useSelector((state: IMe) => state.user).user;
  const [showForm, setShowForm] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number>();

  const { extraActivities } = useSelector(
    (state: RootState) => state.extraActivites
  );

  const handleSubmit = async (
    values: IExtraActivitiesForm,
    actions: FormikHelpers<IExtraActivitiesForm>
  ): Promise<void> => {
    setLoader(true);
    const body = {
      ...values,
      start: `${getDateFormatter(values.start.year, 'YYYY')}-${getDateFormatter(
        values.start.month,
        'MM'
      )}-${getDateFormatter(values.start.day, 'DD')}`,
      end: `${getDateFormatter(values.end.year, 'YYYY')}-${getDateFormatter(
        values.end.month,
        'MM'
      )}-${getDateFormatter(values.end.day, 'DD')}`
    } as IExtraActivitiesData;

    if (!itemId) {
      await ExtraActivitieService.setExtraActivity(me.candidate_id, body);
    } else {
      await ExtraActivitieService.updateExtraActivity(
        me.candidate_id,
        itemId,
        body
      );
    }
    setLoader(false);
    onClose();
    return;
  };

  const removeActivity = async (id: number): Promise<void> => {
    setLoader(true);
    await ExtraActivitieService.deleteExtraActivity(me.candidate_id, id);
    setLoader(false);
    onClose();
  };

  const handleEdit = async (activity: IExtraActivitiesData): Promise<void> => {
    setItemId(activity.id);
    isExtra(true);
    const body = {
      ...activity,
      start: {
        day: getDateFormatter(activity.start, 'D'),
        month: getDateFormatter(activity.start, 'MM'),
        year: getDateFormatter(activity.start, 'YYYY')
      },
      end: {
        day: getDateFormatter(activity.end, 'D'),
        month: getDateFormatter(activity.end, 'MM'),
        year: getDateFormatter(activity.end, 'YYYY')
      }
    } as IExtraActivitiesForm;
    setInitialForm(body);
    setShowForm(true);
  };

  return (
    <div className="extra">
      <div className="extras">
        {!showForm &&
          extraActivities?.map((item: IExtraActivitiesData) => (
            <div key={item.id} className="extra__all">
              <div className="extra__all__left">
                <span className="extra__all__left-title">
                  <span className="s-hidden">{item.title}</span>
                  <span className="extra__all__left-title-year">
                    {getDateFormatter(item.start, 'YYYY')} -{' '}
                    {getDateFormatter(item.end, 'YYYY')}
                  </span>
                </span>

                <span className="certification__all__left-type s-hidden">
                  {item.description}
                </span>
              </div>
              <div className={`extra__all__right ${loader ? 'disabled' : ''}`}>
                <span
                  onClick={() => removeActivity(item.id)}
                  className="extra__all__right-action"
                >
                  <Image name="trash.svg" />
                </span>
                <span
                  onClick={() => handleEdit(item)}
                  className="extra__all__right-action"
                >
                  <Image name="edit-pen.svg" />
                </span>
              </div>
            </div>
          ))}
      </div>

      {!showForm && (
        <div
          onClick={() => {
            setShowForm(true);
            isExtra(true);
          }}
          className="add-experience"
        >
          <ActionButton
            className="action-add"
            onClick={() => setShowForm(true)}
            iconName="plus.svg"
          />
          <span className="add-experience-title">
            {t('Add New Extra Activity')}
          </span>
        </div>
      )}
      {showForm && (
        <div className="extra__content">
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialForm}
            validationSchema={validation(t)}
            validateOnChange={true}
            enableReinitialize
          >
            {({
              setFieldValue,
              values,
              isValid,
              errors,
              touched,
              setFieldTouched
            }) => {
              return (
                <Form>
                  <div className="extra__content__new">
                    <div className="extra__content__activity">
                      <div className="extra__content__activity__field">
                        <span className="label">Activity</span>
                        <FormField
                          className="activity_input activity-inp"
                          fieldName="title"
                          placeholder="activity"
                          type={InputType.text}
                        />
                      </div>
                      <div className="extra__content__activity__field website">
                        <span className="label">Website</span>
                        <FormField
                          className="activity_input activity-inp"
                          fieldName="website"
                          placeholder="website"
                          type={InputType.text}
                        />
                      </div>
                    </div>
                    <div className="extra__content__start">
                      <div className="extra__content__start__left">
                        <span className="label">Start</span>
                        <div className="down-extr">
                          <DropDown
                            error={
                              touched.start?.day && errors.start?.day
                                ? errors.start?.day
                                : ''
                            }
                            defaultValue={values.start.day}
                            placeholder="Day"
                            dropdownClassName="dropdowns day dropdown"
                            items={days}
                            selectedValue={(e) => {
                              setFieldTouched('start.day', true);
                              setFieldValue('start.day', e);
                            }}
                          />
                          <DropDown
                            error={
                              touched.start?.month && errors.start?.month
                                ? errors.start?.month
                                : ''
                            }
                            defaultValue={values.start.month}
                            placeholder="Month"
                            dropdownClassName="dropdowns month dropdown"
                            items={months}
                            selectedValue={(e) => {
                              setFieldTouched('start.month', true);
                              setFieldValue('start.month', e);
                            }}
                          />
                          <DropDown
                            error={
                              touched.start?.year && errors.start?.year
                                ? errors.start?.year
                                : ''
                            }
                            defaultValue={values.start.year}
                            placeholder="Year"
                            dropdownClassName="dropdowns year dropdown"
                            items={years}
                            selectedValue={(e) => {
                              setFieldTouched('start.year', true);
                              setFieldValue('start.year', e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="extra__content__start__left">
                        <span className="label">Finish</span>
                        <div className="down-extr">
                          <DropDown
                            error={
                              touched.end?.day && errors.end?.day
                                ? errors.end?.day
                                : ''
                            }
                            maxRange={
                              +values.start.month >= +values.end.month &&
                              +values.start.year >= +values.end.year
                                ? +values.start.day
                                : undefined
                            }
                            defaultValue={values.end.day}
                            placeholder="Day"
                            dropdownClassName="dropdowns day dropdown"
                            items={days}
                            selectedValue={(e) => {
                              setFieldTouched('end.day', true);
                              setFieldValue('end.day', e);
                            }}
                          />
                          <DropDown
                            error={
                              touched.end?.month && errors.end?.month
                                ? errors.end?.month
                                : ''
                            }
                            maxRange={
                              +values.start.month >= +values.end.month &&
                              +values.start.year >= +values.end.year
                                ? +values.start.month
                                : undefined
                            }
                            defaultValue={values.end.month}
                            placeholder="Month"
                            dropdownClassName="dropdowns month dropdown"
                            items={months}
                            selectedValue={(e) => {
                              setFieldTouched('end.month', true);
                              setFieldValue('end.month', e);
                            }}
                          />
                          <DropDown
                            error={
                              touched.end?.year && errors.end?.year
                                ? errors.end?.year
                                : ''
                            }
                            defaultValue={values.end.year}
                            placeholder="Year"
                            dropdownClassName="dropdowns year dropdown"
                            items={years}
                            maxRange={+values.start.year}
                            selectedValue={(e) => {
                              setFieldTouched('end.year', true);
                              setFieldValue('end.year', e);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="extra__content__desc">
                      <span className="label">Description</span>
                      <FormField
                        className="activity_input activity-inp descs"
                        type={InputType.text}
                        fieldName="description"
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <Button
                    isLoading={loader}
                    t={t}
                    type="submit"
                    className={`save-btn ${!isValid ? 'invalid-btn' : ''}`}
                    title="Save"
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default ExtraActivitiesPopup;
