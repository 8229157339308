import ApiBase from 'services/base/apiBase';

export class SubscribtionPlansService {
  private static apiBase = new ApiBase();

  public static getPlansData(): Promise<any> {
    return this.apiBase.getAsync(`/subscription/`);
  }

  public static getPlans(): Promise<any> {
    return this.apiBase.getAsync('/subscription/plans/')
  }
}
