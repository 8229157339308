import React, { FC } from 'react';
import TermStatement from 'components/shared/TermStatement/Index';
import { ITerms } from 'core/interface/terms';

import './Index.scss';

const GeneralAndPrivacy: FC<ITerms> = ({ term }): JSX.Element => {
  return (
    <div className="general">
      {term.map((term, index) => (
        <div key={index} className="general__term">
          <TermStatement title={term.title} description={term.description} />
        </div>
      ))}
    </div>
  );
};

export default GeneralAndPrivacy;
