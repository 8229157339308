import { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => {
  const createStyle = {
    color: `red !important`,
    backgroundColor: `blue !important`
  };

  return {
    containerRoot: {
      '& .MuiCollapse-wrapperInner': {
        width: '100%'
      }
    },
    contentRoot: {
      width: '100%',
      padding: '20px',
      boxShadow: '10px 10px 10px 10px black',
      borderRadius: '20px',
      color: 'black',
      backgroundColor: 'white'
    },
    message: {
      padding: 0,
      fontWeight: 500
    },
    action: {
      marginRight: -4,
      '& svg': {
        width: 20,
        height: 20,
        opacity: 0.48,
        '&:hover': { opacity: 1 }
      }
    },
    variantInfo: { ...createStyle },
    variantSuccess: { ...createStyle },
    variantWarning: { ...createStyle },
    variantError: { ...createStyle }
  };
});

// ----------------------------------------------------------------------

type SnackbarIconProps = {
  icon: any;
  color: string;
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => 'white'
      }}
    >
      {/* <Icon icon={icon} width={24} height={24} /> */}
    </Box>
  );
}

type NotistackProviderProps = {
  children: ReactNode;
};

function NotistackProvider({ children }: NotistackProviderProps) {
  const classes = useStyles();

  return (
    <SnackbarProvider
      dense
      maxSnack={5}
      preventDuplicate
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      // iconVariant={{
      //   success: <SnackbarIcon icon={checkmarkCircle2Fill} color="success" />,
      //   error: <SnackbarIcon icon={infoFill} color="error" />,
      //   warning: <SnackbarIcon icon={alertTriangleFill} color="warning" />,
      //   info: <SnackbarIcon icon={alertCircleFill} color="info" />
      // }}
      classes={classes}
    >
      {children}
    </SnackbarProvider>
  );
}

export default NotistackProvider;
