import * as Yup from 'yup';
import { ICertificationsForm } from 'core/interface/certifications';
import { TFunction } from 'i18next';

const initialValues: ICertificationsForm = {
  courseTitle: '',
  institute: '',
  receivedCertificate: false,
  yearAttended: ''
};

const validations = (t: TFunction) => {
  return Yup.object({
    courseTitle: Yup.string().required(t('required')),
    institute: Yup.string().required(t('required')),
    yearAttended: Yup.string().required(t('required')),
    receivedCertificate: Yup.boolean()
  });
};

export { initialValues, validations };
