import { useSnackbar } from 'notistack';
import Image from 'components/image/Image';
import { useNavigate } from 'react-router';
import { IMe } from 'core/interface/me';
import { useSelector } from 'react-redux';
import { Pagination, Box } from '@material-ui/core';
import { RootState } from 'redux/store';
import { useDispatch } from 'react-redux';
import { SetFilter } from '../../redux/slices/invitations';
import { useEffect, useState } from 'react';
import { InvitationsService } from 'services/invitations/invitations';
import Navbar from 'components/Navbar';
import { getTranslatedList } from './texts';
import { useTranslation } from 'react-i18next';
import { getDateFormatter } from 'helpers/dateConverter';
import {
  Invitation,
  InvitationDetail,
  PeriodType
} from 'core/interface/invitations';

import './index.scss';

const AllInvitations = (): JSX.Element => {
  const { PERIOD_TYPES_INVITATIONS } = getTranslatedList();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [period, setPeriod] = useState<string>(
    PERIOD_TYPES_INVITATIONS[1]?.value
  );
  const me = useSelector((state: IMe) => state.user).user;
  const dispatch = useDispatch();
  const { filters, invitations }: any = useSelector(
    (state: RootState) => state.invitations
  );

  const { page, limit, offset, role } = filters;

  const changeFilter = (field: string, value: any) => {
    dispatch(SetFilter(field, value));
  };

  useEffect(() => {
    changeFilter('offset', page * limit - limit);
  }, [page, limit]);

  useEffect(() => {
    InvitationsService.getInvitations({ limit, offset, role });
  }, [limit, offset, role]);

  const setOption = (role: string) => {
    setPeriod(role);
    changeFilter('role', role);
  };

  const onResend = async (id: number): Promise<InvitationDetail | void> => {
    const res = await InvitationsService.resendInvitation(id);
    if (res) {
      enqueueSnackbar(t('Email have been resent'), {
        variant: 'success'
      });
    }
  };

  const onDelete = (id: number) => {
    InvitationsService.deleteInvitation(id);
    
  };
  

  return (
    <>
      <Navbar />
      <div className="invitaition">
        <div className="back_buttons" onClick={() => navigate('/dashboard')}>
          <Image name="arrow-left.svg" />
          {t('Back to profile')}
        </div>
        {me?.role === 'admin' && (
          <div className="invitaition_selector">
            {PERIOD_TYPES_INVITATIONS?.map((option: PeriodType) => {
              return (
                <div key={option?.value} className="invitaition_selector_btns">
                  <button
                    className={
                      period === option?.value
                        ? 'violetButton'
                        : 'violetButtonDisabled'
                    }
                    onClick={() => setOption(option?.value)}
                  >
                    {me?.role === 'admin' ? option.value : option.label}
                  </button>
                </div>
              );
            })}
          </div>
        )}
        <h2 className="invitaition_title">{t('Your Invitations')}</h2>
        <div className="invitaition_list">
          {invitations?.results?.length ? (
            invitations?.results?.map((el: Invitation) => (
              <div key={el?.id} className="invitaition_list_card">
                <div className="invitaition_list_card_item">
                  {el?.first_name && <span>{el?.first_name}</span>}
                  <span className="invitaition_list_card_item_email">
                    {el?.email}
                  </span>
                  <div
                    className={
                      el?.accepted
                        ? 'invitaition_list_card_item_status_accepted'
                        : 'invitaition_list_card_item_status_notAccepted'
                    }
                  >
                    {el?.accepted ? t('Accepted') : t('Not accepted yet')}
                  </div>
                </div>
                {me?.role === 'admin' && <hr className="divider" />}
                <div className="invitaition_list_card_item_actions">
                  {me?.role === 'admin' && (
                    <div className="invitaition_list_card_item_actions_invited">
                      <p>
                        {t('Invited by')}: {el?.invited_by}
                      </p>
                      <span> {getDateFormatter(el?.sent_on)}</span>
                    </div>
                  )}
                  <div className="invitaition_list_card_item_actions_btns">
                    <button
                      onClick={() => onResend(el?.id)}
                      disabled={el.accepted}
                      className="invitaition_list_card_item_actions_btns_resend"
                    >
                      {t('Resend')}
                      <img
                        src="/static/images/reset.svg"
                        style={{
                          width: '18px',
                          height: '18px',
                          marginLeft: '7px',
                          cursor: 'pointer'
                        }}
                      />
                    </button>
                    <button
                      onClick={() => onDelete(el?.id)}
                      className="invitaition_list_card_item_actions_btns_delete"
                    >
                      {t('Delete')}
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
        {invitations?.count && invitations?.count > limit ? (
          <Pagination
            className="pagination"
            count={Math.ceil((invitations?.count as number) / limit)}
            page={page}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            onChange={(_, value: number) => {
              changeFilter('page', value);
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default AllInvitations;
