import React, { useEffect } from 'react';
import { SaveUser } from '../../redux/slices/user';
import { RootState, store } from '../../redux/store';
import { AuthService } from 'services/auth/auth';
import { IMe } from 'core/interface/me';
import { useSelector } from 'react-redux';
import { JwtProviderProps } from 'core/interface/jwtProviderProps';
import { useSnackbar } from 'notistack';
import { JobService } from 'services/jobs/job';

export default function JwtProvider({ children }: JwtProviderProps) {
  const me = useSelector((state: IMe) => state.user).user;
  const { enqueueSnackbar } = useSnackbar();
  const { messages } = useSelector((state: RootState) => state.errors);

  const getMe = async () => {
    const { dispatch } = store;
    try {
      const response = await AuthService.getAccount();

      await JobService.getassesments(response.id);

      dispatch(SaveUser(response));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!me) {
      getMe();
    }
  }, []);

  useEffect(() => {
    if (messages) {
      for (const message of messages) {
        if (!(message as any).showed && (message as any).value) {
          enqueueSnackbar((message as any).value, {
            variant: (message as any).variant
          });
        }
      }
    }
  }, [messages]);
  return <>{children}</>;
}
