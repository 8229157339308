import { IAction } from 'core/interface/action';

export const SAVE_VALUES = 'save-assessment';
export const SAVE_ASSESSMENT_VALUES = 'save-assessment-values';
export const SAVE_ASSESSMENT_REPORT = 'save-assessment-report';
export const SAVE_ASSESSMENT_BY_GROUP = 'save-assessment-group';

const defaultState = {
  assessment: null,
  assessmentData: [],
  assessmentReport: [],
  assessmentByGroup: []
};

export const SaveAssessment = (value: any | number[]) => ({
  type: SAVE_VALUES,
  payload: value
});

export const SaveAssessmentValues = (value: any | number[]) => ({
  type: SAVE_ASSESSMENT_VALUES,
  payload: value
});
export const SaveAssessmentReport = (value: any | number[]) => ({
  type: SAVE_ASSESSMENT_REPORT,
  payload: value
});
export const SaveAssessmentByGroup = (value: any | number[]) => ({
  type: SAVE_ASSESSMENT_BY_GROUP,
  payload: value
});

const assessmentReducer = (
  state = defaultState,
  action: IAction<any | number[]>
) => {
  switch (action.type) {
    case SAVE_VALUES:
      return {
        ...state,
        assessment: action.payload
      };
    case SAVE_ASSESSMENT_VALUES:
      return {
        ...state,
        assessmentData: action.payload
      };
    case SAVE_ASSESSMENT_REPORT:
      return {
        ...state,
        assessmentReport: action.payload
      };
    case SAVE_ASSESSMENT_BY_GROUP:
      return {
        ...state,
        assessmentByGroup: action.payload
      };
    default:
      return state;
  }
};

export default assessmentReducer;
