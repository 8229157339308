import React, { FC, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import {
  IAddLanguageForm,
  ILanguageBody
} from 'core/interface/candidate-language';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { initialValues, validationSchema } from './form';
import useLocales from 'hooks/useLocales';
import { IAddLanguagePopup } from 'core/interface/languagePopup';
import Level from 'components/Level/Index';
import Autocomplete from 'components/shared/Autocomplete/Index';
import { LanguageService } from 'services/language';
import Button from 'components/Button/Index';
import { IMe } from 'core/interface/me';

import './Index.scss';

const AddLanguagePopup: FC<IAddLanguagePopup> = ({ onClose }): JSX.Element => {
  const { t } = useLocales();
  const me = useSelector((state: IMe) => state.user).user;
  const [loader, setLoader] = useState<boolean>(false);
  const [timeoutFunc, setTimeoutFunc] = useState<any>(null);
  const { languages } = useSelector((state: RootState) => state.languages);

  const handleSubmit = async (
    values: IAddLanguageForm,
    actions: FormikHelpers<IAddLanguageForm>
  ): Promise<void> => {
    setLoader(true);
    const body = {
      language: languages?.find((lang) => lang.name === values.language)?.id,
      proficiency: values.proficiency
    } as ILanguageBody;
    await LanguageService.setCandidateLanguage(me.candidate_id, body);
    onClose();
    setLoader(false);
  };

  const getLanguages = async (query: string = '', bounce = true) => {
    try {
      if (bounce) {
        if (timeoutFunc) {
          clearTimeout(timeoutFunc);
        }
        const timeout = setTimeout(() => {
          LanguageService.getAllLanguages(query);
        }, 500);
        setTimeoutFunc(timeout);
      } else {
        LanguageService.getAllLanguages(query);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="languagesp">
      <Formik
        validationSchema={validationSchema(t)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue, isValid, values, errors }) => {
          return (
            <Form>
              <div className="langs">
                <div className="lang">
                  <div className="lang-side">
                    <span className="label">{t('Language')}</span>

                    <Autocomplete
                      fieldName="language"
                      className=""
                      show_arrow={true}
                      value={values.language}
                      options={
                        languages?.map((e) => ({
                          id: e?.id,
                          title: e?.name
                        })) || []
                      }
                      show_location={false}
                      placeholder="Language"
                      onChange={(e) => {
                        setFieldValue(`language`, e);
                        getLanguages(e);
                      }}
                      onInputChange={(e) => {
                        getLanguages(e);
                      }}
                      values={languages}
                      onFocus={(e) => {
                        getLanguages(e);
                      }}
                    />
                    {errors.language && (
                      <span className="err-msg">{t('required')}</span>
                    )}
                  </div>
                  <div className="lang-side prof-side">
                    <span
                      className={`label ${errors.proficiency ? 'err' : ''}`}
                    >
                      {t('Proficiency')}
                    </span>

                    <Level
                      level={values.proficiency}
                      isReadonly={false}
                      onClick={(e) => {
                        setFieldValue(`proficiency`, e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <Button
                isLoading={loader}
                t={t}
                title="Save"
                className={`save-btn ${!isValid ? 'invalid-btn' : ''}`}
                type="submit"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddLanguagePopup;
