import React, { FC, useEffect, useRef, useState } from 'react';
import { ILanguage } from 'core/interface/language';
import DefaultImage from 'components/DefaultImage/Index';
import Footer from 'components/Footer/Index';
import Navbar from 'components/Navbar';
import { SwitcherEnum } from 'core/enum/switcherEnum';
import { ISocialMediaData } from 'core/interface/socialMedia';
import { IDropDownItem } from 'core/interface/dropDown';
import CompenecyPreferencesTab from 'components/Tabs/CompetencyPreferences/Index';
import EducationExperienceTab from 'components/Tabs/EducationAndExperience/Index';
import ImageAndYoutubeTab from 'components/Tabs/ImageAndYoutube/Index';
import SkillsTab from 'components/Tabs/Skills/Index';
import { headerItemsProfile } from 'core/constants/header';
import { ProfileTabEnum } from 'core/enum/dashboardTab';
import { IHeaderItems } from 'core/interface/headerItems';
import useLocales from 'hooks/useLocales';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { RootState } from 'redux/store';
import { CandidateService } from 'services/candidate/candidate';
import { ShareUrlService } from 'services/shareUrl/shareURL';
import { isValidEmail } from 'utils/isValidEmail';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import SkeletonLoader from 'components/shared/Skeleton/Index';
import ProfileAbout from 'components/shared/profileTabs/About/Index';
import Popup from 'components/shared/Popups/Popup/Index';
import DownloadPDFPopup from 'components/shared/Popups/DownloadPDF/Index';

import './Index.scss';

const ViewProfile: FC<ILanguage> = ({ params }): JSX.Element => {
  const { t } = useLocales();
  const { id } = useParams();

  const { candidates } = useSelector((state: RootState) => state.candidates);
  const [showDownload, setShowDownload] = useState(false)

  const { socialMedia } = useSelector((state: RootState) => state.socialMedia);
  const [candidate, setCandidate] = useState<any>();
  const [tabHeader, setTabHeader] = useState<IHeaderItems[]>(
    headerItemsProfile(t)
  );
  const [tabName, setTabName] = useState<string>(ProfileTabEnum.ABOUT_ME);
  // const me = useSelector((state: IMe) => state.user).user;
  const uploadFile = useRef<HTMLInputElement>(null);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  useEffect(() => {
    getCandidateById();
  }, []);

  useEffect(() => {
    if (candidates && candidates.id) {
      getCandidate();
    }
  }, []);

  const getCandidateById = async () => {
    const candidates = await CandidateService.getCandidate(id ? +id : 0);
    setCandidate(candidates);
  };

  const updateCandidate = async (isAnonym: number): Promise<void> => {
    const body = {
      access: Boolean(isAnonym)
    };
    CandidateService.updateCandidate(body, candidates.id);
  };

  const getCandidate = async (): Promise<void> => {
    CandidateService.getCandidate(candidates.id);
  };

  const handleTabChange = (clickedItem: IHeaderItems): void => {
    setTabHeader((prevValues) =>
      prevValues.map((item) =>
        item === clickedItem
          ? { ...item, isActive: true }
          : { ...item, isActive: false }
      )
    );
    if (clickedItem.value) {
      setTabName(clickedItem.value);
    }
  };

  const handleSwitchChange = (e: SwitcherEnum): void => {
    updateCandidate(e);
  };

  const showSelectedTab = (): JSX.Element | null => {
    switch (tabName) {
      case ProfileTabEnum.ABOUT_ME:
        return <ProfileAbout t={t} candidate_id={candidate?.id} fromGuest />;
      case ProfileTabEnum.COMPETENCY_SCORES:
        return <CompenecyPreferencesTab t={t} fromGuest />;
      case ProfileTabEnum.SKILLS:
        return <SkillsTab t={t} candidate_id={candidate?.id} fromGuest />;
      case ProfileTabEnum.IMAGE_YOUTUBE:
        return (
          <ImageAndYoutubeTab t={t} fromGuest candidate_id={candidate?.id} />
        );
      case ProfileTabEnum.EDUCATION_EXPERIENCE:
        return (
          <EducationExperienceTab
            t={t}
            candidate_id={candidate?.id}
            fromGuest
          />
        );
      default:
        return <ProfileAbout t={t} candidate_id={candidate?.id} fromGuest />;
    }
  };

  return (
    <div>
      <Navbar />
      <div className="dashboard">
        <div className="dashboard__main">
          <div className="dashboard__left">
            <div className="dashboard__left__image">
              {!candidates ? (
                <SkeletonLoader />
              ) : (
                <>
                  {candidates && candidates.avatar ? (
                    <Image
                      className="dashboard__left__image-img"
                      name="profile-image.png"
                      width={236}
                      height={236}
                      url={candidates?.avatar}
                    />
                  ) : (
                    <DefaultImage
                      width={236}
                      height={236}
                      className="dashboard__left__image-img"
                      letter={candidates.name}
                    />
                  )}
                </>
              )}
            </div>
            <div className="dashboard__left__content">
              <div className="dashboard__left__contact">
                <div className="dashboard__left__contact__user">
                  <div className="dashboard__left__contact__user__icon">
                    <Image
                      className="dashboard__left__contact__user__icon-img"
                      name="email.svg"
                      width={12}
                      height={12}
                    />
                  </div>
                  <span className="dashboard__left__contact__user-contact">
                    {candidates?.email}
                  </span>
                </div>
                {candidates?.phone && (
                  <div className="dashboard__left__contact__user">
                    <div className="dashboard__left__contact__user__icon">
                      <Image
                        className="dashboard__left__contact__user__icon-img"
                        name="phone.svg"
                        width={12}
                        height={12}
                      />
                    </div>
                    <span className="dashboard__left__contact__user-contact">
                      {candidates.phone}
                    </span>
                  </div>
                )}
              </div>
              <div className="dashboard__left__social">
                {socialMedia &&
                  socialMedia.map((item: ISocialMediaData) => (
                    <a
                      className="disable-block"
                      href={item.url}
                      key={item.id}
                      target="blank"
                    >
                      <Image
                        key={item.id}
                        className="dashboard__left__social-icons"
                        width={31}
                        height={31}
                        name={`${item.network}.svg`}
                      />
                    </a>
                  ))}
              </div>
              <div className="dashboard__left__actions">
                <Button
                  isLoading={uploadLoading}
                  onClick={() => {
                    if (candidate?.report_urls?.mo_de) {
                      window.open(candidate?.report_urls?.mo_de, 'blank');
                    }
                  }}
                  t={t}
                  className="dashboard__left__actions-lightGreen disable-block"
                  title={t('Motivator Demotivator Report')}
                />
                <Button
                  onClick={() => {
                    if (candidate?.cv)
                      window.open(candidate?.cv, '_blank');
                  }}
                  t={t}
                  iconName="viewCv.svg"
                  className="dashboard__left__actions-purple disable-block"
                  title={t('View CV')}
                />
                <Button
                  disabled={uploadLoading || !candidates?.cv}
                  onClick={() => setShowDownload(true)}
                  t={t}
                  iconName="resume.svg"
                  className="dashboard__left__actions-sky disable-block"
                  title={t('recrout_resume')}
                />
                <Button
                  t={t}
                  onClick={() => {
                    if (candidate?.report_urls?.feedback) {
                      window.open(candidate?.report_urls?.feedback, 'blank');
                    }
                  }}
                  iconName="report.svg"
                  className="dashboard__left__actions-green disable-block"
                  title={t('Candidate Report')}
                />
                <div className="otherColorButton">
                  <Button
                    t={t}
                    className="dashboard__left__actions-lightPurple disable-block"
                    title={t('Send to ATS/HRIS')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard__right">
            <div className="dashboard__right__bottom">
              <div className="dashboard__right__bottom__tabs">
                <div className="dashboard__right__bottom__tabs__header">
                  {tabHeader.map((item, index) => (
                    <div key={index}>
                      <div className="dashboard__right__bottom__tabs__header__item disable-block">
                        <span
                          onClick={() => handleTabChange(item)}
                          className={`dashboard__right__bottom__tabs__header__item-title ${
                            item.isActive ? 'active' : ''
                          }`}
                        >
                          {t(item.title)}
                          <span className="line"></span>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="dashboard__right__bottom-tab">
                {showSelectedTab()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {showDownload && (
        <Popup
          title={t('Download PDF')}
          description={t('Please select the file you would like to download')}
          onClose={() => setShowDownload(false)}
        >
          <DownloadPDFPopup setIsClose={() => setShowDownload(false)} />
        </Popup>
      )}
    </div>
  );
};

export default ViewProfile;
