import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { Gender } from 'core/enum/gender';
import { IActionvationWhoForm } from 'core/interface/activation';

const initialValues: IActionvationWhoForm = {
  first_name: '',
  last_name: '',
  gender: Gender.MALE,
  birthday: {
    day: '',
    month: '',
    year: ''
  }
};

const validationSchema = (t: TFunction) => {
  return Yup.object({
    first_name: Yup.string()
      .trim()
      .min(3, `${t('least')} 3 ${t('characters')}`)
      .max(15, `${t('less_than')} 15 ${t('characters')}`)
      .required(t('required')),
    last_name: Yup.string()
      .trim()
      .required(t('required'))
      .min(3, `${t('least')} 3 ${t('characters')}`)
      .max(15, `${t('less_than')} 15 ${t('characters')}`)
      .required(t('required')),
    gender: Yup.number().required(t('required')),
    birthday: Yup.object().shape({
      day: Yup.number().min(1).max(31).required(),
      month: Yup.string().trim().required(),
      year: Yup.number().required()
    })
  });
};

export { initialValues, validationSchema };
