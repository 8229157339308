import React from 'react';
import { Modal as MUIModal } from '@mui/material';
import { CustomModalProps } from './types';

import './Index.scss';

export default function Modal({
  open,
  onClose,
  children,
  className
}: CustomModalProps) {
  return (
    <MUIModal
      open={open}
      onClose={onClose}
      className="modal"
    >
      <div className={className}>{children}</div>
    </MUIModal>
  );
}
