import { TFunction } from 'i18next';
import * as Yup from 'yup';

const validationSchema = (t: TFunction) => {
  return Yup.object({
    testimonials: Yup.array().of(
      Yup.object({
        avatar: Yup.mixed().default(null).required(t('required')),
        first_name: Yup.string().required(t('required')),
        last_name: Yup.string().required(t('required')),
        job_title: Yup.string().required(t('required')),
        description: Yup.string().required(t('required')),
      })
    ),
  });
};

export { validationSchema };
