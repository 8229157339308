import { IDropDownItem } from 'core/interface/dropDown';

const industries: IDropDownItem[] = [
  {
    value: 'some',
    to_show: 'some industy'
  }
];

const jobContracts: IDropDownItem[] = [
  { value: 1, to_show: 'Full-time' },
  { value: 2, to_show: 'Part-time' },
  { value: 3, to_show: 'Internship' },
  { value: 4, to_show: 'Freelance' }
];

export { industries, jobContracts };
