import * as Yup from 'yup';
import { IActivationEducation } from 'core/interface/activation';

const initialValues: IActivationEducation = {
  educational_attainment: '',
  average_graduation: '',
  isGraduated: false,
  school: '',
  study_field: '',
  degree: undefined,
  isCurrent: false,
  start: {
    month: '',
    year: '',
    day: ''
  },
  finish: {
    month: '',
    year: '',
    day: ''
  }
};

const validationSchema = () => {
  return Yup.object({
    educational_attainment: Yup.string().required(),
    average_graduation: Yup.string().required(),
    isGraduated: Yup.boolean().default(false),
    school: Yup.string().required(),
    study_field: Yup.string().required(),
    degree: Yup.string().required(),
    isCurrent: Yup.boolean().default(false),
    start: Yup.object({
      month: Yup.string().required(),
      year: Yup.string().required(),
      day: Yup.string().required()
    }),
    finish: Yup.object().shape({
      month: Yup.string(),
      year: Yup.string(),
      day: Yup.string()
    })
  });
};

export { initialValues, validationSchema };
