import { IUser } from 'core/interface/user';
import ApiBase from '../base/apiBase';
import { setRequestError } from '../../redux/slices/errors';
import { store } from '../../redux/store';
import { SaveCandidate } from '../../redux/slices/candidate';
import { SaveFiles } from '../../redux/slices/file';
import { TFunction } from 'i18next';

export class AccountServce {
  private static apiBase = new ApiBase();

  public static async uploadCV(
    candidate_id: number,
    body: FormData,
    t: TFunction
  ): Promise<void> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.updateAsync(
        `/candidates/${candidate_id}/`,
        body
      );
      dispatch(SaveCandidate(res));
      dispatch(setRequestError(t('CV Uploaded'), true));
    } catch (error) {
      dispatch(setRequestError({ data: `${t('Request Entity Too Large')}` }));
    }
  }

  public static async updateAccount(
    candidate_id: number,
    data: FormData
  ): Promise<IUser> {
    return await this.apiBase.updateAsync(`/account/${candidate_id}/`, data);
  }
  public static async getUploadedImages(candidate_id: number): Promise<any> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/candidates/${candidate_id}/files/`
      );
      dispatch(SaveFiles(res));
    } catch (error) {
      console.log(error);
    }
  }
  public static async uploadImages(
    candidate_id: number,
    body: any
  ): Promise<any> {
    try {
      await this.apiBase.postAsync(`/candidates/${candidate_id}/files/`, body);
      AccountServce.getUploadedImages(candidate_id);
    } catch (error) {
      console.log(error);
    }
  }
  public static async removeUploadedImages(
    candidate_id: number,
    file_id: number
  ): Promise<any> {
    try {
      const res = await this.apiBase.deleteAsync(
        `/candidates/${candidate_id}/files/${file_id}/`
      );
      console.log(res);

      AccountServce.getUploadedImages(candidate_id);
    } catch (error) {
      console.log(error);
    }
  }
}
