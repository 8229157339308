import React, { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { NotificationService } from 'services/notifications/notifications';
import { Link } from 'react-router-dom';
import NoData from 'components/shared/NoData/Index';
import { getDateFormatter } from 'helpers/dateConverter';
import { INotificationCard } from 'core/interface/notificationCard';

import './Index.scss';

const pattern =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

const NotificationCard: FC<INotificationCard> = (): JSX.Element => {
  const [showAll, setShowAll] = useState(false);
  const { notifications, unReadCount, allNotifications }: any = useSelector(
    (state: RootState) => state.notifications
  );

  const toChatPath = useCallback(
    (description: string) => {
      return pattern.test(description)
        ? description.substring(description.indexOf('/dashboard'))
        : '';
    },
    [pattern]
  );

  const readNotifications = async (id: number) => {
    await NotificationService.markAsRead(id);
    getNotification();
  };
  const getAllNotifications = () => {
    setShowAll(!showAll);
    getNotification();
  };

  const getNotification = (): void => {
    NotificationService.getUnreadCount();
  };

  const readAllNotifications = async (): Promise<void> => {
    await NotificationService.getAllReadNotifications();
    getNotification();
  };

  const notificationsData = showAll ? allNotifications : notifications;

  return (
    <div className="card_container">
      <div className="card_container_massages">
        <span>
          {' '}
          {`You have ${unReadCount?.unread_count || 'no'} unread message`}
        </span>
      </div>
      <div className="card scrollable">
        {notificationsData?.map((item: any) => (
          <div
            key={item.id}
            className="card__right"
            onClick={() => readNotifications(item.id)}
          >
            {item.descrtiption ? (
              <Link target="_blank" to={toChatPath(item.descrtiption)}>
                <span className="card__right-sender">{item.verb}</span>
              </Link>
            ) : (
              <span className="card__right-sender">{item.verb}</span>
            )}
            <span className="card__right-date">
              {getDateFormatter(item.timestamp, 'DD-MM-YYYY')}
            </span>
          </div>
        ))}

        {unReadCount?.unread_count === 0 && notificationsData.length === 0 ? (
          <div className="no_data">
            <NoData />
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="card__right_btn">
        <button className="card__right_btn_read" onClick={readAllNotifications}>
          Read Everything
        </button>
        <button className="card__right_btn_show" onClick={getAllNotifications}>
          Show {showAll ? 'Unread' : 'All'}
        </button>
      </div>
    </div>
  );
};

export default NotificationCard;
