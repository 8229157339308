;
import useLocales from 'hooks/useLocales';
import { ICandidate } from 'core/interface/candidate';
import Image from 'components/image/Image';
import Pagination from 'components/shared/Pagination/Index';
import { RequestsService } from 'services/requests/request';
import { useNavigate, useParams } from 'react-router';
import { CircularProgress } from '@mui/material';
import NoData from 'components/shared/NoData/Index';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import './index.scss';

const MatchResults = ({ handleModal, getMatchCandidates, matchProfileCandidate }: any): JSX.Element => {
  const { t } = useLocales();
  const navigate = useNavigate();
  const { id, type } = useParams();
  const getUserProfile = (id: string) => {
    navigate(`/view-profile/${id}`)
  }
  const { enqueueSnackbar } = useSnackbar();
  const [candidates, setCandidates] = useState(matchProfileCandidate?.results || []);

  const askRequest = async (candidateId: number, originId: number) => {
    try {
      const response = await RequestsService.askRequest(
        type === 'find-candidate'
          ? { origin_profile: originId, candidate: candidateId }
          : { origin_job: originId, candidate: candidateId }
      );
      if (response && response.id) {
        console.log(response, response.id, candidateId);
        setCandidates((prevCandidates: any[]) =>
          prevCandidates.map((candidate: { id: number; }) =>
            candidate.id === response?.candidate?.id
              ? { ...candidate, if_request_sent: true }
              : candidate
          )
        );
        enqueueSnackbar("Request has been sent", { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar("Failed to send request", { variant: 'error' });
    }
  };

  useEffect(() => {
    setCandidates(matchProfileCandidate?.results || []);
  }, [matchProfileCandidate]);

  return (
    <>
      <div className="result-content">
        <div className="results-container">
          <p className="title">{t('Match Results')}</p>
          <div>
            {matchProfileCandidate === null ?
              <div className="loadingContainer">
                <CircularProgress className="loading" />
              </div>
              : matchProfileCandidate?.results?.length === 0 ?
                <NoData /> : candidates?.map((e: ICandidate) => (
                  <div key={e.id} className="candidate">
                    <div className="profile-info">
                      <Image
                        url={e.avatar}
                        name="profile-view.svg"
                        width={75}
                        height={75}
                      />
                      <div>
                        <p className="name">{e.name}</p>
                        <span className="bio">{e.bio}</span>
                      </div>
                    </div>
                    <div className="score">
                      <p className="score-text">
                        {e?.score}% {t('Talent Match')}
                      </p>
                      <p className="score-text">
                        {e?.culture_score}% {t('Talent Match')}
                      </p>
                    </div>
                    <div className="buttons">
                      <div className="button" onClick={() => handleModal(true, e?.id)}>
                        <Image name="more_info.svg" width={45} height={45} />
                      </div>
                      {e?.can_view_profile ?
                        <div className="button" onClick={() => getUserProfile(e?.id)}>
                          <Image name="vew_profile.svg" width={45} height={45} />
                        </div> :
                        <div className={`button ${e?.if_request_sent && "disabled"}`} onClick={() => !e?.if_request_sent && askRequest(+e?.id, id ? +id : -1)}>
                          <Image name="profile-view.svg " width={45} height={45} />
                        </div>}
                    </div>
                  </div>
                ))}
          </div>
        </div>
        <Pagination
          hasNext={matchProfileCandidate?.next ? matchProfileCandidate.next : null}
          itemsPerPage={10}
          dataLength={matchProfileCandidate?.count ?? 1}
          onNext={(e) => getMatchCandidates(10, e.prevPage)}
          onPrev={(e) => getMatchCandidates(10, e.prevPage !== 0 ? e.prevPage : 0)}
        />
      </div>
    </>
  );
};

export default MatchResults;
