import { Radar } from 'react-chartjs-2';
import { useRef, useState } from 'react';
import { Box, useTheme } from '@material-ui/core';
import useLocales from 'hooks/useLocales';
import { containsNestedArray } from 'helpers/arraysToObject';

interface label {
  name: string;
  id: number;
}

export interface sortedPoint {
  point?: number;
  label?: string;
  descriptions?: string;
  comparePoint?: number;
}

let XYIndexes: any = {};
const RadarChartComp = ({
  title,
  labels,
  points,
  compareTitle = "Competencies Score",
  comparePoints,
  style = {},
  boxSx = {},
  descriptions = null,
  assessment = false,
  color = '',
  fromPDF = false,
  chartHeight,
  chartWidth,
  years
}: any) => {
  const canvasRef = useRef(null);
  const theme = useTheme();
  const [, setCreated] = useState<any>({});
  const { t } = useLocales();

  title = title || t('Competencies Score');
  let sortedPoints: Array<sortedPoint> = [];
  if (!labels?.[0]?.id) {
    sortedPoints =
      labels
        ?.map((el: string, i: number) => ({
          point: points[i],
          label: el,
          descriptions: assessment
            ? descriptions[i]
            : descriptions.find((item: any) => item.name === el)?.description
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  } else {
    const sortedLabels = labels.sort((a: any, b: any) => b.id - a.id).reverse();
    const sortedInPoints = points
      .sort((a: any, b: any) => b.id - a.id)
      .reverse();
    sortedPoints =
      sortedLabels
        ?.map((el: label, i: number) => ({
          point: sortedInPoints[i].min_score,
          label: el.name,
          descriptions: assessment
            ? descriptions[i]
            : descriptions.find((item: any) => item.name === el.name)
                .description
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  }

  if (sortedPoints && sortedPoints?.length) {
    const label: any = [];
    sortedPoints.map((el) => label.push(el.label));
    labels = label;
  }

  const titleCallback = (data: any) => {
    let text = '';
    let previousLabel = null;

    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      const currentLabel = element.dataset.label[element.dataIndex];

      if (currentLabel !== previousLabel) {
        text += currentLabel;
        previousLabel = currentLabel;
      }
    }

    return text;
  };
  const labelCallback = (data: any) => {
    return `${
      data.dataset.name === 'second'
        ? compareTitle
        : data.dataset.name === 'first'
          ? title
          : title + ' of ' + data.dataset.name
    }  ${data.formattedValue}`;
  };

  const getColor = (colorToChange?: string) => {
    if (colorToChange) {
      return `${colorToChange}60`;
    } else if (color) {
      return `${color}60`;
    }
    return 'rgba(86, 44, 130, 0.3)';
  };

  const datasets = [
    {
      name: 'first',
      label: labels,
      data: sortedPoints.map((el: any) => el.point),
      backgroundColor: getColor(theme.palette.primary.main),
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      pointBackgroundColor: theme.palette.primary.main
    }
  ];

  if (comparePoints && comparePoints.length) {
    const index = years ? years.length - comparePoints.length : 0;
    let points = [];
    let year = null;
    if (containsNestedArray(comparePoints)) {
      for (let i = 0; i < comparePoints.length; i++) {
        points = comparePoints[i];
        year = years ? years[index + i] : null;
        const dataset = {
          name: year ? year.title : 'second',
          label: labels,
          data: points?.map((el:any) => el?.value || el?.score || ""),
          backgroundColor: year ? `${year.color}60` : 'rgba(28, 157, 215, 0.3)',
          borderColor: year ? year.color : '#1C9DD7',
          borderWidth: 1.5,
          pointBackgroundColor: year ? year.color : '#1C9DD7'
        };
        datasets.push(dataset);
      }
    } else {
      points = comparePoints;
      const dataset = {
        name: 'second',
        label: labels,
        data: points?.map((el: any) => el?.value || el?.score || ""),
        backgroundColor: 'rgba(28, 157, 215, 0.3)',
        borderColor: '#1C9DD7',
        borderWidth: 1.5,
        pointBackgroundColor: '#1C9DD7'
      };
      datasets.push(dataset);
    }
  }


  const drawTextAtIndex = (scale: any, index: string, icon: any, text: any) => {
    const offset = 36;
    const r = scale.drawingArea + offset;
    const angle = scale.getIndexAngle(index) - Math.PI / 2;
    const x = scale.xCenter + Math.cos(angle) * r;
    const y = scale.yCenter + Math.sin(angle) * r;
    const { ctx } = scale;

    ctx.save();
    ctx.translate(x, y);
    ctx.textAlign = 'center';
    ctx.font = "600 10px 'Rubik'";
    ctx.fillStyle = 'black';
    ctx.globalCompositeOperation = 'destination-over';

    const textArray = getLines(ctx, text, 75);
    let Xscore = 0;
    let Yscore = 0;
    textArray.map((item: string, i: number) => {
      let text = item;
      if (i === 0) {
        text = `${(Number(index) + 1).toString()} ${item}`;
      }
      ctx.fillText(
        text,
        labels.length / 2 < Number(index) ? -8 : 8,
        20 - textArray.length * 8 + i * 10
      );
      Xscore = labels.length / 2 < Number(index) ? -40 : -25;
      Yscore = 20 - textArray.length * 8 + i * 10 - 35 - textArray.length * 8;
    });

    XYIndexes[index] = {
      x,
      y,
      scale,
      Xscore,
      Yscore,
      created: false
    };
    ctx.restore();
    if (canvasRef?.current && fromPDF) {
      const createdValue: any = {};
      mouseMove({ offsetX: 100, offsetY: 100 }, createdValue);
    }
  };

  const getLines = (ctx: any, text: any, maxWidth: any) => {
    const lines = [];
    if (text) {
      const words = text?.split(' ');
      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const { width } = ctx.measureText(`${currentLine} ${word}`);
        if (width < maxWidth) {
          currentLine += ` ${word}`;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      lines.push(currentLine);
    }
    return lines;
  };

  const roundRect = (
    ctx: any,
    x: any,
    y: any,
    width: any,
    height: any,
    radius: any = 5,
    fill = false,
    stroke = true
  ) => {
    if (typeof radius === 'number') {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      radius = { ...{ tl: 0, tr: 0, br: 0, bl: 0 }, ...radius };
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(
      x + width,
      y + height,
      x + width - radius.br,
      y + height
    );
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  };

  const mouseMove = (e: any, createdValue: any) => {
    const valuesCanvas: any = Object.values(XYIndexes);
    for (let i = 0; i < valuesCanvas.length; i++) {
      if (!XYIndexes[i].created) {
        const element: any = valuesCanvas[i];
        const { x, y, scale } = element;
        const { ctx } = scale;
        const values = scale.chart.config.data.datasets[0].data;
        ctx.beginPath();
        ctx.strokeStyle = '#6666662b';
        ctx.fillStyle = '#ffffff';
        ctx.shadowBlur = 3;
        ctx.shadowColor = '#6666662b';
        roundRect(ctx, x + element.Xscore, y + element.Yscore, 70, 25, 5, true);
        ctx.beginPath();
        ctx.shadowBlur = 0;
        ctx.shadowColor = 'none';
        ctx.fillStyle = '#666666';
        ctx.font = "400 12px 'Rubik'";
        ctx.fillText(
          `${values[i]} score`,
          x + element.Xscore + 10,
          y + element.Yscore + 16
        );
        ctx.closePath();
        createdValue[`${i}`] = true;
        XYIndexes[i].created = true;
      }
    }
  };

  const tauch = () => {
    if (canvasRef?.current) {
      const { canvas }: any = canvasRef?.current as any;
      const createdValue: any = {};
      canvas.onmousemove = (e: any) => {
        const { offsetX, offsetY } = e;

        for (let i = 0; i < Object.values(XYIndexes).length; i++) {
          const element: any = Object.values(XYIndexes)[i];
          const { x, y, scale } = element;
          const { ctx } = scale;
          if (
            offsetX - x < 30 &&
            offsetX - x > -30 &&
            offsetY - y < 30 &&
            offsetY - y > -30 &&
            !createdValue[`${i}`]
          ) {
            let textArray = [];

            const width =
              canvas.clientWidth - 50 > 240 ? 240 : canvas.clientWidth - 50;
            if (sortedPoints)
              textArray = getLines(ctx, sortedPoints[i]?.descriptions, width);
            const textHeight = textArray.length * 13 + 30;
            ctx.beginPath();
            ctx.strokeStyle = '#6666662b';
            ctx.fillStyle = '#ffffff';
            ctx.shadowBlur = 3;
            ctx.shadowColor = '#6666662b';
            let xAxis = x;
            if (x + width > canvas.clientWidth && x - width > 0) {
              xAxis = x - width;
            } else if (x - width <= 0 && x + width > canvas.clientWidth) {
              xAxis = x - width / 2;
            }
            let yAxis = y;

            if (y + textHeight > canvas.clientHeight) {
              yAxis = y - textHeight;
            }

            roundRect(ctx, xAxis, yAxis, width + 40, textHeight, 20, true);
            ctx.beginPath();

            ctx.shadowBlur = 0;
            ctx.shadowColor = 'none';
            ctx.fillStyle = '#666666';
            ctx.font = "400 12px 'Rubik'";
            for (let j = 0; j < textArray.length; j++) {
              const element = textArray[j];
              ctx.fillText(element, xAxis + 20, yAxis + 25 + 13 * j);
            }
            ctx.closePath();
            createdValue[`${i}`] = true;
          } else if (
            createdValue[i] &&
            (offsetX - x >= 30 ||
              offsetX - x <= -30 ||
              offsetY - y >= 30 ||
              offsetY - y <= -30)
          ) {
            createdValue[`${i}`] = false;
            XYIndexes = {};
            setCreated({});
          }
        }
      };
    }
  };

  return (
    <>
      <Box sx={boxSx}>
        <Box
          style={{ width: '100%', height: chartHeight }}
          sx={style}
          onMouseEnter={() => {
            XYIndexes = {};
            setCreated({});
            tauch();
          }}
          onMouseLeave={() => {
            XYIndexes = {};
            setCreated({});
          }}
        >
          {sortedPoints?.length ? (
            <Radar
              ref={canvasRef}
              {...(chartWidth ? { width: chartWidth, height: chartWidth } : {})}
              data={{
                labels: labels.map((_: label) => '111111111111'),
                datasets
              }}
              options={{
                responsive: true,
                animation: {
                  duration: 0
                },
                scales: {
                  r: {
                    suggestedMin: 5,
                    suggestedMax: 10,
                    pointLabels: {
                      font: {
                        size: 14
                      },
                      color: 'transparent'
                    },
                    ticks: {
                      display: false,
                      maxTicksLimit: 6
                    },
                    grid: {
                      color: '#828282'
                    },
                    angleLines: {
                      color: '#828282'
                    }
                  }
                },
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    callbacks: {
                      title: titleCallback,
                      label: labelCallback
                    },
                    titleFont: {
                      size: 16
                    },
                    bodyFont: {
                      size: 16
                    }
                  }
                }
              }}
              plugins={[
                {
                  id: 'custom_labels',
                  afterDraw: (chart: any) => {
                    const scale = chart.scales.r;
                    XYIndexes = {};
                    for (const key in sortedPoints) {
                      if (Object.prototype.hasOwnProperty.call(labels, key)) {
                        const element = sortedPoints[key]?.label;
                        if (element) {
                          drawTextAtIndex(scale, key, 'headset', element);
                        }
                      }
                    }
                  }
                }
              ]}
            />
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
};

export default RadarChartComp;
