import { IEducationBody } from 'core/interface/education';
import { IEducationSideItem } from 'core/interface/education-side';
import { store } from '../../redux/store';
import ApiBase from 'services/base/apiBase';
import { SaveEducations } from '../../redux/slices/educations';
import { prepareUpdatedData } from '../../helpers/stateChanges';

export class EducationService {
  private static readonly apiBase = new ApiBase<IEducationSideItem>();

  public static async getEducations(
    candidate_id: number
  ): Promise<IEducationSideItem[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/candidates/${candidate_id}/educations/`
      );
      dispatch(SaveEducations(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async setEducation(
    candidate_id: number,
    education: IEducationBody
  ): Promise<IEducationSideItem[] | null> {
    const { dispatch } = store;
    try {     
      const res = await this.apiBase.postAsync(
        `/candidates/${candidate_id}/educations/`,
        education
      );
      const updatedState = prepareUpdatedData(
        'create',
        'educations',
        res,
        'educations'
      );
      dispatch(SaveEducations(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
