import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CreateCareer from 'components/createCareerPage';
import Image from 'components/image/Image';
import Popup from 'components/shared/Popups/Popup/Index';
import { CMSService } from 'services/cms/cms';
import { IMe } from 'core/interface/me';
import { useParams } from 'react-router';

function AboutComp() {
  const [openModal, setOpenModal] = useState(false);
  const { cms } = useSelector((state: RootState) => state.cms);
  const { companies } = useSelector((state: RootState) => state.compony);
  const me = useSelector((state: IMe) => state.user).user;
  const { id } = useParams();

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleChange = (companyData: any) => {
    const formData = new FormData();
    for (const key in companyData) {
      if (key !== 'id') {
        formData.append(key, (companyData as any)[key]);
      }
    }
    CMSService.updateCMS(me?.company, formData);
    handleModal();
  };

  return (
    <>
      <div className="headerContainer">
        <div className="profileImageContainer">
          <Image url={cms?.logo} width={44} height={44} className="image" />
          <p>{companies?.name}</p>
        </div>
        {!id && (
          <div className="pencil_icon" onClick={handleModal}>
            <div className="pencil_circle" />
            <Image
              className="pencil"
              name="pencil.svg"
              width={13}
              height={13}
            />
          </div>
        )}
      </div>
      <div className="headerTitle">
        <h1>{cms?.title}</h1>
      </div>
      <div>
        <Image className="bannerImage" url={cms?.hero_img} />
      </div>
      <div className="aboutUsContainer">
        <p>About us</p>
        <span>{cms?.about_us}</span>
      </div>
      {openModal && (
        <Popup
          title="Create career page"
          className="expertise scrollable"
          onClose={handleModal}
        >
          <CreateCareer
            companyData={cms}
            setNewData={handleChange}
            isOneBtn={true}
            onClose={handleModal}
            showTopSide={false}
            isPopup={true}
            showTitle={true}
          />
        </Popup>
      )}
    </>
  );
}

export default AboutComp;
