import { IAction } from 'core/interface/action';
import { IJobForm } from 'core/interface/job';

export const SAVE_VALUES = 'save-form-value';

const defaultState = {
  jobValues: null
};

export const SaveForm = (value: IJobForm | number[]) => ({
  type: SAVE_VALUES,
  payload: value
});

const jobFormReducer = (
  state = defaultState,
  action: IAction<IJobForm | number[]>
) => {
  switch (action.type) {
    case SAVE_VALUES:
      return {
        ...state,
        jobValues: action.payload
      };
    default:
      return state;
  }
};

export default jobFormReducer;
