import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import useLocales from 'hooks/useLocales';
import { Form, Formik } from 'formik';
import { JobService } from 'services/jobs/job';
import Autocomplete from 'components/shared/Autocomplete/Index';
import DropDown from 'components/Dropdown';
import { ProfileService } from 'services/profile/profile';
import { degreeTypes } from 'core/constants/aboutMePopUpConstants';
import { SkillService } from 'services/skills/skillService';
import { IAutocompleteItem } from 'core/interface/autocompleteItem';
import { getCareerLevelLabels, getSalaryLabels } from 'constants/constants';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import Checkboxes from 'components/shared/Checkbox/Index';
import Multiselect from 'components/shared/Multiselect/Index';
import { initialValues } from './types';
import { LanguageService } from 'services/language';
import { ICandidateLanguage } from 'core/interface/candidate-language';

import './index.scss';

const MatchFilter = ({ applyFilter }: any): JSX.Element => {
  const { t } = useLocales();
  const [initialValue, setInitialValue] = useState<any>();

  const { languages } = useSelector((state: RootState) => state.languages);
  const [formKey, setFormKey] = useState<number>(0);
  const { computerSkills: skills } = useSelector(
    (state: RootState) => state.computerSkills
  )
  const { cities } = useSelector((state: RootState) => state.locations);
  const { industry: industries } = useSelector(
    (state: RootState) => state.industries
  );


  const { schools } =
    useSelector((state: RootState) => state.schools);

  useEffect(() => {
    getCities();
    getIndustries('');
    getSkills();
    getLanguages();
    getSchools()
    setInitialValue(initialValues)
  }, []);

  const getCities = (limit: number = 10, q?: string): void => {
    if (q && q?.length > 0) {
      JobService.getLocations({
        limit,
        q
      });
    }
  };
  const getSchools = (schools: string = '') => {
    ProfileService.getSchools(schools);
  };

  const getSkills = async (query?: string): Promise<void> => {
    SkillService.getSkillsList(query ?? '');
  };

  const getIndustries = (query?: string): void => {
    ProfileService.getIndustries(query);
  };

  const handleCheckbox = (e: string, key: string): void => {
    const value = initialValue[key] as any;
    const index = value.indexOf(e);
    if (index !== -1) {
      value.splice(index, 1);
    } else {
      value.push(e);
    }

    setInitialValue({
      ...initialValue,
      [key]: value
    });
  };


  const getLanguages = (q?: string) => {
    LanguageService.getAllLanguages(q || '');

  };


  const handleRemoveLanguage = (item: ICandidateLanguage): void => {
    setInitialValue({
      ...initialValue,
      languages: initialValue?.languages.filter(
        (e: ICandidateLanguage) => e.id !== item.id
      )
    });
  };
  const handleFilter = (): void => {
    const val = {
      ...initialValue
    };
    applyFilter(val);
  };

  const handleResetChanges = (): void => {
    setFormKey(Math.random());
    setInitialValue({
      industry: '',
      education: '',
      degree_type: '',
      computer_skill: '',
      country: null,
      salary: [],
      career_level: [],
      languages: []
    });
    applyFilter({
      industry: '',
      education: '',
      degree_type: '',
      computer_skill: '',
      country: null,
      salary: [],
      career_level: [],
      languages: []
    });
  };



  return (
    <div className="match-filter">
      <p className="title">{t('Filter')}</p>
      <div className="form" key={formKey}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, actions) => { }}
        >
          <Form>
            <>
              <div className="input-container">
                <span className="label">{t('Industry')}</span>
                <Autocomplete
                  fieldName="industry"
                  value={
                    industries?.find(
                      (industry) => industry.id === +initialValue?.industry
                    )?.name ?? ''
                  }
                  options={
                    industries?.map((e) => ({
                      id: e?.id,
                      title: e?.name
                    })) || []
                  }
                  show_location={false}
                  show_arrow={true}
                  placeholder={t('Industry')}
                  onChange={(e) =>
                    setInitialValue((prev: any) => ({
                      ...prev,
                      industry: String(
                        industries?.find((industry) => industry.name === e)
                          ?.id
                      )
                    }))
                  }
                  onInputChange={(e) => getIndustries(e)}
                  values={industries}
                />
              </div>
              <div className="input-container">
                <span className="label">Education</span>
                <Autocomplete
                  fieldName="education"
                  value={
                    schools?.find(
                      (school) => school.id === +initialValue?.school
                    )?.name ?? ''
                  }
                  options={
                    schools?.map((e) => ({
                      id: e?.id,
                      title: e?.name
                    })) || []
                  }
                  show_location={false}
                  show_arrow={true}
                  placeholder={t('Education')}
                  onChange={(e) =>
                    setInitialValue((prev: any) => ({
                      ...prev,
                      education: String(
                        schools?.find((industry) => industry.name === e)
                          ?.id
                      )
                    }))
                  }
                  onInputChange={(e) => getSchools(e)}
                  values={schools}
                />
              </div>
              <div className="input-container">
                <span className="label">{t('Degree Type')}</span>
                <DropDown
                  dropdownClassName="dropdown"
                  items={degreeTypes}
                  selectedValue={(e) =>
                    setInitialValue((prev: any) => ({ ...prev, degree_type: e }))
                  }
                  defaultValue={initialValue?.degree_type}
                  placeholder="Degree Type"
                />
              </div>
              <div className="input-container">
                <span className="label">{t('Computer skill')}</span>
                <Autocomplete
                  fieldName="computer_skill"
                  value={
                    skills?.find(
                      (computer_skill: IAutocompleteItem) =>
                        computer_skill.id === +initialValue?.computer_skill
                    )?.name ?? ''
                  }
                  options={
                    skills?.map((e: IAutocompleteItem) => ({
                      id: e?.id,
                      title: e?.name
                    })) || []
                  }
                  show_location={false}
                  show_arrow={true}
                  placeholder={t('Computer skill')}
                  onChange={(e) =>
                    setInitialValue((prev: any) => ({
                      ...prev,
                      computer_skill: String(
                        skills?.find(
                          (computer_skill) => computer_skill.name === e
                        )?.id
                      )
                    }))
                  }
                  onInputChange={(e) => getSkills(e)}
                  values={skills}
                />
              </div>
              <div className="input-container">
                <span className="label">
                  {t('Country, province, city')}
                </span>
                <Autocomplete
                  fieldName="country"
                  value={initialValue?.country?.display_name}
                  options={
                    cities?.map((e: any) => ({
                      id: e?.id,
                      title: e?.display_name
                    })) || []
                  }
                  show_location={true}
                  placeholder={t('Country, province, city')}
                  onChange={(e) => {
                    const newCountry = cities?.find(
                      (citie: any) => citie.display_name === e
                    );
                    if (newCountry) {
                      setInitialValue((prev: any) => ({
                        ...prev,
                        country: newCountry
                      }));
                    }
                  }}
                  onInputChange={(e) => {
                    getCities(10, e);
                  }}
                  values={cities}
                />
              </div>
              <div className="input-container">
                <span className="checkbox-label">{t('Salary')}</span>
                {getSalaryLabels(initialValue?.salary)?.map((e, i) => (
                  <Checkboxes
                    checkboxes={e}
                    onArrayChange={(e) => {
                      if (Array.isArray(e)) {
                        handleCheckbox(e[0].value ?? e[0].label, 'salary');
                      }
                    }}
                    key={i}
                  />
                ))}
              </div>
              <div className="input-container">
                <span className="checkbox-label">{t('Career level')}</span>
                {getCareerLevelLabels(initialValue?.career_level).map(
                  (e, i) => (
                    <Checkboxes
                      checkboxes={e}
                      onArrayChange={(e) => {
                        if (Array.isArray(e)) {
                          handleCheckbox(e[0].value ?? e[0].label, 'career_level');
                        }
                      }}
                      key={i}
                    />
                  )
                )}
              </div>
              <div className="input-container">
                <Multiselect
                  options={
                    languages?.map((e) => ({
                      id: e?.id,
                      title: e?.name
                    })) || []
                  }
                  show_location={false}
                  placeholder={t('Language')}
                  onInputChange={(e) => {
                    getLanguages(e ?? "");
                  }}
                  onChange={(e: any) => {
                    if (typeof e != 'string') {
                      setInitialValue({
                        ...initialValue,
                        languages: [...e]
                      });
                    }
                  }}
                  value={initialValue?.languages}
                  fieldName="language"
                />
                <div className="languages-container">
                  {initialValue?.languages.map((e: any) => (
                    <div key={e.id} className="language">
                      <div className="language-name">{e?.title}</div>
                      <div
                        className="remove"
                        onClick={() => handleRemoveLanguage(e)}
                      >
                        <Image name="close.svg" width={10} height={10} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <Button
                className="submit-button"
                t={t}
                title="Filter"
                onClick={handleFilter}
              />
              <div onClick={handleResetChanges} className="reset">
                {t('Reset Filters')}
              </div>
            </>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default MatchFilter;
