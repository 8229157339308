import React, { FC } from 'react';
import { IEducationSide } from 'core/interface/education-side';
import ExperienceItem from '../ExperienceItem/Index';
import { DegreeEnum } from 'core/enum/degree';
import { getDateFormatter } from 'helpers/dateConverter';

import './Index.scss';

const Education: FC<IEducationSide> = ({ t, education }): JSX.Element => {
  return (
    <div className="tab__content">
      {education.map((item) => (
        <div className="edu-cont" key={item.id}>
          <div className="tab__content__header">
            <span className="tab__content__header-title m-hidden">
              {item.study.name}
            </span>
            <span className="tab__content__header-years">
              {getDateFormatter(item.start, 'YYYY')} {item.end ? '-' : ''}{' '}
              {getDateFormatter(item.end, 'YYYY')}
            </span>
          </div>
          <div className="tab__content__desc">
            <span className="tab__content__desc-text">{item.school.name}</span>
          </div>
          <div className="tab__content__about">
            {item.degree && (
              <ExperienceItem
                icon={'bachelor.svg'}
                title={t(DegreeEnum[item.degree])}
              />
            )}
            {item.graduate && (
              <ExperienceItem icon={'graduated.svg'} title={t('graduated')} />
            )}
            {item.grade && (
              <ExperienceItem icon={'translate.svg'} title={item.grade} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Education;
