import React, { useState } from 'react';
import useLocales from 'hooks/useLocales';
import { MatchProfileService } from 'services/matchProfile/matchProfile';
import ChartComponent from 'components/RadarChart/Index';
import Image from 'components/image/Image';
import Popup from 'components/shared/Popups/Popup/Index';
import ConfirmPopup from 'components/shared/Popups/Confirm/Index';
import TalentDescription from 'components/TalentDescription/Index';
import { chartLabelTruncate } from 'helpers/chartLabelTruncate';

import './Index.scss';

const CultureCart = ({
  item,
  handleCloseOpenPopup,
  setSelectedCultureProfile
}: any): JSX.Element => {
  const { t } = useLocales();
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

  const handleOpenDeletePopup = (): void => {
    setShowDeletePopup(!showDeletePopup);
  };

  const chartData = {
    labels: item?.competency_labels,
    datasets: [
      {
        label: '',
        data: item.competency_min_scores,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }
    ]
  };

  const handleDelete = async (id: number): Promise<void> => {
    try {
      await MatchProfileService.deleteMatchProfile(id, true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="cart_block">
      <div className="cart_container">
        <div className="cart_header">
          <h1 className="cart_title">{item?.title}</h1>
          <div className="icon_container">
            <div
              className="pencil_icon"
              onClick={() => {
                handleCloseOpenPopup();
                setSelectedCultureProfile(item?.id);
              }}
            >
              <div className="pencil_circle" />
              <Image
                className="pencil"
                name="pencil.svg"
                width={13}
                height={13}
              />
            </div>
            <div className="trash_icon" onClick={handleOpenDeletePopup}>
              <div className="trash_circle" />
              <Image
                className="trash"
                name="trash.svg"
                width={10}
                height={14}
              />
            </div>
          </div>
        </div>
        <div className="cart_body">
          <ChartComponent
            data={chartData}
            labelTruncateFunction={chartLabelTruncate}
          />
          <TalentDescription bars={[item]} />
        </div>
      </div>
      {showDeletePopup && (
        <Popup title="Are you sure?" onClose={handleOpenDeletePopup}>
          <ConfirmPopup
            approveTitle="Delete"
            description="Do you really want to delete this?"
            onCancel={handleOpenDeletePopup}
            t={t}
            onApprove={() => handleDelete(item.id)}
          />
        </Popup>
      )}
    </div>
  );
};

export default CultureCart;
