import {
  availableAbout,
  availableRelocate,
  workExperience,
  monthlySalary,
  manageExperience
} from 'core/constants/preferenceConstats';
import { contractTypes } from 'core/constants/workType';
import { ICandidate } from 'core/interface/candidate';
import { IPreferencesItem } from 'core/interface/preferences';
import { t } from 'i18next';

export const setPreferenceValues = (
  candidate: ICandidate
): IPreferencesItem[] => {
  return [
    {
      title: `${t('Availability')}`,
      value: availableAbout.find(
        (item) => +item.value === +candidate.availability
      )?.to_show
    },
    {
      title: `${t('Im willing to relocate')}`,
      value: availableRelocate.find(
        (item) => +item.value === +candidate.relocation
      )?.to_show
    },
    {
      title: `${t('Career level')}`,
      value: workExperience.find((item) => +item.value === +candidate.work_exp)
        ?.to_show
    },
    {
      title: `${t('My salary expectation')}`,
      value: monthlySalary.find((item) => +item.value === +candidate.salary)
        ?.to_show
    },
    {
      title: `${t('Management experience')}`,
      value: manageExperience.find(
        (item) => +item.value === +candidate.management_exp
      )?.to_show
    },
    {
      title: `${t('My preferred location')}`,
      value:
        candidate.locations && candidate.locations[0]
          ? candidate.locations[0].display_name
          : ''
    },
    {
      title: `${t('Interested in')}`,
      value: candidate.interests?.length ? candidate.interests.join(', ') : 'NA'
    },
    {
      title: `${t('My preferred contract')}`,
      value: contractTypes
        .flatMap(
          (item) =>
            candidate.contract &&
            candidate.contract.map((contract) =>
              +item.value === +contract ? item.to_show : ''
            )
        )
        .filter(Boolean)
        .join(' / ')
    }
  ] as IPreferencesItem[];
};
