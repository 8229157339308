import React from 'react';
import Dashboard from 'app/dashboard';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core/styles';
import { isAuthorized } from 'utils/isAuthorized';
import SignIn from 'app/signIn/page';
import NotFound from 'app/404/Index';
import ContactUs from 'app/contactUs/page';
import FilterJobs from 'app/filterJobs/Index';
import SignUp from 'app/signUp/page';
import ActivationPage from 'app/activation/Index';
import Membership from 'app/membership/Index';
import MembershipInfo from 'app/membershipInfo/Index';
import { ActivateAccount } from 'app/activateAccount/Index';
import JobDetails from 'app/jobDetails/Index';
import Applications from 'app/applications/Index';
import ResetPassword from 'app/resetPassword/page';
import TermsConditions from 'app/termsConditions/page';
import PrivacyPolicy from 'app/privacyPolicy/page';
import Company from 'app/company/Index';
import CultureProfile from 'components/CultureProfile/Index';
import MatchResult from 'app/matchResult';
import { IUser } from 'core/interface/user';
import ViewProfile from 'components/ViewProfile/Index';
import CandidatePreview from 'components/shared/Preview/Index';
import AllInvitations from 'app/invitations/page';
import FindCandidate from 'app/findCanduadate/Index';
import MatchCandidate from 'app/matchCanduadate/Index';
import CareerInfo from 'components/CareerInfo';
import ConfirmNewPassword from 'components/verifyAccount';

// ----------------------------------------------------------------------

// const nprogressStyle = makeStyles((theme) => ({
//   '@global': {
//     '#nprogress': {
//       pointerEvents: 'none',
//       '& .bar': {
//         top: 0,
//         left: 0,
//         height: 2,
//         width: '100%',
//         position: 'fixed',
//         zIndex: theme.zIndex.snackbar,
//         backgroundColor: theme.palette.primary.main,
//         boxShadow: `0 0 2px ${theme.palette.primary.main}`
//       },
//       '& .peg': {
//         right: 0,
//         opacity: 1,
//         width: 100,
//         height: '100%',
//         display: 'block',
//         position: 'absolute',
//         transform: 'rotate(3deg) translate(0px, -4px)',
//         boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
//       }
//     }
//   }
// }));

// function RouteProgress(props: any) {
//   nprogressStyle();

//   NProgress.configure({
//     speed: 500,
//     showSpinner: false
//   });

//   useMemo(() => {
//     NProgress.start();
//   }, []);

//   useEffect(() => {
//     NProgress.done();
//   }, []);

//   return <Route {...props} />;
// }

export function renderRoutes(
  routes: RouteItem[] = [],
  newUserData: IUser | null
) {
  return (
    <BrowserRouter>
      <Routes>
        {(routes as any).map((route: RouteItem, idx: number) => {
          const { guard: Guard, component: Component } = route;

          return (
            <Route
              path={route.path as string}
              element={
                Guard && Component ? (
                  <Guard newUserData={newUserData}>{Component}</Guard>
                ) : (
                  Component
                )
              }
              key={'router-' + idx}
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
}

export function lang(): string {
  return window.location.pathname.split('/')[1];
}

export type RouteItem = {
  exact?: boolean;
  guard?: ({
    newUserData,
    children
  }: {
    newUserData: IUser | null;
    children: JSX.Element;
  }) => JSX.Element;
  path?: string | string[];
  component?: JSX.Element;
  layout?: () => JSX.Element;
  routes?: {
    component: JSX.Element;
    path?: string | string[];
    exact?: boolean;
  }[];
};
const routes: RouteItem[] = [
  {
    exact: true,
    path: '/dashboard',
    component: <Dashboard params={{ lng: 'en' }} />,
    guard: ({
      newUserData,
      children
    }: {
      newUserData: IUser | null;
      children: JSX.Element;
    }): any => {
      const isAuthenticated: boolean = isAuthorized();
      let isNew: boolean = true;
      let candidateId: number = -1;
      if (newUserData !== null) {
        isNew = newUserData?.is_new;
        candidateId = newUserData.candidate_id;
      }
      if (isAuthenticated && !isNew) {
        return <Navigate to={`/dashboard/candidates/${candidateId}`} />;
      } else if (isAuthenticated && isNew) {
        return <Navigate to={`/activation`} replace />;
      } else if (!isAuthenticated && !isNew) {
        return <Navigate to={`/login`} replace />;
      }
    }
  },
  {
    exact: true,
    path: '/dashboard/candidates/:id',
    component: <Dashboard params={{ lng: 'en' }} />,
    guard: ({
      newUserData,
      children
    }: {
      newUserData: IUser | null;
      children: JSX.Element;
    }): any => {
      const isAuthenticated: boolean = isAuthorized();
      let isNew: boolean = true;
      if (newUserData !== null) {
        isNew = newUserData?.is_new;
      }
      if (isAuthenticated && !isNew) {
        return children;
      } else if (isAuthenticated && isNew) {
        return <Navigate to={`/activation`} replace />;
      } else if (!isAuthenticated && !isNew) {
        return <Navigate to={`/login`} replace />;
      }
    }
  },
  {
    exact: true,
    path: '/login',
    component: <SignIn />,
    guard: ({ children }) => {
      const isAuthenticated: boolean = isAuthorized();
      return isAuthenticated ? (
        <Navigate to={`/dashboard`} replace />
      ) : (
        <>{children}</>
      );
    }
  },
  {
    exact: true,
    path: '/signUp',
    component: <SignUp />,
    guard: ({ children }) => {
      const isAuthenticated: boolean = isAuthorized();
      return isAuthenticated ? (
        <Navigate to={`/dashboard`} replace />
      ) : (
        <>{children}</>
      );
    }
  },
  {
    exact: true,
    path: '/contactUs',
    component: <ContactUs />
  },
  {
    exact: true,
    path: '/filter-job/:id?',
    component: <FilterJobs />
  },
  {
    exact: true,
    path: '/invitations/',
    component: <AllInvitations />
  },
  {
    exact: true,
    path: '/job/:id',
    component: <JobDetails />
  },
  {
    exact: true,
    path: '/applications',
    component: <Applications />
  },
  {
    exact: true,
    path: '/reset-password',
    component: <ResetPassword />
  },
  {
    path: '/404',
    component: <Navigate to={`/dashboard`} replace />
  },
  {
    exact: true,
    path: '/',
    component: <Navigate to="/login" replace />
  },
  {
    path: '*',
    component: <NotFound params={{ lng: 'en' }} />
  },
  {
    path: 'membership',
    component: <Membership />
  },
  {
    path: 'activate-account',
    component: <ActivateAccount />
  },
  {
    path: 'membership-pricing',
    component: <MembershipInfo />
  },
  {
    path: 'activation',
    component: <ActivationPage />,
    guard: ({ newUserData, children }) => {
      const isAuthenticated: boolean = isAuthorized();
      return newUserData === null ? (
        <></>
      ) : isAuthenticated && newUserData && newUserData.is_new ? (
        <>{children}</>
      ) : (
        <Navigate
          to={
            newUserData?.company || newUserData?.contact_job_title
              ? '/company'
              : `/dashboard`
          }
          replace
        />
      );
    }
  },
  {
    exact: true,
    path: '/terms-conditions',
    component: <TermsConditions />
  },
  {
    exact: true,
    path: '/privacy-policy',
    component: <PrivacyPolicy />
  },
  {
    exact: true,
    path: '/company',
    component: <Company />,
    guard: ({
      newUserData,
      children
    }: {
      newUserData: IUser | null;
      children: JSX.Element;
    }): any => {
      const isAuthenticated: boolean = isAuthorized();
      let isNew: boolean = true;
      let candidateId: number = -1;
      if (newUserData !== null) {
        isNew = newUserData?.is_new;
        candidateId = newUserData.candidate_id;
      }

      if (isAuthenticated && !isNew) {
        return children;
      } else if (isAuthenticated && isNew) {
        return <Navigate to={`/activation`} replace />;
      } else if (!isAuthenticated && !isNew) {
        return <Navigate to={`/login`} replace />;
      }
    }
  },
  {
    exact: true,
    path: '/company/:id',
    component: <Company />
  },
  {
    exact: true,
    path: '/company/career/:id/:candidate_Id',
    component: <CareerInfo />
  },
  {
    exact: true,
    path: 'auth/verify-new-password',
    component: <ConfirmNewPassword />
  },
  {
    exact: true,
    path: '/culture',
    component: <CultureProfile />
  },
  {
    exact: true,
    path: '/match-result/:type/:id',
    component: <MatchResult />
  },
  {
    exact: true,
    path: '/find-candidate/:id',
    component: <FindCandidate />
  },
  {
    exact: true,
    path: '/match-candidate/:id',
    component: <MatchCandidate />
  },
  {
    exact: true,
    path: '/preview/:id',
    component: <CandidatePreview params={{ lng: 'en' }} />,
    guard: ({
      newUserData,
      children
    }: {
      newUserData: IUser | null;
      children: JSX.Element;
    }): any => {
      const isAuthenticated: boolean = isAuthorized();
      let isNew: boolean = true;
      if (newUserData !== null) {
        isNew = newUserData?.is_new;
      }
      if (isAuthenticated) {
        return children;
      }
    }
  },
  {
    exact: true,
    path: '/view-profile/:id',
    component: <ViewProfile params={{ lng: 'en' }} />,
    guard: ({
      newUserData,
      children
    }: {
      newUserData: IUser | null;
      children: JSX.Element;
    }): any => {
      const isAuthenticated: boolean = isAuthorized();
      let isNew: boolean = true;
      if (newUserData !== null) {
        isNew = newUserData?.is_new;
      }
      if (isAuthenticated) {
        return children;
      }
    }
  }
];

export default routes;
