import { store } from '../../redux/store';
import ApiBase from 'services/base/apiBase';
import { prepareUpdatedData } from '../../helpers/stateChanges';
import { SaveInvitations, SetFilter } from '../../redux/slices/invitations';
import {
  InvitationList,
  Invitation,
  InvitationDetail,
  Filters
} from 'core/interface/invitations';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';

export class InvitationsService {
  private static readonly baseApi = new ApiBase<InvitationList>();

  public static async getInvitations(params: Filters): Promise<void> {
    try {
      const { dispatch } = store;
      const res = await this.baseApi.getAsync(`/invitations/`, params);
      dispatch(SaveInvitations(res));
      return res;
    } catch (error) {
      console.error('Error in getInvitations:', error);
    }
  }

  public static async setInvitations(params: any): Promise<any> {
    const { dispatch } = store;
    try {
      const res = await this.baseApi.postAsync(`/invitations/`, params);
      const updatedState = prepareUpdatedData(
        'create',
        'invitations',
        res,
        'invitations'
      );
      dispatch(SaveInvitations(updatedState));
      if (res) {
        enqueueSnackbar(t('Thank you! Your invitation has been sent successfully!'), {
          variant: 'success'
        });
      }
      return res;
    } catch (error) {
      console.log('Error in setInvitations:', error);
    }
  }

  public static async resendInvitation(
    id: number
  ): Promise<InvitationDetail | void> {
    try {
      return await this.baseApi.postAsync(
        `/invitations/${id}/resend_invitation_email/`,
        {}
      );
    } catch (error) {
      console.error('Error in resendInvitation:', error);
    }
  }

  public static async deleteInvitation(id: number): Promise<boolean | void> {
    try {
      const { dispatch } = store;
      const res = await this.baseApi.deleteAsync(`/invitations/${id}/`);
      const updatedState = prepareUpdatedData(
        'delete',
        'invitations',
        {
          id: id
        },
        'invitations'
      );

      if (!updatedState?.results?.length && updatedState?.count) {
        dispatch(SetFilter('page', updatedState?.filters?.page - 1));
      }
      dispatch(SaveInvitations(updatedState));
      return res;
    } catch (error) {
      console.error('Error in deleteInvitation:', error);
    }
  }
}
