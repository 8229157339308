import React, { useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { IActionvationWhoForm } from 'core/interface/activation';
import FormField from 'components/FormField/Index';
import { InputType } from 'core/enum/inputType';
import Button from 'components/Button/Index';
import Activation from 'components/feature/activation/Index';
import ImagePicker from 'components/imagePicker';
import useLocales from 'hooks/useLocales';
import { validationSchema } from './form';
import { isValidURL } from 'helpers/isValidURL';

import './Index.scss';

const CreateCareer: any = ({
  onSubmit,
  onClose,
  setCompanyData,
  companyData,
  isPopup = false,
  className,
  isOneBtn = false,
  showTopSide,
  setNewData,
  showTitle
}: any): JSX.Element => {
  const { t } = useLocales();
  const [companyLogo, setCompanyLogo] = useState<any>(
    companyData?.logo ? companyData?.logo : null
  );
  const [mainLogo, setMainLogo] = useState<any>(
    companyData?.hero_img ? companyData?.hero_img : null
  );
  const [formValues, _] = useState<any>({
    title: companyData?.title || '',
    about_us: companyData?.about_us || ''
  });

  const handleSubmit = (
    values: IActionvationWhoForm,
    actions: FormikHelpers<IActionvationWhoForm>
  ) => {
    if (setCompanyData) {
      setCompanyData((prev: any) => ({
        ...prev,
        logo: companyLogo,
        hero_img: mainLogo,
        ...values
      }));
    } else {
      const data = {
        ...values,
        ...(!isValidURL(companyLogo) ? { logo: companyLogo } : {}),
        ...(!isValidURL(mainLogo) ? { hero_img: mainLogo } : {})
      };
      setNewData(data);
    }
    onSubmit && onSubmit();
  };

  return (
    <div className="who">
      <Activation
        showTopSide={showTopSide}
        title="Create career page"
        currentStep={1}
        className={
          isPopup ? '' : `activation-edu ${className ?? ''} ${className}`
        }
        headerIconDisable
        totalSteps={8}
        isCareerPage={true}
        handleModalClose={onClose}
        isPopup={true}
        showTitle={showTitle}
      >
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={validationSchema(t)}
        >
          {({
            setFieldValue,
            isValid,
            values,
            errors,
            touched,
            setFieldTouched
          }) => {
            return (
              <Form>
                <div className="container scrollable ">
                  <div className="fields">
                    <span className="label">Title</span>
                    <FormField
                      inputClassName="input"
                      fieldName="title"
                      placeholder="Text"
                      type={InputType.text}
                    />
                  </div>
                  <div className="fields">
                    <span className="label">About us</span>
                    <FormField
                      inputClassName="input"
                      fieldName="about_us"
                      placeholder="Text"
                      type={InputType.text}
                    />
                  </div>
                  <div className="imagePickerContainer">
                    <div>
                      <span className="label">Company logo</span>
                      <ImagePicker
                        setLogo={setCompanyLogo}
                        logo={companyLogo}
                        max={1}
                      />
                    </div>
                    <div>
                      <span className="label">Main photo</span>
                      <ImagePicker
                        setLogo={setMainLogo}
                        logo={mainLogo}
                        max={1}
                      />
                    </div>
                  </div>
                  {!isOneBtn ? (
                    <Button
                      className={`next ${!isValid ? 'invalid' : `${isValid}`}`}
                      type="submit"
                      title="Next"
                      t={t}
                    />
                  ) : (
                    <Button
                      className={`next button ${
                        !isValid ? 'invalid-btn' : ''
                      } ${isOneBtn ? 'one-btn' : ''}`}
                      type="submit"
                      t={t}
                      title={'Save'}
                    />
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Activation>
    </div>
  );
};

export default CreateCareer;
