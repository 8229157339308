import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { ICompanyInfo } from 'core/interface/matchProfile';


const initialValues: ICompanyInfo = {
  industry: '',
  name: '',
};

const validationSchema = (t: TFunction) => {
  return Yup.object({
    industry: Yup.string().required(t('required')),
    name: Yup.string().required(t('required'))
  });
};

export { validationSchema, initialValues };
