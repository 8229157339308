import React, { FC, useEffect, useState } from 'react';
import { RootState } from 'redux/store';
import ContentBlock from 'components/ContentBlock/Index';
import Preferences from 'components/shared/Preferences/Index';
import CompetencyScores from 'components/shared/competencyScores/Index';
import { ITranslate } from 'core/interface/translate';

import Popup from 'components/shared/Popups/Popup/Index';
import PreferencesPopup from 'components/shared/Popups/Preferences/Index';
import { CompetencieService } from 'services/competencies/competencies';
import { ICompetencyScoresItems } from 'core/interface/competencyScores';
import { useSelector } from 'react-redux';
import { IPreferencesItem } from 'core/interface/preferences';
import { setPreferenceValues } from './candidatePreferences';

import './Index.scss';

const CompenecyPreferencesTab: FC<ITranslate> | any = ({
  t,
  fromGuest
}: any): JSX.Element => {
  const { candidates } = useSelector((state: RootState) => state.candidates);
  const [showPreference, setShowPreference] = useState<boolean>(false);
  const [preferenceItems, setPreferenceItems] = useState<IPreferencesItem[]>(
    []
  );
  useEffect(() => {
    getCompetencies();
  }, []);

  useEffect(() => {
    setPreferenceItems(setPreferenceValues(candidates));
  }, [candidates]);

  const editPreference = (): void => {
    setShowPreference(true);
  };
  const handleClose = (): void => {
    setShowPreference(false);
  };

  const getCompetencies = (): void => {
    CompetencieService.getCompetencies();
  };

  return (
    <div className="tab">
      <ContentBlock
        title={t('preferences')}
        className="tab__pref"
        onActionClick={editPreference}
        showButton={fromGuest ? false : true}
      >
        <Preferences preferences={preferenceItems} />
      </ContentBlock>
      <ContentBlock
        className="tab__comp"
        title={t('competency_scores')}
        showButton={false}
      >
        <CompetencyScores />
      </ContentBlock>
      {showPreference && (
        <Popup title="Preferences" onClose={handleClose}>
          <PreferencesPopup handleClose={handleClose} t={t} />
        </Popup>
      )}
    </div>
  );
};

export default CompenecyPreferencesTab;
