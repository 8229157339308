import React, { FC, useState, useEffect, useRef } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { OptionType } from 'core/interface/autocomplete';
import Image from 'components/image/Image';

import './Index.scss';

const SingleSelect: FC<any> = ({
  options,
  placeholder,
  className,
  onChange,
  onInputChange,
  value,
  onBlur
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const multiselectRef = useRef<HTMLDivElement>(null);

  const toggleArrow = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetElement = event.target as Element;
      if (
        multiselectRef.current &&
        !multiselectRef.current.contains(targetElement) &&
        !targetElement.classList.contains('MuiAutocomplete-option')
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [multiselectRef]);


  return (
    <div className="multi" ref={multiselectRef}>
      <Autocomplete
        className={`multisel customAutocomplete ${className}`}
        freeSolo
        open={isOpen}
        onOpen={toggleArrow}
        onClose={toggleArrow}
        options={options}
        clearIcon={false}
        value={value}
        ListboxProps={{
          className: 'scrollable'
        }}
        getOptionLabel={(option) => (option as OptionType).title ?? ''}
        onInputChange={(e) =>
          onInputChange && onInputChange((e?.target as HTMLInputElement)?.value)
        }
        onChange={(_, value) =>
          onChange && onChange(value as string | OptionType[])
        }
        renderInput={(params) => (
          <TextField key={params.id} {...params} placeholder={placeholder} />
        )}
        onBlur={(e) => onBlur && onBlur(e)}
      />
      <span onClick={() => setIsOpen(true)}>
        <Image
          className={`arrow-down ${isOpen ? 'active' : ''}`}
          name="down-arrow.svg"
        />
      </span>
    </div>
  );
};

export default SingleSelect;
