import React, { FC, useEffect } from 'react';
import { getDateFormatter } from 'helpers/dateConverter';
import { AccountServce } from 'services/upload/file-upload';
import Activation from '../Index';
import Image from 'components/image/Image';
import Button from 'components/Button/Index';
import { IMe } from 'core/interface/me';
import { IAuthSteps } from 'core/interface/authSteps';
import { useSelector } from 'react-redux';
import { ActivationPageEnum } from 'core/enum/activationPage';
import { Degrees } from 'core/constants/degreeConstants';
import { EducationService } from 'services/education/educationService';

import './Index.scss';

const EducationView: FC<IAuthSteps> = ({ t, onClick }): JSX.Element => {
  const me = useSelector((state: IMe) => state.user).user;
  const { educations } = useSelector((state: any) => state.educations);

  useEffect(() => {
    if (me && me?.candidate_id) {
      getEducations(me.candidate_id);
    }
  }, [me]);

  const getEducations = (candidate_id: number): void => {
    EducationService.getEducations(candidate_id);
  };
  const handleInfoChange = async () => {
    const body = {
      is_new: false
    };
    const formData = new FormData();
    for (const key in body) {
      if (key !== 'id') {
        formData.append(key, (body as any)[key]);
      }
    }
    try {
      onClick(ActivationPageEnum.CONGRATS);
      await AccountServce.updateAccount(me.id, formData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="education">
      <Activation title="What is your education" currentStep={4}>
        <>
          <div className="blocks">
            {educations.map((edu: any) => (
              <div key={edu.id} className="blocks__block">
                <div className="blocks__block__header">
                  <span className="blocks__block__header-title">
                    {edu.study.name}
                  </span>
                  <span className="blocks__block__header-years">
                    {getDateFormatter(edu.start, 'YYYY')}
                    {edu.end ? '-' : ''}
                    {getDateFormatter(edu.end, 'YYYY')}
                  </span>
                </div>
                <div className="blocks__block__cont">
                  <span className="blocks__block__cont-sub">
                    {edu.school.name}
                  </span>
                  <div className="blocks__block__cont__actions">
                    <div className="blocks__block__cont__actions-action">
                      <span className="blocks__block__cont__actions-action__img">
                        <Image name="bachelor.svg" width={12} height={12} />
                      </span>
                      <span className="blocks__block__cont__actions-action__title">
                        {
                          Degrees.find((deg) => deg.value === edu.degree)
                            ?.to_show
                        }
                      </span>
                    </div>
                    <div className="blocks__block__cont__actions-action">
                      <span className="blocks__block__cont__actions-action__img">
                        <Image name="translate.svg" width={12} height={12} />
                      </span>
                      <span className="blocks__block__cont__actions-action__title">
                        {edu.grade}
                      </span>
                    </div>
                    {edu.graduate && (
                      <div className="blocks__block__cont__actions-action">
                        <span className="blocks__block__cont__actions-action__img">
                          <Image name="graduated.svg" width={12} height={12} />
                        </span>
                        <span className="blocks__block__cont__actions-action__title">
                          Gradueted
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="buttons">
            <Button
              className="back button"
              t={t}
              title="add_training"
              onClick={() => onClick(ActivationPageEnum.EDUCATION)}
            />
            <Button
              className="next button"
              t={t}
              title="Next"
              onClick={handleInfoChange}
            />
          </div>
        </>
      </Activation>
    </div>
  );
};

export default EducationView;
