import React, { FC } from 'react';
import Image from 'components/image/Image';

import './Index.scss';

const Footer: FC = (): JSX.Element => {
  return (
    <div className="footer">
      <div className="footer__left">
        <span className="footer__left-item">© Recrout 2024</span>
        <a href="/privacy-policy" className="footer__left-item">
          Privacy statement
        </a>
        <a href="/terms-conditions" className="footer__left-item">
          General terms
        </a>
        <a href="contactUs" className="footer__left-item">
          Contact
        </a>
      </div>
      <div className="footer__right">
        <a
          href="https://www.linkedin.com/company/recrout/"
          target="blank"
          className="footer__right-item"
        >
          <Image name="linkedin1.svg" width={31} height={31} />
        </a>
        <a
          href="https://www.facebook.com/recrout/"
          target="blank"
          className="footer__right-item"
        >
          <Image name="facebook1.svg" width={31} height={31} />
        </a>
        <a
          href="https://www.twitter.com/recrout/"
          target="blank"
          className="footer__right-item"
        >
          <Image name="twitter1.svg" width={31} height={31} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
