import { ISingleMessage } from 'core/interface/message';

const singleMessages: ISingleMessage[] = [
  {
    message: 'Yes, je sollicatie op',
    subtitle: 'Assistent account is geaccepteerd!',
    date: '17.08.2023'
  },
  {
    message: 'Yes, je sollicatie op',
    subtitle: 'Assistent account is geaccepteerd!',
    date: '17.08.2023'
  },
  {
    message: 'Yes, je sollicatie op',
    subtitle: 'Assistent account is geaccepteerd!',
    date: '17.08.2023'
  },
  {
    message: 'Yes, je sollicatie op',
    subtitle: 'Assistent account is geaccepteerd!',
    date: '17.08.2023'
  },
  {
    message: 'Yes, je sollicatie op',
    subtitle: 'Assistent account is geaccepteerd!',
    date: '17.08.2023'
  }
];

export { singleMessages };
