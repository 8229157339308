import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import Image from 'components/image/Image'
import { organizationalCulture } from './mockData'
import Popup from 'components/shared/Popups/Popup/Index'
import OrganizationCulture from 'components/feature/organizationCulture'
import { CMSService } from 'services/cms/cms'
import { IMe } from 'core/interface/me'
import { callForAllElement } from 'helpers/callAll'
import { useParams } from 'react-router'

function Organization() {
  const [openModal, setOpenModal] = useState(false)
  const { cms } = useSelector((state: RootState) => state.cms)
  const me = useSelector((state: IMe) => state.user).user;
  const { id } = useParams();

  const handleModal = () => {
    setOpenModal(!openModal)
  }
  
  const handleChange = (companyData: any) => {
    callForAllElement(companyData, (image) => {
      const formData = new FormData();
      formData.append("image", image);
      return CMSService.updateCMSGallery(me?.company, formData, "gallery")
    });
    handleModal()
  }


  return (
    <div>
      <div className="organizationalContainer">
        <div className="organizationalContainer_first">
          <h1>Our organizational culture</h1>
          {!id && <div className="pencil_icon" onClick={handleModal}>
            <div className="pencil_circle" />
            <Image
              className="pencil"
              name="pencil.svg"
              width={13}
              height={13}
            />
          </div>}
        </div>
        <div className="organizationalContainer_second">
          {cms?.gallery?.map((item: any, index: number) => {
            const size = organizationalCulture[index % organizationalCulture.length];
            return (
              <Image
                key={item.image}
                url={item.image}
                className="align"
                width={size.width}
                height={size.height}
              />
            );
          })}
        </div>
      </div>
      {openModal && (
        <Popup title="Create career page" className="expertise" onClose={handleModal}>
          < OrganizationCulture
            companyData={cms}
            isOneBtn={true}
            setNewData={handleChange}
            onClose={handleModal}
            showTitle
            showTopSide={false}
            isPopup={true} />
            
        </Popup>
      )
      }
    </div>
  )
}

export default Organization

