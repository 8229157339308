import React, { FC } from 'react';
import Image from 'components/image/Image';
import { IActivationPage } from 'core/interface/activation';
import Steps from 'components/shared/Step/Index';

import './Index.scss';

const Activation: FC<IActivationPage> = ({
  title,
  children,
  sub_title,
  currentStep,
  totalSteps = 5,
  className,
  showTopSide = true,
  isPopup = false,
  headerIconDisable,
  isCareerPage,
  handleModalClose,
  showTitle
}): JSX.Element => {
  return (
    <div
      className={
        currentStep == 5
          ? 'scrollable'
          : headerIconDisable
            ? 'fromCareer'
            : isPopup
              ? ''
              : `activation ${className ?? ''}`
      }
    >
      <div className="activation__body">
        {isCareerPage && (
          <span
            className="popup__content__main-close"
            onClick={handleModalClose}
          >
            <Image name="close.svg" width={22} height={22} />
          </span>
        )}
        {showTopSide && (
          <>
            {!headerIconDisable && (
              <Image
                className="activation__body-logo"
                name="new-rec-logos.svg"
                width={210}
                height={91}
              />
            )}
            <span className="activation__body-title">{title}</span>
            {currentStep && (
              <div className="activation__body-step">
                <Steps totalSteps={totalSteps} currentStep={currentStep} />
              </div>
            )}
            {sub_title && (
              <span className="activation__body-sub">{sub_title}</span>
            )}
          </>
        )}
        {showTitle && <span className="activation__body-title">{title}</span>}

        {children && <div className="activation__body-content">{children}</div>}
      </div>
    </div>
  );
};

export default Activation;
