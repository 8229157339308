import RadarChartComp from '../RadarChart/RadarChartComp';

function CompareChart({ names, minScores, descriptions, comparePoints, title, className, percent }: any) {
  return (
    <div className={`competenciesb ${className || ''}`}>
        <div className="competenciesb__top">
          <div className="competenciesb__top__left">
            <span className="competenciesb__top__left-title">{title}</span>
            {percent && (
                <span className="competenciesb__top__left-percent">{isNaN(Number(percent)) ? 0 : percent}%</span>
          )}
          </div>
          <div className="competenciesb__top__right">
            <div className="competenciesb__top__right__item">
              <span className="competenciesb__top__right__item-dot"></span>
              <span className="competenciesb__top__right__item-title">Job</span>
            </div>
            <div className="competenciesb__top__right__item">
              <span className="competenciesb__top__right__item-dot purple"></span>
              <span className="competenciesb__top__right__item-title">Candidate</span>
            </div>
          </div>
        </div>
        <RadarChartComp
          boxSx={{ width: '100%' }}
          labels={names}
          points={minScores || []}
          descriptions={descriptions}
          comparePoints={comparePoints}
          assessment={true}
          chartHeight={500}
          chartWidth={500}
        />
    </div>
  );
}

export default CompareChart;
