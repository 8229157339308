import { IDropDownItem } from '../interface/dropDown';
import { IRadioButtonItem } from '../interface/radioButton';

const languageValues = [
  {
    value: 'English',
    to_show: 'English'
  },
  {
    value: 'Dutch',
    to_show: 'Dutch'
  }
];
const phoneValues = [
  {
    value: '+32',
    to_show: '+32'
  },
  {
    value: '+31',
    to_show: '+31'
  }
];

const days = [
  { value: '1', to_show: '1' },
  { value: '2', to_show: '2' },
  { value: '3', to_show: '3' },
  { value: '4', to_show: '4' },
  { value: '5', to_show: '5' },
  { value: '6', to_show: '6' },
  { value: '7', to_show: '7' },
  { value: '8', to_show: '8' },
  { value: '9', to_show: '9' },
  { value: '10', to_show: '10' },
  { value: '11', to_show: '11' },
  { value: '12', to_show: '12' },
  { value: '13', to_show: '13' },
  { value: '14', to_show: '14' },
  { value: '15', to_show: '15' },
  { value: '16', to_show: '16' },
  { value: '17', to_show: '17' },
  { value: '18', to_show: '18' },
  { value: '19', to_show: '19' },
  { value: '20', to_show: '20' },
  { value: '21', to_show: '21' },
  { value: '22', to_show: '22' },
  { value: '23', to_show: '23' },
  { value: '24', to_show: '24' },
  { value: '25', to_show: '25' },
  { value: '26', to_show: '26' },
  { value: '27', to_show: '27' },
  { value: '28', to_show: '28' },
  { value: '29', to_show: '29' },
  { value: '30', to_show: '30' },
  { value: '31', to_show: '31' }
];
const months = [
  { value: '01', to_show: 'January' },
  { value: '02', to_show: 'February' },
  { value: '03', to_show: 'March' },
  { value: '04', to_show: 'April' },
  { value: '05', to_show: 'May' },
  { value: '06', to_show: 'June' },
  { value: '07', to_show: 'July' },
  { value: '08', to_show: 'August' },
  { value: '09', to_show: 'September' },
  { value: '10', to_show: 'October' },
  { value: '11', to_show: 'November' },
  { value: '12', to_show: 'December' }
];
const years: IDropDownItem[] = [];

for (let year = new Date().getFullYear(); year >= 1900; year--) {
  years.push({
    value: String(year),
    to_show: String(year)
  });
}

const radioButtons: IRadioButtonItem[] = [
  {
    label: 'Male',
    value: '1'
  },
  {
    label: 'Female',
    value: '2'
  }
];
const socialNetwork: IDropDownItem[] = [
  { value: 'linkedin', to_show: 'Linkedin' },
  { value: 'facebook', to_show: 'Facebook' },
  { value: 'twitter', to_show: 'Twitter' },
  { value: 'instagram', to_show: 'Instagram' },
  { value: 'youtube', to_show: 'YouTube' },
  { value: 'tiktok', to_show: 'TikTok' },
  { value: 'pinterest', to_show: 'Pinterest' },
  { value: 'snapchat', to_show: 'Snapchat' },
  { value: 'google_plus', to_show: 'Google+' }
];

const socialNetworkCareer: IDropDownItem[] = [
  { value: 'linkedin', to_show: 'Linkedin' },
  { value: 'facebook', to_show: 'Facebook' },
  { value: 'twitter', to_show: 'Twitter' },
  { value: 'instagram', to_show: 'Instagram' },
  { value: 'youtube', to_show: 'YouTube' },
];

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  {
    title: 'The Lord of the Rings: The Return of the King',
    year: 2003
  },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  {
    title: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001
  },
  {
    title: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980
  },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  {
    title: 'The Lord of the Rings: The Two Towers',
    year: 2002
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  {
    title: 'Star Wars: Episode IV - A New Hope',
    year: 1977
  },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  {
    title:
      'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964
  },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  {
    title: 'Star Wars: Episode VI - Return of the Jedi',
    year: 1983
  },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  {
    title: 'Eternal Sunshine of the Spotless Mind',
    year: 2004
  },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 }
];

const degreeTypes: IDropDownItem[] = [
  { value: 1, to_show: 'Certificate' },
  { value: 2, to_show: 'Bachelor' },
  { value: 3, to_show: 'Master' },
  { value: 4, to_show: 'PhD' }
];

const statusTypes: IDropDownItem[] = [
  { value: "draft", to_show: 'Draft' },
  { value: "published", to_show: 'Published' },
  { value: "closed", to_show: 'Closed' },
];

const socialMedia: IDropDownItem[] = [
  { value: 1, to_show: 'Facebook' },
  { value: 2, to_show: 'Linkedin' },
  { value: 3, to_show: 'Twitter' }
];

const jobType: IDropDownItem[] = [
  { value: 1, to_show: 'Full time' },
  { value: 2, to_show: 'Part time' }
];

export {
  languageValues,
  phoneValues,
  days,
  months,
  years,
  radioButtons,
  socialNetwork,
  top100Films,
  degreeTypes,
  socialMedia,
  jobType,
  socialNetworkCareer,
  statusTypes
};
