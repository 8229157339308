import { SocialMediaType } from 'core/interface/socialMediaExpertise';
import { store } from '../../redux/store';
import ApiBase from 'services/base/apiBase';
import { SaveMediaExpertise } from '../../redux/slices/socialMediaExpertise';
import { prepareUpdatedData } from '../../helpers/stateChanges';

export class SocialMediaExpertiseService {
  private static apiBase = new ApiBase();

  public static async getSocialMediaExpertise(
    candidate_id: number
  ): Promise<SocialMediaType[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        `/candidates/${candidate_id}/social-media-expertises/`
      );
      dispatch(SaveMediaExpertise(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  public static async removeSocialMediaExpertise(
    candidate_id: number,
    expertise_id: number
  ): Promise<boolean | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.deleteAsync(
        `/candidates/${candidate_id}/social-media-expertises/${expertise_id}/`
      );
      const updatedState = prepareUpdatedData(
        'delete',
        'socialMediaExpertise',
        { id: expertise_id },
        'socialMedia'
      );
      dispatch(SaveMediaExpertise(updatedState));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
