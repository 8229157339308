import {
  INotificationBody,
  INotificationData
} from 'core/interface/notificationCard';
import { store } from '../../redux/store';
import ApiBase from 'services/base/apiBase';
import {
  Savenotifications,
  SavenotificationsAll,
  SavenotificationsCount
} from '../../redux/slices/notification';

export class NotificationService {
  private static readonly apiBase = new ApiBase();

  public static async getAllNotifications(): Promise<
    INotificationData[] | null
  > {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync('/notifications/all/');
      dispatch(SavenotificationsAll(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async getUnreadNotifications(): Promise<
    INotificationData[] | null
  > {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync('/notifications/unread/');
      dispatch(Savenotifications(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async getAllReadNotifications(): Promise<INotificationData[]> {
    return await this.apiBase.getAsync('/notifications/mark-all-as-read/');
  }

  public static async markAsRead(slug: number): Promise<INotificationData[]> {
    return await this.apiBase.getAsync(`/notifications/mark-as-read/${slug}/`);
  }

  public static async markAsUnread(slug: number): Promise<INotificationData[]> {
    return await this.apiBase.getAsync(
      `/notifications/mark-as-unread/${slug}/`
    );
  }

  public static async getUnreadCount(): Promise<INotificationData[] | null> {
    const { dispatch } = store;
    try {
      const res = await this.apiBase.getAsync(
        '/notifications/api/unread_count/'
      );
      dispatch(SavenotificationsCount(res));
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public static async getAllCount(): Promise<INotificationData[]> {
    return await this.apiBase.getAsync('/notifications/api/all_count/');
  }
  public static async addNotification(
    notification: INotificationBody
  ): Promise<INotificationData[]> {
    return await this.apiBase.postAsync('/notifications/add/', notification);
  }
}
