import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { fetchImageAndConvertToBase64 } from 'utils/fetchImageAndConvertToBase64';

import './Index.scss';

const PdfHeader: FC<{ isAnonym: boolean }> = ({ isAnonym = false }): JSX.Element => {
  const { candidate } = useSelector((state: RootState) => state.candidate);

  const [image, setImage] = useState<string>('');

  const getImage = async (url: string) => {
    const data = await fetchImageAndConvertToBase64(url);

    if (data) {
      setImage(data);
    }
  };

  useEffect(() => {
    if (candidate?.avatar) {
      getImage(candidate.avatar);
    }
  }, [candidate]);

  return (
    <div className="c-header">
      <img
        style={{
          borderRadius: '50%'
        }}
        width={100}
        height={100}
        src={isAnonym ? '/static/images/recrout-logo-letter.jpg' : image}
        alt=""
      />
      <div className="name-surname">
        <h1 className="name">{isAnonym ? 'Anonymous' : candidate?.name}</h1>
        <h2 className="role">{isAnonym ? 'Candidate' : candidate?.email}</h2>
      </div>
    </div>
  );
};

export default PdfHeader;
