import React, { FC, useEffect } from 'react';
import ExperienceItem from '../ExperienceItem/Index';
import {
  IWorkExperienceSide,
  IWorkExperiences
} from 'core/interface/work-experience-side';
import { ContractEnum } from 'core/enum/contract';
import { getDateFormatter } from 'helpers/dateConverter';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import './Index.scss';

const Experience: FC<IWorkExperienceSide> = ({ t }): JSX.Element => {
  const { experience } = useSelector(
    (state: RootState) => state.workExperiences
  );

  console.log(experience, 999);
  

  return (
    <div className="tab__content">
      {experience?.map((item: IWorkExperiences) => (
        <div className="tab-work" key={item.id}>
          <div className="tab__content__header">
            <span className="tab__content__header-title m-hidden">
              {item.company} &nbsp; {item.title}
            </span>
            <span className="tab__content__header-years">
              {getDateFormatter(item.start, 'YYYY')} {item.end ? '-' : ''}{' '}
              {getDateFormatter(item.end, 'YYYY')}
            </span>
          </div>
          <div className="tab__content__desc">
            <span className="tab__content__desc-text">
              {item.industry.name}
            </span>
          </div>
          <div className="tab__content__about">
            {item.location && (
              <ExperienceItem
                icon="location.svg"
                title={item.location.display_name}
              />
            )}
            {item.contract && (
              <ExperienceItem
                icon="contract.svg"
                title={t(ContractEnum[item.contract])}
              />
            )}
            {item.industry && (
              <ExperienceItem
                icon="engineering.svg"
                title={item.industry.name}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Experience;
