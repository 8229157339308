import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { ICompetencies, ICompetenciesGroup } from 'core/interface/competencies';
import { CircularProgress } from '@mui/material';
import { IJobForm, IJobFormState } from 'core/interface/job';
import { CompetencieService } from 'services/competencies/competencies';
import useLocales from 'hooks/useLocales';
import Button from 'components/Button/Index';
import Popup from 'components/shared/Popups/Popup/Index';
import NewJobPopup from 'components/shared/Popups/NewJobPopup/Index';
import CompetenciesSelect from 'components/shared/CompetenciesSelect';
import MatchingProfileBlock from 'components/MatchingProfileBlock/Index';
import NoData from 'components/shared/NoData/Index';
import { JobService } from 'services/jobs/job';
import { RootState } from '../../../redux/store';

import './Index.scss';

const PostAJob: FC = (): JSX.Element => {
  const { t } = useLocales();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [ids, setIds] = useState<string[]>([]);
  const { jobs } = useSelector((state: RootState) => state.jobs);
  const [showJobIndustriesPopup, setShowJobIndustriesPopup] =
    useState<boolean>(false);
  const [firstStepData, setFirstStepData] = useState<IJobForm | number[]>();
  const [selectedJobId, setSelectedJobId] = useState<any>();
  const [selectedJob, setSelectedJob] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);


  const { assessmentByGroup } = useSelector(
    (state: RootState) => state.assessment
  );

  const handleOpenPopup = (): void => {
    setShowPopup(true);
    setSelectedJobId('');
    setSelectedJob([]);
  };

  const getAssessment = () => {
    JobService.getAssessmentByGroup();
  };

  const getApplications = (): void => {
    setLoading(true)
    setTimeout(() => {
      JobService.getJobs();
      setLoading(false)
    }, 1000);
  };

  const handleClosePopup = (): void => {
    setShowPopup(!showPopup);
  };

  const handleClosePopupMatch = (): void => {
    setShowJobIndustriesPopup(!showJobIndustriesPopup);
  };

  useEffect(() => {
    getCompetencies();
    getApplications();
    getAssessment();
  }, []);

  useEffect(() => {
    const selectedJob = jobs?.results?.find(
      (profile: any) => profile.id === selectedJobId
    );

    if (selectedJob) {
      setSelectedJob(selectedJob);
    }
  }, [jobs, selectedJobId]);

  const handleSetSelected = (label: string, id: any, text: string) => {
    const newSelected: string[] = [...selectedLabels];
    const newId: string[] = [...ids];
    const index = selectedLabels.indexOf(label);
    const indexID = ids.indexOf(id);
    if (index !== -1 && indexID !== -1) {
      newSelected.splice(index, 1);
      newId.splice(indexID, 1);
    } else if (newSelected.length < 10 && newId.length < 10) {
      newSelected.push(label);
      newId.push(id);
    } else if (newSelected.length < 7) {
      return;
    }
    setSelectedLabels(newSelected);
    setIds(newId);
  };

  const handleDelete = (id: number) => {
    JobService.deleteJobByIdOrSlug(id);
  };

  const handleNextStep = (evt?: IJobForm | number[]): void => {
    evt && setFirstStepData(evt);
    handleClosePopup();
    setShowJobIndustriesPopup(!showJobIndustriesPopup);
  };

  const handleCompetenciesNextStep = () => {
    if (selectedLabels.length >= 7 && selectedLabels.length <= 10) {
      const idLabelArray = ids.map((id, index) => ({
        id,
        competency: selectedLabels[index]
      }));

      console.log(selectedJob, selectedJob?.competency_min_scores, selectedJobId, idLabelArray, "score");
      const existingLabels = selectedJob?.competency_labels || [];
      const competencyScores = selectedJob?.competency_min_scores || [];
      const data = {
        firstStepData,
        competencies: idLabelArray,
        edit: selectedJobId ?? false,
        scores: idLabelArray.map(({ competency }) => 
          existingLabels.includes(competency) 
            ? competencyScores[existingLabels.indexOf(competency)] 
            : 0
        ),
        postAJob: true
      };
      localStorage.setItem('selected', JSON.stringify(data));
      navigate('/culture');
    }
  };

  const getCompetencies = (): void => {
    CompetencieService.getCompetencies();
  };

  const getJobApplication = (id: number) => {
    navigate(`/find-candidate/${id}`);
  };

  const getJobList = (id: number, type: string) => {
    navigate(`/match-result/${type}/${id}`);
  };

  useEffect(() => {
    if (selectedJob?.id) {
      setSelectedLabels(selectedJob?.competency_labels);
      const a = selectedJob?.competencies?.map((e: any) => e?.competency?.id);
      setIds(a);
    }
  }, [selectedJob]);

  return (
    <div className="postjob">
      <div className="postjob__header">
        <span className="postjob__header-title">Job Posts</span>
        <Button
          onClick={handleOpenPopup}
          t={t}
          title="Create New Job Post"
          className="postjob__header-btn"
        />
      </div>
      {loading ? (
        <div className="loadingContainer">
          <CircularProgress className="loading" />
        </div>
      ) : jobs?.results?.length === 0 ? (
        <NoData />
      ) : (
        <div className="postjob_blocks">
          {jobs?.results?.map((el: any) => (
            <MatchingProfileBlock
              id={el.id}
              title={el.title}
              name={''}
              date={el.last_competencies_updated_on}
              key={el.id}
              setEditPopUp={() => {
                handleClosePopup();
              }}
              setDeletedItem={handleDelete}
              setSelectedMatchProfileId={setSelectedJobId}
              getOnePost={getJobApplication}
              getList={getJobList}
              type="job-posts"
            />
          ))}
        </div>
      )}

      {showPopup && (
        <Popup
          title="Create your new job post"
          description="Please let people know what’s important for your job opening"
          onClose={handleClosePopup}
        >
          <NewJobPopup nextStep={handleNextStep} selectedJob={selectedJob} />
        </Popup>
      )}
      {showJobIndustriesPopup && (
        <Popup
          className="popup-add-new"
          title="Create your new job post"
          description="Please select the most important competencies for your job opening."
          onClose={handleClosePopupMatch}
        >
          <CompetenciesSelect
            competencies={assessmentByGroup}
            selectedList={selectedLabels}
            onSelect={handleSetSelected}
            next={handleCompetenciesNextStep}
            matchProfile={false}
          />
        </Popup>
      )}
    </div>
  );
};

export default PostAJob;
