import moment from 'moment';

export const getDateFormatter = (
  date: string | Date,
  format: string = 'YYYY-MM-DD'
) => {
  if (!date) {
    return '';
  }
  return moment(new Date(date)).format(format);
};
