import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import Image from 'components/image/Image';
import { IJobCard } from 'core/interface/jobCard';
import { JobCardActionEnum } from 'core/enum/jobCardAction';
import { JobService } from 'services/jobs/job';

import './Index.scss';

const JobCard: FC<IJobCard> = ({
  id,
  actions,
  icon,
  sub_title,
  title,
  matches,
  messageAndResponse,
  profileType
}): JSX.Element => {
  const navigate = useNavigate();
  const [disableActions, setDisableActions] = useState<boolean>(false);

  const handleActionClick = (action: string): void => {
    switch (action) {
      case JobCardActionEnum.MORE_INFO:
        navigate(`/job/${id}`);
        break;
      case JobCardActionEnum.ACCEPT:
        applyForAJob();
        break;
      case JobCardActionEnum.CANCEL:
        reject();
        break;
      case JobCardActionEnum.PROFILE_VIEW:
        navigate(`/company/${id}`);
        break;
    }
  };

  const applyForAJob = async (): Promise<void> => {
    setDisableActions(true);
    await JobService.applyForAJob(id);
    setDisableActions(false);
  };

  const reject = async (): Promise<void> => {
    const pathName = window.location.pathname;
    if (pathName !== '/filter-job') {
      await JobService.rejectApplication(id);
    }
  };

  return (
    <div className="jCard">
      <div className="jCard__left">
        <Image name={icon} url={icon} className="jCard__left-image" />
        <div className="jCard__left__info">
          <span className="jCard__left__info-title">
            {title}
            {profileType && (
              <span className="jCard__left__info-title-type">
                {profileType}
              </span>
            )}
          </span>
          <span className="jCard__left__info-sub">{sub_title}</span>
        </div>
      </div>
      <div className="jCard__middle">
        <div className="jCard__middle__percents">
          {matches &&
            matches.map((match, index) => (
              <div key={index} className="jCard__middle__matches">
                {match.percent !== 0 && (
                  <span className="jCard__middle__matches-title">
                    {match.percent}% {match.title}
                  </span>
                )}
              </div>
            ))}
        </div>

        {messageAndResponse && (
          <div className="jCard__middle__details">
            <div className="jCard__middle__details__item">
              <span className="jCard__middle__details__item-title">
                Message send on
              </span>
              <span className="jCard__middle__details__item-desc">
                Oct 18 2023, 10:40 a.m.
              </span>
            </div>
            <div className="jCard__middle__details__item">
              <span className="jCard__middle__details__item-title">
                Response on:
              </span>
              <span className="jCard__middle__details__item-desc">
                Oct 18 2023, 10:40 a.m.
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="jCard__right">
        {actions.map((action, index) => (
          <div key={index} className="jCard__right__actions">
            {action === JobCardActionEnum.ACCEPT &&
              matches?.some((e) => e.percent !== 0) && (
                <span
                  onClick={() => handleActionClick(JobCardActionEnum.ACCEPT)}
                  className={`${
                    disableActions ? 'disabled' : ''
                  } jCard__right__actions-action`}
                >
                  <Image name="accept.svg" width={45} height={45} />
                </span>
              )}
            {action === JobCardActionEnum.MORE_INFO && (
              <span
                onClick={() => handleActionClick(JobCardActionEnum.MORE_INFO)}
                className={`${
                  disableActions ? 'disabled' : ''
                } jCard__right__actions-action`}
              >
                <Image name="more_info.svg" width={45} height={45} />
              </span>
            )}
            {action === JobCardActionEnum.PROFILE_VIEW && (
              <span
                onClick={() =>
                  handleActionClick(JobCardActionEnum.PROFILE_VIEW)
                }
                className={`${
                  disableActions ? 'disabled' : ''
                } jCard__right__actions-action`}
              >
                <Image name="profile-view.svg" width={45} height={45} />
              </span>
            )}
            {action === JobCardActionEnum.ACCEPT &&
              matches?.some((e) => e.percent !== 0) && (
                <span
                  onClick={() => handleActionClick(JobCardActionEnum.CANCEL)}
                  className={`${
                    disableActions ? 'disabled' : ''
                  } jCard__right__actions-action`}
                >
                  <Image name="cancel.svg" width={45} height={45} />
                </span>
              )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobCard;
