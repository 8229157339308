import { ILanguageFormInitial } from 'core/interface/candidate-language';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

const initialValues: ILanguageFormInitial = {
  languages: [
    {
      id: 0,
      language: '',
      proficiency: 0
    }
  ]
};

const validationSchema = (t: TFunction) => {
  return Yup.object({
    languages: Yup.array(
      Yup.object().shape({
        id: Yup.number(),
        language: Yup.string().trim().required(t('required')),
        proficiency: Yup.number().default(0)
      })
    )
  });
};

export { initialValues, validationSchema };
